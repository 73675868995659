import { RpeEditResponse } from "./rpe-edit-response";

export class ConsultFileRpeEdit{

    // Campo 4 RPE
    public  icaoCompany: any;
    // Campo 59 RPE
    public  icaoAirportFlightPrevious: string;
    // Campo 60 RPE
    public  icaoAirportDestiny: string;
    // Campo 63 RPE
    public  cdTypeFlightDeparture: string;
    // Campo 62 RPE
    public  cdTypeFlightArrival: string;
    // Campo 72 RPE
    public  cdTypeLineFlightDeparture: string;
    // Campo 71 RPE
    public  cdTypeLineFlightArrival: string;
    //Demais campos
    public fligthLegDTO : RpeEditResponse;

}
