export class ParametersEdit {

    public id: number;
    public nameParameter: string;
    public parameterDescription
    public initialDate: any;
    public finalDate: any;
    public valueParameter: number;

    constructor(){
        this.id = undefined;
        this.nameParameter = undefined;
        this.parameterDescription = undefined;
        this.initialDate = undefined;
        this.finalDate = undefined;
        this.valueParameter = undefined;
    }
    
}