export class ListRpeGeneratedExcel {

    idRpe: number;
    empiata: string;
    cdNumVoo: string;
    dtVoo: any;
    dtGene: any;
    sisOrigin: string;
    destAirport: string;
    tipo: string;

    constructor(){
        this.idRpe = undefined;
        this.empiata = undefined;
        this.cdNumVoo = undefined;
        this.dtVoo = undefined;
        this.dtGene = undefined;
        this.sisOrigin = undefined;
        this.destAirport = undefined;
        this.tipo = undefined;
    }

}