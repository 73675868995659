<div>
  <form >
    <div class="card text-center">

      <div class="card-header titleModalCrud">
          <h5>Cadastro de Usuários</h5>
      </div>

      <div class="card-body">

        <div class="form-row">
          <div class="col-4 label">
            <label for="userName">Nome do Usuário: </label>
          </div>
          <div class="col-8">
            <mat-form-field class="example-full-width inputLayout">
                <input matInput id="userName" name="userName"
                     [(ngModel)]="user.name" placeholder="Nome"
                    aria-label="Nome" >
            </mat-form-field>
          </div>
        </div>

        <div class="form-row">
          <div class="col-4 label">
            <label for="userEmail">Email do Usuário: </label>
          </div>
          <div class="col-8">
            <mat-form-field class="example-full-width inputLayout">
                <input matInput id="userEmail" name="userEmail"
                    pattern="[a-zA-Z0-9.-_]{1,}@latam.com"
                    [(ngModel)]="user.email" placeholder="Email"
                    aria-label="Email" >
            </mat-form-field>
          </div>
        </div>

        <div class="form-row">
          <div class="col-4 label">
            <label for="userProfile">Perfil: </label>
          </div>
          <div class="col-8">
            <mat-form-field class ="inputLayout">
              <mat-label >Perfil</mat-label>
              <mat-select [(ngModel)]="typeProfileChoose.type"
               matInput id="userProfile" name="userProfile">
                <mat-option *ngFor="let profile of typeProfiles" [value]="profile.type">
                  {{profile.type}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

      </div>
    </div>
    <br>
    <button mat-raised-button
      matTooltip="Cancelar " class="btn btn-primary btnClean" (click)="cancelOperation()"
      >
      <mat-icon style="font-size: x-large;">cancel</mat-icon>
      Cancelar
    </button>
    <button mat-raised-button
      matTooltip="Salvar " class="btn btn-primary btnSave" (click)="saveUserProfile()"
      >
      <mat-icon style="font-size: x-large;">save</mat-icon>
      Salvar
    </button>

  </form>

</div>
