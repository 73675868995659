import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { endpoints } from '../../../environments/endpoint';
import { Retorno } from './model/retorno';

@Injectable({
  providedIn: 'root'
})
export class AppUploadFileService {

  constructor(private http: HttpClient) {}

  public upload(files: Set<File>, callback: (response: Retorno[]) => void ):
    { [key: string]: { progress: Observable<number> } } {

    // this will be the our resulting map
    const status: { [key: string]: { progress: Observable<number> } } = {};


    files.forEach(file => {
      // create a new multipart-form for every file
      const formData = new FormData();
      formData.set("file", file, file.name);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', endpoints.REST_POST_EXCEL_FILE, formData, {
        reportProgress: true
      });

      // create a new progress-subject for every file
      const progress = new Subject<number>();

      // send the http-request and subscribe for progress-updates
      this.http.request<Retorno[]>(req).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {

          // calculate the progress percentage
          const percentDone = Math.round(100 * event.loaded / event.total);

          // pass the percentage into the progress-stream
          progress.next(percentDone);
          files.clear();
        } else if (event instanceof HttpResponse) {
          // console.log(event.body);
          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.complete();

          // let data: Retorno[] = event.json;

          callback(event.body);
          files.clear();

        }
      });

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable()
      };
    });

    // return the map of progress.observables
    return status;
  }

}
