/**
 * ARQUIVO DE CONFIGURACAO DE ENDPOINTS UTILIZADOS PELA APLICACAO
 */

export const environment = {

  host: 'https://gte.appslatam.com/api',
  robotsHost: 'https://gte.appslatam.com',

  production: true,
  message: 'producción',
  api_key: 'API_KEY',
  um: '553904246302',
  dois: '-45hg2k5g7umsm91o38fhnnbcsmr5loab',
  tres: '.apps',
  quatro: '.googleusercontent',
  cinco: '.com'

};
