<br>
<div class="row card">
  <div class="text-center col-12">
    <div class="card-header">
      Lista de Indicadores
    </div>
    <div class="card-body ">
      <div class="alert alert-danger" role="alert">
        Esta lista irá exibir <b>APENAS</b> os voos que apresentaram falha durante seu processamento e <b>NÃO</b> foram
        corrigidos em tempo hábil!
      </div>
      <br>
      <table mat-table [dataSource]="dataSourceIndicadores" class="mat-elevation-z8">
        <ng-container matColumnDef="numeroVoo">
          <th mat-header-cell *matHeaderCellDef>Núm. Voo</th>
          <td mat-cell *matCellDef="let v">{{ v.flightNumberRPE }}</td>
        </ng-container>
        <ng-container matColumnDef="dataVoo">
          <th mat-header-cell *matHeaderCellDef>Data e Hora do Voo</th>
          <td mat-cell *matCellDef="let v">{{ formatDateHour(v.flightDateRPE) }}</td>
        </ng-container>
        <ng-container matColumnDef="dataGeracao">
          <th mat-header-cell *matHeaderCellDef>Dt. Erro</th>
          <td mat-cell *matCellDef="let v">
            {{ getFormatDateToString(v.errorGenerationDateRPE) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="tipoErro">
          <th mat-header-cell *matHeaderCellDef>Tipo Erro</th>
          <td matTooltipPosition="right" [matTooltip]="v.errorDescriptionRPE" mat-cell *matCellDef="let v">
            {{ v.errorTypeRPE | truncate: 10 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="tipoOrigem">
          <th mat-header-cell *matHeaderCellDef>Origem</th>
          <td mat-cell *matCellDef="let v">{{ v.originAirportCodeRPE }}</td>
        </ng-container>
        <ng-container matColumnDef="dataEnvioRpe">
          <th mat-header-cell *matHeaderCellDef>Dt. Abertura</th>
          <td mat-cell *matCellDef="let v">
            {{ v.ticketControl != null ? getFormatDateToString(v.ticketControl.dtTckStart) :  '' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="dataFimAjuste">
          <th mat-header-cell *matHeaderCellDef>Dt. Encerramento</th>
          <td mat-cell *matCellDef="let v">
            {{ v.ticketControl != null ? getFormatDateToString(v.ticketControl.dtTckEnd) : '' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ticket">
          <th mat-header-cell *matHeaderCellDef>Ticket</th>
          <td mat-cell *matCellDef="let v">{{ v.ticketControl != null ? v.ticketControl.numTck : '' }}</td>
        </ng-container>
        <ng-container matColumnDef="sisOrigin">
          <th mat-header-cell *matHeaderCellDef>Sistema Origem</th>
          <td mat-cell *matCellDef="let v">{{ v.dataSourceSystemNameRPE }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" [showFirstLastButtons]="true">
      </mat-paginator>

      <br />
      <button mat-raised-button (click)="exportIndicatorsAsXLSX()"
        style="background-color: #1b0088;border-color:#1b0088; color: #FFF;float: left;">
        <mat-icon>get_app</mat-icon>
        Exportar Excel
      </button>
      <button mat-raised-button style="background-color: #1b0088;border-color:#1b0088; color: #FFF; float: right;"
        routerLink="/dashboardOne">
        <mat-icon>arrow_back_ios</mat-icon>
        Voltar
      </button>
    </div><br><br>
  </div>
</div>