export class ListIndicatorsExcel {

    cdNumVoo: string;
    dtVoo: any;
    dtProcess: any;
    tpError: string;
    adAeropOrin: string;
    dtTckStart: any;
    dtTckEnd: any;
    numTck: number;
    sisOrigin: string;

    constructor(){
        this.cdNumVoo = undefined;
        this.dtVoo = undefined;
        this.dtProcess = undefined;
        this.tpError = undefined;
        this.adAeropOrin = undefined;
        this.dtTckStart = undefined;
        this.dtTckEnd = undefined;
        this.numTck = undefined;
        this.sisOrigin = undefined;
    }

}
