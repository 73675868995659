<div class="col-8 center">

  <mat-form-field>
    <mat-select placeholder="{{this.types[0]}}" [(ngModel)]="typeDashboard" name="typeDashboard">
      <mat-option *ngFor="let type of types" [value]="type">
        {{type}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div style="display: block;">
    <canvas baseChart width="400" height="280" [datasets]="barChartData" [labels]="barChartLabels"
      [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend"
      (chartClick)="chartClicked($event)">
    </canvas>
    <!-- <canvas baseChart width="400" height="280" [datasets]="barChartData" [labels]="barChartLabels"
      [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType"
      (chartClick)="chartClicked($event)">
    </canvas> -->
  </div>


  <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

    <ng-container matColumnDef="Data">
      <th mat-header-cell *matHeaderCellDef>DATA DE OPERAÇÃO</th>
      <td mat-cell *matCellDef="let v">{{ v.dtFlight }}</td>
    </ng-container>

    <ng-container matColumnDef="Aeroporto">
      <th mat-header-cell *matHeaderCellDef>AEROPORTO</th>
      <td mat-cell *matCellDef="let v">{{ v.airportName}}</td>
    </ng-container>

    <ng-container matColumnDef="Operacao">
      <th mat-header-cell *matHeaderCellDef>TIPO DE OPERAÇÃO</th>
      <td mat-cell *matCellDef="let v">{{ v.tpOperation }}</td>
    </ng-container>

    <ng-container matColumnDef="Natureza">
      <th mat-header-cell *matHeaderCellDef>NATUREZA DO VOO</th>
      <td mat-cell *matCellDef="let v">{{ v.tpNature }}</td>
    </ng-container>

    <ng-container matColumnDef="Numero">
      <th mat-header-cell *matHeaderCellDef>NUMERO DO VOO</th>
      <td mat-cell *matCellDef="let v">{{ v.numFlight }}</td>
    </ng-container>

    <ng-container matColumnDef="Quantidade">
      <th mat-header-cell *matHeaderCellDef>QUANTIDADE</th>
      <td mat-cell *matCellDef="let v">{{ v.qntPassager }}</td>
    </ng-container>

    <ng-container matColumnDef="Tarifa">
      <th mat-header-cell *matHeaderCellDef>TARIFA</th>
      <td mat-cell *matCellDef="let v">{{ v.valueRate | mascaraDinheiro}}</td>
    </ng-container>

    <ng-container matColumnDef="Valor">
      <th mat-header-cell *matHeaderCellDef>VALOR</th>
      <td mat-cell *matCellDef="let v">{{ v.value | mascaraDinheiro}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" [showFirstLastButtons]="true"></mat-paginator>

</div>
