<h2>INCLUIR TARIFA (R$)</h2>

<div class="example-container">

  <mat-form-field>
    <mat-label>Aeroporto</mat-label>
    <mat-select [(ngModel)]="airportChoose">
      <mat-option *ngFor="let airport of airports" [value]="airport.codeIATA">
        {{airport.codeIATA}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="natureza">
    <mat-label>Natureza</mat-label>
    <mat-select [(ngModel)]="typeNatureChoose">
      <mat-option *ngFor="let nature of natures" [value]="nature">
        {{nature}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="categoria">
      <mat-label>Código TTBS</mat-label>
      <input matInput placeholder="Código TTBS" type="string" class="example-left-align" [(ngModel)]="codeTTBS">
    </mat-form-field>

  <mat-form-field class="categoria">
    <mat-label>Categoria</mat-label>
    <mat-select [(ngModel)]="categoryChoose">
      <mat-option *ngFor="let category of categories" [value]="category">
        {{category}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="categoria">
      <mat-label>Tipo Embarque</mat-label>
      <mat-select [(ngModel)]="departureTypeChoose">
        <mat-option *ngFor="let departureType of departuresType" [value]="departureType">
          {{departureType}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  <mat-label>{{ dtInicialLabel }}</mat-label>
  <div class="input-group">
    <!--
    <input id="modalDtInicial" autocomplete="off" [(ngModel)]="dtInicial" (click)="mdt.toggle()"
      (click)="clickEventHandlerSelectAir($event)" (dateSelect)="onDateStartSelect($event)" class="form-control"
      placeholder="dd/mm/yyyy" name="mdt" placement="top" [displayMonths]="displayMonths" ngbDatepicker
      #mdt="ngbDatepicker" />
    <div class="input-group-append">
      <button class="btn btn-outline-secondary calendar" (click)="mdt.toggle()" type="button">
      </button>
    </div>
    -->
    <mat-form-field class="col-12">
      <mat-label>Data Inicial</mat-label>
      <input matInput id="modalDtInicial" [matDatepicker]="datepickerInicial" [(ngModel)]="dtInicial" placeholder="dd/mm/yyyy" name="mdt" maxlength="10" (click)="datepickerInicial.open()">
      <mat-datepicker-toggle matSuffix [for]="datepickerInicial"></mat-datepicker-toggle>
      <mat-datepicker #datepickerInicial></mat-datepicker>
    </mat-form-field>
  </div>

  <mat-label>{{ dtFinalLabel }}</mat-label>
  <div class="input-group">
    <mat-form-field class="col-12">
      <mat-label>Data Final</mat-label>
      <input matInput id="modalDtFinal" [matDatepicker]="datepickerFinal" [(ngModel)]="dtFinal" placeholder="dd/mm/yyyy" name="mdt" maxlength="10" (click)="datepickerFinal.open()">
      <mat-datepicker-toggle matSuffix [for]="datepickerFinal"></mat-datepicker-toggle>
      <mat-datepicker #datepickerFinal></mat-datepicker>
    </mat-form-field>
    <!--
    <input id="modalDtFinal" disabled="{{isDisabled}}" autocomplete="off" [(ngModel)]="dtFinal" (click)="mdf.toggle()"
      (click)="clickEventHandlerSelectAir($event)" (dateSelect)="onDateEndSelectAirTax($event)"
      [markDisabled]="isDisabledLastOneAir" placement="top" class="form-control" placeholder="dd/mm/yyyy" name="mdf"
      [displayMonths]="displayMonths" ngbDatepicker #mdf="ngbDatepicker" />
    <div class="input-group-append">
      <button class="btn btn-outline-secondary calendar" (click)="mdf.toggle()" type="button">
      </button>
    </div>
    -->
  </div>

  <mat-form-field>
    <input matInput placeholder="Tarifa" type="number" class="example-right-align" [(ngModel)]="airportTax">
    <span matPrefix>R$&nbsp;</span>
  </mat-form-field>

</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" (click)="includeRegister()">Registrar</button>
</mat-dialog-actions>
