import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { NgbDatePTParserFormatter } from '../app-search-information-by-period/NgbDatePTParserFormatter';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n, I18n } from '../app-search-information-by-period/CustomDatepickerI18n';
import { NgbDatepickerConfig, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { RpeGerado } from '../app-data-rpe-list/model/rpe-gerado';
import { EditarRpeFormularioService } from './app-edit-rpe-form.service';
import { Protocolo } from '../app-data-rpe-list/model/protocolo';
import { ProtocoloChamado } from '../app-enum/protocolo-chamado';
import { RpeEdit } from './model/rpe-edit';
import { Status } from '../app-data-rpe-list/model/status';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { RpeEditRequest } from './model/rpe-edit-request';
import { EditarRpeService } from '../app-data-rpe-list/app-data-rpe-list.service';
import { RpeEmit } from '../app-search-information-by-date-and-rpe/model/rpe-emit';
import { RpeEditResponse } from './model/rpe-edit-response';
import { DialogCustomComponent } from '../app-dialog-custom/app-dialog-custom.component';
import { properties } from '../../../environments/properties';
import { ResourceLoader } from '@angular/compiler';
import * as FileSaver from 'file-saver';
import { ConsultFileRpeEdit } from './model/consult-file-rpe-edit';
import { EditFileRpe } from './model/edit-file-rpe';
import { HistFileRpeEdit } from './model/hist-file-rpe-edit';
import { UntypedFormControl } from '@angular/forms';
import { ComboBoxService } from '../app-search-information-by-date-and-rpe/combo-box.service';
import { ComboBoxEmpresa } from '../app-search-information-by-date-and-rpe/model/combo-box-empresa';
import {map, startWith } from 'rxjs/operators';
import { ComboBoxAeroporto } from '../app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { ComboBoxTypeOperation } from '../app-search-information-by-date-and-rpe/model/combo-box-type-operation';
import { AuthenticationService } from '../../../app/app-authenticator/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-rpe-form',
  templateUrl: './app-edit-rpe-form.component.html',
  styleUrls: ['./app-edit-rpe-form.component.css'],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter}],
  ],
})

export class EditarRpeFormularioComponent {

  public rpe: RpeEdit;
  public rpeList: RpeEmit;
  public rpeRequest: RpeEditRequest;
  public rpeEditResponse: RpeEditResponse;
  public rpeEditFormulario: RpeEditResponse;
  public tipoAeronaveTeste: string;
  public status: Status;
  public mensagemResposta: any;
  private eventsSubscription: any;
  public idResume: number;
  public dtFlightString: string;
  public dtFlightArrivalString: string;
  checked = false;
  public hours: string;
  public hide: boolean;
  public convert: boolean;
  public customPatterns = {'6': { pattern: new RegExp('[a-zA-Z0-9]+')}};
  public consultFileEditRPE: ConsultFileRpeEdit;
  public editFileRPE: EditFileRpe;
  public histFileRpeEdit: HistFileRpeEdit;
  empresaCtrl = new UntypedFormControl();
  public empresa: ComboBoxEmpresa[];
  filteredEmpresa: Observable<ComboBoxEmpresa[]>;
  aeroportoCtrlDest = new UntypedFormControl();
  aeroportoCtrlPrev = new UntypedFormControl();
  public aeroportoDest: ComboBoxAeroporto[];
  public aeroportoPrev: ComboBoxAeroporto[];
  filteredAeroportoDest: Observable<ComboBoxAeroporto[]>;
  filteredAeroportoPrev: Observable<ComboBoxAeroporto[]>;
  typeOperationDecCtrl = new UntypedFormControl();
  typeOperationPosCtrl = new UntypedFormControl();
  public typeOperationDec: ComboBoxTypeOperation[];
  public typeOperationPos: ComboBoxTypeOperation[];
  filteredTypeOperationDec: Observable<ComboBoxTypeOperation[]>;
  filteredTypeOperationPos: Observable<ComboBoxTypeOperation[]>;
  public dataChegada: any;
  public dataPartida: any;
  public dataContestacao: any;

  @Input() events: Observable<RpeEmit>;
  @Input() eventsId: Observable<number>;

  constructor(public editarRpeFormularioService: EditarRpeFormularioService,
              public datePipe: DatePipe,
              public editarRpeService: EditarRpeFormularioService,
              public dialog: MatDialog,
              private authenticationService: AuthenticationService,
              public comboBoxService: ComboBoxService
              ) {
     this.rpe = new RpeEdit();
     this.rpeRequest = new RpeEditRequest();
     this.rpeList = new RpeEmit();
     this.rpeEditResponse = new RpeEditResponse();
     this.rpeEditFormulario = new RpeEditResponse();
     this.convert = true;
     this.checked = false;
     this.hide = true;
     this.consultFileEditRPE = new ConsultFileRpeEdit();
     this.consultFileEditRPE.fligthLegDTO = new  RpeEditResponse();
     this.editFileRPE = new EditFileRpe();
     this.histFileRpeEdit = new HistFileRpeEdit();
     this.editFileRPE.histEditFileRpe = new HistFileRpeEdit();


   }

  ngOnInit() {

    //('rpe edit ... ' );
    this.eventsSubscription = this.events.subscribe((rpeGerado) => this.getDadosRpeGeradoForm(rpeGerado));
    this.obterComboBoxCompany();
    this.obterComboBoxAirport();
    this.obterComboBoxTypeOperation();
  }



  private isValidDate(date: any): boolean {
    if(date == null || date == undefined || date == 0 || date == "Invalid date"){
      return false;
    }
    return true;
  }


  private factoryRPERequest(): void {

    const dtFlight = this.isValidDate(this.consultFileEditRPE.fligthLegDTO.dateFlightDeparture) ?
      this.consultFileEditRPE.fligthLegDTO.dateFlightDeparture : null;
       //new Date(this.consultFileEditRPE.fligthLegDTO.dateFlightDeparture.year, this.consultFileEditRPE.fligthLegDTO.dateFlightDeparture.month - 1,
       //this.consultFileEditRPE.fligthLegDTO.dateFlightDeparture.day) : null;

    const dtFlightArrival = this.isValidDate(this.consultFileEditRPE.fligthLegDTO.dateFlightArrival) ?
      this.consultFileEditRPE.fligthLegDTO.dateFlightArrival : null;
       //new Date(this.consultFileEditRPE.fligthLegDTO.dateFlightArrival.year, this.consultFileEditRPE.fligthLegDTO.dateFlightArrival.month - 1,
       //this.consultFileEditRPE.fligthLegDTO.dateFlightArrival.day) : null;

    const dtDisputeString = this.isValidDate(this.consultFileEditRPE.fligthLegDTO.dtContestation) ?
      this.consultFileEditRPE.fligthLegDTO.dtContestation : null;
      //new Date(this.consultFileEditRPE.fligthLegDTO.dtContestation.year, this.consultFileEditRPE.fligthLegDTO.dtContestation.month - 1,
      //this.consultFileEditRPE.fligthLegDTO.dtContestation.day) : null;
  //  this.consultFileEditRPE.fligthLegDTO.dtContestation = this.datePipe.transform(dtDisputeString, 'yyyy-MM-dd');
    //this.editFileRPE.dtContestation = this.datePipe.transform(dtDisputeString, 'yyyy-MM-dd') + 'T00:00:00';
    this.editFileRPE.dtContestation = (dtDisputeString != null ? moment(dtDisputeString).format("YYYY-MM-DD") + 'T00:00:00' : '2024-03-26T00:00:00');


    //this.editFileRPE.dateFlightDeparture = this.datePipe.transform(dtFlight, 'yyyy-MM-dd');
    this.editFileRPE.dateFlightDeparture = (dtFlight != null ? moment(dtFlight).format("YYYY-MM-DD") : null)

    //this.editFileRPE.dateFlightArrival = this.datePipe.transform(dtFlightArrival, 'yyyy-MM-dd');
    this.editFileRPE.dateFlightArrival = (dtFlightArrival != null ? moment(dtFlightArrival).format("YYYY-MM-DD") : null);

    this.editFileRPE.prefix = this.consultFileEditRPE.fligthLegDTO.prefix;
    this.editFileRPE.typeAircraft = this.consultFileEditRPE.fligthLegDTO.typeAircraft;

    this.editFileRPE.numFlightDeparture = this.consultFileEditRPE.fligthLegDTO.numFlightDeparture;
    this.editFileRPE.numFlightArrival = this.consultFileEditRPE.fligthLegDTO.numFlightArrival;

    this.editFileRPE.hrFlightDeparture = this.consultFileEditRPE.fligthLegDTO.hrFlightDeparture;
    this.editFileRPE.hrFlightArrival = this.consultFileEditRPE.fligthLegDTO.hrFlightArrival;

    this.editFileRPE.idContestation = this.consultFileEditRPE.fligthLegDTO.idContestation;
   // this.editFileRPE.dtContestation = this.consultFileEditRPE.fligthLegDTO.dtContestation + 'T00:00:00';

    this.editFileRPE.id = this.consultFileEditRPE.fligthLegDTO.id;

    //7 novos campos
    this.editFileRPE.icaoCompany = this.consultFileEditRPE.icaoCompany;
    this.editFileRPE.icaoAirportFlightPrevious = this.consultFileEditRPE.icaoAirportFlightPrevious;
    this.editFileRPE.icaoAirportDestiny = this.consultFileEditRPE.icaoAirportDestiny;
    this.editFileRPE.cdTypeFlightDeparture = this.consultFileEditRPE.cdTypeFlightDeparture;
    this.editFileRPE.cdTypeFlightArrival = this.consultFileEditRPE.cdTypeFlightArrival;
    this.editFileRPE.cdTypeLineFlightDeparture = this.consultFileEditRPE.cdTypeLineFlightDeparture;
    this.editFileRPE.cdTypeLineFlightArrival = this.consultFileEditRPE.cdTypeLineFlightArrival;
    this.editFileRPE.histEditFileRpe = this.histFileRpeEdit;

  }

  public editarRpe(): void {

   // let email : string = "heliooliveira.everis@latam.com"; // para teste
    let email : string = this.authenticationService.getCurrentUser().email;

    this.factoryRPERequest();
    this.editarRpeFormularioService.editarRpe(this.editFileRPE.id, this.editFileRPE, email).subscribe(
      protocoloResponse => {

        this.editarRpeFormularioService
          .getFileEdited(this.consultFileEditRPE.fligthLegDTO.id, this.editFileRPE)
          .toPromise()
          .then(response => {

           this.histFileRpeEdit.histCdTypeFlightArrival = this.editFileRPE.cdTypeFlightArrival;
           this.histFileRpeEdit.histCdTypeFlightDeparture= this.editFileRPE.cdTypeFlightDeparture;
           this.histFileRpeEdit.histCdTypeLineFlightDeparture = this.editFileRPE.cdTypeLineFlightDeparture;
           this.histFileRpeEdit.histCdTypeLineFlightArrival = this.histFileRpeEdit.histCdTypeLineFlightArrival;
           this.histFileRpeEdit.histIcaoAirportFlightPrevious = this.editFileRPE.icaoAirportFlightPrevious;
           this.histFileRpeEdit.histIcaoAirportDestiny = this.editFileRPE.icaoAirportDestiny;


            this.openDialog(
              properties.MODAL_TITLE_SUCESS,
              properties.MODAL_SEND_MESSAGE_QUEUE
            );
          }).catch(error => {
            //(error)
            this.openDialog(
              properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
              properties.MODAL_SEND_MESSAGE_QUEUE_ERROR);
          });

      }, err => {
        let protocolo: Protocolo = err.error;
        this.validarStatusCadastroChamado(protocolo);
        this.status = Status.ERRO;
        this.openDialog(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
              properties.MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE
        );
    });
  }

  /**
   * [[ REALIZAR O DOWNLOAD DO ARQUIVO ]]
   * @param response
   * @param nameFile
   * @param typeFile
   */
  saveBlobResumeBoardingERPE(response: Blob, nameFile: string, typeFile: string): any {
    const blob = new Blob([response], { type:  typeFile});
    const file = new File([blob], nameFile, { type: typeFile });
    FileSaver.saveAs(file);
  }

  private getDateBySelected(dtDispute: NgbDate): Date {

    if (dtDispute.day == 0) {
      return new Date();
    }

    return new Date(this.consultFileEditRPE.fligthLegDTO.dtContestation.year,
                      this.consultFileEditRPE.fligthLegDTO.dtContestation.month - 1,
                        this.consultFileEditRPE.fligthLegDTO.dtContestation.day);

  }

    getDadosRpeGeradoForm(rpeGerado: any){
      //("Chegou aqui após eventsSubject 1", rpeGerado)
      this.hide = true;
      this.mensagemResposta = undefined;
      this.editarRpeService.getDadosRpeGeradoForm(rpeGerado).subscribe(dataSource => {
        //("Retorno status 200 da chamada getDadosRpeGeradoForm",dataSource)
        if (dataSource == undefined) {
          this.openDialog(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
               properties.MODAL_CONTENT_RPE_GENERATED_NOT_FOUND
          );
        } else {

            this.hide = false;
            //("Modificando status do Hide por o retornod a chamada  veio com dados",)
            dataSource.fligthLegDTO.hrFlightDeparture
            = this.setHoutNoSeconds(dataSource.fligthLegDTO.dateFlightDeparture);

            dataSource.fligthLegDTO.hrFlightArrival
             = this.setHoutNoSeconds(dataSource.fligthLegDTO.dateFlightArrival);

            dataSource.fligthLegDTO.dtContestation
            //= this.setCalendarValue(dataSource.fligthLegDTO.dtContestation);
            = moment(dataSource.fligthLegDTO.dtContestation).format("YYYY-MM-DD");


            dataSource.fligthLegDTO.dateFlightDeparture
            //= this.setCalendarValue(dataSource.fligthLegDTO.dateFlightDeparture);
            = moment(dataSource.fligthLegDTO.dateFlightDeparture).format("YYYY-MM-DD");
            dataSource.fligthLegDTO.dateFlightArrival
            = moment(dataSource.fligthLegDTO.dateFlightArrival).format("YYYY-MM-DD");

            //= this.setCalendarValue(dataSource.fligthLegDTO.dateFlightArrival);

            if(dataSource.fligthLegDTO.idContestation == null ) {
              this.checked = false;
            }else{
              this.checked = true;
            }

            dataSource.icaoCompany = dataSource.icaoCompany.toUpperCase();
            dataSource.cdTypeLineFlightDeparture = dataSource.cdTypeLineFlightDeparture.toUpperCase();
            dataSource.cdTypeLineFlightArrival = dataSource.cdTypeLineFlightArrival.toUpperCase();


            this.histFileRpeEdit.histCdTypeFlightArrival = dataSource.cdTypeFlightArrival;
            this.histFileRpeEdit.histCdTypeFlightDeparture= dataSource.cdTypeFlightDeparture;
            this.histFileRpeEdit.histCdTypeLineFlightDeparture = dataSource.cdTypeLineFlightDeparture;
            this.histFileRpeEdit.histCdTypeLineFlightArrival = dataSource.cdTypeLineFlightArrival;
            this.histFileRpeEdit.histIcaoAirportFlightPrevious = dataSource.icaoAirportFlightPrevious;
            this.histFileRpeEdit.histIcaoAirportDestiny = dataSource.icaoAirportDestiny;

            this.consultFileEditRPE = dataSource;

            this.dataChegada = new Date(moment(this.consultFileEditRPE.fligthLegDTO.dateFlightArrival).format("YYYY-MM-DDTHH:mm:ss"));
            this.dataPartida = new Date(moment(this.consultFileEditRPE.fligthLegDTO.dateFlightDeparture).format("YYYY-MM-DDTHH:mm:ss"));
            this.dataContestacao = new Date(moment(this.consultFileEditRPE.fligthLegDTO.dtContestation).format("YYYY-MM-DDTHH:mm:ss"));
            //(this.dataChegada, "<---dataChegada")
            //(this.dataPartida, "<---dataPartida")

        }
      }, err => {
        const protocolo: Protocolo = err.error;
        // //(protocolo);
          this.openDialog(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
                properties.MODAL_CONTENT_RPE_GENERATED_NOT_FOUND
          );
      });

    }

    private setHoutNoSeconds(date: any): any{
      if(date != null && date != undefined){
        let vectorAll: string[] = date.split('T');
        let vector = vectorAll[1].split(':');
        return vector[0] + ':' + vector[1];
      }
      return null;
    }

    public validRequestDto(): void{
      if(this.consultFileEditRPE.fligthLegDTO.prefix == null
         || this.consultFileEditRPE.fligthLegDTO.prefix == "" ){
          this.mensagemResposta = 'O campo com a Matricula da aeronave não pode estar vazia.';
            this.status = Status.ERRO;
              this.convert = false;
      }else{
        this.editarRpe();
      }
    }

    public cancelar(): void{
      this.hide = true;
      this.mensagemResposta = undefined;
      this.rpe = new RpeEdit();
    }

    public openDialog(titleParam: string, messageParam: string): void {
      const dialogRef = this.dialog.open(DialogCustomComponent, {
          width: '450px',
          data: {title: titleParam, message: messageParam}
      });
    }


    validarStatusCadastroChamado(protocolo: Protocolo): void {
      switch (protocolo.status) {
        case ProtocoloChamado.CONTROLE_SALVO:
          this.mensagemResposta = 'Chamado cadastrado com sucesso.';
          break;
        case ProtocoloChamado.DATAHEALTH_ASSOCIADO:
          this.mensagemResposta = 'Número DataHealth já está associado a outro chamado.';
          break;
        case ProtocoloChamado.DATAHEALTH_NAO_ENCONTRADO:
          this.mensagemResposta = 'Número DataHeath informado não encontrado/inexistente.';
          break;
        case ProtocoloChamado.REQUEST_INVALIDO:
          this.mensagemResposta = 'Dados informados invalidos.';
        default:
          this.mensagemResposta = protocolo.descricao;
          break;
      }
    }

    public getIsoByDate(dtNgbDate: NgbDate, control: boolean): string {
      const hourEditRpe: string = control ? '00:00:00' : '23:59:59';
      const monthTempEditRpe: string = (dtNgbDate.month < 10 ) ? '0' + dtNgbDate.month : '' + dtNgbDate.month;
      const dayTemoEditRpe: string = (dtNgbDate.day < 10 ) ? '0' + dtNgbDate.day : '' + dtNgbDate.day;

      const dateIsoEditRpe: string =  dtNgbDate.year
                              + '-' + monthTempEditRpe
                                + '-' + dayTemoEditRpe
                                  + 'T' + hourEditRpe;

      // //('[ PERIODO ] {}: ' + dateIso);
      return dateIsoEditRpe;
    }

    private setCalendarValue(dt: string): NgbDate {

      if ( dt === null || dt === undefined) {
        return new NgbDate(0, 0, 0);
      }

      let dtArray: string[];
      dtArray = dt.split('T');
      dtArray = dtArray[0].split('-');

      return new NgbDate(parseInt(dtArray[0]),
                          parseInt(dtArray[1]),
                            parseInt(dtArray[2]));
    }

    private setHoursDate(dt: string): string {
     let dtArray: string[];
     dtArray = dt.split('T');

     return dtArray[1];
    }

    public obterComboBoxCompany(): void {
      this.comboBoxService.getComboBoxEmpresa().subscribe(
        dataSource => {
            this.empresa = dataSource;
            this.filteredEmpresa = this.empresaCtrl.valueChanges
            .pipe(
              startWith(''),
              map(empresa => empresa ? this._filterEmpresa(empresa) : this.empresa.slice())
            );
          }, err => {
            const protocolo: Protocolo = err.error;
        });
    }

    private _filterEmpresa(value: string): ComboBoxEmpresa[] {
      const filterValue = value.toLowerCase();
      return this.empresa.filter(empresa => empresa.icaoCode.toLowerCase().indexOf(filterValue) === 0);
    }

    public obterComboBoxAirport(): void {
      this.comboBoxService.getComboBoxAeroporto().subscribe(
        dataSource => {
            this.aeroportoDest = dataSource;
            this.aeroportoPrev = dataSource;

            this.filteredAeroportoDest = this.aeroportoCtrlDest.valueChanges
              .pipe(
                startWith(''),
                map(aeroporto => aeroporto ? this._filterAeroporto(aeroporto, this.aeroportoDest) : this.aeroportoDest.slice())
              );

              this.filteredAeroportoPrev = this.aeroportoCtrlPrev.valueChanges
              .pipe(
                startWith(''),
                map(aeroporto => aeroporto ? this._filterAeroporto(aeroporto, this.aeroportoPrev) : this.aeroportoPrev.slice())
              );

          }, err => {
            const protocolo: Protocolo = err.error;
        });
    }

    private _filterAeroporto(value: string, aeroportoCombo: ComboBoxAeroporto[]): ComboBoxAeroporto[] {
      const filterValue = value.toLowerCase();
      return aeroportoCombo.filter(aeroporto => aeroporto.codeICAO.toLowerCase().indexOf(filterValue) === 0);
    }

    public obterComboBoxTypeOperation(): void {

      this.comboBoxService.getComboBoxTypeOperation().subscribe(dataSource =>{

        this.typeOperationDec = dataSource;
        this.typeOperationPos = dataSource;

            this.filteredTypeOperationDec = this.typeOperationDecCtrl.valueChanges
            .pipe(
              startWith(''),
              map(typeOperation => this.typeOperationDec ? this._filterTypeOperation(typeOperation, this.typeOperationDec)
              : this.typeOperationDec.slice())
            );

            this.filteredTypeOperationPos = this.typeOperationPosCtrl.valueChanges
            .pipe(
              startWith(''),
              map(typeOperation => this.typeOperationPos ? this._filterTypeOperation(typeOperation, this.typeOperationPos)
              : this.typeOperationPos.slice())
            );

          }, err => {
            const protocolo: Protocolo = err.error;
        });

    }

    private _filterTypeOperation(value: string, typeOperationCombo: ComboBoxTypeOperation[]): ComboBoxTypeOperation[] {
      const filterValue = value.toLowerCase();
      return typeOperationCombo.filter(typeOperation => typeOperation.name.toLowerCase().indexOf(filterValue) === 0);
    }

    public exibeInfo(){
      //(this.dataChegada)
      //(this.dataPartida)
    }

}
