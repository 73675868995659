<br>
<div class="card text-center">
  <div class="card-header" style="background-color: #1b0088;">
    <h5 style="color: #ffffff;">Geração de Relatórios: {{reportType}}</h5>
  </div>
  <div class="card-body">

    <form #searchInfoReport>

      <br>
      <div class="form-row">

        <!--<div [className]="isValidToRenderized('resum') || isValidToRenderized('pass') ? 'col-4' : 'col-12'">-->
        <div class="col-12">
            <mat-form-field id="matFormFieldOrigin" class="example-full-width inputLayout">
              <input matInput id="origin"
                              name="origin"
                              [(ngModel)]="formSearch.airportName"
                              [placeholder]="this.aeroportoCtrl.disabled ? 'Todos os Aeroportos foram selecionados' : 'Aeroporto'"
                              placeholder="Aeroporto"
                              aria-label="Aeroporto"
                              [matAutocomplete]="autoAirport"
                              [formControl]="aeroportoCtrl"
                              oninput="this.value = this.value.toUpperCase()">
              <mat-autocomplete #autoAirport="matAutocomplete">
                <mat-option (click)='disableAirportsFilds()' *ngIf="isValidToRenderizedAnacBag()">
                  <span>Todos os aeroportos </span>
                </mat-option>
                <mat-option *ngFor="let aeroporto of filteredAeroporto | async" [value]="aeroporto.codeIATA">
                  <span>{{aeroporto.codeIATA}} - {{aeroporto.descriptionName}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="row col-12 d-flex justify-content-center">
          <mat-form-field class="col-4 p2 inputPadding" [hidden]="isValidToRenderized('anac')">
            <mat-label>Mês</mat-label>
            <mat-select [(value)]="formSearch.month">
              <mat-option value="0">Selecione</mat-option>
              <mat-option value="1">Janeiro</mat-option>
              <mat-option value="2">Fevereiro</mat-option>
              <mat-option value="3">Março</mat-option>
              <mat-option value="4">Abril</mat-option>
              <mat-option value="5">Maio</mat-option>
              <mat-option value="6">Junho</mat-option>
              <mat-option value="7">Julho</mat-option>
              <mat-option value="8">Agosto</mat-option>
              <mat-option value="9">Setembro</mat-option>
              <mat-option value="10">Outubro</mat-option>
              <mat-option value="11">Novembro</mat-option>
              <mat-option value="12">Dezembro</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-4 p2 inputPadding" [hidden]="isValidToRenderized('anac')">
            <input matInput id="year"
            type="number"
            onKeyPress="if(this.value.length==4) return false;"
            min="0"
            autocomplete="off"
            [(ngModel)]="formSearch.year"
            class="form-control"
            placeholder="Ano"
            name="year"/>
          </mat-form-field>
          <!--
          <div class="col-4" [hidden]="isValidToRenderized('anac')">
              <div class="input-group">
                <input id="year"
                      type="number"
                      onKeyPress="if(this.value.length==4) return false;"
                      min="0"
                      autocomplete="off"
                      [(ngModel)]="formSearch.year"
                      class="form-control"
                      placeholder="Ano"
                      name="year"/>
              </div>
          </div>
          -->
        </div>

        <mat-form-field class="col-4 p-1" [hidden]="isValidToRenderized('resum')">
          <mat-label>Data Inicial</mat-label>
          <input matInput id="startDate" [matDatepicker]="datepickerInicial" [(ngModel)]="formSearch.startDate" placeholder="dd/mm/yyyy" name="startDate" maxlength="10" (click)="datepickerInicial.open()">
          <mat-datepicker-toggle matSuffix [for]="datepickerInicial"></mat-datepicker-toggle>
          <mat-datepicker #datepickerInicial></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="col-4 p-1" [hidden]="isValidToRenderized('resum')">
          <mat-label>Data Final</mat-label>
          <input matInput id="endDate" [matDatepicker]="datepickerFinal" [(ngModel)]="formSearch.endDate" placeholder="dd/mm/yyyy" name="endDate" maxlength="10" (click)="datepickerFinal.open()">
          <mat-datepicker-toggle matSuffix [for]="datepickerFinal"></mat-datepicker-toggle>
          <mat-datepicker #datepickerFinal></mat-datepicker>
        </mat-form-field>

        <!--
        <div class="col-4" [hidden]="isValidToRenderized('resum') || isValidToRenderized('pass')">
            <div class="input-group">
              <input id="numberFlight"
                    type="number"
                    autocomplete="off"
                    [(ngModel)]="formSearch.numberFlight"
                    class="form-control"
                    placeholder="Número do voo"
                    name="numberFlight"/>
            </div>
        </div>
        -->

        <mat-form-field id="matFormFieldOrigin" class="inputPadrao col-4 p-1" [hidden]="isValidToRenderized('resum') || isValidToRenderized('pass')">
          <mat-label>Núm. voo</mat-label>
          <input matInput
          id="numberFlight"
          placeholder="Num. Pouso"
          autocomplete="off"
          [(ngModel)]="formSearch.numberFlight"
          class="form-control"
          name="numberFlight" />
        </mat-form-field>

      </div>

      <br>

      <div class="form-row">
          <div class="text-center col-12">
              <button type="button" class="btn btn-danger btnClean" (click)="clearFields()" [disabled]="controlSave">
                      <mat-icon style="font-size: x-large;">delete</mat-icon> Limpar Campos
              </button>
              <button type="button" class="btn btn-primary btnSave" (click)="exportReport()" [disabled]="controlSave">
                      <mat-icon style="font-size: x-large;">cloud_download</mat-icon> Gerar Relatório
              </button>
          </div>
      </div>

    </form>

  </div>
</div>
