<div id='pagefullLayout'>
  <form #formLayout>
    <br>
    <div class="card text-center">
      <div class="card-header" style="background-color: #1b0088;">
        <h5 style="color: #ffffff;">Manutenção de Layout</h5>
      </div>
      <div class="card-body">
        <br>
        <div class="container d-flex">
          <br>
          <div class="row col-12 d-flex justify-content-start">
              <div class="col-2 label d-flex justify-content-start">
                <label for="template">Status Layout: </label>
              </div>
              <div class="col-2">
                <mat-button-toggle-group #group="matButtonToggleGroup" [value]="selectedVal"
                  (change)="onValChange(group.value)">
                  <mat-button-toggle value="A" class="c-button-toggle-1">Ativo</mat-button-toggle>
                  <mat-button-toggle value="I" class="c-button-toggle-2">Inativo</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            <div class="col-4 label">
              <label for="template">Duplicar Layout:
                <mat-checkbox type="checkbox" [(ngModel)]="duplicaCheckbox" name="duplica"
                  (change)="setDuplicaCheck(($event.checked))" [disabled]="disabledCheckDuplica"
                  class="c-check-box-duplica">
                </mat-checkbox>
              </label>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-2 label">
            <label for="template">Layout: </label>
          </div>
          <div class="col-10 d-flex justify-content-start">
            <mat-form-field id="inputLayout">
              <input id="template" name="template" aria-label="template" matInput [formControl]="templateCtrl"
                [matAutocomplete]="autoLayout" value="{{template.idLayout}} {{separator}} {{template.nameLayout}}"
                (focus)="cleanValueInput()" [disabled]="true" (blur)="foraFocus()">
              <mat-autocomplete #autoLayout="matAutocomplete" (optionSelected)='setTemplate($event.option.value)'>
                <mat-option *ngFor="let templateInList of filteredTemplate | async" [value]="templateInList">
                  {{templateInList.idLayout}} - {{templateInList.nameLayout}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-2 label">
            <label for="origin">Aeroporto: </label>
          </div>
          <div class="col-4">
            <mat-form-field id="matFormFieldOrigin" class="inputsWidthCem">
              <input matInput id="origin" name="origin" [(ngModel)]="template.iataAirport" placeholder="Aeroporto"
                aria-label="Aeroporto" [matAutocomplete]="autoAirport" [formControl]="aeroportoCtrl"
                oninput="this.value = this.value.toUpperCase()" maxlength="3" [disabled]="controlSave">
              <mat-autocomplete #autoAirport="matAutocomplete">
                <mat-option *ngFor="let aeroporto of filteredAeroporto | async" [value]="aeroporto.codeIATA">
                  <span>{{aeroporto.codeIATA}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-2 label">
            <label for="templateConciliationType">Conciliação por: </label>
          </div>
          <div class="col-4">
            <mat-form-field id="matFormFieldOrigin" class="inputsWidthCem">
              <input matInput id="templateConciliationType" name="templateConciliationType"
                [(ngModel)]="template.conciliationType" placeholder="Tipo de conciliação"
                aria-label="Tipo de conciliação" [matAutocomplete]="autoConciliationType"
                [formControl]="conciliationTypeCtrl" oninput="this.value = this.value.toUpperCase()"
                [disabled]="controlSave">
              <mat-autocomplete #autoConciliationType="matAutocomplete">
                <mat-option *ngFor="let type of conciliationTypeList" [value]="type"
                  (onSelectionChange)="validSelectedIten($event, type)">
                  <span>{{type}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-2 label">
            <label for="templateName">Nome do Layout: </label>
          </div>
          <div class="col-4">
            <mat-form-field class="inputsWidthCem">
              <input matInput id="templateName" name="templateName" [(ngModel)]="template.nameLayout"
                placeholder="Nome do Layout" aria-label="Nome do Layout" [formControl]="layoutNameCtrl"
                [disabled]="controlSave">
            </mat-form-field>
          </div>
          <div class="col-2 label">
            <label for="templateStartLine">Linha inicial: </label>
          </div>
          <div class="col-4">
            <mat-form-field class="inputsWidthCem">
              <input matInput id="templateStartLine" name="templateStartLine" [(ngModel)]="template.startLine"
                placeholder="Linha inicial" aria-label="Linha inicial" [formControl]="startLineCtrl"
                oninput="this.value = this.value.toUpperCase()" maxlength="2" [disabled]="controlSave">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="card text-center">
      <div class="card-body">
        <div class="container">
          <br>
          <div class="row">
            <div class="col-3 label justify-content-center">
              <label for="amountPaxBoardDom" >Col Qtde de passageiros Emb. Dom.: </label>
            </div>
            <div class="col-7 justify-content-start">
              <mat-form-field class="inputsWidthCem">
                <input matInput id="amountPaxBoardDom" name="amountPaxBoardDom"
                  [(ngModel)]="template.layoutAmount.amountPaxBoardDom" placeholder="Qtd. Emb. Dom."
                  aria-label="Qtd. Emb. Dom." [formControl]="amountPaxBoardDomCtrl"
                  oninput="this.value = this.value.toUpperCase()" maxlength="2" [disabled]="controlSave">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-3 label justify-content-center">
              <label for="amountPaxBoardInt">Col Qtde de passageiros Emb. Int.: </label>
            </div>
            <div class="col-7 justify-content-start">
              <mat-form-field class="inputsWidthCem">
                <input matInput id="amountPaxBoardInt" name="amountPaxBoardInt"
                  [(ngModel)]="template.layoutAmount.amountPaxBoardInt" placeholder="Qtd. Emb. Int."
                  aria-label="Qtd. Emb. Int." [formControl]="amountPaxBoardIntCtrl"
                  oninput="this.value = this.value.toUpperCase()" maxlength="2" [disabled]="controlSave">
              </mat-form-field>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-3 label justify-content-center">
              <label for="amountPaxConDom">Col Qtde de passageiros Con. Dom.: </label>
            </div>
            <div class="col-7 justify-content-start">
              <mat-form-field class="inputsWidthCem">
                <input matInput id="amountPaxConDom" name="amountPaxConDom"
                  [(ngModel)]="template.layoutAmount.amountPaxConDom" placeholder="Qtd. Con. Dom."
                  aria-label="Qtd. Con. Dom." [formControl]="amountPaxConDomCtrl"
                  oninput="this.value = this.value.toUpperCase()" maxlength="2" [disabled]="controlSave">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-3 label justify-content-center">
              <label for="amountPaxConInt">Col Qtde de passageiros Con. Int.: </label>
            </div>
            <div class="col-7 justify-content-start">
              <mat-form-field class="inputsWidthCem">
                <input matInput id="amountPaxConInt" name="amountPaxConInt"
                  [(ngModel)]="template.layoutAmount.amountPaxConInt" placeholder="Qtd. Con. Int."
                  aria-label="Qtd. Con. Int." [formControl]="amountPaxConIntCtrl"
                  oninput="this.value = this.value.toUpperCase()" maxlength="2" [disabled]="controlSave">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="card text-center">
      <div class="card-body">
        <div class="container">
        <br>
          <div class="row">
            <div class="col-3 label">
              <label for="dtOperation">Coluna Data de Operação: </label>
            </div>
            <div class="col-3">
              <mat-form-field class="inputsWidthOitenta">
                <input matInput id="dtOperation" name="dtOperation" [(ngModel)]="template.layoutFlight.dtOperation"
                  placeholder="Dt. Operação" aria-label="Dt. Operação" [formControl]="dtOperationCtrl"
                  oninput="this.value = this.value.toUpperCase()" maxlength="2" [disabled]="controlSave">
              </mat-form-field>
            </div>
            <div class="col-2 label">
              <label for="maskFormatation">Formatação: </label>
            </div>
            <div class="col-3">
              <mat-form-field id="matFormFieldOrigin" class="inputsWidthCem">
                <input matInput id="maskFormatation" name="maskFormatation"
                  [(ngModel)]="template.layoutFlight.maskFormatation" placeholder="Formatação da Data"
                  aria-label="Formatação da Data" [formControl]="maskFormatationCtrl" [disabled]="controlSave"
                  [matAutocomplete]="autoConciliationMask">
                <mat-autocomplete #autoConciliationMask="matAutocomplete">
                  <mat-option *ngFor="let masck of conciliationMaskList" [value]="masck">
                    <span>{{masck}}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>

          <br>
          <div class="row">
            <div class="col-3 label">
              <label for="numFlight">Coluna Número do vôo: </label>
            </div>
            <div class="col-3">
              <mat-form-field>
                <input matInput id="numFlight" name="numFlight" [(ngModel)]="template.layoutFlight.numFlight"
                  placeholder="Número do Voo" aria-label="Número do voo" [formControl]="numFlightCtrl"
                  oninput="this.value = this.value.toUpperCase()" maxlength="2" [disabled]="controlSave">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-3 label">
              <label for="typeOperation">Coluna Tipo de Operação: </label>
            </div>
            <div class="col-3">
              <mat-form-field>
                <input matInput id="typeOperation" name="typeOperation" [(ngModel)]="template.layoutFlight.typeOperation"
                  placeholder="Tipo de Operação" aria-label="Tipo de Operação" [formControl]="typeOperationCtrl"
                  oninput="this.value = this.value.toUpperCase()" maxlength="2" [disabled]="controlSave">
              </mat-form-field>
            </div>
          </div>

          <br>
          <div class="row">
            <div class="col-3 label">
              <label for="natureFlight">Coluna Natureza do Vôo: </label>
            </div>
            <div class="col-3">
              <mat-form-field>
                <input matInput id="natureFlight" name="natureFlight" [(ngModel)]="template.layoutFlight.natureFlight"
                  placeholder="Natureza do Voo" aria-label="Natureza do voo" [formControl]="natureFlightCtrl"
                  oninput="this.value = this.value.toUpperCase()" maxlength="2" [disabled]="controlSave">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-3 label">
              <label for="valueRate">Coluna Valor da Tarifa: </label>
            </div>
            <div class="col-3">
              <mat-form-field>
                <input matInput id="valueRate" name="valueRate" [(ngModel)]="template.layoutFlight.valueRate"
                  placeholder="Tipo de Operação" aria-label="Tipo de Operação" [formControl]="valueRateCtrl"
                  oninput="this.value = this.value.toUpperCase()" maxlength="2" [disabled]="controlSave">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-3 label">
              <label for="valueRateFull">Coluna Valor Total: </label>
            </div>
            <div class="col-3">
              <mat-form-field>
                <input matInput id="valueRateFull" name="valueRateFull" [(ngModel)]="template.layoutFlight.valueRateFull"
                  placeholder="Tipo de Operação" aria-label="Tipo de Operação" [formControl]="valueRateFullCtrl"
                  oninput="this.value = this.value.toUpperCase()" maxlength="2" [disabled]="controlSave">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="card text-center">
      <div class="card-body">
        <button type="button" class="btn btn-danger btnClean" (click)="cleanTemplate()" [disabled]="controlSave">
          <mat-icon style="font-size: x-large;">delete</mat-icon> Limpar Campos
        </button>
        <button type="button" class="btn btn-primary btnSave" (click)="saveOrEditTemplate()" [disabled]="controlSave">
          <mat-icon style="font-size: x-large;">{{iconButton}}</mat-icon> {{nameButton}}
        </button>
      </div>
    </div>
  </form>
</div>

<br><br><br>
