import { OpenDialog } from './../../../utils/openDialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '../model/User';
import { UserProfileService } from '../service/user-profile.service';
import { AppModalAdministratorUserComponent } from '../modal/app-modal-administrator-user.component';
import { Profile } from '../model/Profile';
import { properties } from '../../../../environments/properties';
import { DialogCustomComponent } from '../../../../app/app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-app-administrator',
  templateUrl: './app-administrator.component.html',
  styleUrls: ['./app-administrator.component.css']
})
export class AppAdministratorComponent implements OnInit {

  public displayedColumns: string[] = [
    'identification',
    'name',
    'email',
    'perfil',
    'status',
    'edit'
  ];


  public dataSourceUserProfile: MatTableDataSource<User>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private readonly ATIVO:   string  = "A";
  private readonly INATIVO: string  = "I";

  constructor(private dialog: MatDialog,  private userProfileService:UserProfileService, private openDialogUtils: OpenDialog) { }

  ngOnInit() {
    this.loadUsers();
  }

  private loadUsers(): void {
    this.userProfileService.getListUsersProfile().subscribe(
     dataSource => {
      this.dataSourceUserProfile = new MatTableDataSource(dataSource);
      this.dataSourceUserProfile.paginator = this.paginator;
    }, err => {
      this.openDialog(
        properties.MODAL_ERROR_TITLE,
        properties.MODAL_CONTENT_LOAD_USER_ERROR);
    });
  }

  public modalConfig(user: User):MatDialogConfig{

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = user;
    dialogConfig.width = '50%';
    dialogConfig.height = '65%';

    return dialogConfig;

  }

  public openCreateOrUpdatdUserForApplication(dialogConfig: MatDialogConfig): void{

    this.dialog
    .open(AppModalAdministratorUserComponent, dialogConfig)
    .afterClosed().subscribe(responseDialog => {
      this.loadUsers();
    });


  }

  public createUser(){
    let user: User = new User();
    user.status = this.ATIVO;
    user.profile = new Profile();
    let dialogConfig = this.modalConfig(user);
    this.openCreateOrUpdatdUserForApplication(dialogConfig);
  }

  public editUser(user: User){

    let dialogConfig = this.modalConfig(user);
    this.openCreateOrUpdatdUserForApplication(dialogConfig);
  }

  public changed(user: User){

    user.status = user.status === this.ATIVO ? this.INATIVO : this.ATIVO;

    this.userProfileService.saveUserProfile(user).subscribe(dataSource =>{
      this.updateTableDataSource(user);
      this.openDialog(
        properties.MODAL_TITLE_SUCESS,
        properties.MODAL_CONTENT_USER_SAVE_SUCESS);
    }, err => {
      this.openDialog(
        properties.MODAL_ERROR_TITLE,
        properties.MODAL_CONTENT_USER_SAVE_ERROR);
        user.status = user.status === this.ATIVO ? this.INATIVO : this.ATIVO;
        this.updateTableDataSource(user);
    });

  }

  public updateTableDataSource(user: User):void{

    this.dataSourceUserProfile.data.filter((value,key)=>{
      if(value.id === user.id){
        value.status = user.status;
      }
    });

  }

  private openDialog(admTitleParam: string, admmessageParam: string): void {
    this.openDialogUtils.openDialog(admTitleParam,admmessageParam)
  }

}
