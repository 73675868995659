import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Rpe } from './model/rpe';
import { NgbDatePTParserFormatter } from '../app-search-information-by-period/NgbDatePTParserFormatter';
import { NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n, I18n } from '../app-search-information-by-period/CustomDatepickerI18n';
import { NgbDatepickerConfig, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { RpeEmit } from './model/rpe-emit';
import { DatePipe } from '@angular/common';
import {map, startWith} from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ComboBoxService } from './combo-box.service';
import { ComboBoxAeroporto } from './model/combo-box-aeroporto';
import { Protocolo } from '../app-enum/Protocolo';
import { ComboBoxEmpresa } from './model/combo-box-empresa';
import { DialogCustomComponent } from '../app-dialog-custom/app-dialog-custom.component';
import { properties } from '../../../environments/properties';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-search-information-by-date-and-rpe',
  templateUrl: './app-search-information-by-date-and-rpe.component.html',
  styleUrls: ['./app-search-information-by-date-and-rpe.component.css'],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter}],
  ],
})
export class SearchInformationByDateAndRpeComponent implements OnInit {
  public rpe: Rpe;
  typeFlightMoviment = '';
  aeroportoCtrl = new UntypedFormControl();
  empresaCtrl = new UntypedFormControl();
  public aeroportoIBD: ComboBoxAeroporto[];
  public empresaIBD: ComboBoxEmpresa[];
  filteredAeroporto: Observable<ComboBoxAeroporto[]>;
  filteredEmpresa: Observable<ComboBoxEmpresa[]>;

  @Output() rpeGerado = new EventEmitter<RpeEmit>();

  constructor(config: NgbDatepickerConfig,
              calendar: NgbCalendar,
              public datePipe: DatePipe,
              public comboBoxService: ComboBoxService,
              public dialog: MatDialog,
              ) {
    config.minDate = {year: 1976, month: 1, day: 1};
    config.maxDate = {year: 2099, month: 12, day: 31};
    config.outsideDays = 'hidden';
    this.rpe = new Rpe();
  }

  ngOnInit() {
    this.obterComboBoxAirportSIB();
    this.obterComboBoxCompanySIB();
  }

  public obterComboBoxAirportSIB(): void {
    this.comboBoxService.getComboBoxAeroporto().subscribe(
      dataSourceSearch => {
          this.aeroportoIBD = dataSourceSearch;
          this.filteredAeroporto = this.aeroportoCtrl.valueChanges
            .pipe(
              startWith(''),
              map(aeroporto => aeroporto ? this._filterAeroporto(aeroporto) : this.aeroportoIBD.slice())
            );
        }, err => {
          const protocolo: Protocolo = err.error;
      });
  }

  public obterComboBoxCompanySIB(): void {
    this.comboBoxService.getComboBoxEmpresa().subscribe(
      dataSource => {
          this.empresaIBD = dataSource;
          this.filteredEmpresa = this.empresaCtrl.valueChanges
          .pipe(
            startWith(''),
            map(empresa => empresa ? this._filterEmpresa(empresa) : this.empresaIBD.slice())
          );
        }, err => {
          const protocolo: Protocolo = err.error;
      });
  }

  private _filterAeroporto(value: string): ComboBoxAeroporto[] {
    const filterValue = value.toLowerCase();
    return this.aeroportoIBD.filter(aeroporto => aeroporto.codeIATA.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterEmpresa(value: string): ComboBoxEmpresa[] {
    const filterValue = value.toLowerCase();
    return this.empresaIBD.filter(empresa => empresa.iataCode.toLowerCase().indexOf(filterValue) === 0);
  }

  public eventoPesquisas(): void {

    if(this.validateFields()){
      this.openDialog(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
            properties.MODAL_CONTENT_PARAMETER_NULL);
        return;
    }

    const rpeEmit: RpeEmit = new RpeEmit();
    rpeEmit.numVoo = this.rpe.numVoo;
    //const myDate = new Date(this.rpe.dateDTO.year, this.rpe.dateDTO.month - 1, this.rpe.dateDTO.day);
    //console.log(myDate), "<variável MyDate";
    rpeEmit.dateDTO = moment(this.rpe.dateDTO).format('YYYY-MM-DD');
    rpeEmit.company = this.rpe.company.toUpperCase();
    rpeEmit.origin = this.rpe.origin.toUpperCase();
    rpeEmit.type = this.typeFlightMoviment;
    console.log("Chegou em cima do rpeGerado.emit")
    this.rpeGerado.emit(rpeEmit);

  }

  public openDialog(titleParam: string, messageParam: string): void {
    const dialogRef = this.dialog.open(DialogCustomComponent, {
        width: '450px',
        data: {title: titleParam, message: messageParam}
    });
  }

  private validateFields(): boolean{

    // console.log('validando campos ... ');
    if(this.rpe.origin == null || this.rpe.origin == undefined || this.rpe.origin == ""){
      return true;
    }

    if(this.rpe.numVoo == null || this.rpe.numVoo == undefined || this.rpe.numVoo == ""){
      return true;
    }

    if( this.rpe.company == null ||  this.rpe.company == undefined ||  this.rpe.company == ""){
      return true;
    }

    if(this.rpe.dateDTO == null || this.rpe.dateDTO == undefined){
      return true;
    }

    return false;
  }

}
