<body>
    <form class="formUser">
        <div id="login" class="container">
            <div class="row mb-3">
                <div class="col-sm text-center buttons-login" *ngIf='!isLoggedIn()'>
                    <button id="loginLatam" title="Login LATAM" href="#" class="btn btn-info btn-md col-sm-12" role="button" (click)="signIn()">Login Latam</button>
                </div>
            </div>
        </div>
    </form>
</body>