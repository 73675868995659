import { OpenDialog } from './../../utils/openDialog';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ComboBoxService } from '../../app-datahealth/app-search-information-by-date-and-rpe/combo-box.service';
import { DateService } from '../../app-conciliation/conciliation/service/date.service';
import { I18n, CustomDatepickerI18n } from '../../app-datahealth/app-search-information-by-period/CustomDatepickerI18n';
import { NgbDatepickerI18n, NgbDateParserFormatter, NgbDatepickerConfig, NgbCalendar, NgbDatepicker, NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatePTParserFormatter } from '../../app-datahealth/app-search-information-by-period/NgbDatePTParserFormatter';
import { ComboBoxAeroporto } from '../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { ComboBoxEmpresa } from '../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-empresa';
import { Protocolo } from '../../app-datahealth/app-enum/Protocolo';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';

import { DialogCustomComponent } from '../../../../src/app/app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { properties } from '../../../../src/environments/properties';
import * as FileSaver from 'file-saver';
import * as moment from "moment";

import { FlightGenerated } from '../app-generated-manual-files/model/FlightGenerated';
import { FormSearchGeneratedFile } from '../app-generated-manual-files/model/FormSearchGeneratedFile';
import { FlightProcess } from '../app-generated-manual-files/model/FlightProcess';
import { DataParaValidacao } from '../app-generated-manual-files/model/DataParaValidacao';
import { AppGeneratedManualFilesService } from '../app-generated-manual-files/app-generated-manual-files.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDatepicker } from '@angular/material/datepicker';


@Component({
  selector: 'app-app-generated-manual-files-async',
  templateUrl: './app-generated-manual-files-async.component.html',
  styleUrls: ['./app-generated-manual-files-async.component.css'],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{ provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter }],
  ],
})
export class AppGeneratedManualFilesAsyncComponent implements OnInit {

  @ViewChild('datepickerInicial') datepickerInicial: MatDatepicker<Date>;
  @ViewChild('datepickerFinal') datepickerFinal: NgbDatepicker;

  private RPE: string = "RPE";
  private STAMP: string = "SELO";
  private intervaloEntreDataInicioEFim: number = 9;

  public displayedColumnsGmn: string[] = [
    'select',
    'compyCodeIataArrival',
    'originIataCodeArrival',
    'destIataCodeArrival',
    'numFlightArrival',
    'dateFlightArrivalOrigin',
    'dateFlightArrivalDestine',
    'compyCodeIata',
    'originIataCode',
    'destIataCode',
    'numFlightDeparture',
    'dttmFlightDepartureOrigin',
    'dttmFlightDepartureDestine'
  ];

  private IMG_SUCCESMFA: string = "../../assets/success.png";
  private IMG_ERRORMFA: string = "../../assets/error.png";

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dataSourceflightsMFA = new MatTableDataSource<FlightProcess>();

  public formSearchMFA: FormSearchGeneratedFile = new FormSearchGeneratedFile();
  public controlSaveMFA: boolean = false;
  public controlShowTableMFA: boolean = true;

  public aeroportoMFA: ComboBoxAeroporto[];
  public empresaMFA: ComboBoxEmpresa[];
  public todosRegistros: number;

  aeroportoCtrl = new FormControl();
  empresaCtrl = new FormControl();
  flightNumberCtrl = new FormControl();
  dateCtrl = new FormControl();

  filteredAeroporto: Observable<ComboBoxAeroporto[]>;
  filteredEmpresa: Observable<ComboBoxEmpresa[]>;
  selection = new SelectionModel<any>(true, []);

  eventsSubscription: any;
  reportType: string = "";
  reportTitle: string = "";
  tituloBotao: string = "Pesquisar Voo(s)";
  imgIcon: string = "search";

  periodoValido: DataParaValidacao = this.manipulaData(new Date(Date.now()), -0);
  intervaloValido: DataParaValidacao = this.manipulaData(new Date(Date.now()), -0);
  inicioValido: DataParaValidacao = this.manipulaData(new Date(Date.now()), -0);

  constructor(
    private routeMFA: ActivatedRoute,
    public comboBoxServiceMFA: ComboBoxService,
    public dateServiceMFA: DateService,
    private dialogMFA: MatDialog,
    private router: Router,
    private appGeneratedManualFilesService: AppGeneratedManualFilesService,
    private openDialogUtils: OpenDialog,
    config: NgbDatepickerConfig,
    private calendar: NgbCalendar,
    public datePipe: DatePipe,) {
      config.minDate = {year: 1976, month: 1, day: 1};
      config.maxDate = {year: 2099, month: 12, day: 31};
      config.outsideDays = 'hidden';
  }

  ngOnInit() {
    this.eventsSubscription = this.routeMFA.params.subscribe(params => {
      this.reportType = this.getReportTypeByRequestGMFA(params['typeSerach']);
      this.clearFields();
      this.controlShowTableMFA = true;
    });

    this.obterComboBoxAirportGMFA();
    this.obterComboBoxCompanyGMFA();
  }

  ngDoCheck(){
    this.isValidToRenderHeaderGMFA();

  }

  atualizaIntervaloValido() {
    const startDate: NgbDate = this.formSearchMFA.startDate;
    const maxEndDate = this.calendar.getNext(startDate, 'd', 3); // Adiciona 3 dias à data inicial

    // Define a data mínima e máxima para o datepickerFinal
    this.datepickerFinal.minDate = startDate;
    this.datepickerFinal.maxDate = maxEndDate;
  }


  public isValidToRenderHeaderGMFA(): void{
    // //("change request header: " + this.reportType);
    if(this.reportType == 'rpe'){
      $("#headerRpeFlight").show();
    }else{
      $("#headerRpeFlight").hide();
    }
  }

  public getReportTypeByRequestGMFA(typeFileSearch: string): string {
    // //("receive this request: " + typeFileSearch);
    this.reportTitle = typeFileSearch == "rpe" ? "RPE's" : "SELO's";
    return typeFileSearch;
  }

  public enableToGeneratedFilesGMFA(): boolean {
    return this.selection?.selected?.length <= 0;
  }

  public desabilitaCombobox() {
    //(this.formSearchMFA.all, "<----this.formSearchMFA.all")
    //(this.formSearchMFA.endDate, "<----this.formSearchMFA.endDate")
    //(this.formSearchMFA.startDate, "<----this.formSearchMFA.startDate")
    if(this.empresaCtrl.disabled){
      this.empresaCtrl.enable();
      this.aeroportoCtrl.enable();
    }else{
      this.empresaCtrl.disable();
      this.aeroportoCtrl.disable();
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceflightsMFA.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceflightsMFA.data.forEach(row => this.selection.select(row));
  }

  clearDataSourceListMFA() {
    this.selection.clear();
  }

  /**
   * [[ FUNCAO PARA BUSCAR OS VOOS QUE NÃO FORAM PROCESSADOS ]]
   */
  public searchFlight() {

    this.disableInputs();
    this.controlShowTableMFA = true;

    if(!this.formSearchMFA.all){

      if(!this.isValidToSearch()){
        this.enableInputs();
        this
        .dialogMFA
        .open(
          DialogCustomComponent,
          {width: '450px',
          data: { title: properties.MODAL_ERROR_TITLE, message: properties.MODAL_CHAMADO_MENSAGEM_REQUEST_INVALIDO }});
      }
      //
      //(this.formSearchMFA, "<----this.formSearchMFA")
      this.appGeneratedManualFilesService
      .getFlightNotProcessedAuto(this.formSearchMFA, this.reportType)
      .subscribe(
        dataSource => {

          if(dataSource.length > 0){

            this.controlShowTableMFA = false;
            dataSource.sort((a, b) => (a.numFlightDeparture < b.numFlightDeparture) ? -1 : 1);
            dataSource.sort((a, b) => (a.dttmFlightDepartureOrigin < b.dttmFlightDepartureOrigin) ? -1 : 1);
            this.clearDataSourceListMFA();
            this.dataSourceflightsMFA = new MatTableDataSource(dataSource);
            this.dataSourceflightsMFA.paginator = this.paginator;
            this.todosRegistros = dataSource.length;

          }else{
            this
            .dialogMFA
            .open(
              DialogCustomComponent, {
                width: '450px',
                data: { title: properties.MODAL_ERROR_TITLE, message: properties.MODAL_CONTENT_ERROR_GENERATED_FILE_MANUAL }});
          }
          ////('succes operation, flights not process: ' + JSON.stringify(dataSource));
        }, err => {

          this
          .dialogMFA
          .open(
            DialogCustomComponent, {
              width: '450px',
              data: { title: properties.MODAL_ERROR_TITLE, message: properties.MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE }});
          this.enableInputs();
        }, () => {

          this.enableInputs();
        });

    }else{
      //let dataInicio =  this.formSearchMFA.startDate.year + "-" + this.formSearchMFA.startDate.month + "-" + this.formSearchMFA.startDate.day;
      //let dataFinal =  this.formSearchMFA.endDate.year + "-" + this.formSearchMFA.endDate.month + "-" + this.formSearchMFA.endDate.day;
      let dataInicio =  moment(this.formSearchMFA.startDate).format('YYYY-MM-DD');
      let dataFinal =  moment(this.formSearchMFA.endDate).format('YYYY-MM-DD');
      this.appGeneratedManualFilesService.generateFlight(dataInicio, dataFinal, this.reportType).subscribe();
      alert("Iniciamos a geração, você será notificado através do Google Chat.");
      window.location.reload();
      // chamar a função de datas somente do RPE ou SELO
    }




  }

  /**
   * [[ VALIDACAO VALIDAR PARAMETROS DE BUSCA ]]
   */
  public isValidToSearch(): boolean {

    return this.isEmptyGM(this.formSearchMFA.airportName) && this.isEmptyGM(this.formSearchMFA.companyName) && this.formSearchMFA.startDate != null && this.formSearchMFA.endDate != null ;
  }

  /**
   * [[ FUNCAO PARA LIMPAR OS CAMPOS DA TELA ]]
   */
  public clearFields() {
    this.formSearchMFA = new FormSearchGeneratedFile();
    this.dataSourceflightsMFA = new MatTableDataSource<FlightProcess>();
  }

  public obterComboBoxAirportGMFA(): void {
    this.comboBoxServiceMFA.getComboBoxAeroporto().subscribe(
      dataSource => {
        this.aeroportoMFA = dataSource;
        this.filteredAeroporto = this.aeroportoCtrl.valueChanges
          .pipe(
            startWith(''),
            map(aeroporto => aeroporto ? this._filterAeroporto(aeroporto) : this.aeroportoMFA.slice())
          );
      }, err => {
        const protocolo: Protocolo = err.error;
      });
      //(this.filteredAeroporto, "<---This.filtered aeroporto generated manual")
  }

  public obterComboBoxCompanyGMFA(): void {
    //(this.comboBoxServiceMFA.getComboBoxEmpresa(), "<---- combobox da empresa");
    this.comboBoxServiceMFA.getComboBoxEmpresa().subscribe(
      dataSource => {
        this.empresaMFA = dataSource;
        this.filteredEmpresa = this.empresaCtrl.valueChanges
          .pipe(
            startWith(''),
            map(empresa => empresa ? this._filterEmpresa(empresa) : this.empresaMFA.slice())
          );
      }, err => {
        const protocolo: Protocolo = err.error;
      });
    //(this.filteredEmpresa, "<---This.filtered empresa")
  }



  private _filterAeroporto(value: string): ComboBoxAeroporto[] {
    const filterValue = value.toLowerCase();
    return this.aeroportoMFA.filter(aeroporto => aeroporto.codeIATA.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterEmpresa(value: string): ComboBoxEmpresa[] {
    const filterValue = value.toLowerCase();
    return this.empresaMFA.filter(empresa => empresa.iataCode.toLowerCase().indexOf(filterValue) === 0);
  }

  public getFormatDateToStringIso(date: any): string {
    return this.dateServiceMFA.ngbDateTimeToStringNoSeconds(date);
  }

  public disableInputs(): void {
    this.controlSaveMFA = true;
    this.aeroportoCtrl.disable();
    this.empresaCtrl.disable();
    this.flightNumberCtrl.disable();
    this.dateCtrl.disable();
  }

  public enableInputs(): void {
    this.controlSaveMFA = false;
    this.aeroportoCtrl.enable();
    this.empresaCtrl.enable();
    this.flightNumberCtrl.enable();
    this.dateCtrl.enable();
  }

  /**
   * [[ GERAR ARQUIVOS DE RPE E SELO ]]
   */
  public generatedFiles(): void {

    this.disableInputs();
    let listFlightGenerate: Array<FlightGenerated> = new Array();
    this.selection.selected.forEach( flight => { listFlightGenerate.push(this.factoryGeneratedFile(flight, this.formSearchMFA.airportName)); })

    this.appGeneratedManualFilesService
    .createdFilesManualy(listFlightGenerate).subscribe();

    this.openDialog(
      properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
      'Iniciamos a Geração de Arquivos. Você receberá os arquivos pelo Chat do Google. Estaremos te redirecionando para a Pagina Inicial');
      this.router.navigate(['/dashboardOne'])
      setTimeout(function(){
        window.location.reload();
      }, 5000)



  }

  /**
   * [[ MODAL DE DIALOGO ]]
   * @param titleParam
   * @param messageParam
   */
  private openDialog(genMnTitleParam: string, genMnMessageParam: string): void {
    this.openDialogUtils.openDialog(genMnTitleParam,genMnMessageParam)
  }

  /**
   * [[ REALIZAR O DOWNLOAD DO ARQUIVO ]]
   * @param response
   * @param nameFile
   * @param typeFile
   */
  saveBlobResumeBoardingGMFA(response: Blob, nameFile: string, typeFile: string): any {
    const blob = new Blob([response], { type:  typeFile});
    const file = new File([blob], nameFile, { type: typeFile });
    FileSaver.saveAs(file);
  }

  /**
   * [[ TRANSFORMACAO DO OBJETO ]]
   * @param flight
   */
  factoryGeneratedFile(flight: FlightProcess, airport: string): FlightGenerated {
    let flightGeneratedFile: FlightGenerated = new FlightGenerated();

    //("valid this flight to process: " + flight);
    if(this.isValidFlightSearchArrival(flight, airport)){

      flightGeneratedFile.oriIataCode = flight.originIataCode;
      flightGeneratedFile.destIataCode = flight.destIataCode;
      flightGeneratedFile.compyIataCode = flight.compyCodeIata;
      flightGeneratedFile.dttmFlight = flight.dttmFlightDepartureOrigin;
      flightGeneratedFile.numFlight = flight.numFlightDeparture;

    }else{

      flightGeneratedFile.oriIataCode = flight.originIataCodeArrival;
      flightGeneratedFile.destIataCode = flight.destIataCodeArrival;
      flightGeneratedFile.compyIataCode = flight.compyCodeIataArrival;
      flightGeneratedFile.dttmFlight = flight.dttmFlightArrivalOrigin;
      flightGeneratedFile.numFlight = flight.numFlightArrival;
    }

    flightGeneratedFile.reportType = (this.reportType == 'rpe') ? this.STAMP : this.RPE ;
    return flightGeneratedFile;
  }

  private isValidFlightSearchArrival(flight: FlightProcess, airport: string): boolean{

    return (airport == flight.originIataCode || airport == flight.destIataCode);
  }

  /**
   * [[ VALIDACAO DE STRING ]]
   * @param object
   */
  public isEmptyGM(object: string): boolean{
    return object != null && object != undefined && object.trim() != "";
  }

  manipulaData(data: Date, dias: number): DataParaValidacao{
    let valorConvertido;

    if(dias >  0){
      valorConvertido = moment(data, 'DD/MM/YYYY').add( dias, 'days');
    }else if(dias < 0){
      valorConvertido = moment(data, 'DD/MM/YYYY').subtract( -dias , 'days');
    }else{
      valorConvertido = moment(data, 'DD/MM/YYYY')
    }

    let dados: string[] = valorConvertido.format("DD/MM/YYYY").split('/');
    let d: number = parseInt(dados[0]);
    let m: number = parseInt(dados[1]);
    let y: number = parseInt(dados[2]);
    return {dia: d, mes: m, ano: y};
  }

  atualizaIntevaloValido(){

    if(this.formSearchMFA.startDate != null){
      let dataInicial = new Date(
        this.formSearchMFA.startDate.year,
        this.formSearchMFA.startDate.month -1,
        this.formSearchMFA.startDate.day
      );
      this.inicioValido = this.manipulaData(dataInicial, 0);
      this.intervaloValido = this.selecionaMenorData(dataInicial);
    }
  }

  selecionaMenorData(data: Date){
    let periodo = new Date(this.periodoValido.ano, this.periodoValido.mes, this.periodoValido.dia);
    let intervalo = this.manipulaData(data, this.intervaloEntreDataInicioEFim);
    let maximoIntervalo = new Date(intervalo.ano, intervalo.mes, intervalo.dia);

    if(maximoIntervalo >= periodo){
      return this.manipulaData(new Date(Date.now()), -0);
    }else{
      return this.manipulaData(data, this.intervaloEntreDataInicioEFim);
    }
  }

  limparValorDoDataFinal(){
    this.formSearchMFA.endDate = null;
  }

  btnAll(){
    //("passou")
    let inputCompany: any = document.querySelector("#company");
    //("passou2")
    let inputOrigin: any = document.querySelector("#origin");
    //("passou3")
    let inputNumberFlightDeparture: any = document.querySelector("#endDate");
    //("passou4")
    let inputNumberFlightArrival: any = document.querySelector("#numberFlightArrival");
    //("passou5")

    if(this.formSearchMFA.all){
      //("passou6")
      this.intervaloEntreDataInicioEFim = 9;
      //("passou7")
      this.tituloBotao = "Pesquisar Voo(s)";
      //("passou8")
      this.imgIcon = "search";
      //("passou9")
      inputNumberFlightDeparture.disabled = false;
      //("passou10")
      inputCompany.disabled = false;
      //("passou11")
      inputOrigin.disabled = false;
      //("passou12")
      inputNumberFlightArrival.disabled = false;
      //("passou13")
    }else{
      this.intervaloEntreDataInicioEFim = 2;
      //("passou14")
      this.tituloBotao = "Iniciar Geração";
      //("passou15")
      this.imgIcon = "play_arrow"
      //("passou16")
      this.formSearchMFA.companyName = "";
      //("passou17")
      this.formSearchMFA.airportName = "";
      //("passou18")
      this.formSearchMFA.startDate = null;
      //("passou19")
      this.formSearchMFA.endDate = null;
      //("passou20")
      this.formSearchMFA.numberFlightDeparture = null;
      //("passou21")
      this.formSearchMFA.numberFlightArrival = null;
      //("passou22")
      inputNumberFlightArrival.disabled = true;
      //("passou233")
      inputNumberFlightDeparture.disabled = true;
      //("passou24")
      inputCompany.disabled = true;
      //("passou25")
      inputOrigin.disabled = true;
      //("passou26")
    }
  }


}
