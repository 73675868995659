import { Component, OnInit } from '@angular/core';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {Router, ActivatedRoute} from '@angular/router';
import { DashboardPizzaRQ } from './model/DashboardPizzaRQ';
import { DashboardPizzaRS } from './model/DashboardPizzaRS';
import { Protocolo } from '../app-enum/Protocolo';
import { AppDashboardPizzaRpeGeneratedService } from './app-dashboard-pizza-rpe-generated.service';

@Component({
  selector: 'app-dashboard-pizza-rpe-generated',
  templateUrl: './app-dashboard-pizza-rpe-generated.component.html',
  styleUrls: ['./app-dashboard-pizza-rpe-generated.component.css']
})
export class AppDashboardPizzaRpeGeneratedComponent implements OnInit {

  public indexSelectColunRep;
  private eventsSubscription: any;
  public requestRep: DashboardPizzaRQ = new DashboardPizzaRQ();

  // Pie
  public pieChartOptionsRep: any = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  public pieChartLabelsRep: any[] = [];
  public pieChartDataRep: number[] = [];
  public pieChartTypeRep: any = 'pie';
  public pieChartLegendRep = true;
  public pieChartPluginsRep = [];
  public pieChartColorsRep = [];

  private setPieChartLabelsRep(rs: DashboardPizzaRS[]): void {
    this.pieChartLabelsRep = [];
    for (const value of rs) {
      this.pieChartLabelsRep.push(value.type);
    }
  }

  private setPieChartDataRep(rs: DashboardPizzaRS[]): void {
    this.pieChartDataRep = [];
    for (const value of rs) {
      this.pieChartDataRep.push(value.qntRpe);
    }
  }

  private setPieChartTypeRep(): void {
    this.pieChartTypeRep = 'pie';
  }

  private setPieChartLagendRep(): void {
    this.pieChartLegendRep = true;
  }

  private setPieChartPluginsRep(): void {
    this.pieChartPluginsRep = [pluginDataLabels];
  }

  private setPieChartColorsRep(): void {
    this.pieChartColorsRep = [
      {
        backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
      },
    ];
  }

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private dashboardPizzaService: AppDashboardPizzaRpeGeneratedService) {}

  ngOnInit() {
    this.eventsSubscription = this.route.params.subscribe(params => {
      this.requestRep.dtVoo = params['date'];
      // console.log( '[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 2 ] ' + this.request.dtVoo);
      this.getDataDashboardLine(this.requestRep.dtVoo);
    });
  }

  private getDataDashboardLine(dtSearch: string): void {

    let rs: DashboardPizzaRS[];
    this.dashboardPizzaService.getDataByDate(this.requestRep).subscribe(
      data => {
        rs = data;
        // console.log(rs);
      },
      err => {
        const protocolo: Protocolo = err.error;
        // console.log(protocolo);
      },
      () => {

        this.setPieChartLabelsRep(rs);
        this.setPieChartDataRep(rs);
        this.setPieChartTypeRep();
        this.setPieChartLagendRep();
        this.setPieChartPluginsRep();
        this.setPieChartColorsRep();
      }
    );
  }

  public chartClicked(e: any): void {
    // console.log('[ COMPONENT ]' + this.route.component.name);
    if (e.active.length > 0) {
      this.indexSelectColunRep = e.active[0]._index;
      // console.log('[ INFO ] ' +  ' [ SELECT LABEL ] ' + this.pieChartLabels[this.indexSelectColun]);
      this.router.navigate(['dashboardTreRpeGenerated', this.requestRep.dtVoo, this.pieChartLabelsRep[this.indexSelectColunRep]]);
    }
  }

}
