export class UserInvoice {

  id: string;
  email: string;
  verified_email: boolean;
  name: string;
  given_name: string;
  family_name: string;
  picture:string;
  locale: string;
  hd: string;

  constructor(email: string, name: string, image: string){
    this.email = email;
    this.name = name;
    this.picture = image;
  }

}
