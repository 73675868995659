<div class="card text-center col-12">

  <br>
  <div class="form-row">
    <div class="col-1 label">
      <label for="airportRegisterIATA">Linhas: </label>
    </div>
    <div class="col-5">
      <mat-form-field>
        <input matInput id="amountLine" name="amountLine" [(ngModel)]="amountLine"
          placeholder="Quantidade de linhas no arquivo" aria-label="Quantidade de linhas no arquivo" [disabled]="true"
          autocomplete="off">
      </mat-form-field>
    </div>
    <div class="col-1 label">
      <label for="airportRegisterICAO">Quantidade: </label>
    </div>
    <div class="col-5">
      <mat-form-field>
        <input matInput id="amoutPaxUt" name="amoutPaxUt" [(ngModel)]="amoutPaxUt"
          placeholder="Quantidade de passageiros" aria-label="Quantidade de passageiros" [disabled]="true"
          autocomplete="off">
      </mat-form-field>
    </div>
  </div>

  <div class="form-row">
    <div class="col-2 label">
      <label for="filter">Pesquisa: </label>
    </div>
    <div class="col-10">
      <mat-form-field style="width: 90%;">
        <input matInput id="filter" (keyup)="applyFilter($event.target.value)"
          placeholder="Pesquisa: Sequencia de CheckIn, Número do Selo ou Nome do Passageiro" />
      </mat-form-field>
    </div>
  </div>

  <br>
  <table class="mat-elevation-z8" mat-table [dataSource]="dataSourceflights" style="width: 100%; text-align: center;">

    <ng-container matColumnDef="companyOperator">
      <th mat-header-cell *matHeaderCellDef class="textAliginCenter">Operadora</th>
      <td mat-cell *matCellDef="let v">{{ v.companyOperator }}</td>
    </ng-container>

    <ng-container matColumnDef="dtFlight">
      <th mat-header-cell *matHeaderCellDef class="textAliginCenter">Data do Voo</th>
      <td mat-cell *matCellDef="let v">{{ v.dtFlight }}</td>
    </ng-container>

    <ng-container matColumnDef="numberFlight">
      <th mat-header-cell *matHeaderCellDef class="textAliginCenter">Num. Voo</th>
      <td mat-cell *matCellDef="let v">{{ v.numberFlight }}</td>
    </ng-container>

    <ng-container matColumnDef="airportOrigin">
      <th mat-header-cell *matHeaderCellDef class="textAliginCenter">Origem</th>
      <td mat-cell *matCellDef="let v" matTooltip="{{ v.iataAirportOri }} - {{ v.descAirportOri }} ">{{ v.airportOrigin }}</td>
    </ng-container>

    <ng-container matColumnDef="airportDestination">
      <th mat-header-cell *matHeaderCellDef class="textAliginCenter">Destino</th>
      <td mat-cell *matCellDef="let v" matTooltip="{{ v.iataAirportDest }} - {{ v.descAirportDest }} ">{{ v.airportDestination }}</td>
    </ng-container>

    <ng-container matColumnDef="flightNature">
      <th mat-header-cell *matHeaderCellDef class="textAliginCenter">Natureza</th>
      <td mat-cell *matCellDef="let v">{{ v.flightNature }}</td>
    </ng-container>

    <ng-container matColumnDef="numberStamp">
      <th mat-header-cell *matHeaderCellDef class="textAliginCenter">Numero Selo</th>
      <td mat-cell *matCellDef="let v">{{ v.stampNumer }}</td>
    </ng-container>

    <ng-container matColumnDef="statusTkt">
      <th mat-header-cell *matHeaderCellDef class="textAliginCenter">Utilização Tck</th>
      <td mat-cell *matCellDef="let v" matTooltip="{{v.statusTkt == 'U' ? 'Utilizado' : 'Cancelado'}}">{{ v.statusTkt }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sequeceCheckIn">
      <th mat-header-cell *matHeaderCellDef class="textAliginCenter">CheckIn</th>
      <td mat-cell *matCellDef="let v">{{ v.sequeceCheckIn }}</td>
    </ng-container>

    <ng-container matColumnDef="namePax">
      <th mat-header-cell *matHeaderCellDef class="textAliginCenter">Nome Pax. </th>
      <td mat-cell *matCellDef="let v">{{ v.namePax }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 15, 25, 50, 100, 150, 200]" showFirstLastButtons></mat-paginator>

</div>
<br>
<div class="form-row">
  <div class="text-center col-12">
    <button type="button" class="btn btn-danger btnClean" (click)="closeModal()">
      <mat-icon style="font-size: x-large;position: relative;top: 5px;">close</mat-icon> Fechar Modal
    </button>
  </div>
</div>