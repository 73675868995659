import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { HistoricRpeSeloExcluded } from '../model/HistoricRpeSeloExcluded';
import { ExclusionFileRpeSeloService } from '../services/exclusion-file-rpe-selo.service';
import { DateService } from '../../../../app/app-conciliation/conciliation/service/date.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {

  public displayedColumns: string[] = [
    'id',
    'flightPrefix',
    'flightAircraft',
    'flightOrigin',
    'flightDestination',
    'numFlightArrival',
    'dateFlightArrival',
    'numFlightDeparture',
    'dateFlightDeparture',
    'flightCompany',
    'typeNature',
    'idFlightLeg',
    'dateFileGeneratedBefore',
    'typeFileGeneratedBefore',
    'reason',
    'dateUpdate'
  ];

  public dataSourceHistoric: MatTableDataSource<HistoricRpeSeloExcluded>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(@Inject(MAT_DIALOG_DATA) public history: HistoricRpeSeloExcluded[],
              private dateService: DateService,
              private dialog: MatDialog,
              public exclusionRpeSeloService: ExclusionFileRpeSeloService,
              private dialogRef: MatDialogRef<LogsComponent>) {}

  public cancelOperation(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.loadTable();

  }

  public getFormatDateToStringIso(date: any): string {
    return this.dateService.ngbDateStringToString(date);
  }

  loadTable(){
    this.dataSourceHistoric = new MatTableDataSource(this.history.sort((a,b)=> a.dateUpdate < b.dateUpdate ? 1 : -1));
    this.dataSourceHistoric.paginator = this.paginator;
  }

}
