import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ListaVooExcel } from './model/list-flight-excel';
import { ListIndicatorsExcel } from './model/list-indicators-excel';
import { ListContestationExcel } from './model/list-contestation-excel';
import { ListWorkaroundExcel } from './model/list-workaround-excel';
import { ListRpeGeneratedExcel } from './model/list-rpe-generated-excel';
import { ListStampExcel } from './model/list-stamp-excel';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    var array = new Array();


for(var i=0; i< json.length; i ++){

  let obj = new ListaVooExcel();
  obj.compAir = json[i].flightNumberRPE;
  obj.cdNumVoo = json[i].flightNumberRPE;
  obj.dtVoo = this.formatDateHour(json[i].flightDateRPE);
  obj.dtProcess = this.formatDateHour(json[i].errorGenerationDateRPE);
  obj.tpError = json[i].errorDescriptionRPE;
  obj.adAeropOrin = json[i].originAirportCodeRPE;
  obj.dtTckStart = json[i].ticketControl != null ? json[i].ticketControl.dtTckStart : '';
  obj.dtTckEnd = json[i].ticketControl != null ? json[i].ticketControl.dtTckEnd : '';
  obj.numTck = json[i].ticketControl != null ? json[i].ticketControl.numTck : '';

  array.push(obj)
}

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(array);

    worksheet.A1.v = 'Comp. Aérea';
    worksheet.B1.v = 'Número do Voo';
    worksheet.C1.v = 'Data e Hora do Voo';
    worksheet.D1.v = 'Data do Erro';
    worksheet.E1.v = 'Tipo Erro';
    worksheet.F1.v = 'Origem';
    worksheet.G1.v = 'Data Abertura Ticket';
    worksheet.H1.v = 'Data Encerramento Ticket';
    worksheet.I1.v = 'Número Ticket';
    worksheet['!cols'] = [
      { width: 10 },
      { width: 15 },
      { width: 25 },
      { width: 15 },
      { width: 25 },
      { width: 15 },
      { width: 20 },
      { width: 25 },
      { width: 15 },
    ]
    const workbook: XLSX.WorkBook = { Sheets: { 'Planilha': worksheet }, SheetNames: ['Planilha'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  public exportIndicatorsAsXLSXFile(json: any[], excelFileName: string): void {

    var array = new Array();

    for(var i=0; i < json.length; i++){

      let obj = new ListIndicatorsExcel();
      obj.cdNumVoo = json[i].flightNumberRPE;
      obj.dtVoo = this.formatDateHour(json[i].flightDateRPE);
      obj.dtProcess = this.formatDateHour(json[i].errorGenerationDateRPE);
      obj.tpError = json[i].errorTypeRPE;
      obj.adAeropOrin = json[i].originAirportCodeRPE;
      obj.dtTckStart = json[i].ticketControl != null ? this.getFormatDateToString(json[i].ticketControl.dtTckStart) : '';
      obj.dtTckEnd = json[i].ticketControl != null ? this.getFormatDateToString(json[i].ticketControl.dtTckEnd) : '';
      obj.numTck = json[i].ticketControl != null ? json[i].ticketControl.numTck: '';
      obj.sisOrigin = json[i].dataSourceSystemNameRPE;
      array.push(obj);

    }

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(array);

    worksheet.A1.v = 'Número do Voo';
    worksheet.B1.v = 'Data e Hora do Voo';
    worksheet.C1.v = 'Data do Erro';
    worksheet.D1.v = 'Tipo Erro';
    worksheet.E1.v = 'Origem';
    worksheet.F1.v = 'Data Abertura Ticket';
    worksheet.G1.v = 'Data Encerramento Ticket';
    worksheet.H1.v = 'Número Ticket';
    worksheet.I1.v = 'Sistema Origem';
    worksheet['!cols'] = [
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 25 },
      { width: 15 },
      { width: 20 },
      { width: 25 },
      { width: 15 },
      { width: 15 }
    ]
    const workbook: XLSX.WorkBook = { Sheets: { 'Planilha': worksheet }, SheetNames: ['Planilha'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public getFormatDateToString(dt: string): string {
    if ( dt === null || dt === undefined) {
      return '';
    }
    let dtarr: string[];
    dtarr = dt.split('T');
    dtarr = dtarr[0].split('-');
    return dtarr[2] + '-' + dtarr[1] + '-' + dtarr[0];
  }

  public getFormatDateBr(dt: string): string {
    // console.log('data voo: ' + dt);
    if ( dt === null || dt === undefined) {
      return '';
    }
    let dtarr: string[];
    dtarr = dt.split(' ');
    dtarr = dtarr[0].split('-');

    return dtarr[2] + '-' + dtarr[1] + '-' + dtarr[0];
  }

  public exportContestationAsXLSXFile(json: any[], excelFileName: string): void {

    var array = new Array();

    for(var i=0; i < json.length; i++){
      let obj = new ListContestationExcel();
      obj.idControlDispute = json[i].idControlDispute;
      obj.fkRpe = json[i].fkRpe;
      obj.cdCompAerea = json[i].cdCompAerea;
      obj.cdNumVoo = json[i].cdNumVooDeparture != null ? json[i].cdNumVooDeparture : json[i].cdNumVooArrival
      obj.dtFlight = json[i].dtFlightDeparture !=null ? this.getFormatDateBr(json[i].dtFlightDeparture) : this.getFormatDateBr(json[i].dtFlightArrival);
      obj.originAirport = json[i].originAirport;
      obj.destAirport = json[i].destAirport;
      obj.typeFlightMovement = json[i].typeFlightMovement == 'A' ? 'Pouso' : 'Decolagem';
      obj.dtDispute = this.getFormatDateToString(json[i].dtDispute);
      obj.typeDispute = json[i].typeDispute;
      obj.name = json[i].name;
      obj.dtAction = this.getFormatDateToString(json[i].dtAction);
      array.push(obj);
    }

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(array);

    worksheet.A1.v = 'Contestação';
    worksheet.B1.v = 'Núm. Rpe';
    worksheet.C1.v = 'Companhia Aéreas';
    worksheet.D1.v = 'Núm. Voo';
    worksheet.E1.v = 'Dt. Voo';
    worksheet.F1.v = 'Origem Aeroporto';
    worksheet.G1.v = 'Dest. Aeroporto';
    worksheet.H1.v = 'Tipo Voo';
    worksheet.I1.v = 'Dt. Contestação';
    worksheet.J1.v = 'Tipo Contestação';
    worksheet.K1.v = 'Nome';
    worksheet.L1.v = 'Dt. Ação';
    worksheet['!cols'] = [
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 25 },
      { width: 15 },
      { width: 20 },
      { width: 25 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 }
    ]
    const workbook: XLSX.WorkBook = { Sheets: { 'Planilha': worksheet }, SheetNames: ['Planilha'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  formatDateHour(dt: string): string {
    if ( dt === null || dt === undefined) {
      return '';
    }
    let dtarr: string[];
    let dtarH: string[];
    dtarr = dt.split('T');
    dtarH = dt.split('T');
    dtarr = dtarr[0].split('-');
    return dtarr[2] + '/' + dtarr[1] + '/' + dtarr[0] + ' ' + dtarH[1];

  }

  public exportAsExcelFileWorkaround(json: any[], excelFileName: string): void {

    var array = new Array();


for(var i=0; i< json.length; i ++){
  let obj = new ListWorkaroundExcel();
  obj.idWaRpe = json[i].idWaRpe;
  obj.airlineComp = json[i].airlineComp;
  obj.cdNumVoo = json[i].cdNumVoo;
  obj.dtVoo = json[i].dtVoo;
  obj.dtProcess = this.formatDateHour(json[i].dtProcess);
  obj.tpWa = json[i].tpWa;
  obj.adAeropOrin = json[i].adAeropOrin;
  obj.destAirport = json[i].destAirport;

  array.push(obj)
}

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(array);

    worksheet.A1.v = 'ID Workaround';
    worksheet.B1.v = 'Número Voo';
    worksheet.C1.v = 'Data do Voo';
    worksheet.D1.v = 'Data do Erro';
    worksheet.E1.v = 'Tipo Erro';
    worksheet.F1.v = 'Origem';
    worksheet.G1.v = 'Destino';
    worksheet['!cols'] = [
      { width: 15 },
      { width: 15 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 20 },
      { width: 20 },
    ]
    const workbook: XLSX.WorkBook = { Sheets: { 'Planilha': worksheet }, SheetNames: ['Planilha'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFileRpeGenerated(json: any[], excelFileName: string): void {

    var array = new Array();


for(var i=0; i< json.length; i ++){
  let obj = new ListRpeGeneratedExcel();
  obj.idRpe = json[i].idRpe;
  obj.empiata = json[i].empiata;
  obj.cdNumVoo = json[i].cdNumVoo;
  obj.dtVoo = json[i].dtVoo;
  obj.dtGene = this.formatDateHour(json[i].dtGeneratedRpe);
  obj.sisOrigin = json[i].sisOrigin;
  obj.destAirport = json[i].destAirport;
  obj.tipo = json[i].tipo == 'P' ? 'Pouso' : 'Decolagem';

  array.push(obj)
}

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(array);

    worksheet.A1.v = 'ID Rpe';
    worksheet.B1.v = 'Comp. Aérea';
    worksheet.C1.v = 'Número Voo';
    worksheet.D1.v = 'Data do Voo';
    worksheet.E1.v = 'Data da Geração';
    worksheet.F1.v = 'Origem';
    worksheet.G1.v = 'Destino';
    worksheet.H1.v = 'Tipo do Voo';
    worksheet['!cols'] = [
      { width: 15 },
      { width: 15 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 20 },
      { width: 20 },
    ]
    const workbook: XLSX.WorkBook = { Sheets: { 'Planilha': worksheet }, SheetNames: ['Planilha'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFileStamp(json: any[], excelFileName: string): void {

    var array = new Array();


for(var i=0; i< json.length; i ++){
  let obj = new ListStampExcel();
  obj.dtVoo = this.formatDateHour(json[i].dtVoo);
  obj.numVoo = json[i].numVoo;
  obj.aeroporto = json[i].aeroporto;
  obj.descError = json[i].descError;
  obj.namePax = json[i].namePax;
  obj.descSsr = json[i].descSsr;
  obj.nameSis = json[i].nameSis;

  array.push(obj)
}

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(array);

    worksheet.A1.v = 'Data do Voo';
    worksheet.B1.v = 'Número Voo';
    worksheet.C1.v = 'Aeroporto';
    worksheet.D1.v = 'Descrição Erro';
    worksheet.E1.v = 'Nome';
    worksheet.F1.v = 'Descrição SSR';
    worksheet.G1.v = 'Nome Sistema';
    worksheet['!cols'] = [
      { width: 15 },
      { width: 15 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 20 },
      { width: 20 },
    ]
    const workbook: XLSX.WorkBook = { Sheets: { 'Planilha': worksheet }, SheetNames: ['Planilha'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }



}
