import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ComboBoxAeroporto } from './model/combo-box-aeroporto';
import { Observable } from 'rxjs';
import { endpoints } from '../../../environments/endpoint';
import { ComboBoxEmpresa } from './model/combo-box-empresa';
import { ComboBoxTypeOperation } from './model/combo-box-type-operation';
import { CookieService } from 'ngx-cookie-service';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class ComboBoxService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  getComboBoxAeroporto(): Observable<ComboBoxAeroporto[]> {

    return this.http.get<ComboBoxAeroporto[]>(endpoints.REST_GTECLOUD_GET_AIRPORTS, {headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Bearer ' + this.cookieService.get('acme-user-token')
    })});

  }

  getComboBoxEmpresa(): Observable<ComboBoxEmpresa[]> {

    return this.http.get<ComboBoxEmpresa[]>(endpoints.REST_GET_COMBO_BOX_COMPANY, {headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Bearer ' + this.cookieService.get('acme-user-token')
    })});

  }

  getComboBoxTypeOperation(): Observable<ComboBoxTypeOperation[]>{

    return this.http.get<ComboBoxTypeOperation[]>(endpoints.REST_GET_COMBO_BOX_TYPE_OPERATION, {headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Bearer ' + this.cookieService.get('acme-user-token')
    })});
  }


}
