<div class="flex">
  <div class="flex-item">
    <div style="display: block;">
    <!-- <canvas class="dashLine" baseChart
                [datasets]="lineChartDataCont"
                [labels]="lineChartLabelsCont"
                [options]="lineChartOptions"
                [legend]="lineChartLegendDCon"
                [plugins]="lineChartPluginsDCon"
                (chartClick)="chartClicked($event)"></canvas> -->
    <canvas class="dashLine" baseChart
                [datasets]="lineChartDataCont"
                [labels]="lineChartLabelsCont"
                [options]="lineChartOptions"

                [legend]="lineChartLegendDCon"
                chartType="line"
                [plugins]="lineChartPluginsDCon"
                (chartClick)="chartClicked($event)"></canvas>
    </div>
  </div>
</div>
