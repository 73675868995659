import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { ConciliationExcelReport } from './model/ConciliationExcelReport';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class GenerateExecelFileService {

  constructor() { }

  public exportAsExcelFile(list: ConciliationExcelReport[], excelFileName: string): void {

    // console.log('plan receive: ' + list);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(list);

        worksheet.A1.v = 'Dt. de Operação';
        worksheet.B1.v = 'Num. Voo';
        worksheet.C1.v = 'Valor da Taxa Emb.';
        worksheet.D1.v = 'Valor da Taxa Conex.';
        worksheet.E1.v = 'Tp. Operação';
        worksheet.F1.v = 'Tp. Natureza';
        worksheet.G1.v = 'Qtd. Plan. Emb.';
        worksheet.H1.v = 'Qtd. Plan. Con.';
        worksheet.I1.v = 'Qtd. GTE. Emb.';
        worksheet.J1.v = 'Qtd. GTE. Con.';
        worksheet.K1.v = 'Dif. Embarque';
        worksheet.L1.v = 'Dif. Conexao';
        worksheet.M1.v = 'Qtd. Pax. Isentos';
        worksheet.N1.v = 'Qtd. Pax. Colo';
        worksheet.O1.v = 'Qtd. Pax. Tran.';
        worksheet['!cols'] = [
            { width: 15 },
            { width: 12 },
            { width: 12 },
            { width: 12 },
            { width: 18 },
            { width: 18 },
            { width: 14 },
            { width: 14 },
            { width: 14 },
            { width: 14 },
            { width: 14 },
            { width: 14 },
            { width: 14 },
            { width: 14 },
            { width: 14 },

        ]

        const workbook: XLSX.WorkBook = { Sheets: { 'Planilha': worksheet }, SheetNames: ['Planilha'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);

  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }


}
