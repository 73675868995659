import { Component, OnInit, Input } from '@angular/core';
import { DashboardInformation } from '../../../model/dashboard-information';
import { Conciliation } from '../../../model/conciliation';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { Router } from '@angular/router';
import { DashboardTypeFilterService } from '../../../service/dashboard-type-filter.service';
import { MatLabel } from '@angular/material/form-field';
import { Color } from 'chartjs-plugin-datalabels/types/options';

@Component({
  selector: 'app-dashboard-monthly-variation-chart',
  templateUrl: './dashboard-monthly-variation-chart.component.html',
  styleUrls: ['./dashboard-monthly-variation-chart.component.css']
})
export class DashboardMonthlyVariationChartComponent implements OnInit {

  public ANOATUAL: string = 'Ano Atual';
  public ANOANTERIOR: string = 'Ano Anterior';
  public lineChartData: any[] = [{ data: [], label: '' }];
  public indexSelectColun;
  public indexLabelPoint;

  public lineChartLabels: MatLabel[] = [
    'Janeiro', 'Fevereiro', 'Março',
    'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro',
    'Outubro', 'Novembro', 'Dezembro'];

  public lineChartOptions: (any & { annotation: any }) = {
    responsive: true,
    legend: {
      position: 'top',
    },
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          position: 'left',
        },
      ]
    },
    annotation: {},
  };

  public lineChartColorsMonV: any[] = [];
  public lineChartLegendMonV = true;
  public lineChartTypeMonV = 'line';
  public lineChartPluginsMonV = [pluginAnnotations];

  public type: string;
  public conciliations: Conciliation[];
  private yearNow: number;
  private dashboardInfo: DashboardInformation;
  private types: string[];

  constructor(private router: Router, dashboardTypeFilter: DashboardTypeFilterService) {
    this.yearNow = new Date().getFullYear();
    this.types = dashboardTypeFilter.getTypeFilter();
  }

  ngOnInit() {
    this.setColorsLine();
  }

  @Input()
  set typeDashboard(type: string) {
    this.type = type;
    // console.log(this.type);
    if (this.dashboardInfo !== undefined) {
      this.builder(this.dashboardInfo);
    }
  }

  @Input()
  set dashboard(dashboard: DashboardInformation) {
    if (dashboard !== undefined) {
      this.builder(dashboard);
      this.dashboardInfo = dashboard;
    }
  }

  private setColorsLine(): void {
    this.lineChartColorsMonV = [
      {
        backgroundColor: 'rgba(224,255,255,0.5)',
        borderColor: 'rgba(27,0,136,1)',
        pointBackgroundColor: 'rgb(245,245,245)',
        pointRadius: 15,
        pointHoverRadius: 15,
        pointStyle: 'circle',
      },
      {
        backgroundColor: 'rgba(224,255,255,0.5)',
        borderColor: 'rgba(100,0,136,1)',
        pointBackgroundColor: 'rgb(245,245,245)',
        pointRadius: 15,
        pointHoverRadius: 15,
        pointStyle: 'circle',
      }
    ];
  }

  private builder(dashboard: DashboardInformation) {

    const anoPassado: number[] = this.getConciliationFrom(dashboard, this.yearNow - 1);
    const esseAno: number[] = this.getConciliationFrom(dashboard, this.yearNow);
    const anoPassadoVariacao = this.builderMonthlyVariation(anoPassado);
    const esseAnoVariacao = this.builderMonthlyVariation(esseAno);
    esseAnoVariacao[0] = this.calculeVariation(esseAno[0], anoPassado[11]);

    anoPassadoVariacao[0] = esseAnoVariacao[0];

    this.lineChartData = [
      {
        data: esseAnoVariacao, label: this.ANOATUAL, fill: false, datalabels: {
          formatter: (value) => {
            return value + '%';
          }
        }
      },
      {
        data: anoPassadoVariacao, label: this.ANOANTERIOR, fill: false, datalabels: {
          formatter: (value) => {
            return value + '%';
          }
        }
      }];
  }

  private builderMonthlyVariation(primario: number[]): number[] {

    const primarioCopia: number[] = [...primario];

    for (let index = 1; index < primario.length; index++) {
      primarioCopia[index] = this.calculeVariation(primario[index], primario[index - 1]);
    }

    return primarioCopia;
  }

  private calculeVariation(valorAtual, valorAnterior) {
    return Math.floor(100 * (valorAtual / valorAnterior - 1));
  }

  private getConciliationFrom(dashboard: DashboardInformation, year: number): number[] {

    const data: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    const conciliations: Conciliation[] = dashboard.lastTwelveMonths.concat(dashboard.lastYear);

    conciliations
      .filter(conciliation => conciliation.dtFlight.getFullYear() === year)
      .forEach(conciliation => {

        const month: number = conciliation.dtFlight.getMonth();

        if (this.type === this.types[0]) {
          data[month] = conciliation.valueRate;
        } else {
          data[month] = conciliation.qntPassager;
        }
      });


    return data;
  }

  public chartClicked(event: any): void {

    // console.log('cliquei na barra: ' + event);

    if (event.active.length > 0) {
      const chart = event.active[0]._chart;
      this.indexSelectColun = event.active[0]._index;
      this.indexLabelPoint = event.active[chart.tooltip._active[0]._datasetIndex].$datalabels.$context.dataset.label;
    }

    const array = event.active;
    const active = array[0]._index
    const label = this.lineChartLabels[active];
    const yearSelect = this.indexLabelPoint;

    let year: number = 0;
    if(yearSelect == this.ANOATUAL){
      year = this.yearNow;
    }else{
      year = (this.yearNow - 1);
    }

    const month = this.indexSelectColun + 1;
    this.router.navigate(['dashboard-final', year, month]);

  }

}


