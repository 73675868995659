import { Injectable } from '@angular/core';
import { Indicador } from './app-indicators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { endpoints } from '../../../environments/endpoint';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndicadoresService {

  constructor(private http: HttpClient) { }

  public obterIndicadorCritico(): Observable<Indicador> {
    return this.obterIdicador(endpoints.REST_GET_INDICADOR_CRITICO);
  }

  public obterIndicadorIntermediario(): Observable<Indicador> {
    return this.obterIdicador(endpoints.REST_GET_INDICADOR_INTERMEDIARIO);
  }

  public obterIndicadorInformativo(): Observable<Indicador> {
    return this.obterIdicador(endpoints.REST_GET_INDICADOR_INFORMATIVO);
  }

  private obterIdicador(url: string): Observable<Indicador> {
    return this.http.get<Indicador>(url);
  }

}
