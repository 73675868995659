
export class FlightSeloRPE {

    public numFlight: number;
    public airport: string;
    public dateFlightIni: string;
    public dateFlightEnd: string;
    public typeFlight: string; // A =POUSO, 2 D = DECOLAGEM e  AD = POUSO E DECOLAGEM
    public typeFile: string; //RPE, SELO, RPE_SELO
    public company: string;
}
