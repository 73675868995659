import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class RpeEdit {

    public prefixAircraft: string;
    public typeAircraft: string;
    public numFlight: string;
    public dtFlight: NgbDate;
    public hrFlight: any;
    public numFlightArrival: string;
    public dtFlightArrival: NgbDate;
    public hrFlightArrival: any;
    public flightPrev: string;
    public flight: string;
    public contestation: boolean;
    public dtDispute: NgbDate;
    public idControlDispute: number;

}
