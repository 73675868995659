import { Component, Inject } from '@angular/core';
import { TabsToChoose } from '../../model/tabs-to-choose';
import { ResponseGeneric } from '../../model/ResponseGeneric';
import { Warns } from '../../model/warns';
import { properties } from '../../../../../environments/properties';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-tab-to-be-imported',
  templateUrl: './tab-to-be-imported.component.html',
  styleUrls: ['./tab-to-be-imported.component.css']
})
export class TabToBeImportedComponent {

  public tabsToChoose: TabsToChoose[];

  public warn: Warns[];

  public nameTable: string;

  constructor(public dialogRef: MatDialogRef<TabToBeImportedComponent>,
              @Inject(MAT_DIALOG_DATA) public response: ResponseGeneric) {
    this.tabsToChoose = response.tabsToChooses;
    this.warn = response.warnnings;
  }

  public dialogClose(): void {
    this.dialogRef.close(true);
  }

  public checked(): boolean {
    return this.filterTabsChecked().length === 0;
  }

  private filterTabsChecked(): TabsToChoose[] {
    return this.tabsToChoose.filter(tab => tab.check === true);
  }

  public checkHasWarn(): boolean{
    return Object.keys(this.warn).length > 0;
  }

  private getWarnKeys(){
    return Array.from(this.warn.keys());
  }
}
