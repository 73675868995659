import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class Periodo {

    private dtInicio: any;
    private dtFim: any;

    public setDtInicio(dtInicioNgbDate: any): void {
        this.dtInicio = dtInicioNgbDate;
    }

    public setDtFim(dtFimNgbDate: NgbDate): void {
        this.dtFim = dtFimNgbDate;
    }

    public getDtInicio() {
      return this.dtInicio;
    }

    public getDtFim() {
      return this.dtFim;
    }

    constructor(){
      this.dtInicio= '';
      this.dtFim='';
    }

    /**
     * FUNCAO QUE DEVOLVE O VALOR FORMATADO NO PADRAO ISO
     * @param dtNgbDate
     * @param control
     */
    private getIsoByDate(dtNgbDate: NgbDate, control: boolean): string {
      const hourPeri: string = control ? '00:00:00' : '23:59:59';
      const monthTempPeri: string = (dtNgbDate.month < 10 ) ? '0' + dtNgbDate.month : '' + dtNgbDate.month;
      const dayTemoPeri: string = (dtNgbDate.day < 10 ) ? '0' + dtNgbDate.day : '' + dtNgbDate.day;

      const dateIsoPeri: string =  dtNgbDate.year
                              + '-' + monthTempPeri
                                + '-' + dayTemoPeri
                                  + 'T' + hourPeri;

      // console.log('[ PERIODO ] {}: ' + dateIso);
      return dateIsoPeri;
    }
}
