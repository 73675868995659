<div style="display: block;">
  <canvas
    baseChart width="400"
    height="280"
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    (chartClick)="chartClicked($event)">
  </canvas>
  <!-- <canvas
    baseChart width="400"
    height="280"
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [chartType]="barChartType"
    (chartClick)="chartClicked($event)">
  </canvas> -->
</div>
