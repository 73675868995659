<br>
<form>
  <div class="card text-center">
    <div class="card-header" style="background-color: #1b0088;">
      <h5 style="color: #ffffff;">Tabela de Planilhas Importadas
      </h5>
    </div>
    <div class="card-body">

      <p>
        <mat-form-field class="inputLayout">
          <mat-label>Aeroporto</mat-label>

          <input matInput id="airport" name="airport" [(ngModel)]="airportChoose" placeholder="Aeroporto"
            aria-label="Aeroporto" [formControl]="airportCtrl" [matAutocomplete]="autoAeroporto"
            oninput="this.value = this.value.toUpperCase()">

          <mat-autocomplete #autoAeroporto="matAutocomplete">
            <mat-option *ngFor="let airport of filteredAeroportos  | async" [value]="airport.codeIATA">
              {{airport.codeIATA}} - {{airport.descriptionName}}
            </mat-option>
          </mat-autocomplete>

        </mat-form-field>
      </p>

      <table class="example-full-width" cellspacing="0">
        <tr>
          <td>
            <div style="margin-right: 10%">
              <div class="input-group date">
                <mat-form-field class="inputDate">
                  <mat-label>DE:</mat-label>
                  <input matInput id="ModalDtInicial" [matDatepicker]="datepickerInicio" [(ngModel)]="dtInicial"
                  class="form-control" placeholder="dd/mm/yyyy" name="dp" maxlength="10" (click)="datepickerInicio.open()">
                  <mat-hint>dd/mm/yyyy</mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="datepickerInicio"></mat-datepicker-toggle>
                  <mat-datepicker #datepickerInicio></mat-datepicker>
                </mat-form-field>
                <!--<div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button">
                  </button>

                </div>
                -->
              </div>
            </div>
          </td>
          <td>
            <!--
          <mat-form-field appearence="fill" class="col-2">
            <mat-label>Número Voo:</mat-label>
            <input matInput name="numVoo" [(ngModel)]="rpe.numVoo">
          </mat-form-field>

          <mat-form-field appearance="fill" class="col-4">
            <mat-label>Data do Voo:</mat-label>
            <input matInput [matDatepicker]="datepicker" [(ngModel)]="rpe.dateDTO">
            <mat-hint>dd/mm/yyyy</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
          </mat-form-field>

            -->
            <div class="input-group date">
              <mat-form-field class="inputDate">
                <mat-label>ATÉ:</mat-label>
                <input matInput id="ModalDtFinal" [matDatepicker]="datepickerFinal" [(ngModel)]="dtFinal"
                class="form-control" placeholder="dd/mm/yyyy" name="dtFinal" maxlength="10" (click)="datepickerFinal.open()">
                <mat-hint>dd/mm/yyyy</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="datepickerFinal"></mat-datepicker-toggle>
                <mat-datepicker #datepickerFinal></mat-datepicker>
              </mat-form-field>
              <!--
              <input id="modalDtFinal" disabled="{{isDisabled}}" autocomplete="off" [(ngModel)]="dtFinal"
                (click)="d2.toggle()" (click)="clickEventHandlerSelectSprea($event)" (dateSelect)="onDateEndSelectSpre($event)"
                [markDisabled]="isDisabledLastOneSpre" class="form-control" placeholder="dd/mm/yyyy" name="dtFinal"
                [displayMonths]="displayMonths" ngbDatepicker #d2="ngbDatepicker" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button">
                </button>
              </div>
              -->
            </div>
          </td>
        </tr>
      </table>
      <br>
      <mat-card-actions>
        <button mat-raised-button color="primary"
          style="background-color: #1b0088;border-color:#1b0088; color: #FFF; margin-top: 1%;" [disabled]="disabled()"
          (click)="search()">
          <mat-icon>search</mat-icon>
          Pesquisar
        </button>
      </mat-card-actions>

    </div>
  </div>
</form>

<div class="card text-center">
  <div class="card-header">
    Tabela
  </div>
  <div class="card-body">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0">

      <ng-container matColumnDef="idArquivo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Identificador</th>
        <td mat-cell *matCellDef="let v">{{ v.id }}</td>
      </ng-container>

      <ng-container matColumnDef="NomeArquivo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome Arquivo</th>
        <td mat-cell *matCellDef="let v">{{ v.nmFile }}</td>
      </ng-container>

      <ng-container matColumnDef="Operacao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Operações</th>
        <td mat-cell *matCellDef="let v"> <button mat-raised-button color="primary"
            style="background-color:red;border-color:#1b0088; color: #FFF; margin-top: 1%;" [disabled]="disabled()"
            (click)="delete(v)">
            <mat-icon>delete_sweep</mat-icon>
          </button></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" [showFirstLastButtons]="true"></mat-paginator>
  </div>
