import { Component, OnInit, Inject } from '@angular/core';
import { TypeNatureAndAirport } from '../../model/type-nature-and-airport';
import { NgbDate, NgbDateStruct, NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { properties } from '../../../../../environments/properties';
import { I18n } from '../../../../app-datahealth/app-search-information-by-period/CustomDatepickerI18n';
import { NgbDatePTParserFormatter } from '../../../../app-datahealth/app-search-information-by-period/NgbDatePTParserFormatter';
import { CustomDatepickerI18n } from '../../../../app-datahealth/app-search-information-by-period/CustomDatepickerI18n';
import { AirpotTaxService } from '../../service/airpot-tax.service';
import { AirportTax } from '../../model/airport-tax';
import { DateService } from '../../service/date.service';
import { ComboBoxAeroporto } from '../../../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { DialogCustomComponent } from '../../../../app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { CategoryService } from '../../service/category.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-tab-airport-tax-register',
  templateUrl: './tab-airport-tax-register.component.html',
  styleUrls: ['./tab-airport-tax-register.component.css'],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{ provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter }],
  ],
})
export class TabAirportTaxRegisterComponent implements OnInit {

  dtInicialLabel = properties.HTML_CONST_DT_START;
  dtFinalLabel = properties.HTML_CONST_DT_END;
  emptyDate = properties.HTML_CONST_DT_TO_SELECTED;
  displayMonths = properties.HTML_CONST_QTD_MOUNTHS;

  public airports: ComboBoxAeroporto[];

  public natures: string[];

  public airportChoose: string;

  public typeNatureChoose: string;

  public activeInput: string;

  public isDisabled: boolean;

  public dtInicial: NgbDate;

  public dtFinal: NgbDate;

  public airportTax: number;

  public codeTTBS: string;

  public categoryChoose: string;

  public departureTypeChoose: string;

  public categories: string[];

  public departuresType: string[];

  constructor(public dialogRef: MatDialogRef<TabAirportTaxRegisterComponent>,
              @Inject(MAT_DIALOG_DATA) public typeNatureAndAirport: TypeNatureAndAirport,
              private airportTaxService: AirpotTaxService,
              private dateService: DateService,
              private dialog: MatDialog,
              categoryService: CategoryService
  ) {

    this.airports = typeNatureAndAirport.airports;
    this.natures = typeNatureAndAirport.typeNatures;

    this.categories = categoryService.getCategories();

    this.departuresType = ['Conexão', 'Embarque'];

    this.dtFinal = new NgbDate(3000, 12, 31);

  }

  ngOnInit() {
  }

  public clickEventHandlerSelectAir(event: any) {
    this.activeInput = event.currentTarget.id;
  }

  public onDateStartSelect(dtSelect: NgbDate) {
    this.isDisabled = false;
    $('#modalDtFinal').trigger('click');
  }

  public onDateEndSelectAirTax(dtSelect: NgbDate) {
  }

  public isDisabledLastOneAir(date: NgbDateStruct) {
    let dateIniAir: any;
    let yearIniAir: number;
    let mounthIniAir: number;
    let dayIniAir: number;

    dateIniAir = $('#modalDtInicial').val();
    yearIniAir = parseInt(dateIniAir.substring(6, 10), 10);
    mounthIniAir = parseInt(dateIniAir.substring(3, 5), 10);
    dayIniAir = parseInt(dateIniAir.substring(0, 2), 10);

    const selectUserDateAir = new Date(yearIniAir, (mounthIniAir - 1), dayIniAir);
    const maxDateSelectAir = new Date(yearIniAir, (mounthIniAir - 1), dayIniAir);
    maxDateSelectAir.setDate(selectUserDateAir.getDate() + 30);
    const maxDateOptionAir = { day: maxDateSelectAir.getDate(), month: (maxDateSelectAir.getMonth() + 1), year: maxDateSelectAir.getFullYear() };

    return date.year < yearIniAir
      || (date.month <= mounthIniAir && date.year === yearIniAir && date.day <= dayIniAir)
      || (date.month < mounthIniAir && date.year === yearIniAir)
      || date.year > maxDateOptionAir.year
      || date.year > maxDateOptionAir.year
      || (date.month > maxDateOptionAir.month && date.year === maxDateOptionAir.year)
      || (date.month === maxDateOptionAir.month && date.day > maxDateOptionAir.day);
  }

  public onchangeAirport(airport: string): void {
    this.airportChoose = airport;
  }

  public onChangeTypeNature(nature: string): void {
    this.typeNatureChoose = nature;
  }


  public includeRegister() {

    const airportTax: AirportTax = {
      dtStart: moment(this.dtInicial).format('YYYY-MM-DD')+ 'T00:00:00.000Z',
      dtEnd: moment(this.dtFinal).format('YYYY-MM-DD')+ 'T00:00:00.000Z',
      //dtEnd: this.dateService.ngbDateToObjectDate(this.dtFinal),
      iataAirport: this.airportChoose,
      typeNature: this.typeNatureChoose,
      valueTax: this.airportTax,
      id: undefined,
      codeTtbs : this.codeTTBS,
      airportCategory: this.categoryChoose,
      departureType: this.departureTypeChoose,
    };

    this.airportTaxService
      .registerAirportTax(airportTax)
      .subscribe(response => {
        this.openDialog(properties.MODAL_TITLE_SUCESS, properties.MODAL_BODY_SUCESS_OPERATION);
        this.dialogRef.close(true);
      });

  }

  private openDialog(titleParam: string, messageParam: string): void {
    this.dialog.open(DialogCustomComponent, {
      width: '450px',
      data: { title: titleParam, message: messageParam }
    });

  }

}
