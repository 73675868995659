import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { endpoints } from '../../../environments/endpoint';
import { Voo } from './model/voo';
import { VooRequisicao } from './model/vooRequisicao';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class ListarDadosVooService {

  REST_GET_DADOS_VOOS = endpoints.REST_GET_DADOS_VOOS;

  constructor(private http: HttpClient) { }

  getDadosVoo(voo: VooRequisicao): Observable<Voo[]> {
    let params1 = new HttpParams().set('flightBeginDate', voo.dtInicio);
    let params2 = new HttpParams().set('flightEndDate', voo.dtFim);
    let params3 = new HttpParams().set('system', voo.sistOrigin);
    return this.http.get<Voo[]>(this.REST_GET_DADOS_VOOS+"?"+params1+"&"+params2+"&"+params3, httpOptions); 
  }

  private handleError<T>( operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
        // console.error(error);
        return of(result as T);
      };
  }

}
