import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class AuthFilter implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return this.authenticationService.isUserSignedIn()
      .then(
        response => {
          // console.log('response ' + JSON.stringify(response));
          if (response) {
            return true;
          }
          //console.error('Invalid response.');
          this.authenticationService.signOut();
          return false;
        }
      ).catch(error => {
        // console.error('error to verify signed user.');
        this.authenticationService.signOut();
        //this.router.navigate(['/login-google'], { queryParams: { returnUrl: state.url }});
        return false;
      });

  }
}
