
import { Injectable } from '@angular/core';
import { Voo } from './model/voo';

@Injectable({
  providedIn: 'root'
})
export class FiltrarSelecionadosService {

  constructor() { }

  public retornarVooAptosParaCadastroChamado(voos: Voo[]): Voo[] {
    const voosFiltados: Voo[] = voos.filter(voo => voo.checked === true);
    return voosFiltados;
  }

  public filtrarNumeroTicket(voos: Voo[], vooTicket: number, filterBySearchSelected: any): Voo[] {

    // console.log('[ WPENHA ] Filter by Origin | number of flight | number of ticket', vooTicket, filterBySearchSelected);
    let vooTicketFiltro: Voo[];
    /**
     * [[ WPENHA ]] TRATAMENTO PARA QUE SEJA POSSIVEL SELECIONAR TODOS OS REGISTROS
     *          NA TABELA VALIDADO O FILTRO APLICAD E NÃO SOMENTE O NUMERO DO TICKET
     */
    if (filterBySearchSelected != ''
          && filterBySearchSelected != null
            && filterBySearchSelected != undefined
        ) {

          if (isNaN(filterBySearchSelected)) {
              return voos.filter(
                voo => voo.originAirportCodeRPE.toUpperCase().trim().includes(filterBySearchSelected.toUpperCase().trim())
              ).filter(
                voo => voo.ticketControl.numTck === vooTicket
              );
          } else {
              if ( vooTicket != null && vooTicket != undefined ) {
                return voos.filter(
                  voo => voo.flightNumberRPE.trim().includes(filterBySearchSelected.trim())
                ).filter(
                  voo => voo.ticketControl.numTck === vooTicket
                );
              } else {
                return voos.filter(
                  voo => voo.flightNumberRPE.trim().includes(filterBySearchSelected.trim())
                );
              }
          }
    }

    return voos.filter(voo => voo.ticketControl != null && voo.ticketControl.numTck === vooTicket);
  }

}



