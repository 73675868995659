import { SaveBlobResume } from './../../../utils/saveBlobResume';
import { OpenDialog } from './../../../utils/openDialog';
import { Component, OnInit, Inject } from '@angular/core';
import { FlightLeg } from '../models/flight-leg';
import * as FileSaver from 'file-saver';
import { SummaryRpeService } from '../services/summary-rpe.service';
import { properties } from '../../../../environments/properties';
import { DialogCustomComponent } from '../../../../app/app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-report-type',
  templateUrl: './app-modal-report-type.component.html',
  styleUrls: ['./app-modal-report-type.component.css']
})
export class AppModalReportTypeComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public flight: FlightLeg,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<AppModalReportTypeComponent>,
    private summaryService: SummaryRpeService,
    private openDialogUtils: OpenDialog,
    private saveBlobResume: SaveBlobResume

  ) { }

  ngOnInit() {
  }

  public getFileStamptoDownloadList(idFlightLeg: FlightLeg, isFull: boolean): void {
    this
      .summaryService.getFileRPEtoDownloadListPax(idFlightLeg, isFull)
      .toPromise()
      .then(response => {

        const toDay = new Date();
        const date: string = toDay.getDate() + '_' + (toDay.getMonth() + 1) + '_' + toDay.getFullYear();
        const nameFile = 'REPORT_LIST_PASSENGERS_' + date + '.xlsx';
        const type = 'text/plain';

        if (response.size != 0) {
          this.saveBlobResumeBoarding(response, nameFile, type);
        } else {
          this.openDialog(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
            properties.MODAL_CONTENT_ERROR_GENERATED_FILE_ZIP);
        }

      }).catch(error => {
        this.openDialog(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_ERROR_GENERATED_FILE_ZIP);
      });
  }

  saveBlobResumeBoarding(mdRepresponse: Blob, mdRepnameFile: string, mdReptypeFile: string): any {
    this.saveBlobResume.saveBlobResumeBoarding(mdRepresponse, mdRepnameFile, mdReptypeFile)
  }

  private openDialog(mdRepTitleParam: string, mdRepMessageParam: string): void {
    this.openDialogUtils.openDialog(mdRepTitleParam,mdRepMessageParam)
  }

  public generatedList(isFull: boolean): void{
    this.getFileStamptoDownloadList(this.flight, isFull);
    this.closeModal();
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

}
