<br><br>
<div>
  <div>
    <div style="display: block">
      <canvas baseChart
        [datasets]="barChartDataWor"
        [labels]="barChartLabelsWor"
        [options]="barChartOptionsWork"
        [plugins]="barChartPluginsWor"
        [legend]="barChartLegendWor">
      </canvas>
      <!-- <canvas baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType">
      </canvas> -->
    </div>
  </div>
</div>
