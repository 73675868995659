import { Component, OnInit } from '@angular/core';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {Router, ActivatedRoute} from '@angular/router';
import { DashboardPizzaService } from './app-dashboard-pizza.service';
import { DashboardPizzaRQ } from './model/DashboardPizzaRQ';
import { DashboardPizzaRS } from './model/DashboardPizzaRS';
import { Protocolo } from '../app-enum/Protocolo';

@Component({
  selector: 'app-dashboard-pizza',
  templateUrl: './app-dashboard-pizza.component.html',
  styleUrls: ['./app-dashboard-pizza.component.css']
})
export class DashboardPizzaComponent implements OnInit {

  public indexSelectColunDash;
  private eventsSubscriptionDash: any;
  public requestDash: DashboardPizzaRQ = new DashboardPizzaRQ();

  // Pie
  public pieChartOptionsDash: any = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  public pieChartLabelsDash: any[] = [];
  public pieChartDataDash: number[] = [];
  public pieChartTypeDash: any = 'pie';
  public pieChartLegendDash = true;
  public pieChartPluginsDash = [];
  public pieChartColorsDash = [];

  private setPieChartLabelsDash(rs: DashboardPizzaRS[]): void {
    this.pieChartLabelsDash = [];
    for (const value of rs) {
      this.pieChartLabelsDash.push(value.sistemaOrigem);
    }
  }

  private setPieChartDataDash(rs: DashboardPizzaRS[]): void {
    this.pieChartDataDash = [];
    for (const value of rs) {
      this.pieChartDataDash.push(value.qntRpe);
    }
  }

  private setPieChartTypeDash(): void {
    this.pieChartTypeDash = 'pie';
  }

  private setPieChartLagendDash(): void {
    this.pieChartLegendDash = true;
  }

  private setPieChartPluginsDash(): void {
    this.pieChartPluginsDash = [pluginDataLabels];
  }

  private setPieChartColorsDash(): void {
    this.pieChartColorsDash = [
      {
        backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
      },
    ];
  }

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private dashboardPizzaService: DashboardPizzaService) {}

  ngOnInit() {
    this.indexSelectColunDash = this.route.params.subscribe(params => {
      this.requestDash.dtVoo = params['date'];
      // console.log( '[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 2 ] ' + this.request.dtVoo);
      this.getDataDashboardLine(this.requestDash.dtVoo);
    });
  }

  private getDataDashboardLine(dtSearch: string): void {

    let rs: DashboardPizzaRS[];
    this.dashboardPizzaService.getDataByDate(this.requestDash).subscribe(
      data => {
        rs = data;
        // console.log(rs);
      },
      err => {
        const protocolo: Protocolo = err.error;
        // console.log(protocolo);
      },
      () => {

        this.setPieChartLabelsDash(rs);
        this.setPieChartDataDash(rs);
        this.setPieChartTypeDash();
        this.setPieChartLagendDash();
        this.setPieChartPluginsDash();
        this.setPieChartColorsDash();
      }
    );
  }

  public chartClicked(e: any): void {
    // console.log('[ COMPONENT ]' + this.route.component.name);
    if (e.active.length > 0) {
      this.indexSelectColunDash = e.active[0]._index;
      // console.log('[ INFO ] ' +  ' [ SELECT LABEL ] ' + this.pieChartLabels[this.indexSelectColun]);
      this.router.navigate(['dashboardTre', this.requestDash.dtVoo, this.pieChartLabelsDash[this.indexSelectColunDash]]);
    }
  }

}
