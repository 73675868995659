import { SaveBlobResume } from './../../utils/saveBlobResume';
import { OpenDialog } from './../../utils/openDialog';
import { AnacBagagesService } from './services/anac-bagages.service';
import { ReportScreen } from './models/ReportScreen';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { I18n, CustomDatepickerI18n } from '../../app-datahealth/app-search-information-by-period/CustomDatepickerI18n';
import { NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatePTParserFormatter } from '../../app-datahealth/app-search-information-by-period/NgbDatePTParserFormatter';
import { ComboBoxAeroporto } from '../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { ComboBoxEmpresa } from '../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-empresa';
import { ComboBoxService } from '../../app-datahealth/app-search-information-by-date-and-rpe/combo-box.service';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { TypeReport } from './models/TypeReport';
import { ResumeBoardingService } from './services/resume-boarding.service';
import { properties } from '../../../environments/properties';
import { DateService } from '../../app-conciliation/conciliation/service/date.service';
import { DialogCustomComponent } from '../../app-datahealth/app-dialog-custom/app-dialog-custom.component';
import * as FileSaver from 'file-saver';
import { ResumePaxBoardingService } from './services/resume-pax-boarding.service';
import { AnacPnaeService } from './services/anac-pnae.service';
import { AnacInspacService } from './services/anac-inspac.service';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-app-reports',
  templateUrl: './app-reports.component.html',
  styleUrls: ['./app-reports.component.css'],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{ provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter }],
  ],
})
export class AppReportsComponent implements OnInit {

  private eventsSubscription: any;

  public controlSave: boolean = false;
  public reportType: string = '';
  public reportYypeResume: string = '';
  public formSearch: ReportScreen = new ReportScreen();
  public aeroportoRPS: ComboBoxAeroporto[];
  public empresa: ComboBoxEmpresa[];

  aeroportoCtrl = new UntypedFormControl();
  empresaCtrl = new UntypedFormControl();

  filteredAeroporto: Observable<ComboBoxAeroporto[]>;
  filteredEmpresa: Observable<ComboBoxEmpresa[]>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public comboBoxService: ComboBoxService,
    public dateService: DateService,
    public resumeBoardingService: ResumeBoardingService,
    public resumePaxBoardingService: ResumePaxBoardingService,
    public anacBagagesService: AnacBagagesService,
    public anacPnaeService: AnacPnaeService,
    public anacInspacService: AnacInspacService,
    private openDialogUtils: OpenDialog,
    private saveBlobResume: SaveBlobResume
    ) { }

  ngOnInit() {

    this.eventsSubscription = this.route.params.subscribe(params => {
      this.reportYypeResume = params['typeReport'];
      this.reportType = this.getReportTypeByRequest(params['typeReport']);
      this.clearFields();
    });

    this.obterComboBoxAirport();
  }

  public disableAirportsFilds(): void {
    this.aeroportoCtrl.disable();
  }

  private diasableButtonsGenerateReportRep(control: boolean): void {
    this.controlSave = control;
  }

  private getReportTypeByRequest(type: string): string {
    type = type.replace('-', '_').replace('-', '_');
    for (let reportInEnum in TypeReport) {
      if (reportInEnum.toString() == type) {
        return TypeReport[reportInEnum.toString()]
      }
    }
    return null;
  }

  public isValidToRenderizedAnacBag(): boolean {

    let type = this.getReportTypeByRequest(this.reportYypeResume);

    if (type == TypeReport.anac_bagage.toString() ) {
      return true;
    }

    if (type == TypeReport.anac_inspac.toString() ) {
      return true;
    }

    if (type == TypeReport.anac_pnae.toString() ) {
      return true;
    }

    return false;

  }

  public isValidToRenderized(componet: string): boolean {
    if (this.reportType.toUpperCase().includes(componet.toUpperCase())) {
      return false;
    }
    return true;
  }

  public obterComboBoxAirport(): void {
    this.comboBoxService.getComboBoxAeroporto().subscribe(
      dataSource => {
        this.aeroportoRPS = dataSource;
        // console.log('response list airport: ' , this.aeroporto);
        this.filteredAeroporto = this.aeroportoCtrl.valueChanges
          .pipe(
            startWith(''),
            map(aeroporto => aeroporto ? this._filterAeroporto(aeroporto) : this.aeroportoRPS.slice())
          );
      }, err => {
        // console.log('error to request list of airports {}, ', err);
      }
    );
  }

  private _filterAeroporto(value: string): ComboBoxAeroporto[] {
    const filterValue = value.toLowerCase();
    return this.aeroportoRPS.filter(aeroporto => aeroporto.codeIATA.toLowerCase().indexOf(filterValue) === 0);
  }

  public clearFields(): void {
    this.formSearch = new ReportScreen();
    this.aeroportoCtrl.enable();
  }

  public exportReport(): void {

    let type = this.getReportTypeByRequest(this.reportYypeResume);

    /**
     * [[ GERANDO RELATORIO DE RESUMO DE EMBARQUE ]]
     */
    if (type == TypeReport.resume_boarding.toString()) {
      this.exportReportResumeBoarding();
    }

    /**
     * [[ GERANDO RELATORIO DE RESUMO DE PASSAGEIROS EMBARCADOS ]]
     */
    if (type == TypeReport.resume_pax_boarding.toString()) {
      this.exportReportResumePaxBoarding();
    }

    /**
     * [[ GERANDO RELATORIO ANAC BAGAGENS ]]
     */
    if (type == TypeReport.anac_bagage.toString()) {
      this.exportReportAnacBagages();
    }

    /**
     * [[ GERANDO RELATORIO ANAC PNAE ]]
     */
    if (type == TypeReport.anac_pnae.toString()) {
      this.exportReportAnacPNAE();
    }

    /**
     * [[ GERANDO RELATORIO ANAC PNAE ]]
     */
    if (type == TypeReport.anac_inspac.toString()) {
      this.exportReportAnacINSPAC();
    }

  }

  public validateFieldsReportAnac(): boolean {

    if (((this.formSearch.airportName != null && this.formSearch.airportName != undefined) || this.aeroportoCtrl.disabled)
          && this.formSearch.airportName != ''
            && this.formSearch.month != null
              && this.formSearch.month > 0
                && this.formSearch.year != null
                  && this.formSearch.year != undefined
                    && this.formSearch.year != 0) {
      this.diasableButtonsGenerateReportRep(true);
      return false;
    }

    this.openDialog(
      properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
      properties.MODAL_CHAMADO_MENSAGEM_REQUEST_INVALIDO);

    this.diasableButtonsGenerateReportRep(false);
    return true;
  }

  public validateFieldsReportResumeBoardingPax(): boolean {

    if (this.formSearch.airportName != null
      && this.formSearch.airportName != undefined
      && this.formSearch.airportName != ''
      && this.formSearch.startDate != null
      && this.formSearch.startDate != undefined
      && this.formSearch.endDate != null
      && this.formSearch.endDate != undefined) {

      this.diasableButtonsGenerateReportRep(true);
      return false;
    }

    this.openDialog(
      properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
      properties.MODAL_CHAMADO_MENSAGEM_REQUEST_INVALIDO);

    this.diasableButtonsGenerateReportRep(false);
    return true;
  }

  public validateFieldsReportResumeBoarding(): boolean {

    if (this.formSearch.airportName != null
      && this.formSearch.airportName != undefined
      && this.formSearch.airportName != ''
      && this.formSearch.startDate != null
      && this.formSearch.startDate != undefined
      && this.formSearch.endDate != null
      && this.formSearch.endDate != undefined) {

      this.diasableButtonsGenerateReportRep(true);
      return false;
    }

    this.openDialog(
      properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
      properties.MODAL_CHAMADO_MENSAGEM_REQUEST_INVALIDO);

    this.diasableButtonsGenerateReportRep(false);
    return true;
  }

  /**
   * [[ RELATORIO DE INSPAC DA ANAC ]]
   */
  public exportReportAnacINSPAC(): void {

    if (this.validateFieldsReportAnac()) {
      return;
    }

    this
      .anacInspacService.getDataReportAnacInspac(
        this.formSearch.airportName,
        this.formSearch.month,
        this.formSearch.year)
      .toPromise()
      .then(response => {

        this.diasableButtonsGenerateReportRep(false);
        const toDay = new Date();
        const date: string = toDay.getDate() + '_' + (toDay.getMonth() + 1) + '_' + toDay.getFullYear();
        const nameFile = "REPORT_INSPAC_" +
                        (this.formSearch.airportName == null ? "" : this.formSearch.airportName + "_")
                          + date + '.xlsx';
        const typeb = 'text/plain';
        if (response.size != 0) {
          this.saveBlobResumeBoarding(response, nameFile, typeb);
        } else {
          this.openDialog(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
            properties.MODAL_CONTENT_ERROR_GENERATED_FILE);
        }
      })
      .catch(error => {
        this.diasableButtonsGenerateReportRep(false);
        this.openDialog(
          properties.MODAL_ERROR_TITLE,
          properties.MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE)
      });
  }

  /**
   * [[ RELATORIO DE PNAE DA ANAC ]]
   */
  public exportReportAnacPNAE(): void {

    if (this.validateFieldsReportAnac()) {
      return;
    }

    this
      .anacPnaeService.getDataReportAnacPnae(
        this.formSearch.airportName,
        this.formSearch.month,
        this.formSearch.year)
      .toPromise()
      .then(response => {

        this.diasableButtonsGenerateReportRep(false);
        const toDay = new Date();
        const date: string = toDay.getDate() + '_' + (toDay.getMonth() + 1) + '_' + toDay.getFullYear();
        const nameFile = "REPORT_PNAE_" +
                (this.formSearch.airportName == null ? "" : this.formSearch.airportName + "_")
                          + date + '.xlsx';

        const typec = 'text/plain';
        if (response.size != 0) {
          this.saveBlobResumeBoarding(response, nameFile, typec);
        } else {
          this.openDialog(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
            properties.MODAL_CONTENT_ERROR_GENERATED_FILE);
        }
      })
      .catch(error => {

        this.diasableButtonsGenerateReportRep(false);
        this.openDialog(
          properties.MODAL_ERROR_TITLE,
          properties.MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE)
      });
  }

  /**
   * [[ RESUMO DE PASSAGEIROS BAGAGENS ]]
   */
  public exportReportAnacBagages(): void {

    if (this.validateFieldsReportAnac()) {
      return;
    }

    this
      .anacBagagesService.getDataReportAnacBagage(
        this.formSearch.airportName,
        this.formSearch.month,
        this.formSearch.year)
      .toPromise()
      .then(response => {

        this.diasableButtonsGenerateReportRep(false);
        const toDay = new Date();
        const date: string = toDay.getDate() + '_' + (toDay.getMonth() + 1) + '_' + toDay.getFullYear();
        const nameFile = "ANAC_Bagagens_" + date + '.csv';
        const typea = 'text/plain';
        if (response.size != 0) {
          this.saveBlobResumeBoarding(response, nameFile, typea);
        } else {
          this.openDialog(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
            properties.MODAL_CONTENT_ERROR_GENERATED_FILE);
        }
      })
      .catch(error => {

        this.diasableButtonsGenerateReportRep(false);
        this.openDialog(
          properties.MODAL_ERROR_TITLE,
          properties.MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE)
      });
  }

  /**
   * [[ RESUMO DE PASSAGEIROS EMBARCADOS ]]
   */
  public exportReportResumePaxBoarding(): void {

    if (this.validateFieldsReportResumeBoardingPax()) {
      return;
    }

    this.diasableButtonsGenerateReportRep(true);
    this
      .resumePaxBoardingService.getDataReportPaxBoarding(
        this.formSearch.airportName,
        moment(this.formSearch.startDate).format('YYYY-MM-DD'),
        //this.dateService.ngbDateToString(this.formSearch.startDate),
        moment(this.formSearch.endDate).format('YYYY-MM-DD'),
        this.formSearch.numberFlight)
      .toPromise()
      .then(responseRep => {

        this.diasableButtonsGenerateReportRep(false);
        const toDay = new Date();
        const date: string = toDay.getDate() + '_' + (toDay.getMonth() + 1) + '_' + toDay.getFullYear();
        const nameFile = "Resumo_de_Passageiros_Embarcados_" + date + '.xlsx';
        const type = 'application/vnd.ms-excel';

        if (responseRep.size != 0) {
          this.saveBlobResumeBoarding(responseRep, nameFile, type);
        } else {
          this.openDialog(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
            properties.MODAL_CONTENT_ERROR_GENERATED_FILE);
        }
      })
      .catch(error => {

        this.diasableButtonsGenerateReportRep(false);
        this.openDialog(
          properties.MODAL_ERROR_TITLE,
          properties.MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE)
      });
  }

  /**
   * [[ RELATORIO DE RESUMO DE EMBARQUE ]]
   */
  public exportReportResumeBoarding(): void {

    if (this.validateFieldsReportResumeBoarding()) {
      return;
    }

    this
      //pao de milho
      .resumeBoardingService.getDataReportBoarding(this.formSearch.airportName,
        moment(this.formSearch.startDate).format('YYYY-MM-DD'),
        moment(this.formSearch.endDate).format('YYYY-MM-DD'))
        //this.dateService.ngbDateToString(this.formSearch.startDate),
        //this.dateService.ngbDateToString(this.formSearch.endDate))
      .toPromise()
      .then(responseRepA => {

        this.diasableButtonsGenerateReportRep(false);
        const toDay = new Date();
        const date: string = toDay.getDate() + '_' + (toDay.getMonth() + 1) + '_' + toDay.getFullYear();
        const nameFile = "Resumo_de_Embarque_" + date + '.csv';
        const type = 'application/vnd.ms-excel';

        if (responseRepA.size != 0) {
          this.saveBlobResumeBoarding(responseRepA, nameFile, type);
        } else {
          this.openDialog(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
            properties.MODAL_CONTENT_ERROR_GENERATED_FILE);
        }

      })
      .catch(error => {

        this.diasableButtonsGenerateReportRep(false);
        this.openDialog(
          properties.MODAL_ERROR_TITLE,
          properties.MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE)
      });
  }

  saveBlobResumeBoarding(ReporResponse: Blob, ReporNameFile: string, ReporTypeFile: string): any {
    this.saveBlobResume.saveBlobResumeBoarding(ReporResponse, ReporNameFile, ReporTypeFile)
  }

  private openDialog(appRepTitleParam: string, appRepMessageParam: string): void {
    this.openDialogUtils.openDialog(appRepTitleParam,appRepMessageParam)
  }

}
