import { Component, OnInit, ViewChild } from '@angular/core';
import { ComboBoxService } from '../../app-datahealth/app-search-information-by-date-and-rpe/combo-box.service';
import { ComboBoxAeroporto } from '../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { Observable, Subscription, interval } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Protocolo } from '../../app-datahealth/app-enum/Protocolo';
import { GenerateConciliationService } from './app-generate-conciliation.service';
import { properties } from '../../../environments/properties';
import { DialogCustomComponent } from '../../app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { ConciliationReport } from './model/ConciliationReport';
import { GenerateExecelFileService } from './generate-execel-file.service';
import { DatePipe } from '@angular/common';
import { ConciliationExcelReport } from './model/ConciliationExcelReport';
import { ComboBoxEmpresa } from '../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-empresa';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-app-generate-conciliation',
  templateUrl: './app-generate-conciliation.component.html',
  styleUrls: ['./app-generate-conciliation.component.css']
})
export class AppGenerateConciliationComponent implements OnInit {

  public controlSave: boolean = false;
  public iataAirport: any;
  public aeroporto: ComboBoxAeroporto[];
  public filteredAeroporto: Observable<ComboBoxAeroporto[]>;
  public empresaCtrl = new UntypedFormControl();
  public empresa: ComboBoxEmpresa[];
  public filteredEmpresa: Observable<ComboBoxEmpresa[]>;
  public iataCompany: any;
  public typeNatures: string[];
  public typeNatureChoose: string;
  public natureCtrl = new UntypedFormControl();

  public displayedColumns: string[] = [
    'dtOperation',
    'numFlight',
    'valRateBoard',
    'valRateCon',
    'natureFlight',
    'typeOperation',
    'importedPlanEmb',
    'importedPlanCon',
    'importedGTEEmb',
    'importedGTECon',
    'diffQtdPaxBoard',
    'diffQtdPaxConection'
  ];

  public dataSourceConciliation: MatTableDataSource<ConciliationReport>;
  public excelListData: ConciliationReport[];
  public excelListDataReport: ConciliationExcelReport[];
  public dataAtual: Date = new Date;

  subscription: Subscription;
  intervalId: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public aeroportoCtrl = new UntypedFormControl();

  constructor(
    public comboBoxService: ComboBoxService,
      public conciliationService: GenerateConciliationService,
        public dialog: MatDialog,
          public generateExecelFileService: GenerateExecelFileService,
            private datePipe: DatePipe,
            config: NgbDatepickerConfig,
            calendar: NgbCalendar
                ) {
                  this.typeNatures = ['Domestico', 'Internacional'];
                }

  ngOnInit() {
    this.obterComboBoxAirportGC();
    this.obterComboBoxCompanyGC();
    const source = interval(10000);
    this.subscription = source.subscribe(val => this.conciliationInProgess());
  }

  public conciliationInProgess(): void {
    if(this.controlSave){
      // console.log('realizado nova requisicao ao backend... ')
      // setInterval(() => this.conciliationInProgess(), 15000);
      this. obterComboBoxAirportGC();
      this.obterComboBoxCompanyGC();
    }else{
      clearInterval(this.intervalId);
    }
  }

  private obterComboBoxAirportGC(): void {
    this.comboBoxService.getComboBoxAeroporto().subscribe(
      dataSourceGen => {
          this.aeroporto = dataSourceGen;
          this.filteredAeroporto = this.aeroportoCtrl.valueChanges
            .pipe(
              startWith(''),
              map(aeroporto => aeroporto ? this._filterAeroportoGC(aeroporto) : this.aeroporto.slice())
            );
        }, err => {
          const protocolo: Protocolo = err.error;
      });
  }

  private _filterAeroportoGC(value: string): ComboBoxAeroporto[] {
    const filterValue = value.toLowerCase();
    return this.aeroporto.filter(aeroporto => aeroporto.codeIATA.toLowerCase().indexOf(filterValue) === 0);
  }

  public obterComboBoxCompanyGC(): void {
    this.comboBoxService.getComboBoxEmpresa().subscribe(
      dataSource => {
          this.empresa = dataSource;
          this.filteredEmpresa = this.empresaCtrl.valueChanges
          .pipe(
            startWith(''),
            map(empresa => empresa ? this._filterEmpresaGC(empresa) : this.empresa.slice())
          );
        }, err => {
          const protocolo: Protocolo = err.error;
      });
  }

  private _filterEmpresaGC(value: string): ComboBoxEmpresa[] {
    const filterValue = value.toLowerCase();
    return this.empresa.filter(empresa => empresa.iataCode.toLowerCase().indexOf(filterValue) === 0);
  }


  private openDialog(titleParam: string, messageParam: string): void {
    const dialogRef = this.dialog.open(DialogCustomComponent, {
        width: '450px',
        data: {title: titleParam, message: messageParam}
    });
  }

  private formatDateToPattern(date: string): string{
    if(date != null && date != undefined){
      let vetor: string[] = date.split('-');
      return vetor[2] + '/' + vetor[1] + '/' + vetor[0];
    }
    return "";
  }

  public generatedConciliation(): void {

    $('#panelTableConciliation').fadeOut();
    if(this.iataAirport !== null && this.iataAirport !== undefined ||
       (this.iataCompany !== null && this.iataCompany !== undefined)){

        this.controlSave = true;
        this.aeroportoCtrl.disable();
        this.empresaCtrl.disable();
        this.natureCtrl.disable();


        if(this.companyAndNaturesaNotSelected()){
            return;
        }

        this.conciliationService.getFileConciliation(this.iataAirport, this.iataCompany,
          this.typeNatureChoose).subscribe(
          dataSource => {
            this.enableComponents();
            if(dataSource.data.length == 0){
              this.openDialog(
                properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
                    properties.MODAL_CONTENT_DASHBOARD_ERROR_DATA_NOT_FOUND_CONCILIATION);
            }else{
              $('#panelTableConciliation').fadeIn(500);

              dataSource.data.sort(function(a,b) {
                return new Date(a.dtOperation) < new Date(b.dtOperation) ? -1 : new Date(a.dtOperation) > new Date(b.dtOperation) ? 1 : 0;
              });

              dataSource.data.forEach(element => {
                element.dtOperation = this.formatDateToPattern(element.dtOperation);
              });

              this.excelListData = dataSource.data;

              this.dataSourceConciliation = new MatTableDataSource(dataSource.data);
              this.dataSourceConciliation.paginator = this.paginator;
            }

          },
          err => {
            this.enableComponents();
            $('#panelTableConciliation').fadeOut();
            // console.log('error in request: ' + err);
            this.openDialog(
              properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
                  properties.MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE);
          }
        );
    }else{
      $('#panelTableConciliation').fadeOut();
      this.openDialog(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
            properties.MODAL_CONTENT_NOT_VALID_AIRPORT_OR_COMPANY_SELECT);
    }

  }

  public exportdocFile(): void {
    const nameFile = this.iataAirport + '_DIVERGENTE_'+ this.datePipe.transform(this.dataAtual, 'dd_MM_yyyy');
    this.factoryReportExcel()
    this.generateExecelFileService.exportAsExcelFile(this.excelListDataReport, nameFile)
  }

  private factoryReportExcel(): void{
    this.excelListDataReport = new Array<ConciliationExcelReport>();
    for(let report of this.excelListData){
      let reportExcel = new ConciliationExcelReport();
      reportExcel.dtOperation = report.dtOperation;
      reportExcel.numFlight = report.numFlight;
      reportExcel.valRateBoard = report.valRateBoard;
      reportExcel.valRateCon = report.valRateCon;
      reportExcel.natureFlight = report.natureFlight;
      reportExcel.typeOperation = report.typeOperation;
      reportExcel.importedPlanEmb = report.importedQtdPax.amountBoadDom != 0 ? report.importedQtdPax.amountBoadDom : report.importedQtdPax.amountBoadInt;
      reportExcel.importedPlanCon = report.importedQtdPax.amountConDom != 0 ? report.importedQtdPax.amountConDom : report.importedQtdPax.amountConInt;
      reportExcel.importedGTEEmb = report.gteQtdPax.amountBoadDom != 0 ? report.gteQtdPax.amountBoadDom : report.gteQtdPax.amountBoadInt;
      reportExcel.importedGTECon =  report.gteQtdPax.amountConDom != 0 ? report.gteQtdPax.amountConDom : report.gteQtdPax.amountConInt;
      reportExcel.diffQtdPaxBoard = report.diffQtdPaxBoard;
      reportExcel.diffQtdPaxConection = report.diffQtdPaxConection;
      reportExcel.amountIsn = report.amountIsn;
      reportExcel.amountIsnColo = report.amountIsnColo;
      reportExcel.amountIsnTransit = report.amountIsnTransit;
      this.excelListDataReport.push(reportExcel);
    }
  }

  private companyAndNaturesaNotSelected() : boolean{

    if(this.iataCompany !== null && this.iataCompany !== undefined
      && (this.typeNatureChoose === null || this.typeNatureChoose === undefined ||
        this.typeNatureChoose === "") ){

        $('#panelTableConciliation').fadeOut();
            this.openDialog(
              properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
                  properties.MODAL_CONTENT_NOT_VALID_NATURE);

        this.enableComponents();
        return true;
    }

    if(this.typeNatureChoose !== null && this.typeNatureChoose !== undefined
       && (this.iataCompany === null || this.iataCompany === undefined || this.iataCompany==="")){

        $('#panelTableConciliation').fadeOut();
            this.openDialog(
              properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
                  properties.MODAL_CONTENT_NOT_VALID_COMPANY);

        this.enableComponents();
        return true;
    }

    return false;
  }

  private enableComponents(): void{

    this.aeroportoCtrl.enable();
    this.empresaCtrl.enable();
    this.natureCtrl.enable();
    this.controlSave = false;

  }

}
