import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './app-commons/app-header/app-header.component';
import { FooterComponent } from './app-commons/app-footer/app-footer.component';
import { DashboardLineComponent } from './app-datahealth/app-dashboard-line/app-dashboard-line.component';
import { DashboardPizzaComponent } from './app-datahealth/app-dashboard-pizza/app-dashboard-pizza.component';
import { DashboardcolumnComponent } from './app-datahealth/app-dashboard-column/app-dashboard-column.component';
import { HomeListDashboardComponent } from './app-datahealth/app-home-dashboard-list/app-home-dashboard-list.component';
import { ListarDadosVooComponent } from './app-datahealth/app-data-flight-list/app-data-flight-list.component';
import { IndicadoresComponent } from './app-datahealth/app-indicators/app-indicators.component';
import { SearchInformationByPeriodComponent } from './app-datahealth/app-search-information-by-period/app-search-information-by-period.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SecondLevelDashboardComponent } from './app-datahealth/app-second-level-dashboard/app-second-level-dashboard.component';
import { ThirdLevelDashboardComponent } from './app-datahealth/app-third-level-dashboard/app-third-level-dashboard.component';
import { MaterialModule } from './material.module';
import { TotalizadoresComponent } from './app-datahealth/app-totalizers/app-totalizers.component';
import { ModalChamadoComponent } from './app-datahealth/app-dialog-ticket/app-dialog-ticket.component';
import { DialogCustomComponent } from './app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ListarRpesNaoGeradosComponent } from './app-datahealth/app-rpe-not-generated-list/app-rpe-not-generated-list.component';
import { HomeListaIndicadoresComponent } from './app-datahealth/app-home-indicators-list/app-home-indicators-list.component';
import { UtilitariosModule } from './app-datahealth/app-utils/utilitarios.module';
import { DatePipe, registerLocaleData, CommonModule } from '@angular/common';

import localePt from '@angular/common/locales/pt';
import { AppPaymentsComponent } from './app-datahealth/app-payments/app-payments.component';
import { EditarRpeComponent } from './app-datahealth/app-data-rpe-list/app-data-rpe-list.component';
import { EditarRpeFormularioComponent } from './app-datahealth/app-edit-rpe-form/app-edit-rpe-form.component';
import { HomeEditarRpeComponent } from './app-datahealth/app-home-edit-rpe/app-home-edit-rpe.component';
import { SearchInformationByDateAndRpeComponent } from './app-datahealth/app-search-information-by-date-and-rpe/app-search-information-by-date-and-rpe.component';
import { AppDashboardContestationComponent } from './app-datahealth/app-dashboard-contestation/app-dashboard-contestation.component';
import { AppDataContestationListComponent } from './app-datahealth/app-data-contestation-list/app-data-contestation-list.component';
import { AppUploadFileComponent } from './app-datahealth/app-upload-file/app-upload-file.component';
import { AppUploadFileService } from './app-datahealth/app-upload-file/app-upload-file.service';
import { AppLoginComponent } from './app-login/app-login.component';
import { SpinnerInterceptor } from './app-interceptor/spinner.interceptor';
import { AuthInterceptor } from './app-interceptor/auth.interceptor';
import { ErrorInterceptor } from './app-interceptor/error.interceptor';
import { AuthFilter } from './app-authenticator/auth.filter';
import { AppConciliationLayoutComponent } from './app-conciliation/app-conciliation-layout/app-conciliation-layout.component';
import { UploadContestationFileComponent } from './app-datahealth/app-contestation-upload-file/app-contestation-upload-file';
import { DragDropDirective } from './drag-drop.directive';
import { ConciliationModule } from './app-conciliation/conciliation/conciliation.module';

import { AppDashboardWorkaroundComponent } from './app-datahealth/app-dashboard-workaround/app-dashboard-workaround.component';
import { AppDashboardRpeGeneratedFlightsComponent } from './app-datahealth/app-dashboard-rpe-generated-flights/app-dashboard-rpe-generated-flights.component';
import { AppDashboardPizzaWorkaroundComponent } from './app-datahealth/app-dashboard-pizza-workaround/app-dashboard-pizza-workaround.component';
import { AppSecondLevelDashboardWorkaroundComponent } from './app-datahealth/app-second-level-dashboard-workaround/app-second-level-dashboard-workaround.component';
import { AppDashboardColumnWorkaroundComponent } from './app-datahealth/app-dashboard-column-workaround/app-dashboard-column-workaround.component';
import { AppDataWorkaroundListComponent } from './app-datahealth/app-data-workaround-list/app-data-workaround-list.component';
import { AppThirdLevelDashboardWorkaroundComponent } from './app-datahealth/app-third-level-dashboard-workaround/app-third-level-dashboard-workaround.component';
import { AppDashboardPizzaRpeGeneratedComponent } from './app-datahealth/app-dashboard-pizza-rpe-generated/app-dashboard-pizza-rpe-generated.component';
import { AppSecondLevelDashboardRpeGeneratedComponent } from './app-datahealth/app-second-level-dashboard-rpe-generated/app-second-level-dashboard-rpe-generated.component';
import { AppThirdLevelDashboardRpeGeneratedComponent } from './app-datahealth/app-third-level-dashboard-rpe-generated/app-third-level-dashboard-rpe-generated.component';
import { AppDashboardColumnRpeGeneratedComponent } from './app-datahealth/app-dashboard-column-rpe-generated/app-dashboard-column-rpe-generated.component';
import { AppDataRpeGeneratedListComponent } from './app-datahealth/app-data-rpe-generated-list/app-data-rpe-generated-list.component';
import { AppDashboardStampComponent } from './app-datahealth/app-dashboard-stamp/app-dashboard-stamp.component';
import { AppSecondLevelDashboardStampComponent } from './app-datahealth/app-second-level-dashboard-stamp/app-second-level-dashboard-stamp.component';
import { AppDashboardColumnStampComponent } from './app-datahealth/app-dashboard-column-stamp/app-dashboard-column-stamp.component';
import { AppDataStampListComponent } from './app-datahealth/app-data-stamp-list/app-data-stamp-list.component';
import { AppFormParameterComponent } from './app-datahealth/app-form-parameter/app-form-parameter.component';
import { AppGenerateConciliationComponent } from './app-conciliation/app-generate-conciliation/app-generate-conciliation.component';
import { AppAdminsitratorAirportComponent } from './app-gte/app-administrator-airport/app-administrator-airport.component';
import { AppModalAdministratorComponent } from './app-gte/app-administrator-airport/app-modal-administrator/app-modal-administrator.component';
import { AppModalDeletedComponent } from './app-gte/app-administrator-airport/app-modal-deleted/app-modal-deleted.component';
import { AppReportsComponent } from './app-gte/app-reports/app-reports.component';
import { AppGeneratedManualFilesComponent } from './app-gte/app-generated-manual-files/app-generated-manual-files.component';
import { AppRpeReportSummaryComponent } from './app-gte/app-flight-report-summary/app-rpe-report-summary.component';
import { RpeResumeFileComponent } from './app-gte/rpe-resume-file/rpe-resume-file.component';
import { AppModalStampComponent } from './app-gte/app-flight-report-summary/app-modal-stamp/app-modal-stamp.component';
import { AppModalReportTypeComponent } from './app-gte/app-flight-report-summary/app-modal-report-type/app-modal-report-type.component';
import { environment } from '../environments/environment';
import { AppAdministratorComponent } from './app-access/app-access-administrator/app-administrator/app-administrator.component';
import { AppModalAdministratorUserComponent } from './app-access/app-access-administrator/modal/app-modal-administrator-user.component';
import { ProfileGuard } from './app-authenticator/guard/profile.guard';
import { AppExclusionRpeSeloComponent } from './app-gte/app-exclusion/app-exclusion-rpe-selo.component';
import { AppDeletionConfirmationRpeSeloComponent } from './app-gte/app-exclusion/modal/app-deletion-confirmation-rpe-selo.component';
import { AppExclusionInformationFileRpeSeloComponent } from './app-gte/app-exclusion/modal/information/app-exclusion-information-file-rpe-selo.component';
import { LogsComponent } from './app-gte/app-exclusion/logs/logs.component';
import { AppGeneratedManualFilesAsyncComponent } from './app-gte/app-generated-manual-files-async/app-generated-manual-files-async.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CookieUtilsService } from './app-cookies/cookie-utils.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgChartsModule } from 'ng2-charts';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GoogleApiModule, NG_GAPI_CONFIG, NgGapiClientConfig } from 'ng-gapi';
import { MatIconModule } from '@angular/material/icon';



export const options: Partial<any> | (() => Partial<any>) = {};

registerLocaleData(localePt);


const gapiClientConfig: NgGapiClientConfig = {
  client_id: `${environment.um}${environment.dois}${environment.tres}${environment.quatro}${environment.cinco}`,
  discoveryDocs: [],
  redirect_uri: 'http://localhost:4200/',
  hosted_domain: 'latam.com',
  scope: [
    'https://www.googleapis.com/auth/userinfo.profile'
  ].join(' ')
};


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DashboardLineComponent,
    DashboardPizzaComponent,
    DashboardcolumnComponent,
    HomeListDashboardComponent,
    SearchInformationByPeriodComponent,
    SecondLevelDashboardComponent,
    ThirdLevelDashboardComponent,
    ListarDadosVooComponent,
    IndicadoresComponent,
    TotalizadoresComponent,
    ModalChamadoComponent,
    DialogCustomComponent,
    ListarRpesNaoGeradosComponent,
    HomeListaIndicadoresComponent,
    AppPaymentsComponent,
    EditarRpeComponent,
    EditarRpeFormularioComponent,
    HomeEditarRpeComponent,
    SearchInformationByDateAndRpeComponent,
    AppDashboardContestationComponent,
    AppDataContestationListComponent,
    AppUploadFileComponent,
    DragDropDirective,
    UploadContestationFileComponent,
    AppConciliationLayoutComponent,
    AppPaymentsComponent,
    AppLoginComponent,
    AppDashboardWorkaroundComponent,
    AppDashboardRpeGeneratedFlightsComponent,
    AppDashboardPizzaWorkaroundComponent,
    AppSecondLevelDashboardWorkaroundComponent,
    AppDashboardColumnWorkaroundComponent,
    AppDataWorkaroundListComponent,
    AppThirdLevelDashboardWorkaroundComponent,
    AppDashboardPizzaRpeGeneratedComponent,
    AppSecondLevelDashboardRpeGeneratedComponent,
    AppThirdLevelDashboardRpeGeneratedComponent,
    AppDashboardColumnRpeGeneratedComponent,
    AppDataRpeGeneratedListComponent,
    AppDashboardStampComponent,
    AppSecondLevelDashboardStampComponent,
    AppDashboardColumnStampComponent,
    AppDataStampListComponent,
    AppFormParameterComponent,
    AppGenerateConciliationComponent,
    AppAdminsitratorAirportComponent,
    AppModalAdministratorComponent,
    AppModalDeletedComponent,
    AppReportsComponent,
    AppGeneratedManualFilesComponent,
    AppRpeReportSummaryComponent,
    RpeResumeFileComponent,
    AppModalStampComponent,
    AppModalReportTypeComponent,
    AppAdministratorComponent,
    AppModalAdministratorUserComponent,
    AppExclusionRpeSeloComponent,
    AppDeletionConfirmationRpeSeloComponent,
    AppExclusionInformationFileRpeSeloComponent,
    LogsComponent,
    AppGeneratedManualFilesAsyncComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MaterialModule,
    HttpClientModule,
    UtilitariosModule,
    NgxSpinnerModule,
    CommonModule,
    BrowserAnimationsModule,
    ConciliationModule,
    NgbModule,
    MatCheckboxModule,
    NgChartsModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    EditarRpeFormularioComponent,
    DatePipe,
    AppUploadFileService,
    AuthFilter,
    ProfileGuard,
    CookieUtilsService,
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ],
  bootstrap: [AppComponent],
  exports: [
    MatTooltipModule,
    NgbModalModule,
    SecondLevelDashboardComponent,
    ThirdLevelDashboardComponent
  ],
  entryComponents: [
    ModalChamadoComponent,
    DialogCustomComponent,
    AppModalAdministratorComponent,
    AppModalDeletedComponent,
    AppModalStampComponent,
    AppModalReportTypeComponent,
    AppModalAdministratorUserComponent,
    AppDeletionConfirmationRpeSeloComponent,
    AppExclusionInformationFileRpeSeloComponent,
    LogsComponent,
  ]
})
export class AppModule { }

