
<app-search-information-by-date-and-rpe
    (rpeGerado)="receberRpe($event)" >
</app-search-information-by-date-and-rpe>

<app-edit-rpe-form
    [events]="eventsSubject.asObservable()"
    (events)="eventsSubjectTwo.asObservable()"
    [eventsId]="eventsSubjectId.asObservable()">
</app-edit-rpe-form>

<br>

