import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { endpoints } from '../../../environments/endpoint';
import { Voo } from './model/voo';
import { Periodo } from '../app-search-information-by-period/Periodo';
import { Index } from './model';
import { VooData } from './model/voo-data';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ListarRpesNaoGeradosService {

  constructor(private http: HttpClient) { }

  public obterIndicadorCritico(): Observable<Voo[]> {
    return this.obterIdicador(endpoints.REST_GET_RPES_NAO_GERADOS_CRITICO);
  }

  public obterIndicadorIntermediario(): Observable<Voo[]> {
    return this.obterIdicador(endpoints.REST_GET_RPES_NAO_GERADOS_INTERMEDIARIO);
  }

  public obterIndicadorInformativo(): Observable<Voo[]> {
    return this.obterIdicador(endpoints.REST_GET_RPES_NAO_GERADOS_INFORMATIVO);
  }

  private obterIdicador(url: string): Observable<Voo[]> {
    return this.http.get<Voo[]>(url);
  }

  public obterListaMulta(periodo: Periodo):  Observable<VooData>{

    let params1 = new HttpParams().set('flightBeginDate', periodo.getDtInicio());
    let params2 = new HttpParams().set('flightEndDate', periodo.getDtFim());
    let params3 = new HttpParams().set('IndexCollectionInit', '');
    let params4 = new HttpParams().set('IndexCollectionEnd', '');

    return this.http.get<VooData>(endpoints.REST_GET_LIST_VALOR_MULTA+"?"+params1+"&"+params2+"&"+params3+"&"+params4, httpOptions);
  }

  public obterListaMultaParcial(periodoIni: any, periodoFim: any):  Observable<VooData>{

    let params1 = new HttpParams().set('flightBeginDate', periodoIni);
    let params2 = new HttpParams().set('flightEndDate', periodoFim);
    let params3 = new HttpParams().set('IndexCollectionInit', '');
    let params4 = new HttpParams().set('IndexCollectionEnd', '');

    return this.http.get<VooData>(endpoints.REST_GET_LIST_VALOR_MULTA+"?"+params1+"&"+params2+"&"+params3+"&"+params4, httpOptions);
  }

}
