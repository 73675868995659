<div>
  <form>
    <div class="card text-center">
      <div class="card-header titleModalDelete">
        <h5>Confirmar Exclusão</h5>
      </div>

      <div class="card-body">
        <table
          mat-table
          [dataSource]="dataSourceFileRPE"
          class="mat-elevation-z8"
        >
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection?.toggle(row) : null"
                [checked]="selection?.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="typeFile">
            <th mat-header-cell *matHeaderCellDef>Arquivo</th>
            <td mat-cell *matCellDef="let element">
              {{ element.typeReportCd == "2" ? "RPE" : "SELO" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="numFile">
            <th mat-header-cell *matHeaderCellDef>Cod. Arquivo</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <ng-container matColumnDef="numFlightArrival">
            <th mat-header-cell *matHeaderCellDef>Num. Pouso</th>
            <td mat-cell *matCellDef="let element">
              {{ exibeInformacaoTeste(element.numFlightArrival) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="dateFlightArrival">
            <th mat-header-cell *matHeaderCellDef>Dt. pouso</th>
            <td mat-cell *matCellDef="let element">
              {{ getFormatDateToStringIso(element.dateFlightArrival) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="numFlightDeparture">
            <th mat-header-cell *matHeaderCellDef>Num. Decolagem</th>
            <td mat-cell *matCellDef="let element">
              {{ element.numFlightDeparture }}
            </td>
          </ng-container>

          <ng-container matColumnDef="dateFlightDeparture">
            <th mat-header-cell *matHeaderCellDef>Dt. decolagem</th>
            <td mat-cell *matCellDef="let element">
              {{ getFormatDateToStringIso(element.dateFlightDeparture) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="dateFileGeneration">
            <th mat-header-cell *matHeaderCellDef>Dt. Geração de arquivo</th>
            <td mat-cell *matCellDef="let element">
              {{ getFormatDateToStringIso(element.dateGeneratedFile) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="generationType">
            <th mat-header-cell *matHeaderCellDef>Tp. Geração</th>
            <td mat-cell *matCellDef="let element">
              {{ element.typeGenerated }}
            </td>
          </ng-container>

          <ng-container matColumnDef="flightOrigin">
            <th mat-header-cell *matHeaderCellDef>Origem</th>
            <td mat-cell *matCellDef="let element">
              {{ element.iataAirportOrigin }}
            </td>
          </ng-container>

          <ng-container matColumnDef="flightDestiny">
            <th mat-header-cell *matHeaderCellDef>Destino</th>
            <td mat-cell *matCellDef="let element">
              {{ element.iataAirportDest }}
            </td>
          </ng-container>

          <ng-container matColumnDef="natureType">
            <th mat-header-cell *matHeaderCellDef>Tp. Natureza</th>
            <td mat-cell *matCellDef="let element">{{ element.typeNature }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <div class="rodape">
        <div class="col-2 label">
          <label for="reason">Motivo Exclusão: </label>
        </div>
        <div class="col-4">
          <textarea
            matInput
            placeholder="Informe o motivo da exclusão"
            cdkAutosizeMinRows="2"
            id="reason"
            name="reason"
            [(ngModel)]="reason"
            [disabled]="controlSave"
            cdkAutosizeMaxRows="2"
          ></textarea>
        </div>
      </div>
      <br />

      <div class="form-row aliginCenterContent">
        <button
          mat-raised-button
          matTooltip="Cancelar "
          class="btn btn-danger btnClean"
          (click)="cancelOperation()"
          [disabled]="controlSave"
        >
          <mat-icon style="font-size: x-large">cancel</mat-icon>
          Cancelar
        </button>
        <button
          mat-raised-button
          matTooltip="Confirmar "
          class="btn btn-primary btnSave"
          (click)="deleteFileRpeSelo()"
          [disabled]="controlSave"
        >
          <mat-icon style="font-size: x-large">delete_forever</mat-icon>
          Confirmar
        </button>
      </div>
      <br />
    </div>
  </form>
</div>
