<br>
<div class="card text-center">
  <div class="card-header" style="background-color: #1b0088;">
    <h5 style="color: #ffffff;">Geração Manual de Arquivos {{reportTitle}}</h5>
  </div>
  <div class="card-body">
    <form #searchInfoReport>
      <br>
      <div class="row col-12 d-flex justify-content-start">

        <mat-form-field id="matFormFieldCompany" class="inputPadrao col-3" [ngClass]="{'col-1': reportType == 'rpe', 'col-2': reportType == 'stamp'}">
          <input matInput id="company" name="company" [(ngModel)]="formSearchMF.companyName" placeholder="Empresa"
            aria-label="Empresa" [formControl]="empresaCtrl"
            oninput="this.value = this.value.toUpperCase()" [matAutocomplete]="autoCompany">
          <mat-autocomplete #autoCompany="matAutocomplete">
            <mat-option *ngFor="let empresa of filteredEmpresa | async" [value]="empresa.iataCode">
              <span>{{empresa.iataCode}} - {{empresa.nameCompany}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field id="matFormFieldOrigin" class="inputPadrao col-3">
          <input matInput id="origin" name="origin" [(ngModel)]="formSearchMF.airportName" placeholder="Aeroporto"
            aria-label="Aeroporto" [formControl]="aeroportoCtrl"
            oninput="this.value = this.value.toUpperCase()" [matAutocomplete]="autoAirport">
          <mat-autocomplete #autoAirport="matAutocomplete">
            <mat-option *ngFor="let aeroporto of filteredAeroporto | async" [value]="aeroporto.codeIATA">
              <span>{{aeroporto.codeIATA}} - {{aeroporto.descriptionName}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="col-3">
          <mat-label>Data Inicial</mat-label>
          <input matInput id="startDate" [matDatepicker]="datepickerInicial" [(ngModel)]="formSearchMF.startDate" placeholder="dd/mm/yyyy" name="startDate" maxlength="10" (click)="datepickerInicial.open()">
          <mat-datepicker-toggle matSuffix [for]="datepickerInicial"></mat-datepicker-toggle>
          <mat-datepicker #datepickerInicial></mat-datepicker>
        </mat-form-field>

        <mat-form-field id="matFormFieldOrigin" class="inputPadrao col-3" [ngClass]="{'col-1': reportType == 'rpe', 'col-2': reportType == 'stamp'}">
          <mat-label>Núm. Decolagem</mat-label>
          <input matInput
          id="numberFlightDeparture"
          placeholder="Núm. Decolagem"
          autocomplete="off"
          [(ngModel)]="formSearchMF.numberFlightDeparture"
          [disabled]="controlSaveMF"
          class="form-control"
          name="numberFlightDeparture" />
        </mat-form-field>

        <mat-form-field id="matFormFieldOrigin" class="inputPadrao col-3" [hidden]="reportType == 'stamp'">
          <mat-label>Núm. Pouso</mat-label>
          <input matInput
          id="numberFlightArrival"
          placeholder="Num. Pouso"
          autocomplete="off"
          [(ngModel)]="formSearchMF.numberFlightArrival"
          [disabled]="controlSaveMF"
          class="form-control"
          name="numberFlightArrival" />
        </mat-form-field>

      </div>
      <br>
      <div class="form-row">
        <div class="text-center col-12">
          <button type="button" class="btn btn-danger btnClean" (click)="clearFields()" [disabled]="controlSaveMF">
            <mat-icon style="font-size: x-large;">delete</mat-icon> Limpar Campos
          </button>
          <button type="button" class="btn btn-primary btnSave" (click)="searchFlight()" [disabled]="controlSaveMF">
            <mat-icon style="font-size: x-large;">search</mat-icon> Pesquisar Voo(s)
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<br>
<div class="card text-center col-12" [hidden]="controlShowTableMF">
  <div class="card-header" >
    <h6>Lista de Voos Não processados </h6>
  </div>
  <div class="card-body">

    <table class="mat-elevation-z8" mat-table [dataSource]="dataSourceflightsMF" style="width: 100%;">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection?.hasValue() && isAllSelected()"
                        [indeterminate]="selection?.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection?.toggle(row) : null"
                        [checked]="selection?.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="compyCodeIataArrival">
        <th mat-header-cell *matHeaderCellDef>{{reportType == 'rpe' ? 'Emp. Aerea' : ''}}</th>
        <td [ngClass]="{'backgroudFlightPrevius': reportType == 'rpe'}" mat-cell *matCellDef="let v">{{ v.compyCodeIataArrival }}</td>
      </ng-container>

      <ng-container matColumnDef="originIataCodeArrival">
        <th mat-header-cell *matHeaderCellDef>{{reportType == 'rpe' ? 'Origem' : ''}}</th>
        <td [ngClass]="{'backgroudFlightPrevius': reportType == 'rpe'}" mat-cell *matCellDef="let v">{{ v.originIataCodeArrival }}</td>
      </ng-container>

      <ng-container matColumnDef="destIataCodeArrival">
        <th mat-header-cell *matHeaderCellDef>{{reportType == 'rpe' ? 'Destino' : ''}}</th>
        <td [ngClass]="{'backgroudFlightPrevius': reportType == 'rpe'}" mat-cell *matCellDef="let v">{{ v.destIataCodeArrival }}</td>
      </ng-container>

      <ng-container matColumnDef="numFlightArrival">
        <th mat-header-cell *matHeaderCellDef>{{reportType == 'rpe' ? 'Num. Voo' : ''}}</th>
        <td [ngClass]="{'backgroudFlightPrevius': reportType == 'rpe'}" mat-cell *matCellDef="let v">{{ v.numFlightArrival != 0 ? v.numFlightArrival : '' }}</td>
      </ng-container>

      <ng-container matColumnDef="dateFlightArrivalOrigin">
        <th mat-header-cell *matHeaderCellDef>{{reportType == 'rpe' ? 'Dt. Voo Ori.' : ''}}</th>
        <td [ngClass]="{'backgroudFlightPrevius': reportType == 'rpe'}" mat-cell *matCellDef="let v">{{ getFormatDateToStringIso(v.dttmFlightArrivalOrigin) }}</td>
      </ng-container>

      <ng-container matColumnDef="dateFlightArrivalDestine">
        <th mat-header-cell *matHeaderCellDef>{{reportType == 'rpe' ? 'Dt. Voo Dest.' : ''}}</th>
        <td [ngClass]="{'backgroudFlightPrevius': reportType == 'rpe'}" mat-cell *matCellDef="let v">{{ getFormatDateToStringIso(v.dttmFlightArrivalDestine) }}</td>
      </ng-container>

      <ng-container matColumnDef="compyCodeIata">
        <th mat-header-cell *matHeaderCellDef>Emp. Aerea</th>
        <td [ngClass]="{'backgroudFlightActual': reportType == 'rpe'}" mat-cell *matCellDef="let v">{{ v.compyCodeIata }}</td>
      </ng-container>

      <ng-container matColumnDef="originIataCode">
        <th mat-header-cell *matHeaderCellDef>Origem</th>
        <td [ngClass]="{'backgroudFlightActual': reportType == 'rpe'}" mat-cell *matCellDef="let v">{{ v.originIataCode }}</td>
      </ng-container>

      <ng-container matColumnDef="destIataCode">
        <th mat-header-cell *matHeaderCellDef>Destino</th>
        <td [ngClass]="{'backgroudFlightActual': reportType == 'rpe'}" mat-cell *matCellDef="let v">{{ v.destIataCode }}</td>
      </ng-container>

      <ng-container matColumnDef="numFlightDeparture">
        <th mat-header-cell *matHeaderCellDef>Num. Voo</th>
        <td [ngClass]="{'backgroudFlightActual': reportType == 'rpe'}" mat-cell *matCellDef="let v">{{ v.numFlightDeparture != 0 ? v.numFlightDeparture : '' }}</td>
      </ng-container>

      <ng-container matColumnDef="dttmFlightDepartureOrigin">
        <th mat-header-cell *matHeaderCellDef>Dt. Voo Ori.</th>
        <td [ngClass]="{'backgroudFlightActual': reportType == 'rpe'}" mat-cell *matCellDef="let v">{{ getFormatDateToStringIso(v.dttmFlightDepartureOrigin) }}</td>
      </ng-container>

      <ng-container matColumnDef="dttmFlightDepartureDestine">
        <th mat-header-cell *matHeaderCellDef>Dt. Voo Dest.</th>
        <td [ngClass]="{'backgroudFlightActual': reportType == 'rpe'}" mat-cell *matCellDef="let v">{{ getFormatDateToStringIso(v.dttmFlightDepartureDestine) }}</td>
      </ng-container>

      <ng-container matColumnDef="header-row-selected">
        <th class="headerTableFlight" mat-header-cell *matHeaderCellDef
            [style.text-align]="center"
            [attr.colspan]="1">
        </th>
      </ng-container>

      <ng-container matColumnDef="header-row-arrival-flight">
        <th class="headerTableFlight" mat-header-cell *matHeaderCellDef
            [style.text-align]="center"
            [attr.colspan]="6">
          Voo de Pouso (Anterior)
        </th>
      </ng-container>

      <!-- Header row second group -->
      <ng-container matColumnDef="header-row-departure-flight">
        <th class="headerTableFlight" mat-header-cell *matHeaderCellDef
            [style.text-align]="center" [attr.colspan]="6">
          Voo Atual
        </th>
      </ng-container>

      <tr id="headerRpeFlight" mat-header-row *matHeaderRowDef="['header-row-selected', 'header-row-arrival-flight', 'header-row-departure-flight']"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsMnFile"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsMnFile"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>

    <button
      type="button"
      class="btn btn-primary btnSave generatedFiles"
      (click)="generatedFiles()"
      [disabled]="enableToGeneratedFilesGMF() || controlSaveMF">
        <mat-icon style="font-size: x-large;">cloud_download</mat-icon> Gerar Arquivo(s)
    </button>

  </div>
</div>
