import { Injectable } from '@angular/core';
import { endpoints } from '../../../environments/endpoint';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardColumnRQ } from './model/DashboardColumnRQ';
import { DashboardColumnRS } from './model/DashboardColumnRS';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class DashboardcolumnService {

  constructor(private http: HttpClient) { }

  private convertDataIsoDCol(requestDcol: DashboardColumnRQ): void {
    let arrayStringDcol: string[] = [];
    arrayStringDcol = requestDcol.dtVoo.split('/');
    requestDcol.dtVoo = arrayStringDcol[2]
                    + '-' +  arrayStringDcol[1]
                      + '-' +  arrayStringDcol[0]
                        + 'T00:00:00';
  }

  getDataByDate(requestDcol: DashboardColumnRQ): Observable<DashboardColumnRS[]> {

    if (!requestDcol.dtVoo.includes('T')) {
      this.convertDataIsoDCol(requestDcol);
    }
    let params1 = new HttpParams().set('flightDate', requestDcol.dtVoo);
    //console.log('Data dashboard column : {}', requestDcol);
    return this.http.get<DashboardColumnRS[]>(endpoints.REST_POST_RPE_NOT_GENERATE_GROUP_BY_TP_ERROR+"/"+requestDcol.sistemaOrigem+"/qtd-rpe-type?"+params1, httpOptions);
  }
}
