<div class="lista">

  <h4>Confirmar Importação</h4>

  <p>O sistema está interpretando a importação com o layout abaixo </p>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

    <ng-container matColumnDef="operacao">
      <th mat-header-cell *matHeaderCellDef>DATA DA OPERAÇÃO</th>
      <td mat-cell *matCellDef="let v">{{ v.dtOperation}}</td>
    </ng-container>

    <ng-container matColumnDef="numeroVoo">
      <th mat-header-cell *matHeaderCellDef>NÚMERO DO VOO</th>
      <td mat-cell *matCellDef="let v">{{ v.numFlight }}</td>
    </ng-container>

    <ng-container matColumnDef="quantidadePassageiro">
      <th mat-header-cell *matHeaderCellDef>QUANTIDADE DE PASSAGEIROS</th>
      <td mat-cell *matCellDef="let v">{{ v.qntPassageiros }}</td>
    </ng-container>

    <ng-container matColumnDef="tipoOperacao">
      <th mat-header-cell *matHeaderCellDef>TIPO DE OPERAÇÃO</th>
      <td mat-cell *matCellDef="let v">{{ v.tpOperation }}</td>
    </ng-container>

    <ng-container matColumnDef="tipoEmbarque">
      <th mat-header-cell *matHeaderCellDef>TIPO DE EMBARQUE</th>
      <td mat-cell *matCellDef="let v">{{ v.tpNature }}</td>
    </ng-container>

    <ng-container matColumnDef="valorTarifa">
      <th mat-header-cell *matHeaderCellDef>VALOR DA TARIFA</th>
      <td mat-cell *matCellDef="let v">{{ v.valRate | mascaraDinheiro }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  </table>

  <br>

  <mat-label>Deseja seguir com a importação</mat-label>

  <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
    <mat-button-toggle value="bold" (click)="dialogClose()">Não</mat-button-toggle>
    <mat-button-toggle value="bold" (click)="dialogNext()">Sim</mat-button-toggle>
  </mat-button-toggle-group>
  
</div>