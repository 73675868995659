import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Voo } from './model/voo';
import { ExcelService } from '../../app-conciliation/service/excel.service';
import { ListarRpesNaoGeradosService } from './app-rpe-not-generated-list.service';
import { Protocolo } from '../app-enum/Protocolo';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TruncateModule } from 'ng2-truncate';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Periodo } from '../app-search-information-by-period/Periodo';
import { DialogCustomComponent } from '../app-dialog-custom/app-dialog-custom.component';
import { properties } from '../../../environments/properties';
import { Index } from './model';
import { VooData } from './model/voo-data';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-rpe-not-generated-list',
  templateUrl: './app-rpe-not-generated-list.component.html',
  styleUrls: ['./app-rpe-not-generated-list.component.css']
})
export class ListarRpesNaoGeradosComponent implements OnInit {

  // public dataSourceIndicadores: MatTableDataSource<VooData>;
  public displayedColumns: string[] = ['numeroVoo', 'dataVoo', 'dataGeracao', 'tipoErro', 'tipoOrigem', 'dataEnvioRpe', 'dataFimAjuste', 'ticket', 'sisOrigin'];
    public dataSourceIndicadores: MatTableDataSource<Voo>;
    private eventsSubscriptionId: any;
    public dataAtual: Date = new Date;
    public tipoListaExcel: string;
    public periodo: Periodo;
    public index: Index;
    public periodoListaIni: any;
    public periodoListaFim: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() events: Observable<Periodo>;
  @Input() eventsPeriodo: Observable<Periodo>;

  constructor(private listarRpesNaoGeradosService: ListarRpesNaoGeradosService,
              private excelService: ExcelService,
              private router: Router,
              private route: ActivatedRoute,
              private datePipe: DatePipe,
              public dialog: MatDialog)
              {

               }

  ngOnInit() {

    this.eventsPeriodo.subscribe((periodo) => {
      console.log("Okay, veio pro app-rpe-not-generated-component")
      this.obterIndicadorMulta(periodo, this.index)
    });
    this.eventsSubscriptionId = this.route.params.subscribe(params => {
    this.periodoListaIni = params['dt-ini'];
    this.periodoListaFim = params['dt-fim'];
    const tipoLista = params['tipo-lista'];
      this.obterListaIndicador(this.periodoListaIni, this.periodoListaFim, tipoLista);
    });

  }

  public obterListaIndicador(periodoListaIni: any, periodoListaFim: any, tipoLista: string): void {
    if (tipoLista === 'critico') {
      this.obterIndicadorCritico();
      this.tipoListaExcel = tipoLista;
    } else if (tipoLista === 'intermediario') {
      this.obterIndicadorIntermediario();
      this.tipoListaExcel = tipoLista;
    } else if (tipoLista === 'informativo'){
      this.obterIndicadorInformativo();
      this.tipoListaExcel = tipoLista;
    }else if(tipoLista === 'multaParcial') {
      this.obterIndicadorMultaPeriodo(periodoListaIni,periodoListaFim);
      this.tipoListaExcel = "multa";
      // console.log("CHEGOU NO SERVICO DA MULTA POR PERIODO");
    }else{
      if(this.periodo == undefined ){
        const periodo: Periodo = this.multaPeriodo();
        const index: Index = this.multaIndex();
        this.obterIndicadorMulta(periodo, index);
        this.tipoListaExcel = "multa";
      }else{
      this.obterIndicadorMulta(this.periodo, this.index);
      this.tipoListaExcel = "multa";
      }
    }
    }

  public obterIndicadorMulta(periodo: Periodo, index: Index): void {
    this.listarRpesNaoGeradosService.obterListaMulta(periodo).subscribe(
      dataSource => {
        this.dataSourceIndicadores = new MatTableDataSource(dataSource.data);
        this.dataSourceIndicadores.paginator = this.paginator;
      }, err => {
          let protocolo: Protocolo = err.error;
          // console.log("Protocolo: ", protocolo);
      });
  }

  public obterIndicadorMultaPeriodo(periodoIni: any,periodoFim: any): void {
    this.listarRpesNaoGeradosService.obterListaMultaParcial(periodoIni, periodoFim).subscribe(
      dataSource => {

        // console.log('resposta multa: ' + JSON.stringify(dataSource))
        if(dataSource.quantidadeRpe == '0'){
          this.openDialogRpeNotGen(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
              properties.MODAL_CONTENT_INDICATORS_DATA_NOT_FOUND_RPE_NOT_PROCESSED
          );
        }

        this.dataSourceIndicadores = new MatTableDataSource(dataSource.data);
        this.dataSourceIndicadores.paginator = this.paginator;

      }, err => {
        let protocolo: Protocolo = err.error;
    });
  }

  public obterIndicadorCritico(): void {
    this.listarRpesNaoGeradosService.obterIndicadorCritico().subscribe(
      dataSource => {

        this.dataSourceIndicadores = new MatTableDataSource(dataSource);
        this.dataSourceIndicadores.paginator = this.paginator;
        }, err => {
          let protocolo: Protocolo = err.error;
      });
  }

  public obterIndicadorIntermediario(): void {
    this.listarRpesNaoGeradosService.obterIndicadorIntermediario().subscribe(
      dataSource => {
        this.dataSourceIndicadores = new MatTableDataSource(dataSource);
        this.dataSourceIndicadores.paginator = this.paginator;
      }, err => {
          let protocolo: Protocolo = err.error;
      });
  }

  public obterIndicadorInformativo(): void {
    this.listarRpesNaoGeradosService.obterIndicadorInformativo().subscribe(
      dataSource => {

        this.dataSourceIndicadores = new MatTableDataSource(dataSource);
        this.dataSourceIndicadores.paginator = this.paginator;

      }, err => {
          let protocolo: Protocolo = err.error;
      });
  }

  private multaPeriodo(): Periodo {
    const periodo = new Periodo();
    periodo.setDtInicio( this.getNgDateByDate(this.getActualStartDateToFirstApresentationNotGe()));
    periodo.setDtFim( this.getNgDateByDate(this.getActualEndDateToFirstApresentation()))
    return periodo;
  }

  private multaIndex(): Index {
    const index = new Index();
    index.indexCollectionEnd = '';
    index.indexCollectionInit = '';
    return index;
  }

  exportIndicatorsAsXLSX(): void {
     this.excelService.exportIndicatorsAsXLSXFile(
       this.dataSourceIndicadores.data,
       'INDICADORES_'
       + this.tipoListaExcel.toUpperCase() + '_'
       + this.datePipe.transform(this.dataAtual, 'dd_MM_yyyy'));
  }

  private getActualEndDateToFirstApresentation(): Date {
    return new Date();
  }

  private getActualStartDateToFirstApresentationNotGe(): Date {
    const date: Date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  }

  private getNgDateByDate(data: Date): NgbDate{
    return new NgbDate(data.getFullYear(),(data.getMonth()+1),data.getDate());
  }

  public getNewRequest(event){
    // console.log("Index: ", this.index.indexCollectionEnd);
  }

  private formatDateHour(dt: String): string {
    if ( dt === null || dt === undefined) {
      return '';
    }
    let dtarr: string[];
    let dtarH: string[];
    dtarr = dt.split('T');
    // +" "+ dtarr[1];
    dtarH = dt.split('T');;
    dtarr = dtarr[0].split('-');
    return dtarr[2] + '-' + dtarr[1] + '-' + dtarr[0] + " " + dtarH[1];

  }

  private getFormatDateToString(dt: string): string {
    if ( dt === null || dt === undefined) {
      return '';
    }
    let dtarr: string[];
    dtarr = dt.split('T');
    dtarr = dtarr[0].split('-');
    return dtarr[2] + '-' + dtarr[1] + '-' + dtarr[0];
  }

  public openDialogRpeNotGen(titleParam: string, messageParam: string): void {
    const dialogRef = this.dialog.open(DialogCustomComponent, {
        width: '450px',
        data: {title: titleParam, message: messageParam}
    });
  }


}
