import { Component, Input, OnInit } from '@angular/core';
import { Conciliation } from '../../../model/conciliation';
import { DashboardInformation } from '../../../model/dashboard-information';
import { DateService } from '../../../service/date.service';
import { Router } from '@angular/router';
import { DashboardTypeFilterService } from '../../../service/dashboard-type-filter.service';
import { MatLabel } from '@angular/material/form-field';

@Component({
  selector: 'app-comparative-dashboard-bar-chart',
  templateUrl: './comparative-dashboard-bar-chart.component.html',
  styleUrls: ['./comparative-dashboard-bar-chart.component.css']
})
export class ComparativeDashboardBarChartComponent implements OnInit {

  public barChartLabels: MatLabel[] = [
    'Janeiro', 'Fevereiro', 'Março',
    'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro',
    'Outubro', 'Novembro', 'Dezembro'];

  public conciliations: Conciliation[];

  public barChartType: any = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public indexSelectColun;
  public indexLabelPoint;

  public barChartOptions: any = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [{}]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };
  public barChartData: any[] = [{ data: [], label: '' }];

  yearNow: number;
  type: string;

  private types: string[];

  constructor(private dateService: DateService, private router: Router, dashboardTypeFilter: DashboardTypeFilterService
  ) {

    this.yearNow = new Date().getFullYear();
    this.type = undefined;
    this.types = dashboardTypeFilter.getTypeFilter();

  }

  ngOnInit() { }

  @Input()
  set typeDashboard(type: string) {
    // console.log('entrei para type ', type);
    this.type = type;
    if (this.conciliations !== undefined) {
      this.setBarChartData();
    }
  }

  @Input()
  set dashboard(dashboard: DashboardInformation) {
    if (dashboard !== undefined) {
      this.conciliations = dashboard
        .lastTwelveMonths
        .concat(dashboard.lastYear);
      this.setBarChartData();
    }
  }

  private setBarChartData() {

    if (this.type === this.types[0]) {

      this.barChartData = [
        {
          data: this.getConciliationFrom(this.conciliations, this.yearNow - 1),
          label: (this.yearNow - 1).toString(),
          datalabels: {
            formatter: (value) => {
              return 'R$' + value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
          }
        },
        {
          data: this.getConciliationFrom(this.conciliations, this.yearNow),
          label: this.yearNow.toString(),
          datalabels: {
            formatter: (value) => {
              return 'R$' + value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
          }
        }
      ];


    } else {

      this.barChartData = [
        {
          data: this.getConciliationFrom(this.conciliations, this.yearNow - 1),
          label: (this.yearNow - 1).toString()
        },
        {
          data: this.getConciliationFrom(this.conciliations, this.yearNow),
          label: this.yearNow.toString()
        }
      ];

    }


  }

  private getConciliationFrom(conciliations: Conciliation[], year: number): number[] {

    const data: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    conciliations
      .filter(conciliation => conciliation.dtFlight.getFullYear() === year)
      .forEach(conciliation => {
        const month: number = conciliation.dtFlight.getMonth();

        if (this.type === this.types[0]) {
          data[month] = conciliation.valueRate;
        } else {
          data[month] = conciliation.qntPassager;
        }
      });

    return data;
  }

  public chartClicked(event: any): void {

    // console.log('cliquei na barra: ' + event);

    if (event.active.length > 0) {
      const chart = event.active[0]._chart;
      this.indexSelectColun = event.active[0]._index;
      this.indexLabelPoint = event.active[chart.tooltip._active[0]._datasetIndex].$datalabels.$context.dataset.label;
    }

    const array = event.active;
    const active = array[0]._index
    const label = this.barChartLabels[active];
    const year = this.indexLabelPoint;
    const month = this.indexSelectColun + 1;
    this.router.navigate(['dashboard-final', year, month]);

  }

}
