import { Component, OnInit, Inject } from '@angular/core';
import { RegisterConfirmation } from '../../model/register-confirmation';
import { NextDialog } from '../../model/next-dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-import-confirmation',
  templateUrl: './import-confirmation.component.html',
  styleUrls: ['./import-confirmation.component.css']
})
export class ImportConfirmationComponent implements OnInit {

  displayedColumns: string[] = ['operacao', 'numeroVoo', 'quantidadePassageiro', 'tipoOperacao', 'tipoEmbarque', 'valorTarifa'];

  constructor(public dialogRef: MatDialogRef<ImportConfirmationComponent>,
              @Inject(MAT_DIALOG_DATA) public dataSource: RegisterConfirmation[]) { }

  ngOnInit() { }

  public dialogClose(): void {

    const next: NextDialog = {bool : false};

    this.dialogRef.close(next);

  }

  public dialogNext(): void {

    const next: NextDialog = {bool : true};

    this.dialogRef.close(next);

  }
}
