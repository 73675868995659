import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardTypeFilterService {

  constructor() { }

  public getTypeFilter(): string[] {
    return ['Financeiro', 'Passageiros'];
  }

}
