<br>
<div class="card text-center">
  <div class="card-header" style="background-color: #1b0088;">
    <h5 style="color: #ffffff;">{{reportTitle}}</h5>
  </div>
  <div class="card-body">
    <form [formGroup]="formulario" (ngSubmit)="consult()">
      <br>
      <div class="row">
        <div class="col-6">
          <mat-form-field id="matFormFieldCompany" class="example-full-width inputLayout">
            <input matInput id="company" name="company" placeholder="Empresa" aria-label="Empresa"
              [matAutocomplete]="autoCompany" formControlName="company" oninput="this.value = this.value.toUpperCase()"
              required>
            <mat-autocomplete #autoCompany="matAutocomplete">
              <mat-option *ngFor="let empresa of filteredEmpresa | async" [value]="empresa.iataCode">
                <span>{{empresa.iataCode}} - {{empresa.nameCompany}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field id="matFormFieldOrigin" class="example-full-width inputLayout">
            <input matInput id="origin" name="origin" placeholder="Aeroporto" aria-label="Aeroporto"
              [matAutocomplete]="autoAirport" formControlName="airport" oninput="this.value = this.value.toUpperCase()"
              required>
            <mat-autocomplete #autoAirport="matAutocomplete">
              <mat-option *ngFor="let aeroporto of filteredAeroporto | async" [value]="aeroporto.codeIATA">
                <span>{{aeroporto.codeIATA}} - {{aeroporto.descriptionName}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <mat-form-field [className]="reportTypeRS == 'rpe' ? 'col-3' : 'col-4'">
          <mat-label>Data Inicial</mat-label>
          <input matInput id="startDate" formControlName="flightStartDate" [matDatepicker]="datepickerInicial" placeholder="Data Inicial" name="startDate" maxlength="10" (click)="datepickerInicial.open()">
          <mat-datepicker-toggle matSuffix [for]="datepickerInicial"></mat-datepicker-toggle>
          <mat-datepicker #datepickerInicial></mat-datepicker>
        </mat-form-field>
        <!--
        <div [className]="reportTypeRS == 'rpe' ? 'col-3' : 'col-4'">
          <div class="input-group">
            <input id="startDate" autocomplete="off" formControlName="flightStartDate" (click)="dtStart.toggle()"
              class="form-control" (dateSelect)="onDateStartSelect($event)" placeholder="Data Inicial" name="startDate"
              displayMonths="displayMonths" ngbDatepicker #dtStart="ngbDatepicker" required />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="dtStart.toggle()" type="button">
              </button>
            </div>
          </div>
        </div>
        -->

        <mat-form-field [className]="reportTypeRS == 'rpe' ? 'col-3' : 'col-4'">
          <mat-label>Data Final</mat-label>
          <input matInput id="endDate" [matDatepicker]="datepickerFinal" formControlName="flightEndDate" placeholder="Data final" name="endDate" maxlength="10" (click)="datepickerFinal.open()">
          <mat-datepicker-toggle matSuffix [for]="datepickerFinal"></mat-datepicker-toggle>
          <mat-datepicker #datepickerFinal></mat-datepicker>
        </mat-form-field>
        <!--
        <div [className]="reportTypeRS == 'rpe' ? 'col-3' : 'col-4'">
          <div class="input-group">
            <input id="endDate" autocomplete="off" formControlName="flightEndDate" (click)="dtEnd.toggle()"
              class="form-control" placeholder="Data Final" name="endDate" displayMonths="displayMonths" ngbDatepicker
              #dtEnd="ngbDatepicker" disabled="{{isDisabled}}" [markDisabled]="isDisabledLastOneRpeRep" required />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="dtEnd.toggle()" type="button">
              </button>
            </div>
          </div>
        </div>
        -->
        <mat-form-field [className]="reportTypeRS == 'rpe' ? 'col-3' : 'col-4'">
            <input matInput id="numberFlightDeparture" type="number" autocomplete="off" formControlName="numFlightDeparture"
              class="form-control" placeholder="Número Decolagem" name="numberFlightDeparture" />
        </mat-form-field>

        <div *ngIf="reportTypeRS == 'rpe'" class="col-3">
          <mat-form-field class="input-group">
            <input matInput id="numFlightArrival" type="number" autocomplete="off" formControlName="numFlightArrival"
              class="form-control" placeholder="Número Pouso" name="numFlightArrival" />
          </mat-form-field>
        </div>

      </div>

      <br>

      <div class="form-row">
        <div class="text-center col-12">
          <button type="button" class="btn btn-danger btnClean" (click)="clearFields()" [disabled]="controlSave">
            <mat-icon style="font-size: x-large;">delete</mat-icon> Limpar Campos
          </button>
          <button type="button" class="btn btn-primary btnSave" type="submit" [disabled]="isInvalid() || controlSave">
            <mat-icon style="font-size: x-large;">search</mat-icon> Pesquisar Voo(s)
          </button>
        </div>
      </div>

    </form>

  </div>

</div>

<br>
<div class="card text-center col-12" [hidden]=controlShowTable>

  <br>
  <div class="form-row">
    <div class="col-2 label">
      <label for="filter">Pesquisa: </label>
    </div>
    <div class="col-12">
      <mat-form-field style="width: 100%;">
        <input matInput id="filter" (keyup)="applyFilter($event.target.value)"
          placeholder="Pesquisa: Numero de Voo, Data de Voo" />
      </mat-form-field>
    </div>
  </div>

  <br />
  <table class="mat-elevation-z8" mat-table [dataSource]="dataSourceflights" style="width: 100%;">

  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection?.hasValue() && isAllSelected()"
                    [indeterminate]="selection?.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection?.toggle(row) : null"
                    [checked]="selection?.isSelected(row)"
                    [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </td>
  </ng-container>

    <ng-container matColumnDef="prefix">
      <th mat-header-cell *matHeaderCellDef><!--Prefixo--></th>
      <td mat-cell *matCellDef="let v"><!--{{ v.prefix }}--></td>
    </ng-container>

    <ng-container matColumnDef="typeAircraft">
      <th mat-header-cell *matHeaderCellDef><!--Tp. Aeroporto--></th>
      <td mat-cell *matCellDef="let v"><!--{{ v.typeAircraft }} --></td>
    </ng-container>

    <ng-container matColumnDef="numFlightArrival">
      <th mat-header-cell *matHeaderCellDef>{{reportTypeRS == 'rpe' ? 'Num. Pouso' : ''}}</th>
      <td mat-cell *matCellDef="let v">{{ v.numFlightArrival }}</td>
    </ng-container>

    <ng-container matColumnDef="dateFlightArrival">
      <th mat-header-cell *matHeaderCellDef>{{reportTypeRS == 'rpe' ? 'Dt. pouso' : ''}}</th>
      <td mat-cell *matCellDef="let v">{{ getFormatDateToStringIso(v.dateFlightArrival) }}</td>
    </ng-container>

    <ng-container matColumnDef="numFlightDeparture">
      <th mat-header-cell *matHeaderCellDef>Num. Decolagem</th>
      <td mat-cell *matCellDef="let v">{{ v.numFlightDeparture }}</td>
    </ng-container>

    <ng-container matColumnDef="dttmFlightDeparture">
      <th mat-header-cell *matHeaderCellDef>Dt. decolagem</th>
      <td mat-cell *matCellDef="let v">{{ getFormatDateToStringIso(v.dateFlightDeparture) }}</td>
    </ng-container>

    <ng-container matColumnDef="dateGeneratedFile">
      <th mat-header-cell *matHeaderCellDef>Dt. Geração de arquivo</th>
      <td mat-cell *matCellDef="let v">{{ getFormatDateToStringIso(v.dateGeneratedFile) }}</td>
    </ng-container>
    <ng-container matColumnDef="typeGenerated">
      <th mat-header-cell *matHeaderCellDef>Tp. Geração</th>
      <td mat-cell *matCellDef="let v" matTooltip="{{v.typeGenerated  == 'A' ? 'Automática' : 'Manual'}}" >{{ v.typeGenerated }}</td>
    </ng-container>
    <ng-container matColumnDef="iataAirportOrigin">
      <th mat-header-cell *matHeaderCellDef>Origem</th>
      <td mat-cell *matCellDef="let v">{{ v.iataAirportOrigin }}</td>
    </ng-container>
    <ng-container matColumnDef="iataAirportDest">
      <th mat-header-cell *matHeaderCellDef>Destino</th>
      <td mat-cell *matCellDef="let v">{{ v.iataAirportDest }}</td>
    </ng-container>
    <ng-container matColumnDef="typeNature">
      <th mat-header-cell *matHeaderCellDef >Tp. Natureza</th>
      <td mat-cell *matCellDef="let v" matTooltip="{{v.typeNature == 'I' ? 'Internacional' : 'Domestica'}}" >{{ v.typeNature }}</td>
    </ng-container>
    <ng-container matColumnDef="viewRpe">
      <th mat-header-cell *matHeaderCellDef>
        <i class="material-icons"> search </i>
      </th>
      <td mat-cell *matCellDef="let v">
        <button matTooltip="Visualizar Informações" type="button" class="btn btn-primary-outline" (click)="viewRpe(v.id)">
          <i class="material-icons">
            search
          </i>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="reportListPax">
      <th mat-header-cell *matHeaderCellDef>
        <i class="material-icons">
          description
        </i>
      </th>
      <td mat-cell *matCellDef="let v">
        <!--<button matTooltip="Lista de Passageiros" [disabled]="reportType != 'rpe' || v.dateFlightDeparture == null" type="button" class="btn btn-primary-outline" (click)="openModalListPaxInformation(v)">-->
          <button matTooltip="Lista de Passageiros" type="button" class="btn btn-primary-outline" (click)="openModalListPaxInformation(v)">

          <i class="material-icons">
            description
          </i>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
  <br>
  <div class="div-btn-download">
    <button mat-raised-button class="btnClean" (click)="downloadRPE('txt')" [disabled]="controlSave">
      <mat-icon style="margin-left: 1%;font-size: x-large;">get_app</mat-icon>
      Download
    </button>
    <button mat-raised-button class="btnClean" (click)="downloadRPE('csv')" [disabled]="controlSave" [hidden]=controlShowDownloadCSV>
      <mat-icon style="margin-left: 1%;font-size: x-large;">get_app</mat-icon>
      Download .CSV
    </button>
    <button mat-raised-button class="btnClean" (click)="downloadRPE('pdf')" [disabled]="controlSave" [hidden]=controlShowDownloadPdf>
      <mat-icon style="margin-left: 1%;font-size: x-large;">get_app</mat-icon>
      Download .PDF
    </button>
    <br>
    <br />
  </div>
</div>
