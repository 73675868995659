import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieUtilsService {

  constructor() {
  }

  public setCookie(name: string, val?: string, daysExpire?: number) {
    const date = new Date();
    const value = val;

    // Set it expire in 7 days
    date.setTime(date.getTime() + (daysExpire * 24 * 60 * 60 * 1000));

    // Set it
    let dtU = date.toUTCString()
    let cookie = `${name}=${value};expires=${dtU};path=/`
    document.cookie = cookie;
  }

  public getCookie(name: string) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');

    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return null;
  }

  public deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));
    let dtU = date.toUTCString()
    let cookie = `${name}=;expires=${dtU};path=/`
    // Set it
    document.cookie = cookie;
  }
}


