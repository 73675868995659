import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { DashboardPizzaRQ } from './model/DashboardPizzaRQ';
import { Observable } from 'rxjs';
import { DashboardPizzaRS } from './model/DashboardPizzaRS';
import { endpoints } from '../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppDashboardPizzaRpeGeneratedService {

  constructor(private http: HttpClient) { }

  convertDataIsoPizzaRpeGen(request: DashboardPizzaRQ): void {
    let arrayStringPizzaRpeGen: string[] = [];
    arrayStringPizzaRpeGen = request.dtVoo.split('/');
    request.dtVoo = arrayStringPizzaRpeGen[2]
                    + '-' +  arrayStringPizzaRpeGen[1]
                      + '-' +  arrayStringPizzaRpeGen[0]
                        + 'T00:00:00';
  }

  getDataByDate(requestPizzaRpeGen: DashboardPizzaRQ): Observable<DashboardPizzaRS[]> {
    if (!requestPizzaRpeGen.dtVoo.includes('T')) {
      this.convertDataIsoPizzaRpeGen(requestPizzaRpeGen);
    }
    let params1 = new HttpParams().set('flightBeginDate', requestPizzaRpeGen.dtVoo);
    return this.http.get<DashboardPizzaRS[]>(endpoints.REST_GET_RPE_GENERATED_PIZZA+"?"+params1, httpOptions);
  }

}
