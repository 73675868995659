<div class="row" style="margin-top: 2%; margin-bottom: -5%; margin-left: 1%;">
  <div class="col-12 indicatorsPayment">
    <div class="alert alert-warning valor-multa-c"
      (click)="getIdRequestMulta('multa')">
      <label for="" class="paymentsLabel">Valor Multa Consolidada:</label>
      <strong class="paymentsLabel"> {{ multaConsolidada.valorMulta | mascaraDinheiro }}</strong>
    </div>
    <img src="../../assets/plata.png" alt="">
  </div>
</div>
<div class="row" style="margin-bottom: -6%; margin-left: 1%;">
  <div class="col-12 indicatorsPayment">
    <div class="alert alert-warning valor-multa-p"
      (click)="getIdRequestMultaPeriodo('multaParcial')">
      <label for="" class="paymentsLabel">Valor Multa por Periodo:</label>
      <strong class="paymentsLabel"> {{ multaEscolhaPeriodo.valorMulta | mascaraDinheiro }}</strong>
    </div>
    <img src="../../assets/plata.png" alt="">
  </div>
</div>
