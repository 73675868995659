<br><br>
<div>
  <div>
    <div class="chart">
      <canvas baseChart
        [data]="pieChartDataRep"
        [labels]="pieChartLabelsRep"
        [options]="pieChartOptionsRep"
        [plugins]="pieChartPluginsRep"
        [legend]="pieChartLegendRep"
        (chartClick)="chartClicked($event)">
      </canvas>
      <!-- <canvas baseChart
        [data]="pieChartData"
        [labels]="pieChartLabels"
        [chartType]="pieChartType"
        [options]="pieChartOptions"
        [plugins]="pieChartPlugins"
        [colors]="pieChartColors"
        [legend]="pieChartLegend"
        (chartClick)="chartClicked($event)">
      </canvas> -->
    </div>
  </div>
</div>
