import { Component, OnInit, ViewChild } from '@angular/core';
import { ComboBoxAeroporto } from '../../../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { AirportService } from '../../service/airport.service';
import { Observable } from 'rxjs/Observable';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { NgbDate, NgbDateStruct, NgbDatepickerI18n, NgbDateParserFormatter,NgbDatepickerConfig, NgbCalendar, } from '@ng-bootstrap/ng-bootstrap';

import { properties } from '../../../../../environments/properties';
import { ImportFile } from '../../model/importFile';
import { ImportFileService } from '../../service/import-file.service';
import { DialogCustomComponent } from '../../../../app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { I18n, CustomDatepickerI18n } from '../../../../../../src/app/app-datahealth/app-search-information-by-period/CustomDatepickerI18n';
import { NgbDatePTParserFormatter } from '../../../../../../src/app/app-datahealth/app-search-information-by-period/NgbDatePTParserFormatter';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-imported-spreadsheet-table',
  templateUrl: './imported-spreadsheet-table.component.html',
  styleUrls: ['./imported-spreadsheet-table.component.css'],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter}],
  ],

})
export class ImportedSpreadsheetTableComponent implements OnInit {

  public dtInicialLabel = properties.HTML_CONST_DT_START;

  public dtFinalLabel = properties.HTML_CONST_DT_END;

  public emptyDate = properties.HTML_CONST_DT_TO_SELECTED;

  public displayMonths = properties.HTML_CONST_QTD_MOUNTHS;

  public airports: ComboBoxAeroporto[];

  public airportChoose: string;

  public filteredAeroportos: Observable<ComboBoxAeroporto[]>;

  public airportCtrl: UntypedFormControl = new UntypedFormControl();

  public dtInicial: NgbDate;

  public dtFinal: NgbDate;

  public activeInput: string;

  public isDisabled: boolean;

  public dataSource: MatTableDataSource<ImportFile>;

  public displayedColumns: string[] = ['idArquivo', 'NomeArquivo', 'Operacao'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    airportServiceSprea: AirportService,
    private importService: ImportFileService,
    private dialog: MatDialog,
    config: NgbDatepickerConfig,
    calendar: NgbCalendar,
    public datePipe: DatePipe,
    ) {
      config.minDate = {year: 1976, month: 1, day: 1};
      config.maxDate = {year: 2099, month: 12, day: 31};
      config.outsideDays = 'hidden';
      airportServiceSprea.getAllAirport().subscribe(airportsSprea => {
      this.airports = airportsSprea;
      this.filteredAeroportos = this.airportCtrl
        .valueChanges
        .pipe(startWith(''), map(airport => {
          return airport ? this._filterAirport(airport) : this.airports;
        }));
    });

  }

  ngOnInit() {
  }

  public disabled(): boolean {
    return this.airportChoose === undefined || this.dtInicial === undefined || this.dtFinal === undefined;
  }

  public clickEventHandlerSelectSprea(event: any) {
    this.activeInput = event.currentTarget.id;
  }

  public onDateStartSelect(dtSelect: NgbDate) {
    this.isDisabled = false;
    $('#modalDtFinal').trigger('click');
  }

  public onDateEndSelectSpre(dtSelect: NgbDate) {
  }

  public isDisabledLastOneSpre(date: NgbDateStruct) {

    const dateIniSpre: any = $('#modalDtInicial').val();

    const yearIniSpre: number = parseInt(dateIniSpre.substring(6, 10), 10);
    const mounthIniSpre: number = parseInt(dateIniSpre.substring(3, 5), 10);
    const dayIniSpre: number = parseInt(dateIniSpre.substring(0, 2), 10);

    const dayEndSpre = date.day;
    const monthEndSpre = date.month;
    const yearEndSpre = date.year;

    const dateStartSpre = new Date();
    dateStartSpre.setMonth(mounthIniSpre);
    dateStartSpre.setFullYear(yearIniSpre);
    dateStartSpre.setDate(dayIniSpre);

    const dateEndSpre = new Date();
    dateEndSpre.setMonth(monthEndSpre);
    dateEndSpre.setFullYear(yearEndSpre);
    dateEndSpre.setDate(dayEndSpre);

    return dateEndSpre.getTime() < dateStartSpre.getTime();
  }

  private _filterAirport(value: string): ComboBoxAeroporto[] {

    const filterValue = value.toLowerCase();

    return this.airports.filter(airport => airport.codeICAO.toLowerCase().indexOf(filterValue) === 0);

  }

  public search(): void {

    this.
      importService.
      getImportFileInformationByAirportName(this.airportChoose, this.dtInicial, this.dtFinal)
      .subscribe(importFiles => {

        this.dataSource = new MatTableDataSource(importFiles);
        this.dataSource.paginator = this.paginator;

      });

  }

  public delete(importFile: ImportFile): void {

    this.importService.deleteImportFileInformationById(importFile.id)
      .toPromise()
        .then(() => {
            this.openDialog(properties.MODAL_TITLE_SUCESS, properties.MODAL_BODY_SUCESS_OPERATION);
            const dataAfterFilter = this.dataSource.data.filter(data => data.id !== importFile.id);
            this.dataSource =  new MatTableDataSource(dataAfterFilter);
            this.dataSource.paginator = this.paginator;

        }).catch(() => {
          this.openDialog(properties.MODAL_ERROR_TITLE, properties.MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE);
        });

  }

  private openDialog(titleParam: string, messageParam: string): void {
    this.dialog.open(DialogCustomComponent, {
      width: '450px',
      data: { title: titleParam, message: messageParam }
    });

  }


}
