<div>
    <form [formGroup]="formAdministratorCrud">
        <div class="card text-center">
            <div class="card-header titleModalCrud">
                <h5>Cadastro de Administradores de Aeroportos</h5>
            </div>
            <div class="card-body">

                <div class="form-row" [hidden]="!showAirportList">
                    <div class="col-2 label">
                        <label for="administratorName">Nome da administradora: </label>
                    </div>
                    <div class="col-10">
                        <mat-form-field class="example-full-width inputLayout">
                            <input matInput id="administratorName" name="administratorName"
                                formControlName="administratorName" [(ngModel)]="administrator.name" placeholder="Nome"
                                aria-label="Nome" [disabled]="controlSave">
                        </mat-form-field>
                    </div>
                </div>

                <!--[[ BOTAO ADD AEROPORTO ]] -->
                <div class="form-row aliginEndContent" [hidden]="!showAirportList">
                    <button mat-raised-button class="btmAddAirport" matTooltip="Adicionar um novo Aeroporto"
                        (click)="createdNewAirport()" [disabled]="controlSave">
                        <mat-icon>add</mat-icon>Novo Aeroporto
                    </button>
                </div>
                <br>
                <!-- [[ TABELA DE AEROPORTO VINCULADO ]]-->
                <div class="form-row">

                    <div class="tableAirportRegistred" [hidden]="!showAirportList" style="width: 100%;">
                        <table mat-table [dataSource]="dataSourceAirportsAllow" class="mat-elevation-z8"
                            style="width: 100%;">

                            <ng-container matColumnDef="identification">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let airport"></td>
                            </ng-container>

                            <ng-container matColumnDef="iataCode">
                                <th mat-header-cell *matHeaderCellDef>Cód. IATA</th>
                                <td mat-cell *matCellDef="let airport">{{ airport.codeIATA }}</td>
                            </ng-container>

                            <ng-container matColumnDef="decription">
                                <th mat-header-cell *matHeaderCellDef>Nome</th>
                                <td mat-cell *matCellDef="let airport">{{ airport.descriptionName }}</td>
                            </ng-container>

                            <ng-container matColumnDef="startValid">
                                <th mat-header-cell *matHeaderCellDef>Inicio Vigência</th>
                                <td mat-cell *matCellDef="let airport">{{ showFormatDateString(airport.startValid, this.airportRegister.startValid)}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="endValid">
                                <th mat-header-cell *matHeaderCellDef>Fim Vigência</th>
                                <td mat-cell *matCellDef="let airport">{{ showFormatDateString(airport.endValid, this.airportRegister.endValid) }}</td>
                            </ng-container>

                            <ng-container matColumnDef="editOrDelete">
                                <th mat-header-cell *matHeaderCellDef>Editar / Excluir</th>
                                <td mat-cell *matCellDef="let airport">

                                    <button matTooltip="Editar" mat-flat-button color="primary" class="iconButtonTable"
                                    [disabled]="this.validEdit" (click)="editAirport(airport)"
                                    [disabled]="controlSave">
                                    <mat-icon>edit</mat-icon>
                                  </button>
                                    <button mat-raised-button type="button" class="btn btn-danger"
                                        (click)="deleteAirport(airport)" [disabled]="controlSave">
                                        <mat-icon style="margin-left: 1%;font-size: x-large;">delete</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                    </div>
                </div>
                <div class="form-row">
                    <div class="formRegistreAirport" [hidden]="showAirportList">
                        <form [formGroup]="formAdministratorAirportCrud">
                            <div class="form-row">
                                <div class="col-1 label">
                                    <label for="airportRegisterIATA">IATA: </label>
                                </div>
                                <div class="col-5">
                                    <mat-form-field class="example-full-width inputLayout">
                                        <input matInput id="airportRegisterIATA" name="airportRegisterIATA"
                                            formControlName="airportRegisterIATA" [(ngModel)]="airportRegister.codeIATA"
                                            placeholder="Código IATA do aeroporto" aria-label="Código IATA do aeroporto"
                                            [matAutocomplete]="iataAutoCompleate"
                                            oninput="this.value = this.value.toUpperCase()" maxlength="3"
                                            [disabled]="controlSave" (focus)="cleanValueInput()" autocomplete="off">
                                        <mat-autocomplete #iataAutoCompleate="matAutocomplete"
                                            (optionSelected)='setAirport($event.option.value)'>
                                            <mat-option *ngFor="let airport of filteredAirportOptions | async"
                                                [value]="airport">
                                                <span>{{airport.codeIATA}}</span>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="col-1 label">
                                    <label for="airportRegisterICAO">ICAO: </label>
                                </div>
                                <div class="col-5">
                                    <mat-form-field class="example-full-width inputLayout">
                                        <input matInput id="airportRegisterICAO" name="airportRegisterICAO"
                                            formControlName="airportRegisterICAO" [(ngModel)]="airportRegister.codeICAO"
                                            placeholder="Código ICAO do aeroporto" aria-label="Código ICAO do aeroporto"
                                            oninput="this.value = this.value.toUpperCase()" maxlength="4"
                                            [disabled]="controlSave" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-2 label">
                                    <label for="airportRegisterName">Nome do Aeroporto: </label>
                                </div>
                                <div class="col-10">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <input matInput id="airportRegisterName" name="airportRegisterName"
                                            formControlName="airportRegisterName"
                                            [(ngModel)]="airportRegister.descriptionName"
                                            placeholder="Nome do Aeroporto" aria-label="Nome do Aeroporto"
                                            [disabled]="controlSave" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>

                            <br>
                            <div class="form-row">
                                <div class="col-1 label">
                                    <label for="airportRegisterICAO">FTP Host:</label>
                                </div>
                                <div class="col-3">
                                    <mat-form-field class="example-full-width inputLayout">
                                        <input matInput id="airportRegisterHostFTP" name="airportRegisterHostFTP"
                                            formControlName="airportRegisterHostFTP" aria-label="FTP Host"
                                            [disabled]="controlSave" autocomplete="off"
                                            [(ngModel)]="airportRegister.host" class="form-control"
                                            placeholder="Host FTP" name="hostFTP" />
                                    </mat-form-field>
                                </div>

                                <div class="col-1 label">
                                    <label for="airportRegisterICAO">FTP Path RPE:</label>
                                </div>
                                <div class="col-3">
                                    <mat-form-field class="example-full-width inputLayout">
                                        <input matInput id="airportRegisterPathFTP" name="airportRegisterPathFTP"
                                            formControlName="airportRegisterPathFTP" aria-label="FTP Path"
                                            [disabled]="controlSave" autocomplete="off"
                                            [(ngModel)]="airportRegister.pathRPE" class="form-control"
                                            placeholder="Path RPE FTP" name="pathRpeFTP" />
                                    </mat-form-field>
                                </div>

                                <div class="col-1 label">
                                    <label for="airportRegisterICAO">FTP Path Selo:</label>
                                </div>
                                <div class="col-3">
                                    <mat-form-field class="example-full-width inputLayout">
                                        <input matInput id="airportRegisterPathSeloFTP" name="airportRegisterPathSeloFTP"
                                            formControlName="airportRegisterPathSeloFTP" aria-label="FTP Path"
                                            [disabled]="controlSave" autocomplete="off"
                                            [(ngModel)]="airportRegister.pathStamp" class="form-control"
                                            placeholder="Path Selo FTP" name="pathFTPStamp" />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-1 label">
                                    <label for="airportRegisterICAO">FTP Usuário:</label>
                                </div>
                                <div class="col-5">
                                    <mat-form-field class="example-full-width inputLayout">
                                        <input matInput id="airportRegisterUserFTP" name="airportRegisterUserFTP"
                                            formControlName="airportRegisterUserFTP" aria-label="FTP User"
                                            [disabled]="controlSave" autocomplete="off"
                                            [(ngModel)]="airportRegister.user" class="form-control"
                                            placeholder="User FTP" name="userFTP" />
                                    </mat-form-field>
                                </div>
                                <div class="col-1 label">
                                    <label for="airportRegisterICAO">FTP Senha:</label>
                                </div>
                                <div class="col-5">
                                    <mat-form-field style="width: 90% !important;">
                                        <input matInput
                                            [type]="hide ? 'password' : 'text'"
                                            id="airportRegisterSecretFTP"
                                            name="airportRegisterSecretFTP"
                                            formControlName="airportRegisterSecretFTP" aria-label="FTP Secret"
                                            [disabled]="controlSave" autocomplete="off"
                                            [(ngModel)]="airportRegister.secret" class="form-control"
                                            placeholder="Secret FTP" name="secretFTP" >
                                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                      </mat-form-field>
                                </div>
                            </div>

                            <br>
                            <div class="form-row">
                                <div class="col-6">
                                    <div class="input-group">
                                        <mat-form-field class="col-6">
                                          <mat-label>Inicio Vigência</mat-label>
                                          <input matInput id="airportRegisterStartValid" [matDatepicker]="datepickerInicial" [(ngModel)]="this.airportRegister.startValid" placeholder="dd/mm/yyyy" name="airportRegisterStartValid" (click)="datepickerInicial.open()">
                                          <mat-datepicker-toggle matSuffix [for]="datepickerInicial"></mat-datepicker-toggle>
                                          <mat-datepicker #datepickerInicial></mat-datepicker>
                                        </mat-form-field>

                                        <!--
                                        <input id="airportRegisterStartValid" name="airportRegisterStartValid"
                                            formControlName="airportRegisterStartValid" aria-label="Inicio Vigência"
                                            [disabled]="controlSave" autocomplete="off"
                                            [(ngModel)]="airportRegister.startValidNgb" (click)="dtStart.toggle()"
                                            class="form-control" placeholder="{{ airportRegister.startValid | date:'dd/MM/yyyy' }}" name="startDate"
                                            displayMonths="2" ngbDatepicker #dtStart="ngbDatepicker" />
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar"
                                                (click)="dtStart.toggle()" type="button">
                                            </button>
                                        </div>
                                        -->
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-group">
                                        <mat-form-field class="col-6">
                                          <mat-label>Fim de Vigência</mat-label>
                                          <input matInput id="airportRegisterEndValid" [matDatepicker]="datepickerFinal" [(ngModel)]="this.airportRegister.endValid" placeholder="dd/mm/yyyy" name="airportRegisterEndValid" maxlength="10" (click)="datepickerFinal.open()">
                                          <mat-datepicker-toggle matSuffix [for]="datepickerFinal"></mat-datepicker-toggle>
                                          <mat-datepicker #datepickerFinal></mat-datepicker>
                                        </mat-form-field>
                                        <!--
                                        <input id="airportRegisterEndValid" autocomplete="off"
                                            name="airportRegisterEndValid" formControlName="airportRegisterEndValid"
                                            [(ngModel)]="airportRegister.endValidNgb" (click)="dtEnd.toggle()"
                                            class="form-control" placeholder="{{ airportRegister.endValid | date: 'dd/MM/yyyy' }}"
                                            aria-label="Fim de Vigência" [disabled]="controlSave" autocomplete="off"
                                            displayMonths="2" ngbDatepicker #dtEnd="ngbDatepicker" />
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="dtEnd.toggle()"
                                                type="button">
                                            </button>
                                        </div>
                                        -->
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

                <br>
                <!-- [[ BOTÕES SALVAR E EDITAR ]] -->
                <div class="form-row aliginCenterContent">

                    <button mat-raised-button
                        [hidden]="administrator.identification === undefined && showAirportList ? false : true"
                        matTooltip="Limpar informações do Administrador" type="button" class="btn btn-danger btnClean"
                        (click)="cleanAdministratorForm()" [disabled]="controlSave">
                        <mat-icon style="font-size: x-large;">delete</mat-icon> Limpar Campos
                    </button>

                    <button mat-raised-button type="button" [hidden]="showAirportList ? false : true"
                        matTooltip="Cancelar " class="btn btn-primary btnClean" (click)="cancelOperation()"
                        [disabled]="controlSave">
                        <mat-icon style="font-size: x-large;">save</mat-icon>
                        Cancelar
                    </button>

                    <button mat-raised-button type="button" [hidden]="showAirportList ? false : true"
                        matTooltip="Salvar Administrador" class="btn btn-primary btnSave"
                        (click)="saveOrEditAdministrator()" [disabled]="controlSave">
                        <mat-icon style="font-size: x-large;">save</mat-icon>
                        Salvar
                    </button>

                    <!-- BOTOES PARA AEROPORTO -->
                    <button mat-raised-button [hidden]="!showAirportList ? false : true"
                        matTooltip="Cancelar a Inclusão de um novo Aeroporto" type="button"
                        class="btn btn-danger btnClean" (click)="cancelInclusionAirport()" [disabled]="controlSave">
                        <mat-icon style="font-size: x-large;;">save</mat-icon>
                        Cancelar Inclusão
                    </button>

                    <button mat-raised-button type="button"
                        [hidden]="!showAirportList && !editAirportList ? false : true"
                        matTooltip="Salvar um novo Aeroporto" class="btn-primary" (click)="saveNewAirport()"
                        [disabled]="controlSave"
                        id="saveAirport">
                        <mat-icon style="font-size: x-large;;">save</mat-icon>
                        Incluir Aeroporto
                    </button>

                    <button mat-raised-button type="button" [hidden]="!editAirportList"
                        matTooltip="Salvar um novo Aeroporto" class="btn-primary" (click)="alterAirport()"
                        [disabled]="controlSave">
                        <mat-icon style="font-size: x-large;;">save</mat-icon>
                        Alterar Aeroporto
                    </button>

                </div>
            </div>
        </div>
    </form>
</div>
