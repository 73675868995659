import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { Observable, of} from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Chamado } from './model/chamado';
import { endpoints } from '../../../environments/endpoint';
import { ProtocoloChamado } from '../app-enum/protocolo-chamado';
import { Protocolo } from './model/protocolo';
import { TicketInsert } from './model/ticket-insert';
import { TicketEdit } from './model/ticket-edit';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})

export class ModalChamadoService {

  REST_POST_ADD_CONTROLE_CHAMADO = endpoints.REST_POST_ADD_CONTROLE_CHAMADO;
  REST_POST_ALT_CONTROLE_CHAMADO = endpoints.REST_POST_ALT_CONTROLE_CHAMADO;

  constructor(private http: HttpClient) { }

  valorParaProtocolo(valor: number): ProtocoloChamado {
      return valor as ProtocoloChamado;
  }

  public getIsoDateByCalendarComponent(dtTckEnd: NgbDate): string{
    
    if(dtTckEnd != null && dtTckEnd != undefined){
      return dtTckEnd.year + '-' + dtTckEnd.month + '-' + dtTckEnd.day + 'T00:00:00'
    }
    
    return null;    
  }

  addChamado(chamado: TicketInsert): Observable<Protocolo> {
    //console.log("Data: {}", chamado);
    chamado.registerTickerControlDTO.dtTckEndIso = this.getIsoDateByCalendarComponent(chamado.registerTickerControlDTO.dtTckEnd)
    return this.http.post<Protocolo>(this.REST_POST_ADD_CONTROLE_CHAMADO, chamado, httpOptions);
  }

  editChamado(chamado: TicketEdit): Observable<Protocolo>{
    chamado.ticketControlDTO.dtTckEndIso = this.getIsoDateByCalendarComponent(chamado.ticketControlDTO.dtTckEnd)
    return this.http.put<Protocolo>(this.REST_POST_ALT_CONTROLE_CHAMADO, chamado, httpOptions);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      //console.error(error);
      return of(result as T);
    };
  }

}
