import { Component, OnInit, Input } from '@angular/core';
import { Multa } from '../app-indicators/app-value-penalty';
import { Periodo } from '../app-search-information-by-period/Periodo';
import { Observable } from 'rxjs';
import { IndicadoresService } from '../app-indicators/app-indicators.service';
import { ValorMultaService } from '../app-indicators/app-value-penalty.service';
import { Router } from '@angular/router';
import { properties } from '../../../environments/properties';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DialogCustomComponent } from '../app-dialog-custom/app-dialog-custom.component';
import { MatDialog } from '@angular/material/dialog';

enum optionType {
  C = 'Consolidado',
  P = 'Periodo'
}

@Component({
  selector: 'app-app-payments',
  templateUrl: './app-payments.component.html',
  styleUrls: ['./app-payments.component.css']
})
export class AppPaymentsComponent implements OnInit {
  @Input("dtInicio")
  dtInicio: any;

  @Input("dtFim")
  dtFim: any;

  public periodo: Periodo;
  public listaPeriodoIni: any;
  public listaPeriodoFim: any;
  multaConsolidada: Multa = { valorMulta: undefined };
  multaEscolhaPeriodo: Multa = { valorMulta: undefined };
  private eventsSubscription: any;
  @Input() events: Observable<Periodo>;

  constructor(
    public indicadorService: IndicadoresService,
    public valorMultaService: ValorMultaService,
    public router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe(periodo =>
      this.obterValorMulta(periodo, optionType.P)
    );
    this.eventsSubscription = this.events.subscribe(periodo =>
      this.listaPeriodoIni = periodo.getDtInicio()
    );
    this.eventsSubscription = this.events.subscribe(periodo =>
      this.listaPeriodoFim = periodo.getDtFim()
    );
    const periodoConsolid: Periodo = this.multaPeriodoConsolidado();
    this.obterValorMulta(periodoConsolid, optionType.C);
  }

  public getIdRequestMulta(tipoLista: string) {
     if (this.multaConsolidada != null) {
       this.router.navigate(['/listaIndicadores', tipoLista]);
     }else {
      this.openDialog(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
        properties.MODAL_CONTENT_INDICATORS_DATA_NOT_FOUND
      );
    }
  }

  public getIdRequestMultaPeriodo(tipoLista: string) {
    if( this.multaEscolhaPeriodo.valorMulta != undefined){
      this.router.navigate(['/listaIndicadores', this.listaPeriodoIni, this.listaPeriodoFim,tipoLista]);
      // console.log("TipoLista: ", tipoLista);
    }else {
     this.openDialog(
       properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
       properties.MODAL_CONTENT_INDICATORS_DATA_NOT_FOUND
     );
   }
 }

  public obterValorMulta(periodo: Periodo, type: string): void {
    this.valorMultaService.obterValorMulta(periodo).subscribe(response => {
      if (type === optionType.C) {
        this.multaConsolidada = response;
      } else {
        this.multaEscolhaPeriodo = response;
      }
    });
  }

  private getNgDateByDate(data: Date): NgbDate {
    return new NgbDate(data.getFullYear(), data.getMonth() + 1, data.getDate());
  }

  private getActualEndDateToFirstApresentation(): Date {
    return new Date();
  }

  private getDateForPaymentConsolid(): Date {
    return new Date(1800, 0, 1, 0, 0, 0, 0);
  }

  private getActualStartDateToFirstApresentation(): Date {
    const date: Date = new Date();
    date.setDate(date.getDate() - 15000);
    return date;
  }

  private multaPeriodo(): Periodo {
    const periodo = new Periodo();
    periodo.setDtInicio(
      this.getNgDateByDate(this.getActualStartDateToFirstApresentation())
    );
    periodo.setDtFim(
      this.getNgDateByDate(this.getActualEndDateToFirstApresentation())
    );
    return periodo;
  }

  private multaPeriodoConsolidado(): Periodo {
    const periodo = new Periodo();
    periodo.setDtInicio(
      this.getNgDateByDate(this.getActualStartDateToFirstApresentation())
    );
    periodo.setDtFim(
      this.getNgDateByDate(this.getActualEndDateToFirstApresentation())
    );
    return periodo;
  }

  public openDialog(titleParam: string, messageParam: string): void {
    const dialogRef = this.dialog.open(DialogCustomComponent, {
      width: '450px',
      data: { title: titleParam, message: messageParam }
    });
  }
}
