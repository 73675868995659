<div id='pagefullRPEedit'>
  <form #formRPEEdit (ngSubmit)="eventoPesquisas()">
    <br>
    <div class="card text-center">

      <div class="card-header" style="background-color: #1b0088;">
        <h5 style="color: #ffffff;">Busca de <b>RPE</b></h5>
      </div>

      <div class="card-body container">
        <div class="form-row row">

          <!--
          <div class="col-2 label">
            <label for="numVoo">Número Voo12345: </label>
          </div>
          <div class="col-4">
            <input type="text" id="numVoo" class="form-control" name="numVoo" [(ngModel)]="rpe.numVoo" />
          </div>
          -->
          <!---->
          <mat-form-field appearence="fill" class="col-2">
            <mat-label>Número Voo:</mat-label>
            <input matInput name="numVoo" [(ngModel)]="rpe.numVoo">
          </mat-form-field>

          <mat-form-field appearance="fill" class="col-4">
            <mat-label>Data do Voo:</mat-label>
            <input matInput [matDatepicker]="datepicker" [(ngModel)]="rpe.dateDTO" (click)="datepicker.open()">
            <mat-hint>dd/mm/yyyy</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
          </mat-form-field>
          <!-- <div class="col-2 label">
            <label for="dateDTO"> Data do Voo: </label>
          </div> -->
          <!-- <div class="col-4">
            <div class="input-group">
              <input
                id="dateDTO"
                autocomplete="off"
                [(ngModel)]="rpe.dateDTO"
                (click)="d2.toggle()"
                class="form-control"
                placeholder="dd/mm/yyyy"
                name="dateDTO"
                displayMonths="1"
                ngbDatepicker
                #d2="ngbDatepicker"/>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button">
                </button>
              </div>
            </div>
          </div> -->

        </div>
        <br>
        <div class="container">
          <div class="form-row row MainDiv">
              <mat-form-field id="matFormFieldCompany" class="MainInput col-4">
                <input matInput id="company" name="company" [(ngModel)]="rpe.company" placeholder="Empresa"
                  aria-label="Empresa" [matAutocomplete]="autoCompany" [formControl]="empresaCtrl"
                  oninput="this.value = this.value.toUpperCase()">
                <mat-autocomplete #autoCompany="matAutocomplete">
                  <mat-option *ngFor="let empresa of filteredEmpresa | async" [value]="empresa.iataCode">
                    <span>{{empresa.iataCode}} - {{empresa.nameCompany}}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>


              <mat-form-field id="matFormFieldOrigin" class="MainInput col-4">
                <input matInput id="origin" name="origin" [(ngModel)]="rpe.origin" placeholder="Aeroporto"
                  aria-label="Aeroporto" [matAutocomplete]="autoAirport" [formControl]="aeroportoCtrl"
                  oninput="this.value = this.value.toUpperCase()">
                <mat-autocomplete #autoAirport="matAutocomplete">
                  <mat-option *ngFor="let aeroporto of filteredAeroporto | async" [value]="aeroporto.codeIATA">
                    <span>{{aeroporto.codeIATA}}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field class="MainInput col-4">
                <mat-label>Movimento Voo</mat-label>
                <mat-select [(value)]="typeFlightMoviment">
                  <mat-option>Selecione</mat-option>
                  <mat-option value="D">Decolagem</mat-option>
                  <mat-option value="A">Pouso</mat-option>
                  <mat-option value="AD">Pouso (Misto)</mat-option>
                </mat-select>
              </mat-form-field>
          </div>
        </div>
        <br><br>
      </div>
    </div>
    <div class="card text-center" style="margin-top: 1%">
      <div class="card-body">
        <button id="btnSearch" class="btn btn-primary btnSave" type="submit" (click)="eventoPesquisas()">
          Pesquisar
        </button>
      </div>
    </div>
  </form>
</div>
