import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../src/environments/environment';
import { AuthenticationService } from './app-authenticator/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'spa-gcp-data-health';

  constructor(private ngxService: NgxUiLoaderService, private auth: AuthenticationService) { }


  ngOnInit() {

    if (environment.production === false) {
      this.auth.setTokenAndType('TOKEN-DEV', 'X-TOKEN');
    }

    this.ngxService.start();

    setTimeout(() => {
      this.ngxService.stop();
    }, 5000);

  }

}
