import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-second-level-dashboard-rpe-generated',
  templateUrl: './app-second-level-dashboard-rpe-generated.component.html',
  styleUrls: ['./app-second-level-dashboard-rpe-generated.component.css']
})
export class AppSecondLevelDashboardRpeGeneratedComponent implements OnInit {

  titleModule = 'RPE Gerados Pouso/Decolagem';

  constructor() { }

  ngOnInit() {
  }

}
