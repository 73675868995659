<br>
<form>
  <div class="card text-center">
    <div class="card-header" style="background-color: #1b0088;">
      <h5 style="color: #ffffff;">Relatório de Conciliados.</h5>
    </div>
    <div class="card-body">

      <p>
        <mat-form-field class="inputLayout">
          <mat-label>Aeroporto</mat-label>

          <input matInput id="airport" name="airport" [(ngModel)]="airportChoose" placeholder="Aeroporto"
            aria-label="Aeroporto" [formControl]="airportCtrl" [matAutocomplete]="autoAeroporto"
            oninput="this.value = this.value.toUpperCase()">

          <mat-autocomplete #autoAeroporto="matAutocomplete">
            <mat-option *ngFor="let airport of filteredAeroportos  | async" [value]="airport.codeIATA">
              {{airport.codeIATA}} - {{airport.descriptionName}}
            </mat-option>
          </mat-autocomplete>

        </mat-form-field>
      </p>

      <table class="example-full-width" cellspacing="0">
        <tr>
          <td>
            <div style="margin-right: 10%">
              <div class="input-group date">
                <mat-form-field class="inputDate">
                  <mat-label>DE:</mat-label>
                  <input matInput id="ModalDtInicial" [matDatepicker]="datepickerInicio" [(ngModel)]="dtInicial"
                    class="form-control" placeholder="dd/mm/yyyy" name="dp" maxlength="10" (click)="datepickerInicio.open()">
                  <mat-hint>dd/mm/yyyy</mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="datepickerInicio"></mat-datepicker-toggle>
                  <mat-datepicker #datepickerInicio></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </td>
          <td>
            <mat-form-field class="inputDate">
              <mat-label>ATÉ:</mat-label>
              <input matInput id="ModalDtFinal" [matDatepicker]="datepickerFinal" [(ngModel)]="dtFinal"
              class="form-control" placeholder="dd/mm/yyyy" name="dtFinal" maxlength="10" (click)="datepickerFinal.open()">
              <mat-hint>dd/mm/yyyy</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="datepickerFinal"></mat-datepicker-toggle>
              <mat-datepicker #datepickerFinal></mat-datepicker>
            </mat-form-field>
          </td>
        </tr>
      </table>
      <br>
      <mat-card-actions>
        <button mat-raised-button color="primary" style="background-color: #1b0088;border-color:#1b0088; color: #FFF; margin-top: 1%;"
          [disabled]="disabled()" (click)="export()">
          <mat-icon>get_app</mat-icon>
          Exportar Relatório
        </button>
      </mat-card-actions>

    </div>
  </div>
</form>
