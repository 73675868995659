import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { StampInfo } from '../models/StampInfo';
import { ComboBoxAeroporto } from '../../../../app/app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { StampTransfer } from '../models/StampTransfer';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-app-modal-stamp',
  templateUrl: './app-modal-stamp.component.html',
  styleUrls: ['./app-modal-stamp.component.css']
})
export class AppModalStampComponent implements OnInit {

  public displayedColumns: string[] = [
    "companyOperator",
    "dtFlight",
    "numberFlight",
    "airportOrigin",
    "airportDestination",
    "flightNature",
    "numberStamp",
    "statusTkt",
    "sequeceCheckIn",
    "namePax",
  ];

  public amountLine = 0;
  public amoutPaxUt = 0;
  public filterValue = '';

  public dataSourceflights = new MatTableDataSource<StampInfo>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public aeroporto: ComboBoxAeroporto[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public stampTransfer: StampTransfer,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<AppModalStampComponent>,) { }

  ngOnInit() {

    this.setInformation(this.stampTransfer);
    this.dataSourceflights = new MatTableDataSource(this.stampTransfer.stampInfo);
    this.dataSourceflights.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.filterValue = filterValue.trim().toLowerCase();
    this.dataSourceflights.filter = filterValue.trim().toLowerCase();
  }

  public setInformation(stampTransfer: StampTransfer): void {

    this.amountLine = stampTransfer.stampInfo.length;
    this.amoutPaxUt = 0;

    stampTransfer.stampInfo.forEach(pax => {
      pax.flightNature = pax.stampFixed.includes("*0") ? "Dom." : "Inter";
      pax.stampNumer = this.getStampNumberByPax(pax);
      pax.numberFlight = parseInt(pax.numberFlight).toString();
      pax.dtFlight = this.getDateFormat(pax.dtFlight);
      if(pax.statusTkt == 'U'){
        this.amoutPaxUt++
      }
      pax.iataAirportOri = this.getAirportIataByIcao(pax.airportOrigin, stampTransfer.aeroportos);
      pax.descAirportOri = this.getDescriptionByIcao(pax.airportOrigin, stampTransfer.aeroportos);
      pax.iataAirportDest = this.getAirportIataByIcao(pax.airportDestination, stampTransfer.aeroportos);
      pax.descAirportDest = this.getDescriptionByIcao(pax.airportDestination, stampTransfer.aeroportos);
    });

  }

  public getAirportIataByIcao(icao: string, airport: ComboBoxAeroporto[]): string{
    let iata: string = '';
    airport.forEach(air => {
      if(air.codeICAO == icao){
        iata = air.codeIATA;
      }
    });
    return iata;
  }

  public getDescriptionByIcao(icao: string, airport: ComboBoxAeroporto[]): string{
    let desc: string = '';
    airport.forEach(air => {
      if(air.codeICAO == icao){
        desc = air.descriptionName;
      }
    });
    return desc;
  }

  public getStampNumberByPax(pax: StampInfo): string{

    return this.isEmpty(pax.infoInbound) ? "002" + parseInt(pax.numberFlight) + pax.hrFlight + this.getValueFormat(pax.sequeceCheckIn) : 'Conexão';
  }

  public isEmpty(string: string): boolean{

    return string == null || string == undefined || string == '';
  }

  public getDateFormat(date: string): string{

    return date.substr(6,2) + '/' +  date.substr(4,2)+ '/' + date.substr(0,4);
  }

  public getValueFormat(checkIn: string): string{
    let ckI: string = "";
    ckI = parseInt(checkIn).toString();
    if(ckI.length < 3){
      let maxValue = 3 - ckI.length;
      for(var i=0;i<maxValue; i++){
        ckI = "0"+ckI;
      }
    }
    return ckI;
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

}
