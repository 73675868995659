import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MascaraDinheiroPipe } from './mascara-dinheiro.pipe';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.'
};

@NgModule({
  declarations: [MascaraDinheiroPipe],
  imports: [
    CommonModule
  ],
  exports : [MascaraDinheiroPipe],
  providers : [{provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig}]
})
export class UtilitariosModule { }
