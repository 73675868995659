import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { DashboardlineRQ } from './model/DashboardlineRQ';
import { DashboardlineRS } from './model/DashboardlineRS';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Periodo } from '../app-search-information-by-period/Periodo';
import { Observable } from 'rxjs';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { Router } from '@angular/router';
import { AppDashboardContestationService } from './app-dashboard-contestation.service';
import { DialogCustomComponent } from '../app-dialog-custom/app-dialog-custom.component';
import { properties } from '../../../environments/properties';
import { DataChar } from '../app-dashboard-line/model/DataChar';
import { Protocolo } from '../app-enum/Protocolo';
import { MatDialog } from '@angular/material/dialog';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-app-dashboard-contestation',
  templateUrl: './app-dashboard-contestation.component.html',
  styleUrls: ['./app-dashboard-contestation.component.css']
})
export class AppDashboardContestationComponent implements OnInit {

  @Input() identification: string;
  @Input() events: Observable<Periodo>;
  private eventsSubscription: any;

  @Output() dateSelectDashOne = new EventEmitter<NgbDateStruct>();

  public requestCont: DashboardlineRQ;
  public responseCont: DashboardlineRS[];
  public indexSelectColunCont;
  public indexLabelPointCont;
  public lineChartDataCont: any[] = [{ data: [], label: '' }];
  public lineChartLabelsCont: any[] = [];

  public lineChartOptions: (any & { annotation: any }) = {
    responsive: true,
    legend: {
      position: 'top',
    },
    hover:{
      intersect: true,
      mode: "nearest",
    },
    scales: {
      x: [{type: 'linear',}],
      y: [
        {type: 'linear',
          id: 'contestados',
          position: 'left',
        },
      ]
    },
    annotation: {},
    title: {
      display: true,
      text: 'RPE\'s Contestados'
    }
  };

  public lineChartColorsDCon: any[] = [];
  public lineChartLegendDCon = true;
  public lineChartTypeDCon = 'line';
  public lineChartPluginsDCon = [pluginAnnotations];

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  constructor(

    private router: Router,
    private dashboardContestationService: AppDashboardContestationService,
    public dialog: MatDialog) {
    this.requestCont = new DashboardlineRQ();
    this.responseCont = new Array<DashboardlineRS>();
    this.requestCont.dtInicio = this.getFormatDateToRqCont(this.getActualStartDateToFirstApresentationDshCon());
    this.requestCont.dtFim =  this.getFormatDateToRqCont(this.getActualEndDateToFirstApresentationContDshCon());
    this.getDataDashboardLine(null);

  }

  public chartClicked(e: any): void {
    if (e.active.length > 0) {
      this.indexSelectColunCont = e.active[0]._index;
      this.indexLabelPointCont = e.active[0].$datalabels.$context.dataset.label;

      if (this.indexLabelPointCont == 'Manual') {
        if (this.lineChartDataCont[1].data[this.indexSelectColunCont] == 0) {
          this.openDialogContDsn(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
                properties.MODAL_CONTENT_INDICATORS_DATA_NOT_FOUND
          );
          return;
        }
      }

      if (this.indexLabelPointCont == 'Automatico'){
        if (this.lineChartDataCont[0].data[this.indexSelectColunCont] == 0) {
          this.openDialogContDsn(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
                properties.MODAL_CONTENT_INDICATORS_DATA_NOT_FOUND
          );
          return;
        }
      }

      // console.log('[ INFO ] ' + ' [ SELECT Data ] [ 1 ] ' + this.lineChartLabels[this.indexSelectColun]);
      // console.log('[ INFO ] ' + ' [ SELECT Point Label ] [ 1 ] ' + this.lineChartLabels[this.indexLabelPoint]);
      this.router.navigate(['listContestation', this.lineChartLabelsCont[this.indexSelectColunCont], this.indexLabelPointCont]);
    }
  }

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe((periodo) => this.getDataDashboardLine(periodo));
  }

  private getDataDashboardLine(periodo: Periodo): void {

    if (periodo != undefined || periodo != null) {
      this.requestCont.dtInicio = periodo.getDtInicio();
      this.requestCont.dtFim = periodo.getDtFim();
    }

    let rs: DashboardlineRS[];
    this.dashboardContestationService.getDataByDate(this.requestCont).subscribe(
      data => {
        rs = data;
        //console.log(rs);
      },
      err => {
        const protocolo: Protocolo = err.error;
        //console.log(protocolo);
        this.setDashBoardNull();
        this.openDialogContDsn(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
              properties.MODAL_CONTENT_DASHBOARD_ERROR_DATA_NOT_FOUND
        );
      },
      () => {
        const dataChar = new DataChar();
        const arrayData = new Array();

        /**
         * ORDENADO RESPOSTA PELA DATA DO VOO
         */
        rs.sort(function(a, b) {
          return a.dtDispute < b.dtDispute ? -1 : a.dtDispute > b.dtDispute ? 1 : 0;
        });

        this.lineChartLabelsCont = [];

        //TESTE INSERCAO

        this.lineChartDataCont = [{ data: [], label: '' }];
        this.lineChartDataCont.splice(0, this.lineChartDataCont.length);

        let dataAuto: DataChar = new DataChar();
        let dataManual: DataChar = new DataChar();
        dataAuto.label = 'Automatico';
        dataManual.label = 'Manual';

        var arrayDataAuto = new Array();
        var arrayDataManual = new Array();
        var arrayDataDays = new Array();

        for (const value of rs) {

          /**
           * VALIDANDO SE JA EXISTE A DATA NO VETOR DE DATAS
           */
          if(!this.inVector(this.getFormatDate(value.dtDispute))){
            this.lineChartLabelsCont.push(this.getFormatDate(value.dtDispute));
            // console.log("VER GRAFICO");
          }

          if (value.trType == 'A'){
            arrayDataAuto.push(value.amountContested);
          } else if(!this.inVectorAutomatic(rs, value.dtDispute)){
            arrayDataAuto.push(0);
          }

          if (value.trType == 'M'){
            arrayDataManual.push(value.amountContested);
          } else if(!this.inVectorManual(rs, value.dtDispute)){
            arrayDataManual.push(0);
          }

        }

        dataAuto.data = arrayDataAuto;
        dataManual.data = arrayDataManual;

        this.lineChartDataCont.push(dataAuto);
        this.lineChartDataCont.push(dataManual);

        this.setColorsLine();
        // dataChar.data = arrayData;
        // this.getDataToChar(dataChar);
      }
    );
  }

  private inVector(date: any){
    // console.log("[[ INFO ]] VERIFICANDO SE EXISTE ESSA DATA: " + date);
    let control: boolean = false;
    for (const myDate of this.lineChartLabelsCont) {
      if (myDate == date) {
        control = true;
        break;
      }
    }
    return control;
  }

  private inVectorAutomatic(rs: DashboardlineRS[], actualDate: any): boolean{

    let control = false;
    for (const value of rs) {
      if(value.trType == 'A' && this.getFormatDate(value.dtDispute) == this.getFormatDate(actualDate)){
        control = true;
        break;
      }
    }

    return control;
  }

  private inVectorManual(rs: DashboardlineRS[], actualDate: any): boolean{

    let control = false;
    for (const value of rs) {
      if (value.trType == 'M' && this.getFormatDate(value.dtDispute) == this.getFormatDate(actualDate)) {
        control = true;
        break;
      }
    }

    return control;
  }

  /**
   * FUNCAO PARA FORMATAR A DATA APRESENTADA NA LEGENDA DOS GRAFICOS
   * @param dt
   */
  private getFormatDate(dt: any): string {

    let stringDt: string[] = dt.split('T');
    let content: string[] = stringDt[0].split('-');

    let year: string = content[0];
    let mouth: string = content[1];
    let day:string = content[2];

    let dtReturn = day + '/' + mouth + '/' + year;
    // console.log('dt to format: ' + dtReturn);
    return dtReturn;
  }

  private setColorsLine(): void {
    this.lineChartColorsDCon = [
        {
          backgroundColor: 'rgba(224,255,255,0.5)',
          borderColor: 'rgba(27,0,136,1)',
          pointBackgroundColor: 'rgb(245,245,245)',
          pointRadius: 10,
          pointHoverRadius: 10,
          pointStyle: 'circle',
        },
        {
          backgroundColor: 'rgba(219,112,147,0.3)',
          borderColor: 'rgba(255,0,136,1)',
          pointBackgroundColor: 'rgb(255,245,245)',
          pointRadius: 10,
          pointHoverRadius: 10,
          pointStyle: 'circle',
        }
    ];
  }

  private getActualEndDateToFirstApresentationContDshCon(): Date {
    return new Date();
  }

  private getActualStartDateToFirstApresentationDshCon(): Date {
    const date: Date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  }

  private setDashBoardNull(): void {
    this.indexLabelPointCont = null;
    this.lineChartDataCont =  [{ data: [], label: '' }];
    this.lineChartLabelsCont = [];
  }

  public openDialogContDsn(titleParam: string, messageParam: string): void {
    const dialogRef = this.dialog.open(DialogCustomComponent, {
        width: '450px',
        data: {title: titleParam, message: messageParam}
    });
  }

  private getFormatDateToRqCont(date: Date): string {
    return date.getFullYear()
      + '-' + (date.getMonth() + 1 )
        + '-' +  date.getDate();
  }


}
