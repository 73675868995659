import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { endpoints } from '../../../../environments/endpoint';
import { Observable } from 'rxjs';
import { AirportTax } from '../model/airport-tax';
import { AirportInfo } from '../model/airport-info';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AirpotTaxService {

  constructor(private http: HttpClient,  private cookieService: CookieService) { }

  public getAllLayouts(): Observable<AirportTax[]> {

    return this
      .http
      .get<AirportTax[]>(endpoints.REST_GET_AIPORT_TAX, {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get('acme-user-token')
      })});

  }

  public registerAirportTax(airportTax: AirportTax): Observable<string> {

    // console.log('inclusão nova taxa de aeroporto', airportTax);
    return this.http.post<string>(endpoints.REST_GET_AIPORT_TAX, airportTax, {headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Bearer ' + this.cookieService.get('acme-user-token')
    })});
  }

  public filterAirportName(taxas: AirportTax[]): string[] {
    const airportArray: string[] = taxas.map(taxa => taxa.iataAirport);
    return Array.from(new Set(airportArray));
  }

  public filterTypeNature(taxas: AirportTax[]): string[] {
    const typeNatureArray: string[] = taxas.map(taxa => taxa.typeNature);
    return Array.from(new Set(typeNatureArray));
  }

  public filterByInfo(airportInfo: AirportInfo, taxas: AirportTax[]): AirportTax[] {
    return taxas?.filter(taxa => airportInfo.filtered(taxa));
  }
}
