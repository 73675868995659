import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { LineError } from '../../model/line-error';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-line-error',
  templateUrl: './modal-line-error.component.html',
  styleUrls: ['./modal-line-error.component.css']
})
export class ModalLineErrorComponent implements OnInit {

  displayedColumns: string[] = ['linha', 'aba'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource: MatTableDataSource<LineError>;

  constructor(public dialogRef: MatDialogRef<ModalLineErrorComponent>,
              @Inject(MAT_DIALOG_DATA) public lineErrorArray: LineError[]) {
    this.dataSource = new MatTableDataSource(lineErrorArray);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

  public dialogClose(): void {
    this.dialogRef.close();
  }

}
