import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DashboardlineRQ } from './model/DashboardlineRQ';
import { DashboardlineRS } from './model/DashboardlineRS';
import { Observable } from 'rxjs';
import { endpoints } from '../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppDashboardWorkaroundService {

  constructor(private http: HttpClient) { }

  convertDataIso(requestDW: DashboardlineRQ): void {
    let arrayString: string[] = [];
    const dtInicio = new Date(requestDW.dtInicio).toISOString();
    const dtFim = new Date(requestDW.dtFim).toISOString();

    arrayString = dtInicio.split('T');
    requestDW.dtInicio = arrayString[0] + 'T00:00:00';
    arrayString = dtFim.split('T');
    requestDW.dtFim = arrayString[0] + 'T23:59:59';
  }

  getDataByDate(requestDW: DashboardlineRQ): Observable<DashboardlineRS[]> {

    if (!requestDW.dtInicio.includes('T')) {
      this.convertDataIso(requestDW);
    }

    let params1 = new HttpParams().set('flightBeginDate', requestDW.dtInicio);
    let params2 = new HttpParams().set('flightEndDate', requestDW.dtFim);
    // console.log('Data: {}', request);
    return this.http.get<DashboardlineRS[]>(endpoints.REST_GET_WORKAROUND_BY_PERIOD + "?" + params1 + "&" + params2, httpOptions);
  }

}
