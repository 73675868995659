import { Component, OnInit } from '@angular/core';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { DashboardColumnRQ } from './model/DashboardColumnRQ';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardColumnRS } from './model/DashboardColumnRS';
import { Protocolo } from '../app-enum/Protocolo';
import { DataChar } from '../app-dashboard-line/model/DataChar';
import { AppDashboardColumnStampService } from './app-dashboard-column-stamp.service';


@Component({
  selector: 'app-dashboard-column-stamp',
  templateUrl: './app-dashboard-column-stamp.component.html',
  styleUrls: ['./app-dashboard-column-stamp.component.css']
})
export class AppDashboardColumnStampComponent implements OnInit {

  private eventsSubscription: any;
  public request: DashboardColumnRQ = new DashboardColumnRQ();

  public barChartOptionsStam: any = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{
      ticks: {
          min: 0,
          // stepSize: 1
      }
    }] },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
      }
    }
  };

  public barChartTypeStam: any = 'bar';
  public barChartLegendStam = true;
  public barChartPluginsStam = [pluginDataLabels];

  public barChartDataStam: any[] = [];
  public barChartLabelsStam: any[] = [];

  private setCharData(rs: DashboardColumnRS[]): void {

    if (rs === null) {
      this.barChartDataStam = [{ data: [], label: '' }];
      return;
    }

    const dataDCST = new DataChar();
    const arrayDataDCST = new Array();
    for (const value of rs) {
      arrayDataDCST.push(value.qntSelo);
    }
    this.barChartDataStam = [{ data: [], label: '' }];
    dataDCST.label = 'Qtd. Não Gerados';
    dataDCST.data = arrayDataDCST;
    this.barChartDataStam.splice(0, this.barChartDataStam.length);
    this.barChartDataStam.push(dataDCST);
  }

  private setCharLabels(rs: DashboardColumnRS[]): void {

    const arrayData = new Array();
    for (const value of rs) {
      let op = value.origem;
      if ( op.length > 50 ) {
        op = 'OUTROS';
      }
      arrayData.push(op);
    }
    this.barChartLabelsStam = arrayData;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dashboardcolumnService: AppDashboardColumnStampService) {

    this.setCharData(null);
    // this.setCharLabels();
  }

  ngOnInit() {
    this.eventsSubscription = this.route.params.subscribe(params => {
      this.request.dtVoo = params['date'];
      // console.log('[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 3 ] ' + this.request.dtVoo);
      // console.log('[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 3 ] ' + this.request.sistemaOrigem);
      this.getDataDashboardColumn(this.request.dtVoo);
    });
  }

  private getDataDashboardColumn(dtSearch: string): void {
    let rs: DashboardColumnRS[];
    this.dashboardcolumnService.getDataByDate(this.request).subscribe(
      data => {
        rs = data;
        // console.log('DASHBOARD COLUMN ' + JSON.stringify(rs));
      },
      err => {
        const protocolo: Protocolo = err.error;
        // console.log(protocolo);
      },
      () => {

        this.setCharData(rs);
        this.setCharLabels(rs);
      }
    );
  }

}
