<h2 mat-dialog-title>Cadastrar Chamado</h2>
<mat-dialog-content>
  <div *ngIf="mensagemResposta != undefined && status == 1" class="alert alert-info" role="alert">
    {{mensagemResposta}}
  </div>
  <div *ngIf="mensagemResposta != undefined && status == -1" class="alert alert-danger" role="alert">
    {{mensagemResposta}}
  </div>
  <div *ngIf="mensagemResposta != undefined && status == 0" class="alert alert-primary" role="alert">
    {{mensagemResposta}}
  </div>
  <br />
  <form id='formulario' class="form-horizontal" (ngSubmit)="abrirChamado()" #clienteForm="ngForm">
    <div class="form-row">
      <div class="col-md-6 mb-3">
        <label for="numTck"><span style="color:red">*</span> Número Ticket:</label>
        <input type="text" class="form-control" name="numTck" id="numTck" [(ngModel)]="chamadoInsert.registerTickerControlDTO.numTck"
          [(ngModel)]="chamadoEdit.ticketControlDTO.numTck" #numTck="ngModel" [ngClass]="{ 'is-invalid': numTck.errors}" required>
        <div *ngIf="numTck.errors && (numTck.dirty || numTck.touched)" class="alert alert-danger">
          <div [hidden]="!numTck.errors.required">Número ticket é obrigatório.</div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <label> Data encerramento:</label>
        <div class="form-row">
          <div class="input-group">
            <input id="dtTckEnd" autocomplete="off" [(ngModel)]="chamadoInsert.registerTickerControlDTO.dtTckEnd"
              [(ngModel)]="chamadoEdit.ticketControlDTO.dtTckEnd" (dateSelect)="onDateStartSelect($event)" class="form-control"
              placeholder="dd/mm/yyyy" name="dp" (click)="d3.toggle()" ngbDatepicker #d3="ngbDatepicker" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-12 mb-3">
        <label for="comments">Observações:</label>
        <textarea class="form-control" type="textarea" [(ngModel)]="chamadoInsert.registerTickerControlDTO.comments" [(ngModel)]="chamadoEdit.ticketControlDTO.comments"
          name="comments" #comments="ngModel" rows="4"></textarea>
      </div>
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancelar</button>
  <button mat-raised-button type="submit" (click)="abrirChamado()">Enviar</button>
</mat-dialog-actions>
