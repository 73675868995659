<br />
  <div class="row">
    <!-- [ GRAFICO COM OS PROBLEMAS DE ORIGEM ] -->
    <div class="col-5">
      <div class="titleDash">
        <h4>{{ titleCharOriginProblem }}</h4>
      </div>
      <app-dashboard-pizza id="charOriginProblem"></app-dashboard-pizza>
    </div>
    <!-- [ GRAFICO COM OS PROBLEMAS ENCONTRADOS ] -->
    <div class="col-7">
      <div class="titleDash">
        <h4>{{ titleCharProblem }}</h4>
      </div>
      <app-dashboard-column id="charProblem"></app-dashboard-column>
    </div>
  </div>
  <br />
 <!-- [ TABELA COM OS PROBLEMAS ] -->
<app-data-flight-list></app-data-flight-list>

<br><br>