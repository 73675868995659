import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-third-level-dashboard-workaround',
  templateUrl: './app-third-level-dashboard-workaround.component.html',
  styleUrls: ['./app-third-level-dashboard-workaround.component.css']
})
export class AppThirdLevelDashboardWorkaroundComponent implements OnInit {

  titleCharOriginProblem = 'Sistema de origem';
  titleCharProblem = 'Identificação do problema';
  
  constructor() { }

  ngOnInit() {
  }

}
