import { Component, OnInit, ViewChild } from '@angular/core';
import { Voo } from './model/voo';
import { VooRequisicao } from './model/vooRequisicao';
import { Protocolo } from './model/protocolo';
import { ProtocoloChamado } from '../app-enum/protocolo-chamado';
import { ExcelService } from '../../app-conciliation/service/excel.service';
import { ModalChamadoComponent } from '../app-dialog-ticket/app-dialog-ticket.component';
import { Router, ActivatedRoute } from '@angular/router';
import { properties } from '../../../environments/properties';
import { MatSort, Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { AppDataWorkaroundListService } from './app-data-workaround-list.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-data-workaround-list',
  templateUrl: './app-data-workaround-list.component.html',
  styleUrls: ['./app-data-workaround-list.component.css']
})
export class AppDataWorkaroundListComponent implements OnInit {

  public vooLis: VooRequisicao;
  public isLoadingResults: boolean;
  public paginaAtual = 1;
  public displayedColumnsDrWor: string[] = [
    'idWaRpe',
    'airlineComp',
    'cdNumVoo',
    'dtVoo',
    'dtProcess',
    'tpWa',
    'adAeropOrin',
    'destAirport',
  ];

  public dataSourceImutavel = new MatTableDataSource<Voo>();

  public mensagemResposta: string;
  modalStatus = false;
  private eventsSubscription: any;
  public dataAtualWork: Date = new Date();
  public filterValueWork = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public sortedData: Voo[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public listarDadosVooService: AppDataWorkaroundListService,
    private excelService: ExcelService,
    public dialog: MatDialog,
    private datePipe: DatePipe
  ) {
    this.vooLis = new VooRequisicao();
  }

  ngOnInit() {
    this.eventsSubscription = this.route.params.subscribe(params => {
      this.vooLis.dtInicio = params.date;
      this.vooLis.dtFim = params.date;
      this.vooLis.sistOrigin = params.sys;
      // console.log('[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 3 ] ' + this.voo.dtInicio);
      // console.log('[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 3 ] ' +this.voo.sistOrigin);
      this.tratarDataFim();
      this.obterLista();
    });
  }

  applyFilter(filterValue: string) {
    this.filterValueWork = filterValue.trim().toLowerCase();
    // console.log('[[ WPENHA ]] applyFilter: {{ }} ' + this.filterValue);

    this.dataSourceImutavel.filter = filterValue.trim().toLowerCase();
  }

  // TODO -> public pousoAirportIata: string; public decolagemAirportIata: string;
  // TODO -> verificacao de origem e destino

  private obterLista(): void {
    this.listarDadosVooService.getDadosVoo(this.vooLis).subscribe(
      dataSource => {
        this.sortedData = dataSource;
      },
      err => {
        const protocolo: Protocolo = err.error;
        this.validarStatusBuscaVoo(protocolo);
      },
      () => {
        this.sortedData.sort(function(a, b) {
          return a.adAeropOrin < b.adAeropOrin
            ? -1 : a.adAeropOrin > b.adAeropOrin ? 1 : 0;
        });

        this.dataSourceImutavel = new MatTableDataSource(this.sortedData);
        this.dataSourceImutavel.paginator = this.paginator;
      }
    );
  }

  validarStatusBuscaVoo(protocolo: Protocolo): void {
    switch (protocolo.status) {
      case ProtocoloChamado.REQUEST_INVALIDO:
        this.mensagemResposta =
          properties.MODAL_CHAMADO_MENSAGEM_REQUEST_INVALIDO;
        break;
      default:
        this.mensagemResposta = protocolo.descricao;
        break;
    }
  }


  exportAsXLSX(): void {
    this.excelService.exportAsExcelFileWorkaround(
      this.dataSourceImutavel.data,
      'LISTA_WORKAROUND_' +
        this.vooLis.sistOrigin.toUpperCase() +
        '_' +
        this.datePipe.transform(this.dataAtualWork, 'dd_MM_yyyy')
    );
  }

  private tratarDataFim(): void {
    let dt: string = this.vooLis.dtFim;
    let dtA: string[] = [];
    dtA = dt.split('T');
    this.vooLis.dtFim = dtA[0] + 'T23:59:59';
  }

  private getFormatDateToString(dtWork: string): string {
    if (dtWork === null || dtWork === undefined) {
      return '';
    }
    let dtarr: string[];
    let formatDt: string;
    dtarr = dtWork.split('-');
    formatDt = dtarr[2] + '-' + dtarr[1] + '-' + dtarr[0];
    return formatDt;
  }

  private getFormatDateToStringIso(dtdtWork: string): string {
    if (dtdtWork === null || dtdtWork === undefined) {
      return '';
    }
    let dtarr: string[];
    let dtar: string[];
    let formatDt: string;
    dtarr = dtdtWork.split('T');
    dtar = dtarr[0].split('-');
    formatDt = dtar[2] + '-' + dtar[1] + '-' + dtar[0];
    return formatDt;
  }

  private formatDateHour(dtWokLis: String): string {
    if (dtWokLis === null || dtWokLis === undefined) {
      return '';
    }
    let dtarr: string[];
    let dtarH: string[];
    dtarr = dtWokLis.split('T');
    // +' '+ dtarr[1];
    dtarH = dtWokLis.split('T');
    dtarr = dtarr[0].split('-');
    return dtarr[2] + '-' + dtarr[1] + '-' + dtarr[0];
  }

}
