import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { endpoints } from '../../../environments/endpoint';
import { RpeEdit } from './model/rpe-edit';
import { Protocolo } from './model/protocolo';
import { RpeEditRequest } from './model/rpe-edit-request';
import { RpeListResponse } from './model/rpe-list-response';
import { RpeEmit } from '../app-search-information-by-date-and-rpe/model/rpe-emit';
import { RpeEditResponse } from './model/rpe-edit-response';
import { EditFileRpe } from './model/edit-file-rpe';
import { ConsultFileRpeEdit } from './model/consult-file-rpe-edit';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class EditarRpeFormularioService {

  constructor(private http: HttpClient) { }

  editarRpe (id: number, rpe: EditFileRpe, email: string): Observable<Protocolo>{
    rpe.prefix = rpe.prefix.toUpperCase();
    let url = endpoints.REST_PUT_ALT_RPE_GERADO.replace("{id}", id.toString()).replace("{email}", email);

    return this.http.put<Protocolo>(url, rpe, httpOptions);
  }

  getFileEdited (id: number, rpe: EditFileRpe):  Observable<any>{

    rpe.prefix = rpe.prefix.toUpperCase();
    let url = endpoints.REST_PUT_ALT_RPE_GERADO_FILE.replace("{id}", id.toString());
    return this.http.put(url, rpe);

  }

  getDadosRpeGeradoForm(rpeGerado: any): Observable<ConsultFileRpeEdit> {
console.log("Chegou no service ",rpeGerado)
    // console.log('Executando serviço buscar dados RpeGerado: {}', rpeGerado);
    let params1 = new HttpParams().set('dtFlight', rpeGerado.dateDTO );

      let params2 = new HttpParams().set('company', rpeGerado.company.toString());

        let params3 = new HttpParams().set('origin', rpeGerado.origin.toString());

          let params4 = new HttpParams().set('type', rpeGerado.type.toString());



    let url =  endpoints.REST_POST_DADOS_RPE_GERADO_LISTA_DATA
                + rpeGerado.numVoo + "?"
                  + params1 + "&"
                    + params2 + "&"
                      + params3 + "&"
                        + params4;

    console.log(url, "<---url que está mandando a requisição")
    return this.http.get<ConsultFileRpeEdit>(url, httpOptions);

  }

  getDadosRpeGeradoFormNew(rpeGerado: any): Observable<any> {

    console.log('Executando serviço buscar dados RpeGerado: {}', rpeGerado);
    let params1 = new HttpParams().set('dtFlight', rpeGerado.dateDTO );
      let params2 = new HttpParams().set('company', rpeGerado.company.toString());
        let params3 = new HttpParams().set('origin', rpeGerado.origin.toString());
          let params4 = new HttpParams().set('type', rpeGerado.type.toString());

    let url =  endpoints.REST_POST_DADOS_RPE_GERADO_LISTA_DATA
                + rpeGerado.numVoo + "?"
                  + params1 + "&"
                    + params2 + "&"
                      + params3 + "&"
                        + params4;

    return this.http.get<any>(url, httpOptions);

  }


}
