import { Component, OnInit } from '@angular/core';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { DashboardColumnRQ } from './model/DashboardColumnRQ';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardColumnRS } from './model/DashboardColumnRS';
import { Protocolo } from '../app-enum/Protocolo';
import { DataChar } from '../app-dashboard-line/model/DataChar';
import { AppDashboardColumnRpeGeneratedService } from './app-dashboard-column-rpe-generated.service';


@Component({
  selector: 'app-dashboard-column-rpe-generated',
  templateUrl: './app-dashboard-column-rpe-generated.component.html',
  styleUrls: ['./app-dashboard-column-rpe-generated.component.css']
})
export class AppDashboardColumnRpeGeneratedComponent implements OnInit {

  private eventsSubscription: any;
  public requestClmG: DashboardColumnRQ = new DashboardColumnRQ();

  public barChartOptionsDashClo: any = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{
      ticks: {
          min: 0,
          // stepSize: 1
      }
    }] },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
      }
    }
  };

  public barChartTypeDasColGe: any = 'bar';
  public barChartLegendDasColGe = true;
  public barChartPluginsDasColGe = [pluginDataLabels];

  public barChartDataDasColGe: any[] = [];
  public barChartLabelsDasColGe: any[] = [];

  private setCharData(rs: DashboardColumnRS[]): void {

    if (rs === null) {
      this.barChartDataDasColGe = [{ data: [], label: '' }];
      return;
    }

    const dataDCLEG = new DataChar();
    const arrayDataDCLEG = new Array();
    for (const value of rs) {
      arrayDataDCLEG.push(value.qntRpe);
    }
    this.barChartDataDasColGe = [{ data: [], label: '' }];
    dataDCLEG.label = 'Qtd. Gerados';
    dataDCLEG.data = arrayDataDCLEG;
    this.barChartDataDasColGe.splice(0, this.barChartDataDasColGe.length);
    this.barChartDataDasColGe.push(dataDCLEG);
  }

  private setCharLabels(rs: DashboardColumnRS[]): void {

    const arrayData = new Array();
    for (const value of rs) {
      let op = value.airport;
      if ( op.length > 50 ) {
        op = 'OUTROS';
      }
      arrayData.push(op);
    }
    this.barChartLabelsDasColGe = arrayData;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dashboardcolumnService: AppDashboardColumnRpeGeneratedService) {

    this.setCharData(null);
    // this.setCharLabels();
  }

  ngOnInit() {
    this.eventsSubscription = this.route.params.subscribe(params => {
      this.requestClmG.dtVoo = params['date'];
      this.requestClmG.sistemaOrigem = params['sys'];
      // console.log('[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 3 ] ' + this.request.dtVoo);
      // console.log('[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 3 ] ' + this.request.sistemaOrigem);
      this.getDataDashboardColumn(this.requestClmG.dtVoo);
    });
  }

  private getDataDashboardColumn(dtSearch: string): void {
    let rs: DashboardColumnRS[];
    this.dashboardcolumnService.getDataByDate(this.requestClmG).subscribe(
      data => {
        rs = data;
        // console.log('DASHBOARD COLUMN ' + JSON.stringify(rs));
      },
      err => {
        const protocolo: Protocolo = err.error;
        // console.log(protocolo);
      },
      () => {

        this.setCharData(rs);
        this.setCharLabels(rs);
      }
    );
  }

}
