import { Component, Output, EventEmitter, Input } from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatePTParserFormatter } from './NgbDatePTParserFormatter';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n, I18n } from './CustomDatepickerI18n';
import { NgbDatepickerConfig, NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Periodo } from './Periodo';
import { DialogCustomComponent } from '../app-dialog-custom/app-dialog-custom.component';
import { MatDialog } from '@angular/material/dialog';
import { properties } from '../../../environments/properties';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';


@Component({
  selector: 'app-search-information-by-period',
  templateUrl: './app-search-information-by-period.component.html',
  styleUrls: ['./app-search-information-by-period.component.css'],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{ provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter }],
  ],
})

export class SearchInformationByPeriodComponent {
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  dtInicialLabel = properties.HTML_CONST_DT_START;
  dtFinalLabel = properties.HTML_CONST_DT_END;
  emptyDate = properties.HTML_CONST_DT_TO_SELECTED;
  displayMonths = properties.HTML_CONST_QTD_MOUNTHS;

  public dtInicial: NgbDate;
  public dtFinal: NgbDate;
  private activeInput: string;
  public isDisabled: boolean;

  @Output() dateSelect = new EventEmitter<NgbDateStruct>();
  @Output() periodo = new EventEmitter<Periodo>();

  constructor(config: NgbDatepickerConfig, calendar: NgbCalendar, public dialog: MatDialog) {

    /**
     * CONFIGURACOES INICIAIS DO CALENDARIO DATAS PERMITIDAS 01/01/1900 ATÉ 31/12/2099
     */
    config.minDate = { year: 1976, month: 1, day: 1 };
    config.maxDate = { year: 2099, month: 12, day: 31 };
    config.outsideDays = 'hidden';
    this.isDisabled = true;

  }

  /**
   * VALIDADOR RANGE DE DATAS NAO PERMITIDAS PARA O INPUT DE DATA FINAL
   * @param date
   */
  isDisabledLastOne(date: NgbDateStruct) {
    let dateIni: any;
    let yearIni: number;
    let mounthIni: number;
    let dayIni: number;

    dateIni = $('#dtInicial').val();
    yearIni = parseInt(dateIni.substring(6, 10));
    mounthIni = parseInt(dateIni.substring(3, 5));
    dayIni = parseInt(dateIni.substring(0, 2));

    var selectUserDate = new Date(yearIni, (mounthIni - 1), dayIni);
    var maxDateSelect = new Date(yearIni, (mounthIni - 1), dayIni);
    maxDateSelect.setDate(selectUserDate.getDate() + 30);
    var maxDateOption = { day: maxDateSelect.getDate(), month: (maxDateSelect.getMonth() + 1), year: maxDateSelect.getFullYear() };

    //console.log('Max Select: ' + maxDateSelect);
    return date.year < yearIni
      || (date.month <= mounthIni && date.year === yearIni && date.day <= dayIni)
      || (date.month < mounthIni && date.year === yearIni)
      || date.year > maxDateOption.year
      || date.year > maxDateOption.year
      || (date.month > maxDateOption.month && date.year === maxDateOption.year)
      || (date.month === maxDateOption.month && date.day > maxDateOption.day);
  }

  /**
   * PEGANDO SELECAO DO CALENDARIO DT INICIAL
   * @param dtSelect
   */
  public onDateStartSelect(dtSelect: NgbDate) {
    // console.log('CLICADO DT START INPUT: ' + dtSelect.day + '/'
    //                                           + dtSelect.month + '/'
    //                                             + dtSelect.year
    //                                               + ' ACTUAL INPUT: ' + this.activeInput);
    this.isDisabled = false;
    $('#dtFinal').click();
  }

  /**
   * PEGANDO SELECAO DO CALENDARIO DT FINAL
   * @param dtSelect
   */
  public onDateEndSelect(dtSelect: NgbDate) {
    // console.log('CLICADO DT END INPUT: ' + dtSelect.day + '/'
    //                                         + dtSelect.month + '/'
    //                                           + dtSelect.year
    //                                             + ' ACTUAL INPUT: ' + this.activeInput);
  }

  /**
   * DEFININDO INPUT ATUAL [ DT INICIAL || DT FINAL ]
   * @param event
   */
  public clickEventHandlerSelectSearInf(event: any) {
    this.activeInput = event.currentTarget.id;
  }

  private validateDays(): boolean {
    let dtStart: Date = new Date(this.dtInicial.year, this.dtInicial.month, this.dtInicial.day, 0, 0, 0, 0);
    let dtEnd: Date = new Date(this.dtFinal.year, this.dtFinal.month, this.dtFinal.day, 0, 0, 0, 0);

    let oneDay = 1000 * 60 * 60 * 24;
    var diff = dtEnd.getTime() - dtStart.getTime();
    var qtdDiff = (diff / oneDay);

    // console.log('[ INFO ] DATA RANGE: ' + qtdDiff);
    if (qtdDiff > 30) {
      // console.log('[ INFO ] DATA RANGE NOT ALLOWED: ' + qtdDiff);
      this.openDialogSearInf(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
        properties.MODAL_CONTENT_MAX_30_PERIOD_SELECT
      );
      this.invalidateSecondChoiseDate();
      return true;
    } else if (qtdDiff <= 0) {
      // console.log('[ INFO ] DATA RANGE NOT ALLOWED [ NEGATIVE RANGE ]: ' + qtdDiff);
      this.openDialogSearInf(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
        properties.MODAL_CONTENT_NEGATIVE_PERIOD_SELECT
      );
      this.invalidateSecondChoiseDate();
      return true;
    }


    return false;
  }

  private invalidateSecondChoiseDate(): void {
    this.dtFinal = null;
  }

  public eventoPesquisa(): void {

    // if (this.validateDays()) {
    //   return;
    // } else {
    // if (this.dtInicial  !== undefined && this.dtFinal !== undefined){

    const periodoInicialFinal: Periodo = new Periodo();

    let dtIniperi: any = moment(this.range.controls.start.value).format('YYYY-MM-DD') + "T00:00:00";
    let dtFimperi: any = moment(this.range.controls.end.value).format('YYYY-MM-DD') + "T00:00:00";
    periodoInicialFinal.setDtInicio(dtIniperi);
    periodoInicialFinal.setDtFim(dtFimperi);
    this.periodo.emit(periodoInicialFinal);
    // }
    // }
  }

  public openDialogSearInf(titleParam: string, messageParam: string): void {
    const dialogRef = this.dialog.open(DialogCustomComponent, {
      width: '450px',
      data: { title: titleParam, message: messageParam }
    });
  }

}
