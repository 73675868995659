import { Component, OnInit } from '@angular/core';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { DashboardColumnRQ } from './model/DashboardColumnRQ';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardColumnRS } from './model/DashboardColumnRS';
import { Protocolo } from '../app-enum/Protocolo';
import { DataChar } from '../app-dashboard-line/model/DataChar';
import { AppDashboardColumnWorkaroundService } from './app-dashboard-column-workaround.service';

@Component({
  selector: 'app-dashboard-column-workaround',
  templateUrl: './app-dashboard-column-workaround.component.html',
  styleUrls: ['./app-dashboard-column-workaround.component.css']
})
export class AppDashboardColumnWorkaroundComponent implements OnInit {

  private eventsSubscription: any;
  public requestClWor: DashboardColumnRQ = new DashboardColumnRQ();

  public barChartOptionsWork: any = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{
      ticks: {
          min: 0,
          // stepSize: 1
      }
    }] },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
      }
    }
  };

  public barChartTypeWor: any = 'bar';
  public barChartLegendWor = true;
  public barChartPluginsWor = [pluginDataLabels];

  public barChartDataWor: any[] = [];
  public barChartLabelsWor: any[] = [];

  private setCharData(rs: DashboardColumnRS[]): void {

    if (rs === null) {
      this.barChartDataWor = [{ data: [], label: '' }];
      return;
    }

    const dataDSCWOR = new DataChar();
    const arrayDataDSCWOR = new Array();
    for (const value of rs) {
      arrayDataDSCWOR.push(value.qntWa);
    }
    this.barChartDataWor = [{ data: [], label: '' }];
    dataDSCWOR.label = 'Descrição do Problema';
    dataDSCWOR.data = arrayDataDSCWOR;
    this.barChartDataWor.splice(0, this.barChartDataWor.length);
    this.barChartDataWor.push(dataDSCWOR);
  }

  private setCharLabels(rs: DashboardColumnRS[]): void {

    const arrayData = new Array();
    for (const value of rs) {
      let op = value.tipoWa;
      if ( op.length > 50 ) {
        op = 'OUTROS';
      }
      arrayData.push(op);
    }
    this.barChartLabelsWor = arrayData;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dashboardcolumnService: AppDashboardColumnWorkaroundService) {

    this.setCharData(null);
    // this.setCharLabels();
  }

  ngOnInit() {
    this.eventsSubscription = this.route.params.subscribe(params => {
      this.requestClWor.dtVoo = params['date'];
      this.requestClWor.sistemaOrigem = params['sys'];
      // console.log('[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 3 ] ' + this.request.dtVoo);
      // console.log('[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 3 ] ' + this.request.sistemaOrigem);
      this.getDataDashboardColumn(this.requestClWor.dtVoo);
    });
  }

  private getDataDashboardColumn(dtSearch: string): void {
    let rs: DashboardColumnRS[];
    this.dashboardcolumnService.getDataByDate(this.requestClWor).subscribe(
      data => {
        rs = data;
        // console.log('DASHBOARD COLUMN ' + JSON.stringify(rs));
      },
      err => {
        const protocolo: Protocolo = err.error;
        // console.log(protocolo);
      },
      () => {

        this.setCharData(rs);
        this.setCharLabels(rs);
      }
    );
  }

}
