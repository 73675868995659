import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor() { }

  public getCategories(): string[] {
    return ['Concessionario', 'Categoria-01', 'Categoria-02', 'Categoria-03', 'Categoria-04' ];
  }
}
