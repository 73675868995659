import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { ParametersEdit } from './model/parameters-edit';
import { Protocolo } from '../app-enum/Protocolo';
import { ParametersRequest } from './model/parameters-request';
import { endpoints } from '../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppFormParameterService {

  constructor(private http: HttpClient) { }

  getParameters(): Observable<ParametersEdit[]> {

    return this.http.get<ParametersEdit[]>(endpoints.REST_GET_PARAMETER_VALUE, httpOptions);
  }

  editParameter(parameter: ParametersRequest): Observable<Protocolo>{

    return this.http.put<Protocolo>(endpoints.REST_PUT_PARAMETER, parameter, httpOptions);
  }
}
