import { OpenDialog } from './../../utils/openDialog';
import { Component, OnInit } from '@angular/core';
import { AppAppConciliationLayoutService } from './app-conciliation-layout.service';
import { Layout } from './model/Layout';
import { Protocolo } from '../../app-datahealth/app-data-rpe-list/model/protocolo';
import { properties } from '../../../environments/properties';
import { DialogCustomComponent } from '../../app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ComboBoxAeroporto } from '../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { ComboBoxService } from '../../app-datahealth/app-search-information-by-date-and-rpe/combo-box.service';
import { MatDialog } from '@angular/material/dialog';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'app-app-conciliation-layout',
  templateUrl: './app-conciliation-layout.component.html',
  styleUrls: ['./app-conciliation-layout.component.css']
})
export class AppConciliationLayoutComponent implements OnInit {

  public EMPTY_STRING_VALIDATION = '';
  public separator = '-';
  public controlSave = false;
  public template: Layout;
  public templateTemp: any;
  public layouts: Layout[];
  public aeroporto: ComboBoxAeroporto[];
  public filteredAeroporto: Observable<ComboBoxAeroporto[]>;
  public filteredTemplate: Observable<Layout[]>;

  public conciliationTypeList: string[] = ['RPE', 'SELO_DOM_EMB', 'SELO_DOM_CON', 'SELO_INT_EMB', 'SELO_INT_CON'];
  public conciliationMaskList: string[] = [
    'dd/MM/yyyy',
    'dd/MM/yyyy HH:mm',
    'dd/MM/yyyy HH:mm:ss',
    'dd/MM/yyyy HH:mm:ss.SSS',
    'dd/MM/yyyy K:mm:ss aa',
    'dd/MM/yy',
    'MM/dd/yyyy',
    'yyyy/MM/dd',
    'dd-MM-yy',
    'dd-MM-yyyy',
    'MM-dd-yyyy',
    'yyyy-MM-dd',
    'yyyy/MM/dd HH:mm:ss',
    'yyyy/MM/dd HH:mm:ss.SSS',
    'yyyy-MM-dd HH:mm:ss',
    'yyyy-MM-dd HH:mm:ss.SSS',
    'dd-MM-yyyy K:mm:ss aa',
    'MM/dd/yyyy K:mm:ss aa',
    'MM-dd-yyyy K:mm:ss aa',
    'yyyy/MM/dd K:mm:ss aa',
    'yyyy-MM-dd K:mm:ss aa',
    'MM/dd/yyyy K:mm:ss',
    'MM/dd/yyyy K:mm:ss',
    'dd-MM-yyyy HH:mm:ss',
    'dd-MM-yyyy HH:mm:ss.SSS',
  ];
  public templateCtrl = new FormControl();
  public aeroportoCtrl = new FormControl();
  public conciliationTypeCtrl = new FormControl();
  public layoutNameCtrl = new FormControl();
  public startLineCtrl = new FormControl();
  public amountPaxBoardDomCtrl = new FormControl();
  public amountPaxBoardIntCtrl = new FormControl();
  public amountPaxConDomCtrl = new FormControl();
  public amountPaxConIntCtrl = new FormControl();
  public maskFormatationCtrl = new FormControl();
  public dtOperationCtrl = new FormControl();
  public numFlightCtrl = new FormControl();
  public typeOperationCtrl = new FormControl();
  public natureFlightCtrl = new FormControl();
  public valueRateCtrl = new FormControl();
  public valueRateFullCtrl = new FormControl();
  private readonly ATIVO = "A";
  private readonly SALVAR = "Salvar";
  private readonly EDITAR = "Editar";
  private readonly DUPLICAR = "Duplicar";
  private readonly ICON_SALVAR = "save";
  private readonly ICON_EDITAR = "edit";
  private readonly ICON_DUPLICAR = "content_copy";
  public  selectedVal = this.ATIVO;
  public  duplicaCheckbox = false;
  public  disabledCheckDuplica = true;
  public  nameButton =  this.SALVAR;
  public  iconButton = this.ICON_SALVAR;

  private readonly SELECIONAR_LAYOUT = 'Selecione um layout cadastrado previamente.';

  constructor(private appAppConciliationLayoutService: AppAppConciliationLayoutService,
              public dialog: MatDialog,
              public comboBoxService: ComboBoxService,
              private openDialogUtils:OpenDialog
            ) {
    this.templateCtrl.setValue('Carregando...');
  }

  ngOnInit() {
    this.startAllLoads();
  }

  private disableAmountForInputs(): void {
    this.amountPaxBoardDomCtrl.disable();
    this.amountPaxBoardIntCtrl.disable();
    this.amountPaxConDomCtrl.disable();
    this.amountPaxConIntCtrl.disable();
  }

  private enableAmountForInputs(): void {
    this.amountPaxBoardDomCtrl.enable();
    this.amountPaxBoardIntCtrl.enable();
    this.amountPaxConDomCtrl.enable();
    this.amountPaxConIntCtrl.enable();
  }

  private disableAllForInputs(): void {
    this.templateCtrl.disable();
    this.aeroportoCtrl.disable();
    this.conciliationTypeCtrl.disable();
    this.startLineCtrl.disable();
    this.amountPaxBoardDomCtrl.disable();
    this.amountPaxBoardIntCtrl.disable();
    this.amountPaxConDomCtrl.disable();
    this.amountPaxConIntCtrl.disable();
    this.maskFormatationCtrl.disable();
    this.dtOperationCtrl.disable();
    this.numFlightCtrl.disable();
    this.typeOperationCtrl.disable();
    this.natureFlightCtrl.disable();
    this.valueRateCtrl.disable();
    this.valueRateFullCtrl.disable();
  }

  private enableAllForInputs(): void {
    this.templateCtrl.enable();
    this.aeroportoCtrl.enable();
    this.conciliationTypeCtrl.enable();
    this.startLineCtrl.enable();
    this.amountPaxBoardDomCtrl.enable();
    this.amountPaxBoardIntCtrl.enable();
    this.amountPaxConDomCtrl.enable();
    this.amountPaxConIntCtrl.enable();
    this.maskFormatationCtrl.enable();
    this.dtOperationCtrl.enable();
    this.numFlightCtrl.enable();
    this.typeOperationCtrl.enable();
    this.natureFlightCtrl.enable();
    this.valueRateCtrl.enable();
    this.valueRateFullCtrl.enable();
  }

  private startAllLoads(): void {
    this.initTemplate();
    this.getAllLayoutsService();
    this.obterComboBoxAirport();
  }

  private initTemplate(): void {
    this.template = new Layout();
  }

  private getAllLayoutsService(): void {
     this.appAppConciliationLayoutService.getAllLayoutsToList().subscribe(
      dataSource => {
        // console.log('loadding all layouts: ' + dataSource);
        $('#template').val(this.SELECIONAR_LAYOUT);
        this.layouts = dataSource.data;
        this.filteredTemplate =
          this.templateCtrl
            .valueChanges.
            pipe(
              startWith(''),
              map(layout => layout ? this._filterLayout(layout) : this.layouts));
      }, err => {
        const protocolo: Protocolo = err.error;
        // console.log(protocolo);
        this.openDialog(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_RPE_GENERATED_NOT_FOUND
        );
      });

  }

  private _filterLayout(value: any): any {

    let filterValue = '';

    if (value.toLowerCase === undefined) {
      filterValue = value.nameLayout;

    } else {
      filterValue = value.toLowerCase().trim();
    }

    return this.layouts.filter(layout => layout.nameLayout.toLowerCase().trim().indexOf(filterValue) === 0);

  }

  private obterComboBoxAirport(): void {
    this.comboBoxService.getComboBoxAeroporto().subscribe(
      dataSource => {
        this.aeroporto = dataSource;
        this.reactiveLayoutChange();
      }, err => {
        const protocolo: Protocolo = err.error;
      });
  }

  private reactiveLayoutChange() {
    this.filteredAeroporto = this.aeroportoCtrl.valueChanges
      .pipe(startWith(''), map(aeroporto => aeroporto ? this._filterAeroportoCL(aeroporto) : this.aeroporto.slice()));
  }

  public foraFocus(): void {
    if (this.template.nameLayout === '') {
      $('#template').val(this.SELECIONAR_LAYOUT);
    } else {
      this.separator = '-';
    }
  }

  public cleanValueInput(): void {
    $('#template').val('');
  }

  private _filterAeroportoCL(value: string): ComboBoxAeroporto[] {
    const filterValue = value.toLowerCase();
    return this.aeroporto.filter(aeroporto => aeroporto.codeIATA.toLowerCase().indexOf(filterValue) === 0);
  }

  private openDialog(conTitleParam: string, conMessageParam: string): void {
    this.openDialogUtils.openDialog(conTitleParam,conMessageParam)
  }

  public cleanTemplate(): void {
    this.cleanValueInput();
    this.template = new Layout();
    this.separator = this.SELECIONAR_LAYOUT;
    this.selectedVal = this.ATIVO;
    this.nameButton = this.SALVAR;
    this.iconButton = this.ICON_SALVAR;
    this.disabledCheckDuplica = true;
    this.duplicaCheckbox = false;
    this.enableAllForInputs();
  }

  public saveOrEditTemplate(): void {

    this.controlSave = true;
    this.disableAllForInputs();

    if (this.validateFildsForm()) {
      this.openDialog(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
        properties.MODAL_CONTENT_VALIDATE_FIELDS_FAILDS);

      this.controlSave = false;
      this.enableAllForInputs();
      return;
    }
    this.assignStatusLayout();
    this.appAppConciliationLayoutService.saveOrEditTemplate(this.template, this.duplicaCheckbox).subscribe(
      dataSource => {
        this.openDialog(
          properties.MODAL_TITLE_SUCESS,
          properties.MODAL_BODY_SUCESS_OPERATION);
        this.controlSave = false;
        this.startAllLoads();
        this.enableAllForInputs();
        this.selectedVal = this.ATIVO;
        this.template.statusLayout= null;
        this.nameButton = this.SALVAR;
        this.iconButton = this.ICON_SALVAR;
        this.disabledCheckDuplica = true;
        this.duplicaCheckbox = false;
      }, err => {
        const protocolo: Protocolo = err.error;
        // console.log(protocolo);
        this.openDialog(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_RPE_GENERATED_NOT_FOUND);
        this.controlSave = false;
        this.enableAllForInputs();

      });
  }

  private validateFildsForm(): boolean {
    if (this.template.conciliationType === 'RPE') {

      const boo =
        (this.template.layoutAmount.amountPaxBoardDom === undefined
          || this.template.layoutAmount.amountPaxBoardDom === this.EMPTY_STRING_VALIDATION) &&
        (this.template.layoutAmount.amountPaxBoardInt === undefined
          || this.template.layoutAmount.amountPaxBoardInt === this.EMPTY_STRING_VALIDATION) &&
        (this.template.layoutAmount.amountPaxConDom === undefined
          || this.template.layoutAmount.amountPaxConDom === this.EMPTY_STRING_VALIDATION) &&
        (this.template.layoutAmount.amountPaxConInt === undefined
          || this.template.layoutAmount.amountPaxConInt === this.EMPTY_STRING_VALIDATION);
      if (boo) { return true; }
    }

    return false;
  }

  public validSelectedIten(event: MatOptionSelectionChange, state: any): void {
    if (event.source.selected) {
      if (state != null
        && state !== undefined
        && state !== 'RPE') {
        this.disableAmountForInputs();
      } else {
        this.enableAmountForInputs();
      }
    }
  }

  public setTemplate(myChoise: Layout): void {
    this.template = myChoise;
    this.selectedVal = this.template.statusLayout;
    this.disabledCheckDuplica = false;
    this.nameButton = this.EDITAR;
    this.iconButton = this.ICON_EDITAR;
  }

  public onValChange(val: string) {
    this.selectedVal = val;
    this.template.statusLayout = this.selectedVal;
  }

  public assignStatusLayout():void{
    if(this.template.statusLayout === undefined
        || this.template.statusLayout === null
        || this.template.statusLayout === ""){

          this.template.statusLayout = this.selectedVal;
        }
  }

  public setDuplicaCheck(valCheckBox: boolean){

    //console.log("Acionado CheckBox valor " + valCheckBox);
    if(valCheckBox){
      this.duplicaLayout();
    }else{
      this.enableAllForInputs();
      this.nameButton = this.EDITAR;
      this.iconButton = this.ICON_EDITAR;
    }

    //console.log("Check Box " + this.duplicaCheckbox);

  }

  public duplicaLayout(){
    this.disableAllForInputs();
    this.aeroportoCtrl.enable();
    this.nameButton = this.DUPLICAR;
    this.iconButton = this.ICON_DUPLICAR;
  }

}
