import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class Airport {
    codeIATA: string;
    codeICAO: string;
    descriptionName: string;
    id: number;
    startValid: any;
    endValid: any;
    startValidNgb: any;
    endValidNgb: any;
    nameUserLastUpdate: string;
    administratorAirport: number;
    lastUpdate: any;
    ftpId: number;
    pathRPE: string;
    pathStamp: string;
    host: string;
    user: string;
    secret: string;
}
