import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Airport } from './model/Airport';
import { endpoints } from '../../../environments/endpoint';
import { Administrator } from './model/Administrator';
import { AirportAdministrator } from './model/AirportAdministrator';
import { DateService } from '../../app-conciliation/conciliation/service/date.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppModalAdministratorService {

  constructor(private http: HttpClient,private dateService: DateService) { }

  public getListAirport(): Observable<Airport[]> {
    let url = endpoints.REST_GTECLOUD_GET_AIRPORTS+ '?operation=authorized'
    return this.http.get<Airport[]>(url, httpOptions);
  }

  public getListAirportAdministrator(adminsitratorCode: number): Observable<Airport[]> {
    let url = endpoints.REST_GTECLOUD_GET_AIRPORTS 
                + '/' + adminsitratorCode + '/administrator'
    return this.http.get<Airport[]>(url, httpOptions);
  }

  public removeAirportAdministrator(airport: Airport): Observable<Airport[]> {
    airport.nameUserLastUpdate = 'APPLICATION-WEB';
    let restTemplate = endpoints.REST_GTECLOUD_GET_AIRPORTS + endpoints.REST_GTECLOUD_COMPLEMENT_ID;
    restTemplate = restTemplate.replace('{id}',(airport.id+''));
    return this.http.put<Airport[]>(restTemplate, airport, httpOptions);
  }

  public getListAdministratorAirport(): Observable<Administrator[]> {
    return this.http.get<Administrator[]>(endpoints.REST_GTECLOUD_GET_ADMINISTRATORS_AIRPORTS, httpOptions);
  }

  public saveOrEditAdministrator(administrator: AirportAdministrator): Observable<AirportAdministrator> {
    
    // console.log('request to created or edit administrator: {}, ', administrator);
    
    administrator.administrator.lastUpdate = new Date();
    administrator.administrator.nameUserLastUpdate = 'APPLICATION-WEB';

    for(let air of administrator.airports){
      air.administratorAirport = 0;
      air.nameUserLastUpdate = administrator.administrator.nameUserLastUpdate;
      air.lastUpdate = administrator.administrator.lastUpdate;
    }

    if (administrator.administrator.identification == 0 
          || administrator.administrator.identification == null 
            || administrator.administrator.identification == undefined) {
        return this.http.post<AirportAdministrator>(endpoints.REST_GTECLOUD_GET_ADMINISTRATORS_AIRPORTS, administrator, httpOptions);
    } else {
        let restTemplate = endpoints.REST_GTECLOUD_GET_ADMINISTRATORS_AIRPORTS + endpoints.REST_GTECLOUD_COMPLEMENT_ID;
        restTemplate = restTemplate.replace('{id}',(administrator.administrator.identification+''));
        return this.http.put<AirportAdministrator>(restTemplate, administrator, httpOptions);
    }
  }

  public deleteAdministrator(administrator: Administrator): Observable<Administrator> {
    let restTemplate = endpoints.REST_GTECLOUD_GET_ADMINISTRATORS_AIRPORTS + endpoints.REST_GTECLOUD_COMPLEMENT_ID;
    restTemplate = restTemplate.replace('{id}',(administrator.identification+''));
    return this.http.delete<Administrator>(restTemplate, httpOptions);
  }

}
