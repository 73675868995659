import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { endpoints } from '../../../environments/endpoint';
import { DashboardlineRQ } from './model/DashboardlineRQ';
import { DashboardlineRS } from './model/DashboardlineRS';
import { Observable} from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class DashboardLineService {

  constructor(private http: HttpClient) { }

  convertDataIso(requestDL: DashboardlineRQ): void {
    let arrayString: string[] = [];
    const dtInicio = new Date(requestDL.dtInicio).toISOString();
    const dtFim = new Date(requestDL.dtFim).toISOString();

    arrayString = dtInicio.split('T');
    requestDL.dtInicio = arrayString[0] + 'T00:00:00';
    arrayString = dtFim.split('T');
    requestDL.dtFim = arrayString[0] + 'T23:59:59';
  }

  getDataByDate(requestDL: DashboardlineRQ): Observable<DashboardlineRS[]> {

    if (!requestDL.dtInicio.includes('T')) {
      this.convertDataIso(requestDL);
    }

    let params1 = new HttpParams().set('flightBeginDate', requestDL.dtInicio);
    let params2 = new HttpParams().set('flightEndDate', requestDL.dtFim);
    // console.log('Data: {}', request);
    return this.http.get<DashboardlineRS[]>(endpoints.REST_POST_RPE_NOT_GENERATE_BY_DATE+"?"+params1+"&"+params2, httpOptions);
  }

}
