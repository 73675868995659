import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '../../../environments/endpoint';
import { ResponseModel } from './model/ResponseModel';
import { Layout } from './model/Layout';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class AppAppConciliationLayoutService {

  constructor(private http: HttpClient) { }

  public getAllLayoutsToList(): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(endpoints.REST_OPERATION_TEMPLATE, httpOptions);
  }

  public saveOrEditTemplate(template: Layout, duplicarLayout: boolean): Observable<ResponseModel> {
    //console.log("SALVANDO ID_LAYOUT " + template.idLayout);
    //console.log("duplicarLayout " + duplicarLayout);
    if(duplicarLayout){
      template.idLayout = undefined;
    }
    if (template.idLayout === 0 || template.idLayout === undefined){
      //console.log("ID_LAYOUT POST " + template.idLayout);
      return this.http.post<ResponseModel>(endpoints.REST_OPERATION_TEMPLATE, template, httpOptions);
    } else {
      //console.log("ID_lAYOUT PUT " + template.idLayout);
      return this.http.put<ResponseModel>(endpoints.REST_OPERATION_TEMPLATE, template, httpOptions);
    }
  }

}
