import { SaveBlobResume } from './../../utils/saveBlobResume';
import { OpenDialog } from './../../utils/openDialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ComboBoxAeroporto } from '../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { ComboBoxEmpresa } from '../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-empresa';
import { Observable } from 'rxjs';
import { ComboBoxService } from '../../app-datahealth/app-search-information-by-date-and-rpe/combo-box.service';
import { UntypedFormControl, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { FlightLeg } from './models/flight-leg';
import { DateService } from '../../app-conciliation/conciliation/service/date.service';
import { SummaryRpeService } from './services/summary-rpe.service';
import { ConsultFormFlightLeg } from './models/consult-form-flight-leg';
import { properties } from '../../../environments/properties';
import { NgbDateStruct, NgbDate, NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import { DialogCustomComponent } from '../../app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { I18n, CustomDatepickerI18n } from '../../app-datahealth/app-search-information-by-period/CustomDatepickerI18n';
import { NgbDatePTParserFormatter } from '../../app-datahealth/app-search-information-by-period/NgbDatePTParserFormatter';
import { AppModalAdministratorComponent } from '../app-administrator-airport/app-modal-administrator/app-modal-administrator.component';
import { AppModalStampComponent } from './app-modal-stamp/app-modal-stamp.component';
import { RpeResumeFileComponent } from '../rpe-resume-file/rpe-resume-file.component';
import { AppModalReportTypeComponent } from './app-modal-report-type/app-modal-report-type.component';
import { StampTransfer } from './models/StampTransfer';
import { RpeTransfer } from './models/RpeTransfer';
import { StampIds } from './models/StampIds';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as $ from 'jquery'
import { MatSelectionList } from '@angular/material/list';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-app-rpe-report-summary',
  templateUrl: './app-rpe-report-summary.component.html',
  styleUrls: ['./app-rpe-report-summary.component.css'],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{ provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter }],
  ],
})
export class AppRpeReportSummaryComponent implements OnInit {

  public displayMonths = properties.HTML_CONST_QTD_MOUNTHS;
  public controlShowTable: boolean = true;

  public displayedColumns: string[] = [
    'select',
    'prefix',
    'typeAircraft',
    'numFlightArrival',
    'dateFlightArrival',
    'numFlightDeparture',
    'dttmFlightDeparture',
    'dateGeneratedFile',
    'typeGenerated',
    'iataAirportOrigin',
    'iataAirportDest',
    'typeNature',
    'viewRpe',
    'reportListPax'
  ];

  public formulario = new UntypedFormGroup({
    airport: new UntypedFormControl('', Validators.required),
    company: new UntypedFormControl('', Validators.required),
    flightStartDate: new UntypedFormControl(undefined, Validators.required),
    flightEndDate: new UntypedFormControl(undefined, Validators.required),
    numFlightDeparture: new UntypedFormControl(undefined),
    numFlightArrival: new UntypedFormControl(undefined)
  });

  public disableAll(): void {
    this.formulario.disable();
    this.controlSave = true;
    if (this.reportTypeRS == 'rpe'){
      this.controlShowDownloadCSV = true;
      this.controlShowDownloadPdf = false;
    }
  }

  public enableAll(): void {
    this.formulario.enable();
    this.controlSave = false;
    if (this.reportTypeRS != 'rpe'){
      this.controlShowDownloadCSV = false;
      this.controlShowDownloadPdf = true;
    }
  }

  public consultFormFlightLeg: ConsultFormFlightLeg;

  public aeroportoRS: ComboBoxAeroporto[];
  public empresaRS: ComboBoxEmpresa[];

  public filteredAeroporto: Observable<ComboBoxAeroporto[]>;
  public filteredEmpresa: Observable<ComboBoxEmpresa[]>;

  public dataSourceflights = new MatTableDataSource<FlightLeg>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  selection = new SelectionModel<any>(true, []);

  public isDisabled: boolean;
  eventsSubscription: any;
  reportYypeResume: any;
  reportTitle = "";
  reportTypeRS = "";
  controlSave = false;
  controlShowDownloadCSV = false;
  controlShowDownloadPdf = false;
  fileType: string;

  public filterValue = '';


  constructor(private comboBoxService: ComboBoxService,
    private dateService: DateService,
    private summaryService: SummaryRpeService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private openDialogUtils: OpenDialog,
    private saveBlobResume: SaveBlobResume) {
    this.isDisabled = true;
  }

  ngOnInit() {

    this.eventsSubscription = this.route.params.subscribe(params => {
      this.reportTypeRS = this.getReportTypeByRequest(params['typeSerach']);
      this.clearFields();
      this.controlShowTable = true;
    });

    this.obterComboBoxAirportRRS();
    this.obterComboBoxCompanyRRS();
  }

  applyFilter(filterValue: string) {
    this.filterValue = filterValue.trim().toLowerCase();
    this.dataSourceflights.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    if (this.dataSourceflights != null) {
      const numSelected = this.selection?.selected?.length;
      const numRows = this.dataSourceflights.data.length;
      return numSelected === numRows;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceflights.data.forEach(row => this.selection?.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: FlightLeg): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection?.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  getReportTypeByRequest(typeFileSearch: string): string {
    this.reportTitle = "Consulta de Informações de " + (typeFileSearch == "rpe" ? "RPE's" : "SELO's");
    return typeFileSearch;
  }

  public getFormatDateToStringIso(date: any): string {
    return this.dateService.ngbDateStringToString(date);
  }

  public consult(): void {
    console.log('A alteração subiu Andrietitiiiiii')
    this.disableAll();
    this.controlShowTable = true;
    this.dataSourceflights = new MatTableDataSource<FlightLeg>();
    this.dataSourceflights.paginator = this.paginator;
    this.selection?.clear();

    this.consultFormFlightLeg = new ConsultFormFlightLeg(this.formulario);
    this
      .summaryService
      .getReportSummaryByFilterOnFlightLeg(this.consultFormFlightLeg, this.reportTypeRS)
      .subscribe(
        flightlegs => {

          this.enableAll();
          this.dataSourceflights = new MatTableDataSource(flightlegs);
          this.dataSourceflights.paginator = this.paginator;

          if(flightlegs.length > 0){
            this.controlShowTable = false;
          }else{
            this.openDialog(
              properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
              properties.MODAL_CONTENT_RPE_GENERATED_NOT_FOUND);
          }
      });
  }

  public isInvalid(): boolean {
    return this.formulario.invalid;
  }

  public clearFields(): void {

    this.formulario.reset();
    this.dataSourceflights = null;
  }

  public isDisabledLastOneRpeRep(date: NgbDateStruct) {

    const dateInitialArrayRpeRep: string[] = $('#startDate').val().toString().split('-');

    const yearRpeRep = parseInt(dateInitialArrayRpeRep[0], 10);
    const monthRpeRep = parseInt(dateInitialArrayRpeRep[1], 10);
    const dayRpeRe = parseInt(dateInitialArrayRpeRep[2], 10);

    const dateInitialRpeRe = new Date(yearRpeRep, monthRpeRep - 1, dayRpeRe);
    const dateCurrentRpeRe = new Date(date.year, date.month - 1, date.day);
    const daysOfDateInitialRpeRe = dateInitialRpeRe.getTime();
    const daysOfDateCurrentdayRpeRe = dateCurrentRpeRe.getTime();
    const diffDaysdayRpeRe = Math.abs(daysOfDateInitialRpeRe - daysOfDateCurrentdayRpeRe) / 86400000;
    return diffDaysdayRpeRe > 30;
  }

  public onDateStartSelect(event) {
    this.isDisabled = false;
    this.formulario.get('flightEndDate').reset();
  }

  public viewRpe(idFlightLeg: number): void {
    if (this.reportTypeRS == 'rpe') {
      this.openModalRPEInformation(idFlightLeg);
    } else {
      this.openModalStampInformation(idFlightLeg);
    }
  }

  private upperFirstLetter(word: string): string{
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  public downloadRPE(fileType: string): void {

    this.fileType = fileType;
    //console.log('Download CSV is ' + fileType);
    const nameFile: string = 'GeracaoManual' + this.upperFirstLetter(this.reportTypeRS)
                                              + this.upperFirstLetter(this.fileType)
                                              + '.zip';
    console.log('receive request to download file: {}', this.selection?.selected);
    let arrId: number[] = new Array();
    this.selection?.selected.forEach(element => {
      arrId.push(element.id);
    });

    this.consultFormFlightLeg = new ConsultFormFlightLeg(this.formulario);

    if (this.reportTypeRS == 'rpe') {
      this.getFileRPEtoDownloadList(
        arrId,
        nameFile,
        this.getIcaoAirportByIata(this.consultFormFlightLeg.airport),
        this.getIcaoCompByIata(this.consultFormFlightLeg.company));
    } else {
      this.getFileStamptoDownloadList(arrId, nameFile);
    }

  }

  public getIcaoAirportByIata(iata: string): string {
    let rtIcao: string = '';
    this.aeroportoRS.forEach(element => {
      if (element.codeIATA == iata) {
        rtIcao = element.codeICAO;
      }
    });
    return rtIcao;
  }

  public getIcaoCompByIata(iata: string): string {
    let rtIcao: string = '';
    this.empresaRS.forEach(element => {
      if (element.iataCode == iata) {
        rtIcao = element.icaoCode;
      }
    });
    return rtIcao;
  }

  public getFileStamptoDownloadList(idFlightLeg: number[], nameFile: string): void {
    this.disableAll();
    let stamps: StampIds = new StampIds();
    stamps.listFlightIdToProcess = idFlightLeg;
    stamps.csvFile = this.fileType=='csv'?true:false;
    this.aeroportoRS.forEach(element =>{
      if(element.codeIATA == this.formulario.get('airport').value){
        stamps.airportIATA = element.codeIATA;
      }
    });

    this.empresaRS.forEach(element => {
      if (element.iataCode == this.formulario.get('company').value) {
        stamps.companyCode = element.iataCode;
      }
    });
    this
      .summaryService.getFileStamptoDownloadList(stamps)
      .toPromise()
      .then(response => {
        //const nameFile = 'GeracaoManual.zip';
        const type = 'application/zip';
        if (response.size != 0) {
          this.saveBlobResumeBoarding(response, nameFile, type);
        } else {
          this.openDialog(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
            properties.MODAL_CONTENT_ERROR_GENERATED_FILE_ZIP);
        }
      }).catch(error => {
        this.openDialog(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_ERROR_GENERATED_FILE_ZIP);
      }).finally(() => {
        this.enableAll();
      });
  }

  public getFileRPEtoDownloadList(idFlightLeg: number[], nameFile: string, icaoAirport: string, icaoCompany: string): void {
    this.disableAll();
    this
      .summaryService.getFileRPEtoDownloadList(idFlightLeg, icaoAirport, icaoCompany, this.fileType)
      .toPromise()
      .then(response => {
        //const nameFile = 'GeracaoManual.zip';
        const type = 'application/zip';
        if (response.size != 0) {
          this.saveBlobResumeBoarding(response, nameFile, type);
        } else {
          this.openDialog(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
            properties.MODAL_CONTENT_ERROR_GENERATED_FILE_ZIP);
        }
      }).catch(error => {
        this.openDialog(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_ERROR_GENERATED_FILE_ZIP);
      }).finally(() => {
        this.enableAll();
      });

  }

  private openDialog(rpeRepTitleParam: string, rpeRepMessageParam: string): void {
    this.openDialogUtils.openDialog(rpeRepTitleParam,rpeRepMessageParam)
  }

  saveBlobResumeBoarding(rpeRepResponse: Blob, rpeRepNameFile: string, rpeRepTypeFile: string): any {
    this.saveBlobResume.saveBlobResumeBoarding(rpeRepResponse, rpeRepNameFile, rpeRepTypeFile)
  }

  private hasArrivalAndDeparture(): boolean {

    return this.formulario.get('numFlightArrival').value !== null || this.formulario.get('numFlightDeparture').value !== null;
  }
  //
  private obterComboBoxAirportRRS(): void {
    this.comboBoxService
      .getComboBoxAeroporto()
      .subscribe(
        dataSource => {
          this.aeroportoRS = dataSource;
          console.log( this.filteredAeroporto = this.formulario.get('airport').valueChanges
          .pipe(
            startWith(''),
            map(aeroporto => aeroporto ? this._filterAeroporto(aeroporto) : this.aeroportoRS.slice())
          ), "<-----vamos sendo passado pro filteredAeroportoo")
          this.filteredAeroporto = this.formulario.get('airport').valueChanges
            .pipe(
              startWith(''),
              map(aeroporto => aeroporto ? this._filterAeroporto(aeroporto) : this.aeroportoRS.slice())
            );
        }, () => {
        });
      console.log(this.aeroportoRS, "<--this.aeroporto")
  }

  private obterComboBoxCompanyRRS(): void {
    this.comboBoxService.getComboBoxEmpresa().subscribe(
      dataSource => {
        this.empresaRS = dataSource;
        this.filteredEmpresa = this.formulario.get('company').valueChanges
          .pipe(
            startWith(''),
            map(empresa => empresa ? this._filterEmpresa(empresa) : this.empresaRS.slice())
          );
      }, () => {
      });
  }

  private _filterAeroporto(value: string): ComboBoxAeroporto[] {
    const filterValue = value.toLowerCase();
    return this.aeroportoRS.filter(aeroporto => aeroporto.codeIATA.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterEmpresa(value: string): ComboBoxEmpresa[] {
    const filterValue = value.toLowerCase();
    return this.empresaRS.filter(empresa => empresa.iataCode.toLowerCase().indexOf(filterValue) === 0);
  }

  public openModalListPaxInformation(idFlightLeg: FlightLeg): void {

    idFlightLeg.company = this.formulario.get('company').value;
    this.empresaRS.forEach(element => {
      if (element.iataCode == idFlightLeg.company) {
        idFlightLeg.companyId = element.identification;
      }
    });

    console.log('report list pax: {} ', idFlightLeg);

    this.disableAll();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = idFlightLeg;
    dialogConfig.width = '40%';
    dialogConfig.height = '20%';
    this
      .dialog
      .open(AppModalReportTypeComponent, dialogConfig)
      .afterClosed().subscribe(responseDialog => {
        this.enableAll();
      });
  }

  public openModalStampInformation(idFlightLeg: number): void {

    this.disableAll();
    this.summaryService.getStampInfo(idFlightLeg).subscribe(
      dataSource => {

        let stampTransfer: StampTransfer = new StampTransfer();
        stampTransfer.aeroportos = this.aeroportoRS;
        stampTransfer.stampInfo = dataSource;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = stampTransfer;
        dialogConfig.width = '80%';
        dialogConfig.height = '90%';

        this
          .dialog
          .open(AppModalStampComponent, dialogConfig)
          .afterClosed().subscribe(responseDialog => {
            this.enableAll();
          });

      }, err => {
        this.enableAll();
      });

  }


  public openModalRPEInformation(idFlightLeg: number): void {

    this.disableAll();
    this.summaryService.getRpeInfo(idFlightLeg).subscribe(
      dataSource => {

        let rpeTransfer: RpeTransfer = new RpeTransfer();
        rpeTransfer.rpeInfo = dataSource;
        rpeTransfer.aeroportos = this.aeroportoRS;
        rpeTransfer.empresas = this.empresaRS;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = rpeTransfer;
        dialogConfig.width = '80%';
        dialogConfig.height = '90%';

        this
          .dialog
          .open(RpeResumeFileComponent, dialogConfig)
          .afterClosed().subscribe(responseDialog => {
            this.enableAll();
          });

      }, err => {
        this.enableAll();
      });

  }

}
