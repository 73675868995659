import { ParametersEdit } from './parameters-edit';

export class ParametersRequest {

    public parameterEdit: ParametersEdit[]; 

    constructor(){
        this.parameterEdit = undefined;
    }

}