import { OpenDialog } from './../../utils/openDialog';
import { Component, OnInit } from '@angular/core';
import { FlightSeloRPE } from './model/FlightSeloRPE';
import { UntypedFormControl } from '@angular/forms';
import { ComboBoxAeroporto } from '../../../app/app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { ComboBoxEmpresa } from '../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-empresa';
import { Observable } from 'rxjs';
import { NgbDate, NgbDateParserFormatter, NgbDatepickerConfig, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { ComboBoxService } from '../../../app/app-datahealth/app-search-information-by-date-and-rpe/combo-box.service';
import { startWith, map } from 'rxjs/operators';
import { Protocolo } from '../../../app/app-datahealth/app-enum/Protocolo';
import { I18n } from '../../../app/app-datahealth/app-search-information-by-period/CustomDatepickerI18n';
import { CustomDatepickerI18n } from '../../../app/app-datahealth/app-search-information-by-period/CustomDatepickerI18n';
import { NgbDatePTParserFormatter } from '../../../app/app-datahealth/app-search-information-by-period/NgbDatePTParserFormatter';
import { ExclusionFileRpeSeloService } from './services/exclusion-file-rpe-selo.service';
import { FlightLegFile } from './model/FlightLegFile';
import { HistoricRpeSeloExcluded } from './model/HistoricRpeSeloExcluded';
import { AppDeletionConfirmationRpeSeloComponent } from './modal/app-deletion-confirmation-rpe-selo.component';
import { DatePipe } from '@angular/common';
import { DialogCustomComponent } from '../../../app/app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { AuthenticationService } from '../../../app/app-authenticator/authentication.service';
import { properties } from '../../../environments/properties';
import { LogsComponent } from './logs/logs.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';


@Component({
  selector: 'app-app-exclusion-rpe-selo',
  templateUrl: './app-exclusion-rpe-selo.component.html',
  styleUrls: ['./app-exclusion-rpe-selo.component.css'],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter}],
  ],
})
export class AppExclusionRpeSeloComponent implements OnInit {

  public flightSeloRPE: FlightSeloRPE;
  public aeroportoERP: ComboBoxAeroporto[];
  public empresaERP: ComboBoxEmpresa[];
  public filteredAeroporto: Observable<ComboBoxAeroporto[]>;
  public filteredEmpresa: Observable<ComboBoxEmpresa[]>;
  public rpe: boolean;
  public selo: boolean;
  public flightLegFile: FlightLegFile[];
  public historicRpeSeloExcluded: HistoricRpeSeloExcluded[];
  public dateIniFlight: NgbDate;
  public dateEndFlight: NgbDate;

  public numFlightCtrl = new UntypedFormControl();
  public aeroportoCtrl = new UntypedFormControl();
  public companyCtrl = new UntypedFormControl();
  public typeFlightCtrl = new UntypedFormControl();
  public dateFlightCtrl = new UntypedFormControl();
  public btnExclusionCtrl: boolean = false;

  constructor(config: NgbDatepickerConfig,
    public comboBoxService: ComboBoxService,
    public exclusionRpeSeloService: ExclusionFileRpeSeloService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    public datePipe: DatePipe,
    private openDialogUtils: OpenDialog) {

    config.minDate = {year: 1976, month: 1, day: 1};
    config.maxDate = {year: 2099, month: 12, day: 31};
    config.outsideDays = 'hidden';

    this.flightSeloRPE = new FlightSeloRPE();



  }

  ngOnInit() {
    this.obterComboBoxAirportERS();
    this.obterComboBoxCompanyRES();
    this.disableAll();
  }

  public obterComboBoxAirportERS(): void {
    this.comboBoxService.getComboBoxAeroporto().subscribe(
      dataSourceExc => {
          this.aeroportoERP = dataSourceExc;
          this.filteredAeroporto = this.aeroportoCtrl.valueChanges
            .pipe(
              startWith(''),
              map(aeroporto => aeroporto ? this._filterAeroporto(aeroporto) : this.aeroportoERP.slice())
            );
        }, err => {
          const protocolo: Protocolo = err.error;
      });
  }

  private _filterAeroporto(value: string): ComboBoxAeroporto[] {
    const filterValue = value.toLowerCase();
    return this.aeroportoERP.filter(aeroporto => aeroporto.codeIATA.toLowerCase().indexOf(filterValue) === 0);
  }

  private obterComboBoxCompanyRES(): void {
    this.comboBoxService.getComboBoxEmpresa().subscribe(
      dataSource => {
        this.empresaERP = dataSource;
        this.filteredEmpresa = this.companyCtrl.valueChanges
          .pipe(
            startWith(''),
            map(empresa => empresa ? this._filterEmpresa(empresa) : this.empresaERP.slice())
          );
      }, () => {
      });
  }

  private _filterEmpresa(value: string): ComboBoxEmpresa[] {
    const filterValue = value.toLowerCase();
    return this.empresaERP.filter(empresa => empresa.iataCode.toLowerCase().indexOf(filterValue) === 0);
  }

  public exclusionEvent(){

    if(! this.validateFields()){
      return;
    }

    //const myIniDate = new Date(this.dateIniFlight.year, this.dateIniFlight.month - 1, this.dateIniFlight.day);
    let dateIniFlightRpeSelo: string = moment(this.dateIniFlight).format('YYYY-MM-DD');
    //const myEndDate = new Date(this.dateEndFlight.year, this.dateEndFlight.month - 1, this.dateEndFlight.day);
    let dateEndFlightRpeSelo: string = moment(this.dateEndFlight).format('YYYY-MM-DD');


    this.flightSeloRPE.typeFile = this.chosenFile();
    this.flightSeloRPE.dateFlightIni = dateIniFlightRpeSelo;
    this.flightSeloRPE.dateFlightEnd = dateEndFlightRpeSelo;


    this.exclusionRpeSeloService.getFlightLegToDelete(this.flightSeloRPE)
    .subscribe(dataSource =>{

      this.flightLegFile = dataSource;

      if(this.flightLegFile !== null && this.flightLegFile !== undefined &&
         this.flightLegFile.length !== 0){

          let dialogConfig = this.modalConfig(this.flightLegFile);
          this.openModalExclusionFileRpeSelo(dialogConfig);

      }else{
        this.openDialog(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_FILE_DELETE_NOT_FOUND);

      }
    }, err => {
      this.openDialog(
        properties.MODAL_ERROR_TITLE,
        properties.MODAL_CONTENT_CONSULT_FLIGHT_INVALID);
    });

  }

  public logsEvent(){


    this.exclusionRpeSeloService.getHistoricRpeSeloDelete(
      this.authenticationService.getCurrentUser().email
      //'giovannimarazzi.everis@latam.com'
    ).subscribe(datasource => {
      this.historicRpeSeloExcluded = datasource;
      if(this.historicRpeSeloExcluded !== null &&
        this.historicRpeSeloExcluded !== undefined &&
        this.historicRpeSeloExcluded.length !== 0) {
          let dialogConfig = this.logsConfig(this.historicRpeSeloExcluded);
          this.openLogsExclusionFileRpeSelo(dialogConfig);

      } else {
        this.openDialog(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_LOGS_NOT_FOUND);
      }
    });
  }

  private chosenFile(): string{

    if(this.rpe && this.selo){
      return "RPE SELO";
    }

    if(this.rpe ){
      return "RPE";
    }

    if(this.selo){
      return  "SELO";
    }

  }

  public modalConfig(flightsLegsFile: FlightLegFile[]):MatDialogConfig{

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = flightsLegsFile;
    dialogConfig.width = '80%';
    dialogConfig.height = '70%';

    return dialogConfig;

  }

  public logsConfig(history: HistoricRpeSeloExcluded[]):MatDialogConfig{

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = history;
    dialogConfig.width = '95%';
    dialogConfig.height = '90%';

    return dialogConfig;

  }

  public openModalExclusionFileRpeSelo(dialogConfig: MatDialogConfig): void{

    this.dialog
    .open(AppDeletionConfirmationRpeSeloComponent, dialogConfig)
    .afterClosed().subscribe(responseDialog => {
     // this.loadUsers();
    });


  }
  public openLogsExclusionFileRpeSelo(dialogConfig: MatDialogConfig): void{

    this.dialog
    .open(LogsComponent, dialogConfig)
    .afterClosed().subscribe(responseDialog => {
     // this.loadUsers();
    });


  }

 public selectedCheckBox() {

   // console.log("RPE = " +this.rpe);
    if(this.rpe){
     this.enableAll();
     return;
    }
    if(this.selo){
      this.enableAll();
      this.typeFlightCtrl.disable();
      this.flightSeloRPE.typeFlight = "";
      return;
    }

    this.disableAll();
  }

  private disableAll():void{

    this.numFlightCtrl.disable();
    this.aeroportoCtrl.disable();
    this.companyCtrl.disable();
    this.typeFlightCtrl.disable();
    this.dateFlightCtrl.disable();
    this.btnExclusionCtrl = true;

  }

  private enableAll(){
    this.numFlightCtrl.enable();
    this.aeroportoCtrl.enable();
    this.companyCtrl.enable();
    this.typeFlightCtrl.enable();
    this.dateFlightCtrl.enable();
    this.btnExclusionCtrl = false;

  }

  private validateFields():boolean{

    if(this.rpe){
      if(this.validateFieldsRPESelo()){
        if(this.flightSeloRPE.typeFlight === "" || this.flightSeloRPE.typeFlight === null
          || this.flightSeloRPE.typeFlight == undefined){
            this.openDialog(
              properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
              properties.MODAL_CONTENT_MOVEMENT_FLIGHT_INVALID);
            return false;
          }
          return true;
      }
    }

    if(this.selo){
      if(this.validateFieldsRPESelo){
        return true;
      }
    }

    return false;
  }

  private validateFieldsRPESelo():boolean{

    /*if(this.flightSeloRPE.numFlight === 0 || this.flightSeloRPE.numFlight === null
    || this.flightSeloRPE.numFlight == undefined){

      this.openDialog(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
        properties.MODAL_CONTENT_NUMBER_FLIGHT_INVALID);

      return false;
    }*/ // removed for upgrade to mass delete

    if(this.flightSeloRPE.airport === "" || this.flightSeloRPE.airport === null
    || this.flightSeloRPE.airport == undefined){

      this.openDialog(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
        properties. MODAL_CONTENT_NOT_VALID_AIRPORT_SELECT);

      return false;
    }
    if(this.flightSeloRPE.company === "" || this.flightSeloRPE.company === null
    || this.flightSeloRPE.company == undefined){

      this.openDialog(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
        properties.MODAL_CONTENT_NOT_VALID_COMPANY);

      return false;
    }
    if(this.dateIniFlight === null|| this.dateIniFlight == undefined){

      this.openDialog(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
        properties. MODAL_CONTENT_DATE_FLIGHT_INVALID);

      return false;
    }
    if(this.dateEndFlight === null|| this.dateEndFlight == undefined){

      this.openDialog(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
        properties. MODAL_CONTENT_DATE_FLIGHT_INVALID);

      return false;
    }


    return true

  }

  private openDialog(exTitleParam: string, exMessageParam: string): void {
    this.openDialogUtils.openDialog(exTitleParam,exMessageParam)
  }

  public clearFields():void{

    this.rpe = false;
    this.selo = false;
    this.flightSeloRPE.airport = undefined;
    this.flightSeloRPE.numFlight = undefined;
    this.flightSeloRPE.typeFlight = undefined;
    this.dateIniFlight = null;
    this.dateEndFlight = null;
    this.disableAll();

  }



}
