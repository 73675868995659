import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardColumnRQ } from './model/DashboardColumnRQ';
import { DashboardColumnRS } from './model/DashboardColumnRS';
import { endpoints } from '../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppDashboardColumnRpeGeneratedService {

  constructor(private http: HttpClient) { }

  private convertDataIsoDashCol(request: DashboardColumnRQ): void {
    let arrayStringDashCol: string[] = [];
    arrayStringDashCol = request.dtVoo.split('/');
    request.dtVoo = arrayStringDashCol[2]
      + '-' + arrayStringDashCol[1]
      + '-' + arrayStringDashCol[0]
      + 'T00:00:00';
  }

  getDataByDate(requestDashCol: DashboardColumnRQ): Observable<DashboardColumnRS[]> {

    if (!requestDashCol.dtVoo.includes('T')) {
      this.convertDataIsoDashCol(requestDashCol);
    }
    if (requestDashCol.sistemaOrigem === 'Decolagem') {
      requestDashCol.sistemaOrigem = 'D';
    } else {
      requestDashCol.sistemaOrigem = 'A';
    }

    const params1 = new HttpParams().set('flightBeginDate', requestDashCol.dtVoo);
    const params2 = new HttpParams().set('type', requestDashCol.sistemaOrigem);

    return this.http.get<DashboardColumnRS[]>(endpoints.REST_GET_RPE_GENERATED_COLUMN + '?' + params1 + '&' + params2, httpOptions);
  }

}
