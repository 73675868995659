import { Component, OnInit, Input, Inject } from '@angular/core';
import { RpeInfo } from '../app-flight-report-summary/models/RpeInfo';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { SummaryRpeService } from '../app-flight-report-summary/services/summary-rpe.service';
import { switchMap } from 'rxjs/operators';
import { RpeTransfer } from '../app-flight-report-summary/models/RpeTransfer';
import { ComboBoxAeroporto } from '../../../app/app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-rpe-resume-file',
  templateUrl: './rpe-resume-file.component.html',
  styleUrls: ['./rpe-resume-file.component.css']
})
export class RpeResumeFileComponent implements OnInit {

  public rpe: RpeInfo;

  constructor(
    private summaryService: SummaryRpeService,
    private activateRouter: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public rpeTransfer: RpeTransfer,
    ) {

   }

  ngOnInit() {
    this.rpe = this.rpeTransfer.rpeInfo;
  }

  public converter(...value: Array<string>): number | string{

    let result: number = 0;
    value.forEach(element => {
      result += parseInt(element);
    });

    if(isNaN(result)){
      return '';
    }

    return result;
  }

  public formatPrefix(prefix: string): string{
    return prefix.substr(0,2) + '-' + prefix.substr(2,3)
  }

  public getAirportIataByIcao(icao: string): string{
    let iata: string = '';
    this.rpeTransfer.aeroportos.forEach(air => {
      if(air.codeICAO == icao){
        iata = air.codeIATA;
      }
    });
    return iata;
  }

  public getDescriptionByIcao(icao: string): string{
    let desc: string = '';
    this.rpeTransfer.aeroportos.forEach(air => {
      if(air.codeICAO == icao){
        desc = air.descriptionName;
      }
    });
    return desc;
  }

  public getIataByIcaoAirlineComp(icao: string): string{
    let iata: string = '';
    this.rpeTransfer.empresas.forEach(comp => {
      if(comp.icaoCode == icao){
        iata = comp.iataCode;
      }
    });
    return iata;
  }

  public getOtherIsents(): string {
    return parseInt(this.rpe.paxConvidadosGovernoBrasileiroDecolagem) +
     // parseInt(this.rpe.paxRetornoAlternadoDecolagem) +
      parseInt(this.rpe.tripulanteExtraDecolagem) +
      parseInt(this.rpe.fiscalAnacDecolagem) +
      parseInt(this.rpe.tripulanteExtraEscala) + ""
      ;
  }
}
