import { SearchInformationByPeriodComponent } from '../app-search-information-by-period/app-search-information-by-period.component';
import { Component, OnInit } from '@angular/core';
import { NgbDatepickerConfig, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { Periodo } from '../app-search-information-by-period/Periodo';
import { MatDialog } from '@angular/material/dialog';
import { properties } from '../../../environments/properties';

@Component({
  selector: 'app-home-dashboard-list',
  templateUrl: './app-home-dashboard-list.component.html',
  styleUrls: ['./app-home-dashboard-list.component.css']
})

export class HomeListDashboardComponent extends SearchInformationByPeriodComponent implements OnInit {

  dataAtual: Date;
  titleCharRpeContestados = properties.HTML_CONST_RPE_GENERATED_X_ADM;
  titleCharRPENaoGerado = properties.HTML_CONST_RPE_NOT_GENERATED;
  titleCharSeloGeradoXNaoGerado = properties.HTML_CONST_SELO_NOT_GENERATED_X_GENERATED;
  titleCharRPEWorkaround = properties.HTML_CONST_RPE_GENERATED_WORKAROUND;
  titleCharWorkaround = properties.HTML_CONST_WORKAROUND;
  tittleCharRpeGeneratedxTotalFlights = properties.HTML_CONST_RPES_GENERATEDS_X_FLIGHTS;

  public eventsSubject: Subject<Periodo> = new Subject<Periodo>();

  constructor(config: NgbDatepickerConfig, calendar: NgbCalendar, dialog: MatDialog) {
    super(config, calendar, dialog);
    this.dataAtual = new Date();
  }

  ngOnInit() {
  }

  receberPeriodo(periodo: Periodo) {
    this.eventsSubject.next(periodo);
  }

}
