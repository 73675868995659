export class RpeEditResponse {
    public id: number;
    public prefix: string;
    public typeAircraft: string;
    public company: string;
    public dateFlightArrival: any;
    public dateFlightDeparture: any;
    public hrFlightDeparture: string;
    public hrFlightArrival: string;
    public numFlightArrival: number;
    public numFlightDeparture: number
    public iataAirportDest: string;
    public iataAirportOrigin: string;
    public dtContestation: any;
    public idContestation: number;
}
