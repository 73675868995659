<img src="{{img}}">
<h2 mat-dialog-title style="margin-left: 50px">{{title}}</h2>

<mat-dialog-content>
  <label>{{message}}</label>
</mat-dialog-content>

<mat-dialog-actions style="display: flex !important; justify-content: center !important;">
  
  <button mat-raised-button mat-dialog-close class="btnEmpty" [mat-dialog-close]="false">
    <mat-icon style="margin-left: 1%;font-size: x-large;">clear</mat-icon>
    Cancelar
  </button>

  <button mat-raised-button mat-dialog-close class="btnClean" [mat-dialog-close]="true">
    <mat-icon style="margin-left: 1%;font-size: x-large;" >save_alt</mat-icon>
    Excluir
  </button>

</mat-dialog-actions>
