import { Component, OnInit, ViewChild } from '@angular/core';
import { ListarDadosVooService } from './app-data-flight-list.service';
import { Voo } from './model/voo';
import { VooRequisicao } from './model/vooRequisicao';
import { Protocolo } from './model/protocolo';
import { ProtocoloChamado } from '../app-enum/protocolo-chamado';
import { ExcelService } from '../../app-conciliation/service/excel.service';
import { ModalChamadoComponent } from '../app-dialog-ticket/app-dialog-ticket.component';
import { FiltrarSelecionadosService } from './app-filter-selected.service';
import { Router, ActivatedRoute } from '@angular/router';
import { properties } from '../../../environments/properties';
import { MatSort, Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { TicketControl } from '../app-dialog-ticket/model/embedded/ticket-control';
import { Ticket } from './model/ticket';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-data-flight-list',
  templateUrl: './app-data-flight-list.component.html',
  styleUrls: ['./app-data-flight-list.component.css']
})
export class ListarDadosVooComponent implements OnInit {

  public vooFt: VooRequisicao;
  public isLoadingResultsDtFig: boolean;
  public paginaAtualDtFig = 1;
  public displayedColumnsDtFig: string[] = [
    'checked',
    'iataComp',
    'numeroVoo',
    'dataVoo',
    'dataGeracao',
    'tipoErro',
    'tipoOrigem',
    'dataEnvioRpe',
    'dataFimAjuste',
    'ticket'
  ];

  public dataSourceImutavel = new MatTableDataSource<Voo>();
  public dataSourceMutavel = new MatTableDataSource<Voo>();

  public mensagemResposta: string;
  modalStatus = false;
  private eventsSubscriptionFliT: any;
  public dataAtualFliT: Date = new Date();
  public allSelectedFliT: boolean = false;
  public indeterminateFliT: boolean = false;
  public isDisabledFliT: boolean = true;
  public filterValueFliT = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public sortedData: Voo[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public listarDadosVooService: ListarDadosVooService,
    private excelService: ExcelService,
    public dialog: MatDialog,
    public filtrarSelecionadosService: FiltrarSelecionadosService,
    private datePipe: DatePipe
  ) {
    this.vooFt = new VooRequisicao();
  }

  ngOnInit() {
    this.eventsSubscriptionFliT = this.route.params.subscribe(params => {
      this.vooFt.dtInicio = params.date;
      this.vooFt.dtFim = params.date;
      this.vooFt.sistOrigin = params.sys;
      // console.log('[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 3 ] ' + this.voo.dtInicio);
      // console.log('[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 3 ] ' +this.voo.sistOrigin);
      this.tratarDataFim();
      this.obterLista();
    });
  }

  applyFilter(filterValue: string) {
    this.filterValueFliT = filterValue.trim().toLowerCase();
    // console.log('[[ WPENHA ]] applyFilter: {{ }} ' + this.filterValue);
    this.dataSourceImutavel.filter = filterValue.trim().toLowerCase();
    this.dataSourceMutavel.filter = filterValue.trim().toLowerCase();

  }

  private obterLista(): void {
    this.listarDadosVooService.getDadosVoo(this.vooFt).subscribe(
      dataSource => {
        this.sortedData = dataSource;
      },
      err => {
        const protocolo: Protocolo = err.error;
        this.validarStatusBuscaVoo(protocolo);
      },
      () => {
        this.sortedData.sort(function(a, b) {
          return a.originAirportCodeRPE < b.originAirportCodeRPE
            ? -1 : a.originAirportCodeRPE > b.originAirportCodeRPE ? 1 : 0;
        });

        this.dataSourceImutavel = new MatTableDataSource(this.sortedData);
        this.dataSourceMutavel = new MatTableDataSource(this.sortedData);
        this.dataSourceMutavel.paginator = this.paginator;
        this.dataSourceImutavel.paginator = this.paginator;
      }
    );
  }

  validarStatusBuscaVoo(protocolo: Protocolo): void {
    switch (protocolo.status) {
      case ProtocoloChamado.REQUEST_INVALIDO:
        this.mensagemResposta =
          properties.MODAL_CHAMADO_MENSAGEM_REQUEST_INVALIDO;
        break;
      default:
        this.mensagemResposta = protocolo.descricao;
        break;
    }
  }

  toggleSelectAll(event) {
    const dataSource = this.dataSourceMutavel.data;
    this.allSelectedFliT = event.checked;
    dataSource.forEach(voo => {
      voo.checked = event.checked;
      if (voo.checked) {
        this.dataSourceMutavel = new MatTableDataSource(dataSource);
        this.dataSourceMutavel.paginator = this.paginator;
        this.isDisabledFliT = false;
      } else if (dataSource.filter(voo => voo.checked).length == 0) {
        this.dataSourceMutavel = this.dataSourceImutavel;
        this.dataSourceMutavel.paginator = this.paginator;
        this.isDisabledFliT = true;
      }
    });
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(
      this.dataSourceMutavel.data,
      'LISTA_ERRO_' +
        this.vooFt.sistOrigin.toUpperCase() +
        '_' +
        this.datePipe.transform(this.dataAtualFliT, 'dd_MM_yyyy')
    );
  }

  openDialog(): void {
    const dataSource = this.dataSourceMutavel.data;
    if (dataSource.filter(voo => voo.checked).length != 0) {
      this.dialog
        .open(ModalChamadoComponent, {
          data: this.filtrarSelecionadosService.retornarVooAptosParaCadastroChamado(
            this.dataSourceMutavel.data
          )
        })
        .afterClosed()
        .subscribe(response => {
          this.obterLista();
          this.allSelectedFliT = false;
          this.filterValueFliT = '';
          $('#mat-input-0').val('');
        });
    }
  }

  private tratarDataFim(): void {
    let dt: string = this.vooFt.dtFim;
    let dtA: string[] = [];
    dtA = dt.split('T');
    this.vooFt.dtFim = dtA[0] + 'T23:59:59';
  }

  filtrarNumeroTicket(voo: Voo) {
    // console.log('[[ WPENHA ]] filtrarNumeroTicket: {{ }} ' + this.filterValue);
    if(voo.ticketControl == null){
      voo.ticketControl = new Ticket();
    }
    let dataSource = this.filtrarSelecionadosService.filtrarNumeroTicket(
      this.dataSourceImutavel.data,
      voo.ticketControl.numTck,
      this.filterValueFliT
    );

    if (voo.checked) {
      this.dataSourceMutavel = new MatTableDataSource(dataSource);
      this.dataSourceMutavel.paginator = this.paginator;
      this.isDisabledFliT = false;
    } else if (dataSource.filter(voo => voo.checked).length == 0) {
      this.dataSourceMutavel = this.dataSourceImutavel;
      this.dataSourceMutavel.paginator = this.paginator;
      this.isDisabledFliT = true;
    }

  }

  private getFormatDateToString(dt: string): string {
    if (dt === null || dt === undefined) {
      return '';
    }
    let dtarr: string[];
    let formatDt: string;
    dtarr = dt.split('-');
    formatDt = dtarr[2] + '-' + dtarr[1] + '-' + dtarr[0];
    return formatDt;
  }

  private getFormatDateToStringIso(dtdtFt: string): string {
    if (dtdtFt === null || dtdtFt === undefined) {
      return '';
    }
    let dtarr: string[];
    let dtar: string[];
    let formatDt: string;
    dtarr = dtdtFt.split('T');
    dtar = dtarr[0].split('-');
    formatDt = dtar[2] + '-' + dtar[1] + '-' + dtar[0];
    return formatDt;
  }

  private formatDateHour(dtflgLis: String): string {
    if (dtflgLis === null || dtflgLis === undefined) {
      return '';
    }
    let dtarr: string[];
    let dtarH: string[];
    dtarr = dtflgLis.split('T');
    // +' '+ dtarr[1];
    dtarH = dtflgLis.split('T');
    dtarr = dtarr[0].split('-');
    return dtarr[2] + '-' + dtarr[1] + '-' + dtarr[0] + ' ' + dtarH[1];
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
