import { Component, OnInit } from '@angular/core';
import { Administrator } from './model/Administrator';
import { AppModalAdministratorComponent } from './app-modal-administrator/app-modal-administrator.component';
import { AppModalAdministratorService } from './app-modal-administrator.service';
import { properties } from '../../../environments/properties';
import { DialogCustomComponent } from '../../app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { Airport } from './model/Airport';
import { TransientObject } from './model/TransientObject';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-administrator-airport',
  templateUrl: './app-administrator-airport.component.html',
  styleUrls: ['./app-administrator-airport.component.css']
})
export class AppAdminsitratorAirportComponent implements OnInit {

  public displayedColumns: string[] = [
    'identification',
    'name',
    'editOrDelete'
  ];

  public listAirportConsult: Airport[] = new Array();
  public listAirportByadministrator: Airport[] = new Array();

  public dataSourceAdministratorAirports: MatTableDataSource<Administrator>;

  constructor(
    private dialog: MatDialog,
    private appModalAdministratorService:AppModalAdministratorService) { }

  ngOnInit() {
    // this.getAllAirport();
    // this.getAirportAdministrator();
    this.loadAdministrators();
  }

  private loadAdministrators(): void {
    this.appModalAdministratorService.getListAdministratorAirport().subscribe(
     dataSource => {
      console.log('list of administrators airports , {} ', dataSource);
      this.dataSourceAdministratorAirports = new MatTableDataSource(dataSource);
      // console.log('list of administrators airports , {} ', dataSource);
    }, err => {
      // console.log('error in list of administrators airports: {}', err)
    });
  }

  public openCreatedAdministratorAirport(transitObject: TransientObject): void{

    if(transitObject == null || transitObject == undefined){
      transitObject = new TransientObject();
    }

    transitObject.allAirportList = this.listAirportConsult;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = transitObject;
    dialogConfig.width = '80%';
    dialogConfig.height = '90%';


    console.log(dialogConfig.data, "<---dialogConfig.Data")
    this
      .dialog
      .open(AppModalAdministratorComponent, dialogConfig)
      .afterClosed().subscribe(responseDialog => {
        this.getAllAirport();
        this.loadAdministrators();
      });

  }

  public deleteAdministrator(adm: Administrator): void{
    this.appModalAdministratorService.deleteAdministrator(adm).subscribe(
      dataSource => {
        this
        .dialog
        .open(DialogCustomComponent, {width: '450px', data: { title: properties.MODAL_TITLE_SUCESS, message: properties.MODAL_BODY_SUCESS_OPERATION }})
        .afterClosed().subscribe(responseDialog => {
          this.loadAdministrators();
        });
    }, err => {
      this
      .dialog
      .open(DialogCustomComponent, {width: '450px', data: { title: properties.MODAL_TITLE_PERIOD_SELECT_ATENCION, message: properties.MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE }})
      .afterClosed().subscribe(responseDialog => {
        this.loadAdministrators();
      });
    })
  }
  // Euder 1
  public editAdministrator(adm: Administrator): void{
    let transientObject: TransientObject = new TransientObject;
    transientObject.administrator = adm;
    console.log('transientObject edit Administrator: {}', transientObject);
    this.openCreatedAdministratorAirport(transientObject);
  }

  private getAllAirport(): void{
    this.appModalAdministratorService.getListAirport().subscribe(
      dataSource => {
        this.listAirportConsult = dataSource;
        // console.log('lista de aeroportos, {} ', this.listAirportConsult);
      }, err => {
        // console.log('error in consult list airport ....')
    });
  }

}
