import { HistFileRpeEdit } from "./hist-file-rpe-edit";

    export class EditFileRpe{

    public id: number;
    public prefix: string;
    public typeAircraft: string;
    public company: string;
    public dateFlightArrival: any;
    public dateFlightDeparture: any;
    public hrFlightDeparture: string;
    public hrFlightArrival: string;
    public numFlightArrival: number;
    public numFlightDeparture: number
    public iataAirportDest: string;
    public iataAirportOrigin: string;
    public dtContestation: any;
    public idContestation: number;

    // Campo 4 RPE
    public  icaoCompany: string;
    // Campo 59 RPE
    public  icaoAirportFlightPrevious: string;
    // Campo 60 RPE
    public  icaoAirportDestiny: string;
    // Campo 63 RPE
    public  cdTypeFlightDeparture: string;
    // Campo 62 RPE
    public  cdTypeFlightArrival: string;
    // Campo 72 RPE
    public  cdTypeLineFlightDeparture: string;
    // Campo 71 RPE
    public  cdTypeLineFlightArrival: string;

    //Historico do arquivo RPE
    public histEditFileRpe: HistFileRpeEdit;
}
