import { RegisterConfirmation } from './register-confirmation';
import { TabsToChoose } from './tabs-to-choose';

export class TabsAndRegisters {

    tabsToChooseCollection: TabsToChoose[];

    registerConfirmationCollection: RegisterConfirmation[];

}
