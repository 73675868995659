import { Component, OnInit } from '@angular/core';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {Router, ActivatedRoute} from '@angular/router';
import { DashboardPizzaRQ } from './model/DashboardPizzaRQ';
import { DashboardPizzaRS } from './model/DashboardPizzaRS';
import { Protocolo } from '../app-enum/Protocolo';
import { AppDashboardPizzaWorkaroundService } from './app-dashboard-pizza-workaround.service';

@Component({
  selector: 'app-dashboard-pizza-workaround',
  templateUrl: './app-dashboard-pizza-workaround.component.html',
  styleUrls: ['./app-dashboard-pizza-workaround.component.css']
})
export class AppDashboardPizzaWorkaroundComponent implements OnInit {

  public indexSelectColunWor;
  private eventsSubscriptionWork: any;
  public requestWord: DashboardPizzaRQ = new DashboardPizzaRQ();

  // Pie
  public pieChartOptionsWork: any = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  public pieChartLabelsWork: any[] = [];
  public pieChartDataWork: number[] = [];
  public pieChartTypeWork: any = 'pie';
  public pieChartLegendWork = true;
  public pieChartPluginsWork = [];
  public pieChartColorsWork= [];

  private setPieChartLabelsWork(rs: DashboardPizzaRS[]): void {
    this.pieChartLabelsWork = [];
    for (const value of rs) {
      this.pieChartLabelsWork.push(value.sistemaOrigem);
    }
  }

  private setPieChartDataWork(rs: DashboardPizzaRS[]): void {
    this.pieChartDataWork = [];
    for (const value of rs) {
      this.pieChartDataWork.push(value.qntWa);
    }
  }

  private setPieChartTypeWork(): void {
    this.pieChartTypeWork = 'pie';
  }

  private setPieChartLagendWork(): void {
    this.pieChartLegendWork = true;
  }

  private setPieChartPluginsWork(): void {
    this.pieChartPluginsWork = [pluginDataLabels];
  }

  private setPieChartColorsWork(): void {
    this.pieChartColorsWork = [
      {
        backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
      },
    ];
  }

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private dashboardPizzaService: AppDashboardPizzaWorkaroundService) {}

  ngOnInit() {
    this.eventsSubscriptionWork = this.route.params.subscribe(params => {
      this.requestWord.dtVoo = params['date'];
      // console.log( '[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 2 ] ' + this.request.dtVoo);
      this.getDataDashboardLine(this.requestWord.dtVoo);
    });
  }

  private getDataDashboardLine(dtSearch: string): void {

    let rs: DashboardPizzaRS[];
    this.dashboardPizzaService.getDataByDate(this.requestWord).subscribe(
      data => {
        rs = data;
        // console.log(rs);
      },
      err => {
        const protocolo: Protocolo = err.error;
        // console.log(protocolo);
      },
      () => {

        this.setPieChartLabelsWork(rs);
        this.setPieChartDataWork(rs);
        this.setPieChartTypeWork();
        this.setPieChartLagendWork();
        this.setPieChartPluginsWork();
        this.setPieChartColorsWork();
      }
    );
  }

  public chartClicked(e: any): void {
    // console.log('[ COMPONENT ]' + this.route.component.name);
    if (e.active.length > 0) {
      this.indexSelectColunWor = e.active[0]._index;
      // console.log('[ INFO ] ' +  ' [ SELECT LABEL ] ' + this.pieChartLabels[this.indexSelectColun]);
      this.router.navigate(['dashboardTreWorkaround', this.requestWord.dtVoo, this.pieChartLabelsWork[this.indexSelectColunWor]]);
    }
  }

}
