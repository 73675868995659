import { Injectable } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  delimiter = '-';

  ngbDateToObjectDate(ngbDate: NgbDate): Date {
    // console.log(ngbDate);
    const date: Date = new Date();
    date.setDate(ngbDate.day);
    date.setMonth(ngbDate.month - 1);
    date.setFullYear(ngbDate.year);
    return date;
  }

  ngbDateStructToObjectDate(ngbDate: NgbDateStruct): Date {
    // console.log(ngbDate);
    const date: Date = new Date();
    date.setDate(ngbDate.day);
    date.setMonth(ngbDate.month - 1);
    date.setFullYear(ngbDate.year);
    return date;
  }

  ngbDateToStringWithTime(ngbDate: NgbDateStruct): string {
    console.log("A seguir é o parâmetro ngmDate, objeto NgbDateStruct")
    console.log(ngbDate);
    const date: Date = new Date();
    date.setFullYear(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setHours(0);
    return date.toISOString().split('.')[0];
  }

  ngbDateToString(ngbDate: NgbDate): string {

    if(ngbDate == null || ngbDate == undefined){
      return '';
    }
    // console.log('ngbdate: {}', ngbDate);
    let date: Date = new Date();
    date.setFullYear(ngbDate.year);
    date.setMonth(ngbDate.month - 1);
    date.setDate(ngbDate.day);
    return date.toISOString().split('T')[0];
  }

  ngbDateToStringMonth(ngbDate: NgbDate): string {

    if(ngbDate == null || ngbDate == undefined){
      return '';
    }

    // console.log('ngbdate: {}', ngbDate);
    let day = ngbDate.day < 10 ? '0'+ngbDate.day : ngbDate.day;
    let month = ngbDate.month < 10 ? '0'+ngbDate.month : ngbDate.month;
    return ngbDate.year + '-' + month + '-' + day;
  }

  ngbDateTimeToString(date: string): string {

    if(date == null || date == undefined){
      return '';
    }

    let parts: string[] = date.split('T');
    let partsDate: string[] = parts[0].split('-');
    return  partsDate[2]+'/'+
              partsDate[1]+'/'+
                partsDate[0] +
                  ' ' + parts[1];
  }

  ngbDateTimeToStringNoSeconds(date: string): string {

    if(date == null || date == undefined){
      return '';
    }

    let parts: string[] = date.split('T');
    let hourParts: string[] = parts[1].split(':');

    let partsDate: string[] = parts[0].split('-');
    return  partsDate[2]+'/'+
              partsDate[1]+'/'+
                partsDate[0] +
                  ' ' + hourParts[0]+':'+hourParts[1];
  }


  ngbDateStringToString(date: string): string {

    if(date == null || date == undefined){
      return '';
    }

    let parts: string[] = date.split('T');
    let partsDate: string[] = parts[0].split('-');
    return  partsDate[2]+'/'+
              partsDate[1]+'/'+
                partsDate[0];
  }

  dateStringNoHour(date: string): string {

    if(date == null || date == undefined){
      return '';
    }

    let parts: string[] = date.split('T');
    return  parts[0];
  }


  stringDateToString(date: string): string {
    if(date == null || date == undefined){
      return '';
    }

    return moment(date).format('DD/MM/YYYY');
  }

  stringToDate(dateString: string): Date {
    const date: Date = new Date();
    const dateArray: string[] = dateString.split('-');
    date.setFullYear(+dateArray[0], +dateArray[1] - 1, +dateArray[2]);
    return date;
  }

}
