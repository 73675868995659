import { FlightGenerated } from './model/FlightGenerated';
import { Injectable } from '@angular/core';
import { DateService } from '../../app-conciliation/conciliation/service/date.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '../../../environments/endpoint';
import { FlightProcess } from './model/FlightProcess';
import { FormSearchGeneratedFile } from './model/FormSearchGeneratedFile';
import * as moment from 'moment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppGeneratedManualFilesService {


  constructor(private http: HttpClient,private dateService: DateService) { }

  /**
   * [[ BUSCA DE VOOS QUE NAO TIVERAO SEUS ARQUIVOS GERADOS RPE && SELO  ]]
   * @param formSearch
   */
  public getFlightNotProcessedAuto(formSearch: FormSearchGeneratedFile, reportType: string): Observable<FlightProcess[]> {

    // console.log('receive request to search flights: {}', formSearch);
    let url = endpoints.REST_GTECLUOD_GET_FLIGHT_NOT_GENERATED;
    url = url.replace('{idflight}',formSearch.companyName + formSearch.airportName + (formSearch.numberFlightDeparture == undefined ? formSearch.numberFlightArrival : formSearch.numberFlightDeparture));
    url = url.replace('{airlineCode}',formSearch.companyName);
    url = url.replace('{airport}',formSearch.airportName);
    //url = url.replace('{dtFlightEnd}',this.dateService.ngbDateToStringMonth(formSearch.endDate == undefined ? formSearch.startDate : formSearch.endDate) + 'T23:59:59');
    url = url.replace('{dtFlightEnd}',moment(formSearch.endDate == undefined ? formSearch.startDate : formSearch.endDate).format('YYYY-MM-DD') + 'T23:59:59');
    url = url.replace('{dtFlightStart}',moment(formSearch.startDate).format('YYYY-MM-DD') + 'T00:00:00');
    //url = url.replace('{dtFlightStart}',this.dateService.ngbDateToStringMonth(formSearch.startDate)+ 'T00:00:00');
    url = url.replace('{typeReport}',reportType);

    if(formSearch.numberFlightDeparture != null && formSearch.numberFlightDeparture != undefined && formSearch.numberFlightDeparture != 0){
      url += "&numFlightDeparture=" + formSearch.numberFlightDeparture
    }

    if(formSearch.numberFlightArrival != null && formSearch.numberFlightArrival != undefined && formSearch.numberFlightArrival != 0){
      url += "&numFlightArrival=" + formSearch.numberFlightArrival
    }

    return this.http.get<FlightProcess[]>(url, httpOptions);
  }

  /**
   * [[ GERACAO DOS ARQUIVOS DE SELO E RPE ]]
   * @param listFlightGenerate
   */
  createdFilesManualy(listFlightGenerate: FlightGenerated[]): Observable<Blob> {
    // console.log('[ receive request to created files STAMP and RPE ] to: {}', listFlightGenerate);
    let url = endpoints.REST_GTECLUOD_CREATED_FILES;
    return this
      .http
      .post(url, listFlightGenerate, {
        responseType: 'blob'
      });
  }

  generateFlight(startDate: string, endDate: string, reportType: string): Observable<FlightProcess[]>{
    // VERIFICAR POIS RPE NÃO RECEBE HORA E SELO RECEBE
    if(reportType == 'rpe'){
      let url = endpoints.ROBOTS_GERACAO_MANUAL + "/" + reportType + "/" + startDate + "/" + endDate;
      return this.http.get<FlightProcess[]>(url, httpOptions);
    }else{
      let url = endpoints.ROBOTS_GERACAO_MANUAL + "/" + reportType + "/" + startDate + "T00:00:00/" + endDate + "T23:59:59";
      return this.http.get<FlightProcess[]>(url, httpOptions);
    }
  }

}
