import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-second-level-dashboard',
  templateUrl: './app-second-level-dashboard.component.html',
  styleUrls: ['./app-second-level-dashboard.component.css']
})
export class SecondLevelDashboardComponent implements OnInit {

  titleModule = 'Origem do Problema';

  constructor() { }

  ngOnInit() {
  }

}
