import { Injectable } from "@angular/core";
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root', // <---- Adiciona isto ao serviço
})

export class SaveBlobResume {

  saveBlobResumeBoarding(response: Blob, nameFile: string, typeFile: string): any {
    const blob = new Blob([response], { type: typeFile });
    const file = new File([blob], nameFile, { type: typeFile });
    FileSaver.saveAs(file);
  }

}
