import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class RpeEditRequest {

    public id: number;
    public prefix: string;
    public typeAircraft: string;
    public dateFlightArrival: any;
    public dateFlightDeparture: any;
    public hrFlightDeparture: string;
    public hrFlightArrival: string;
    public numFlightArrival: number;
    public numFlightDeparture: number
    public dtContestation: any;
    public idContestation: number;

}
