<br>
<h5>
  Clique no quadro abaixo, ou arraste seu arquivo para iniciar a importação:
</h5>

<form #formUpload>
  <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
    <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
  </div>
</form>

<div class="lista" style="margin-top: 100px">

  <div class="titleListExcel">
    <h5>Lista Erros Excel</h5>
  </div>
  <table mat-table [dataSource]="dataSourceExcel" class="mat-elevation-z8" style="width: 100%">

    <ng-container matColumnDef="lineInExcelFile">
      <th mat-header-cell *matHeaderCellDef>Linha Erro</th>
      <td mat-cell *matCellDef="let v">{{ v.data.lineInExcelFile }}</td>
    </ng-container>
    <ng-container matColumnDef="descricao">
      <th mat-header-cell *matHeaderCellDef>Descrição</th>
      <td mat-cell *matCellDef="let v">{{ v.descricao }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" [showFirstLastButtons]="true">
  </mat-paginator>

</div>