import { Component, Inject } from '@angular/core';
import { properties } from '../../../environments/properties';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  message: string;
}

/**
 * @title Dialog Custom
 */
@Component({
  selector: 'app-dialog-custom',
  templateUrl: './app-dialog-custom.component.html',
  styleUrls: ['./app-dialog-custom.component.css']
})
export class DialogCustomComponent {

  private IMG_SUCCESDC: string = "../../assets/success.png";
  private IMG_ERRORDC: string = "../../assets/error.png";
  private IMG_WARNINGDC: string = "../../assets/warning.png";

  title: string;
  message: string;
  img: string;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogCustomComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    this.img = this.validateImage(data.title);
    this.title = data.title;
    this.message = data.message;
  }

  private validateImage(title: string): string {
    if(title == properties.MODAL_TITLE_PERIOD_SELECT_ATENCION){
      return this.IMG_WARNINGDC;
    }else if(title == properties.MODAL_TITLE_SUCESS){
      return this.IMG_SUCCESDC;
    }
    return this.IMG_ERRORDC;
  }

}
