<div>
  <br>
  <div class="card text-center">
    <div class="card-header" style="background-color: #1b0088;">
      <h5 style="color: #ffffff; font-size: 1.0rem;">Controle de Acesso Usuários</h5>
    </div>
    <div class="card-body">   
      
      <div>
        <button mat-raised-button 
          (click)="createUser()" 
          matTooltip="Cadastrar um novo usuário"
          class="btn btn-primary btnSave" 
          style="background-color: #1b0088;border-color:#1b0088; color: #FFF; font-size: 1.0rem;float: right;">
          <mat-icon>add</mat-icon>
          Novo
        </button>
      </div>
      <br><br>

      <table mat-table [dataSource]="dataSourceUserProfile" class="mat-elevation-z8" style="width: 100%;">
      
        <ng-container matColumnDef="identification">
          <th mat-header-cell *matHeaderCellDef>Identificação</th>
          <td mat-cell *matCellDef="let element">{{element.id}}</td>
        </ng-container>
  
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element">{{element.email}}</td>
        </ng-container>

        <ng-container matColumnDef="perfil">
          <th mat-header-cell *matHeaderCellDef>Perfil</th>
          <td mat-cell *matCellDef="let element">{{element.profile.type}}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Ativo/Inativo</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle  [checked]="element.status === ATIVO"  class="example-margin"  
             color="primary" (change)="changed(element)">  {{element.status === ATIVO  ? 'Ativo' : 'Inativo'}}
           </mat-slide-toggle>
          </td>
        </ng-container>
     
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef>Editar</th>
          <td mat-cell *matCellDef="let element">                          
              <button mat-raised-button  
                style="margin-right: 1%; background-color: #1b0088;border-color:#1b0088; color: #FFF;"
                type="button" 
                matTooltip="Editar este usuário"
                class="btn btn-primary" 
                (click)="editUser(element)"
                [disabled]="controlSave">
                <mat-icon style="font-size: x-large;">edit</mat-icon>
              </button>              
          </td>
        </ng-container>  

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15]" [showFirstLastButtons]="true">
      </mat-paginator>
    </div>

  </div>
</div>