<br><br>
<div>
  <div>
    <div style="display: block">
      <canvas baseChart
        [datasets]="barChartDataStam"
        [labels]="barChartLabelsStam"
        [options]="barChartOptionsStam"
        [plugins]="barChartPluginsStam"
        [legend]="barChartLegendStam">
      </canvas>
      <!-- <canvas baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType">
      </canvas> -->
    </div>
  </div>
</div>
