import { Component, OnInit, Inject } from '@angular/core';
import { properties } from '../../../../environments/properties';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  message: string;
}

@Component({
  selector: 'app-app-modal-deleted',
  templateUrl: './app-modal-deleted.component.html',
  styleUrls: ['./app-modal-deleted.component.css']
})
export class AppModalDeletedComponent {

  private IMG_SUCCESDL: string = "../../assets/success.png";
  private IMG_ERRORDL: string = "../../assets/error.png";
  private IMG_WARNINGDL: string = "../../assets/warning.png";

  title: string;
  message: string;
  img: string;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AppModalDeletedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    this.img = this.validateImage(data.title);
    this.title = data.title;
    this.message = data.message;
  }

  private validateImage(title: string): string {
    if(title == properties.MODAL_TITLE_PERIOD_SELECT_ATENCION){
      return this.IMG_WARNINGDL;
    }else if(title == properties.MODAL_TITLE_SUCESS){
      return this.IMG_SUCCESDL;
    }
    return this.IMG_ERRORDL;
  }

}
