<br>

<div class="card text-center container ">
  <div class="card-header row" style="background-color: #1b0088;">
    <h5 style="color: #ffffff;">Manutenção de Parametros</h5>
  </div>
  <div class="card-body  row">

    <div *ngIf="mensagemResposta != undefined && status == 1" class="alert alert-info" role="alert">
      {{mensagemResposta}}
    </div>
    <div *ngIf="mensagemResposta != undefined && status == -1" class="alert alert-danger" role="alert">
      {{mensagemResposta}}
    </div>
    <div *ngIf="mensagemResposta != undefined && status == 0" class="alert alert-primary" role="alert">
      {{mensagemResposta}}
    </div>

    <div class="form-row row">
      <div class="col-6">
        <label for="value">SLA de atendimento (em dias):</label>
      </div>
      <div class="col-2 campos">
        <input type="text" style="text-align: center;" (keypress)="keyPress($event)" class="form-control" name="value"
          id="value" [(ngModel)]="parametersDias.valueParameter" #value="ngModel">
      </div>
    </div>
    <br>
    &nbsp;
    <div class="form-row row">
      <div class="col-6">
        <label for="value">Valor da multa ANAC (em reais):</label>
      </div>
      <div class="col-2 campos">
        <!-- <input currencyMask type="text" class="form-control" name="value" id="value" [(ngModel)]="parametersMulta.valueParameter" #value="ngModel" [options]="{thousands: '.', decimal: ','}"> -->
        <input currencyMask type="text" class="form-control" name="value" id="value"
          [(ngModel)]="parametersMulta.valueParameter" #value="ngModel">
      </div>
    </div>
    <br>
    <div>
      <button class="btn btn-primary btnSave" mat-raised-button type="submit"
        (click)="alterarParametros()">Salvar</button>
    </div>

  </div>
</div>
