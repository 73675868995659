<div class="indicadores">
  <label class='totalizador-nao-gerado'>{{totalizadorNaoGerado.quantidade}}</label><br>
  <label class='label-nao-gerado'> Não Gerados </label><br>
  <img src="../../assets/process.png" alt="">
</div>

<div class="indicadores">
  <label class='totalizador-work-around'>{{totalizadorWorkAround.quantidade}}</label><br>
  <label class='label-work-around'> WorkAround </label><br>
  <img src="../../assets/process.png" alt="">
</div>

<div class="indicadores">
  <label class='totalizador-gerado'>{{totalizadorGerado.quantidade}}</label><br>
  <label class='label-gerado'> RPE's Gerados </label><br>
  <img src="../../assets/process.png" alt="">
</div>  

