import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '../../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AnacBagagesService {

  constructor(private http: HttpClient) { }

  getDataReportAnacBagage(airport: string, month: number, year: number): Observable<Blob> {

    if(airport == null || airport == undefined){
      airport = "";
    }
    // console.log('request to report: ', airport, month, year)
    const params = new HttpParams({
      fromObject: {
        airport: airport,
        month: month.toString(),
        year: year.toString()
      }
    });

    return this
      .http
      .get(endpoints.REST_GTECLOUD_GET_REPORT_ANAC_BAGAGE, {
        params,
        responseType: 'blob'
      });
  }

}
