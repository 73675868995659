import { Injectable } from '@angular/core';
import { Airport } from '../model/airport';
import { Observable } from 'rxjs';
import { ComboBoxService } from '../../../app-datahealth/app-search-information-by-date-and-rpe/combo-box.service';
import { ComboBoxAeroporto } from '../../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';

@Injectable({
  providedIn: 'root'
})
export class AirportService {

  constructor(public comboBoxService: ComboBoxService) { }

  public getAllAirport(): Observable<ComboBoxAeroporto[]> {
    return this.comboBoxService.getComboBoxAeroporto();
  }
}
