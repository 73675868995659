import { OpenDialog } from './../../../utils/openDialog';
import { NgbDate, NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Airport } from '../model/Airport';
import { Administrator } from '../model/Administrator';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppModalAdministratorService } from '../app-modal-administrator.service';
import { DialogCustomComponent } from '../../../../../src/app/app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { properties } from '../../../../../src/environments/properties';
import { TransientObject } from '../model/TransientObject';
import { Observable, of } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AirportAdministrator } from '../model/AirportAdministrator';
import { AppModalDeletedComponent } from '../app-modal-deleted/app-modal-deleted.component';
import { DateService } from '../../../app-conciliation/conciliation/service/date.service';
import { I18n, CustomDatepickerI18n } from '../../../app-datahealth/app-search-information-by-period/CustomDatepickerI18n';
import { NgbDatePTParserFormatter } from '../../../app-datahealth/app-search-information-by-period/NgbDatePTParserFormatter';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { start } from 'repl';

@Component({
  selector: 'app-app-modal-administrator',
  templateUrl: './app-modal-administrator.component.html',
  styleUrls: ['./app-modal-administrator.component.css'],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{ provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter }],
  ],
})
export class AppModalAdministratorComponent implements OnInit {

  public displayedColumns: string[] = [
    'identification',
    'iataCode',
    'decription',
    'startValid',
    'endValid',
    'editOrDelete',
  ];
  informaP:any
  hide = true;
  public dataSourceAirportsAllow: MatTableDataSource<Airport>;
  startNgb: any;
  endNgb: any;
  public administrator:Administrator = new Administrator();
  public airportList: Airport[] = new Array();
  public airportFilterList: Airport[] = new Array();

  public airportRegister: Airport = new Airport();
  public showAirportList: boolean = true;
  public editAirportList: boolean = false;

  public allAirportList: Airport[] = new Array();
  filteredAirportOptions: Observable<Airport[]>;

  formAdministratorCrud = new UntypedFormGroup({
    administratorName: new UntypedFormControl(Validators.required)
  });

  formAdministratorAirportCrud = new UntypedFormGroup({
    airportRegisterName: new UntypedFormControl(Validators.required),
    airportRegisterICAO: new UntypedFormControl(Validators.required),
    airportRegisterIATA: new UntypedFormControl(Validators.required),
    airportRegisterStartValid: new UntypedFormControl(Validators.required),
    airportRegisterEndValid: new UntypedFormControl(Validators.required),
    airportRegisterHostFTP: new UntypedFormControl(Validators.required),
    airportRegisterPathFTP: new UntypedFormControl(Validators.required),
    airportRegisterPathSeloFTP: new UntypedFormControl(Validators.required),
    airportRegisterUserFTP: new UntypedFormControl(Validators.required),
    airportRegisterSecretFTP: new UntypedFormControl(Validators.required),
  })

  airportCtrl =  new UntypedFormControl();
  public controlSave: boolean = false;

  constructor(
    public appModalAdministratorService:AppModalAdministratorService,
    private openDialogUtils: OpenDialog,
    @Inject(MAT_DIALOG_DATA) public transitObject: TransientObject,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<AppModalAdministratorComponent>,
    private dateService: DateService
     ) {
      this.startNgb = new Date(2017,1,1)
      this.endNgb = new Date(2018,1,1)
  }

  ngOnInit() {
    console.log(this.startNgb, "<---startNgb no ngOnInit")
    if(!this.isEmptyAdministrator()){
      this.administrator = this.transitObject.administrator;
      this.getAirportAdministrator(this.administrator.identification);
    }
    console.log("Depois do primeiro if")
    this.allAirportList = this.transitObject.allAirportList;
    this.releaodFilteredAirports();
    console.log("Depois da função releaodFilteredAirports")

  }

  public showFormatDateString(date: string, date2: any): string{
    // console.log('recebendo esta data: {}', date)
    date2 = new Date(date)
    //console.log(date2, "<---datas")
    return this.dateService.stringDateToString(date);
  }

  public showformatDate(date: NgbDate): string{
    // console.log('recebendo esta data: {}', date );
    return moment(date).format('YYYY-MM-DD') + 'T03:00:00';
  }

  private getAirportAdministrator(administratorCode: number): void{
    this.appModalAdministratorService.getListAirportAdministrator(administratorCode).subscribe(
      dataSource => {
        this.airportList = dataSource;
        this.dataSourceAirportsAllow = new MatTableDataSource(this.airportList );
        // console.log('lista de aeroportos administradores, {} ', this.airportList );
      }, err => {
        // console.log('error in consult list airport ....')
    });
  }

  public validAirportListCombo(): Airport[]{

    let tempAirportList: Airport[] = new Array();
    this.airportFilterList = this.allAirportList;

    this.airportFilterList.forEach( airport => {

        airport.id = null;
        let found = tempAirportList.find(function(element) {
          return element.codeIATA == airport.codeIATA;
        });

        if(found== undefined){
          tempAirportList.push(airport);
        }

    })

    return tempAirportList;
  }

  public cleanValueInput(): void{
    this.releaodFilteredAirports();
  }

  private releaodFilteredAirports(): void {
    this.filteredAirportOptions = this.formAdministratorAirportCrud.get('airportRegisterIATA').valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value == undefined ? '' : value.iataCode ),
      map(value => value ? this._filter(value) : this.allAirportList.slice())
    );
  }

  private _filter(iata: string): Airport[] {
    const filterValue = iata.toUpperCase();
    return this.allAirportList.filter(airport => airport.codeIATA.toUpperCase().indexOf(filterValue) === 0);
  }

  private resetAirportRegister(): void {
    this.airportRegister = new Airport();
  }
  // Andrietti
  public editAirport(airportSelect: Airport):void {
    this.resetAirportRegister()
    this.airportRegister = airportSelect;
    this.editAirportList = true;
    this.showAirportList = false;
    this.disableInputsInEditionAirport();
    this.airportRegister.startValid = new Date(this.airportRegister.startValid)
    this.airportRegister.endValid = new Date(this.airportRegister.endValid)
  }

  public converteData(dateInput: string): Date {
    const partesData = dateInput.split('-');
    const ano = parseInt(partesData[0], 10);
    const mes = parseInt(partesData[1], 10) - 1; // Subtraímos 1 porque os meses em JavaScript são indexados a partir de 0
    const dia = parseInt(partesData[2], 10);

    return new Date(ano, mes, dia);
  }

  public disableInputsInEditionAirport(): void{
    this.formAdministratorAirportCrud.get('airportRegisterName').disable();
    this.formAdministratorAirportCrud.get('airportRegisterICAO').disable();
    this.formAdministratorAirportCrud.get('airportRegisterIATA').disable();
  }

  public getDateByNew(): string {
    let date = new Date()
    return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
  }

  public removeAirportInList(airportRemoved: Airport): void{
    let index = this.airportList.indexOf(airportRemoved)
    if ( index > -1) {
      this.airportList.splice(index, 1);
    }

    this.dataSourceAirportsAllow = new MatTableDataSource(this.airportList);
  }

  public deleteAirport (airportSelect: Airport):void {

    this.dialog.open(AppModalDeletedComponent, {
      width: '450px',
      data: { title: properties.MODAL_TITLE_PERIOD_SELECT_ATENCION, message: properties.MODAL_REMOVE_AIRPORT }
    }).afterClosed().subscribe(response => {
      // console.log('modal fechado validando opção selecionada {}', response);
      if (response === true) {
        this.efectiveRemoveAirport(airportSelect);
      }
    });
  }

  public efectiveRemoveAirport(airportSelect: Airport): void{
    this.appModalAdministratorService.removeAirportAdministrator(airportSelect).subscribe(
      dataSource => {
        this.openDialog(
          properties.MODAL_TITLE_SUCESS,
          properties.MODAL_BODY_SUCESS_OPERATION
        );

        this.removeAirportInList(airportSelect);
      }, err => {
        this.openDialog(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE
        );
      }, () => {
        this.showAirportList = true;
      });
  }

  public alterAirport(): void{
    for(let airport of this.airportList){
      if(airport.codeIATA == this.airportRegister.codeIATA){
        this.setAirportRegisterValidDate();
        airport = this.airportRegister;
      }
    }
    this.showAirportList = true;
    this.editAirportList = false;
  }

  public enableForm(): void{
    this.formAdministratorCrud.enable();
    this.controlSave = false;
  }

  public disableForm(): void {
    this.formAdministratorCrud.disable();
    this.controlSave = true;
  }

  /**
   * APRESENTACAO TELA PARA CRIAR NOVO AEROPORTO
   */
  public createdNewAirport(): void{
    this.resetAirportRegister();
    this.enableInputsInCreationAirport();
    this.showAirportList = false;
  }

  public enableInputsInCreationAirport(): void{
    this.formAdministratorAirportCrud.get('airportRegisterName').enable();
    this.formAdministratorAirportCrud.get('airportRegisterICAO').enable();
    this.formAdministratorAirportCrud.get('airportRegisterIATA').enable();
  }

  /**
   * VALIDANDO SE O AEROPORTO JA EXISTE
   */
  public isValidRegisterNewAirport(): boolean{
    let isValid: boolean = true;
    for(let airport of this.airportList){
      if( this.airportRegister.codeIATA == airport.codeIATA ||  this.airportRegister.codeICAO == airport.codeICAO){
        isValid = false;
        break;
      }
    }
    return isValid;
  }

  public setAirportRegisterValidDate(): void{
    if(this.airportRegister.startValid != null && this.airportRegister.startValid != undefined){
      //this.airportRegister.startValid = this.dateService.ngbDateToStringMonth(this.airportRegister.startValidNgb);
      this.airportRegister.startValid = moment(this.airportRegister.startValid).format('YYYY-MM-DD');


    }
    if(this.airportRegister.endValid != null && this.airportRegister.endValid != undefined){
          //this.airportRegister.endValid = this.dateService.ngbDateToStringMonth(this.airportRegister.endValidNgb);
          this.airportRegister.endValid = moment(this.airportRegister.endValid).format('YYYY-MM-DD');
        }
  }

  /**
   * FUNCIONALIDADE PARA SALVAR UM NOVO AEROPORTO
   */
  public saveNewAirport(): void{
    this.setAirportRegisterValidDate();
    this.airportList.push( this.airportRegister);
    this.resetAirportRegister();
    this.dataSourceAirportsAllow = new MatTableDataSource(this.airportList);
    this.showAirportList = true;
  }

  public cancelInclusionAirport():void{
    this.resetAirportRegister();
    this.showAirportList = true;
    this.editAirportList = false;
  }

  public isEmptyAdministratorAirportList(): boolean {
    return this.transitObject.airportList != null && this.transitObject.airportList != undefined && this.transitObject.airportList.length > 0;
  }

  public isEmptyAdministradorFirst(): boolean{
    return this.transitObject.administrator != null && this.transitObject.administrator != undefined
  }

  public isEmptyAdministrator(): boolean {
    if(this.isEmptyAdministradorFirst()
        && this.transitObject.administrator.identification != null
          && this.transitObject.administrator.identification != undefined
            && this.transitObject.administrator.identification != 0){
              return false;
          }

    return true;
  }

  public cleanAdministratorForm(): void {
    this.formAdministratorCrud.reset();
  }

  private openDialog(mdAdTitleParam: string, mdAdMessageParam: string): void {
    this.openDialogUtils.openDialog(mdAdTitleParam,mdAdMessageParam)
  }

  public setAirport(airport: Airport): void {
    // console.log('setando o aeroporto selecionado: {} ', airport );
    this.airportRegister.id = 0;
    this.airportRegister.codeIATA = airport.codeIATA;
    this.airportRegister.codeICAO = airport.codeICAO;
    this.airportRegister.descriptionName = airport.descriptionName;
    this.airportRegister.endValid = null;
    this.airportRegister.startValid = null;
  }

  public getIataCodeInArrayAirportsError(airports: Airport[]): string {
    let resp: string = '';
    for(let air of airports){
      resp += air.codeIATA + ',';
    }
    return resp;
  }

// Andrietti

  public saveOrEditAdministrator(): void{

    this.disableForm();
    let airportAdministrator: AirportAdministrator = new AirportAdministrator();
    airportAdministrator.administrator = this.administrator;
    airportAdministrator.airports = this.airportList;

    this.appModalAdministratorService.saveOrEditAdministrator(airportAdministrator).subscribe(
      dataSource => {
        // console.log('succes in operation: {}', JSON.stringify(dataSource));
        this.dialogRef.close();
        if(dataSource.status==500){
          this.openDialog(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
            properties.MODAL_CONTENT_ERROR_IN_REGISTER_NEW_ADMINSTRATOR + this.getIataCodeInArrayAirportsError(dataSource.airportsError)
          );
          return;
        }
        this.openDialog(
          properties.MODAL_TITLE_SUCESS,
          properties.MODAL_BODY_SUCESS_OPERATION
        );
      }, err => {
        this.dialogRef.close();
        this.openDialog(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE
        );
      });
  }

  public cancelOperation(): void {
    this.dialogRef.close();
  }

}
