import { Component, OnInit, ViewChild } from '@angular/core';
import { Voo } from './model/voo';
import { VooRequisicao } from './model/vooRequisicao';
import { Protocolo } from './model/protocolo';
import { ProtocoloChamado } from '../app-enum/protocolo-chamado';
import { ExcelService } from '../../app-conciliation/service/excel.service';
import { ModalChamadoComponent } from '../app-dialog-ticket/app-dialog-ticket.component';
import { Router, ActivatedRoute } from '@angular/router';
import { properties } from '../../../environments/properties';
import { MatSort, Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { AppDataRpeGeneratedListService } from './app-data-rpe-generated-list.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-data-rpe-generated-list',
  templateUrl: './app-data-rpe-generated-list.component.html',
  styleUrls: ['./app-data-rpe-generated-list.component.css']
})
export class AppDataRpeGeneratedListComponent implements OnInit {

  public voorpe: VooRequisicao;
  public isLoadingResultsDtRpG: boolean;
  public paginaAtual = 1;
  public displayedColumnsDtRpG: string[] = [
    'idRpe',
    'empiata',
    'cdNumVoo',
    'dtVoo',
    'dtGeneratedRpe',
    'sisOrigin',
    'destAirport',
    'tipo',
  ];

  public dataSourceImutavel = new MatTableDataSource<Voo>();

  public mensagemResposta: string;
  modalStatus = false;
  private eventsSubscription: any;
  public dataAtualGenLi: Date = new Date();
  public allSelectedGenLi: boolean = false;
  public indeterminateGenLi: boolean = false;
  public isDisabledGenLi: boolean = true;
  public filterValueGenLi = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public sortedData: Voo[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public listarDadosVooService: AppDataRpeGeneratedListService,
    private excelService: ExcelService,
    public dialog: MatDialog,
    private datePipe: DatePipe
  ) {
    this.voorpe = new VooRequisicao();
  }

  ngOnInit() {
    this.eventsSubscription = this.route.params.subscribe(params => {
      this.voorpe.dtInicio = params.date;
      this.voorpe.dtFim = params.date;
      this.voorpe.sistOrigin = params.sys;
      // console.log('[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 3 ] ' + this.voo.dtInicio);
      // console.log('[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 3 ] ' +this.voo.sistOrigin);
      this.tratarDataFim();
      this.obterLista();
    });
  }

  applyFilter(filterValue: string) {
    this.filterValueGenLi = filterValue.trim().toLowerCase();
    // console.log('[[ WPENHA ]] applyFilter: {{ }} ' + this.filterValue);

    this.dataSourceImutavel.filter = filterValue.trim().toLowerCase();
  }

  private obterLista(): void {
    this.listarDadosVooService.getDadosVoo(this.voorpe).subscribe(
      dataSource => {
        this.sortedData = dataSource;
      },
      err => {
        const protocolo: Protocolo = err.error;
        this.validarStatusBuscaVoo(protocolo);
      },
      () => {
        this.sortedData.sort(function(a, b) {
          return a.sisOrigin < b.sisOrigin
            ? -1 : a.sisOrigin > b.sisOrigin ? 1 : 0;
        });

        this.dataSourceImutavel = new MatTableDataSource(this.sortedData);
        this.dataSourceImutavel.paginator = this.paginator;
      }
    );
  }

  validarStatusBuscaVoo(protocolo: Protocolo): void {
    switch (protocolo.status) {
      case ProtocoloChamado.REQUEST_INVALIDO:
        this.mensagemResposta =
          properties.MODAL_CHAMADO_MENSAGEM_REQUEST_INVALIDO;
        break;
      default:
        this.mensagemResposta = protocolo.descricao;
        break;
    }
  }


  exportAsXLSX(): void {
    this.excelService.exportAsExcelFileRpeGenerated(
      this.dataSourceImutavel.data,
      'LISTA_RPES_GERADOS_' +
        this.voorpe.sistOrigin.toUpperCase() +
        '_' +
        this.datePipe.transform(this.dataAtualGenLi, 'dd_MM_yyyy')
    );
  }

  private tratarDataFim(): void {
    let dt: string = this.voorpe.dtFim;
    let dtA: string[] = [];
    dtA = dt.split('T');
    this.voorpe.dtFim = dtA[0] + 'T23:59:59';
  }

  private getFormatDateToString(dtFtL: string): string {
    if (dtFtL === null || dtFtL === undefined) {
      return '';
    }
    let dtarr: string[];
    let formatDt: string;
    dtarr = dtFtL.split('-');
    formatDt = dtarr[2] + '-' + dtarr[1] + '-' + dtarr[0];
    return formatDt;
  }

  private getFormatDateToStringIso(dtrpeGrnLis: string): string {
    if (dtrpeGrnLis === null || dtrpeGrnLis === undefined) {
      return '';
    }
    let dtarr: string[];
    let dtar: string[];
    let formatDt: string;
    dtarr = dtrpeGrnLis.split('T');
    dtar = dtarr[0].split('-');
    formatDt = dtar[2] + '-' + dtar[1] + '-' + dtar[0];
    return formatDt;
  }

  private formatDateHour(dtrpeLis: String): string {
    if (dtrpeLis === null || dtrpeLis === undefined) {
      return '';
    }
    let dtarr: string[];
    let dtarH: string[];
    dtarr = dtrpeLis.split('T');
    // +' '+ dtarr[1];
    dtarH = dtrpeLis.split('T');
    dtarr = dtarr[0].split('-');
    return dtarr[2] + '-' + dtarr[1] + '-' + dtarr[0]; //+ ' ' + dtarH[1];
  }

}
