import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardInformationService } from '../../../service/dashboard-information.service';
import { Conciliation } from '../../../model/conciliation';
import { DateService } from '../../../service/date.service';
import { DashboardTypeFilterService } from '../../../service/dashboard-type-filter.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatLabel } from '@angular/material/form-field';

@Component({
  selector: 'app-dashboard-final',
  templateUrl: './dashboard-final.component.html',
  styleUrls: ['./dashboard-final.component.css']
})
export class DashboardFinalComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;


  public displayedColumns: string[] = [
    'Data', 'Aeroporto', 'Operacao',
    'Natureza', 'Numero', 'Quantidade',
    'Tarifa', 'Valor'
  ];

  public dataSource: MatTableDataSource<Conciliation>;

  public barChartOptions: any;

  public barChartOptionsFinance: any = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          callback: (value, index, values) => {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
          }
        }
      }]
    },
    tooltips: {
      callbacks: {
        label: (item, data) => {
          const xLabel = data.datasets[item.datasetIndex].label;
          const yLabel = '$' + item.yLabel.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
          return xLabel + ': ' + yLabel;
        }
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    },

  };

  public barChartOptionsGeneric: any = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    },

  };

  public barChartLabels: MatLabel[];

  public barChartType: any = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: any[] = [{ data: [], label: '' }];

  private month: string;
  private year: string;
  public type: string;

  private conciliations: Conciliation[];

  public types: string[];

  constructor(
    private dateService: DateService,
    private dashboardInformationService: DashboardInformationService,
    private route: ActivatedRoute, private dashboardTypeFilterService: DashboardTypeFilterService) {

    this.types = dashboardTypeFilterService.getTypeFilter();

    this.year = route.snapshot.paramMap.get('year');

    this.month = route.snapshot.paramMap.get('month');

    dashboardInformationService.getAllDashboardInformation(this.year, this.month)
      .subscribe(conciliations => this.build(conciliations));

    this.barChartOptions = this.barChartOptionsGeneric;

  }

  public months: string[] = [undefined,
    'Janeiro', 'Fevereiro', 'Março',
    'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro',
    'Outubro', 'Novembro', 'Dezembro'];


  ngOnInit() {
  }

  @Input()
  set typeDashboard(type: string) {

    this.type = type;

    // console.log('type', this.type);

    if (this.conciliations !== undefined) {
      this.builderConciliationBar(this.conciliations);
    }
  }

  private build(conciliations: Conciliation[]): void {

    this.type = this.types[0];

    this.builderConciliationBar(conciliations);

    this.barChartLabels = conciliations.map(conciliation => conciliation.airportName);

    this.conciliations = conciliations;

  }

  private builderConciliationBar(conciliations: Conciliation[]) {
    if (this.type === this.types[0]) {
      this.barChartOptions = this.barChartOptionsFinance;

      this.barChartData = [{
        data: conciliations.map(conciliation => this.type === this.types[0] ? conciliation.valueRate : conciliation.qntPassager),
        label: this.months[this.month] + ' ' + this.year,
        datalabels: {
          formatter: (value) => {
            return 'R$ ' + value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
          }
        }
      }];

    } else {
      this.barChartOptions = this.barChartOptionsGeneric;

      this.barChartData = [{
        data: conciliations.map(conciliation => this.type === this.types[0] ? conciliation.valueRate : conciliation.qntPassager),
        label: this.months[this.month] + ' ' + this.year,
      }];

    }
  }

  public chartClicked(event: any) {

    if (event.active[0] !== undefined) {

      const active = event.active[0]._index;

      const airport = this.barChartLabels[active];

      this
        .dashboardInformationService
        .getAllDashboardInformationFiltered(airport.toString(), this.year, this.month)
        .subscribe(conciliations => {
          this.dataSource = new MatTableDataSource(conciliations);
          this.dataSource.paginator = this.paginator;
        });

    }
  }
}
