<br>
  <!-- [ DASHBOARDS ORIGEM DO PROBLEMA] -->
  <div class="row">
    <div class="titleDash">
      <h4 class="headerTitleModule">{{ titleModule }}</h4>
    </div>
      <div class="centraliza">
      <app-dashboard-column-stamp></app-dashboard-column-stamp>
     </div> 
  </div>
  <br>
<app-data-stamp-list></app-data-stamp-list>
