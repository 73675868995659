import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { endpoints } from '../../../environments/endpoint';
import { Observable } from 'rxjs';
import { Totalizador } from './app-totalizers';

@Injectable({
  providedIn: 'root'
})
export class TotalizadoresService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  public obterTotalizadorGerado(): Observable<Totalizador> {
    return this.obterTotalizador(endpoints.REST_GET_TOTALIZADOR_RPE_GERADO);
  }

  public obterTotalizadorWorkAround(): Observable<Totalizador> {
    return this.obterTotalizador(endpoints.REST_GET_TOTALIZADOR_WORK_AROUND);
  }

  public obterTotalizadorNaoGerado(): Observable<Totalizador> {
    return this.obterTotalizador(endpoints.REST_GET_TOTALIZADOR_RPE_NAO_GERADO);
  }

  private obterTotalizador(url: string): Observable<Totalizador> {
    return this.http.get<Totalizador>(url, this.httpOptions);
  }

}
