import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '../../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ResumePaxBoardingService {

  constructor(private http: HttpClient) { }

  getDataReportPaxBoarding(airport: string, dtStart: string, dtEnd: string, numFlight: any): Observable<Blob> {

    // console.log('request to report: ', airport, dtStart, dtEnd, company, numFlight);

    let params = new HttpParams();
    params = params.set("airport", airport);
    params = params.set("dtStart", dtStart);
    params = params.set("dtEnd", dtEnd);

    if(numFlight!=null && numFlight != undefined){
      params = params.set("numFlight", numFlight);
    }

    return this
      .http
      .get(endpoints.REST_GTECLOUD_GET_REPORT_RESUME_PAX_BOARDING, {
        params,
        responseType: 'blob'
      });

  }

}
