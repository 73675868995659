  <!-- <div class="row" >
    <app-search-information-by-period (periodo)="receberPeriodo($event)"></app-search-information-by-period>
    <app-indicators [events]="eventsSubject.asObservable()" (tipoLista)="receberId($event)"></app-indicators>
  </div> -->
  <br>
  <div class="row" class="margin-left: 0%;">
    <div class="col-12">
      <app-rpe-not-generated-list [eventsPeriodo]="eventsSubject.asObservable()" [events]="eventsSubjectId.asObservable()"></app-rpe-not-generated-list>
    </div>
    <!-- <div class="col-2">
      <app-totalizers></app-totalizers>
    </div> -->
  </div>

