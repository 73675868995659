import { Component, Inject, OnInit } from '@angular/core';
import { DialogCustomComponent } from '../../../../app/app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { properties } from '../../../../environments/properties';
import { DateService } from '../../../../app/app-conciliation/conciliation/service/date.service';
import { FlightLegFile } from '../model/FlightLegFile';
import { ExclusionFileRpeSeloService } from '../services/exclusion-file-rpe-selo.service';
import { HistoricRpeSeloExcluded } from '../model/HistoricRpeSeloExcluded';
import { AppExclusionInformationFileRpeSeloComponent } from './information/app-exclusion-information-file-rpe-selo.component';
import { AuthenticationService } from '../../../../app/app-authenticator/authentication.service';
import { SeloRpeExclude } from '../model/SeloRpeExclude';
import { LogsComponent } from '../logs/logs.component';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-app-deletion-confirmation-rpe-selo',
  templateUrl: './app-deletion-confirmation-rpe-selo.component.html',
  styleUrls: ['./app-deletion-confirmation-rpe-selo.component.css']
})
export class AppDeletionConfirmationRpeSeloComponent implements OnInit {

  public displayedColumns: string[] = [
    'select',
    'typeFile',
    'numFile',
    'numFlightArrival',
    'dateFlightArrival',
    'numFlightDeparture',
    'dateFlightDeparture',
    'dateFileGeneration',
    'generationType',
    'flightOrigin',
    'flightDestiny',
    'natureType'
  ];

  public reason :string;

  public selection: SelectionModel<FlightLegFile>;
  public dataSourceFileRPE: MatTableDataSource<FlightLegFile>;
  public seloRpeExclude: SeloRpeExclude;
  public flightSeloFile: FlightLegFile;
  public listFileExclusion:  HistoricRpeSeloExcluded[] = [];
  public controlSave:  boolean = false;

  constructor( @Inject(MAT_DIALOG_DATA) public listFlightDeleFile: FlightLegFile[],
               private dateService: DateService,
               private dialog: MatDialog,
               public exclusionRpeSeloService: ExclusionFileRpeSeloService,
               private authenticationService: AuthenticationService,
               private dialogRef: MatDialogRef<AppDeletionConfirmationRpeSeloComponent>) { }

  ngOnInit() {
  //  this.listFlightRpeFile = this.listFlightDeleFile;
  //  this.flightSeloFile = this.listFlightDeleFile[1];
    this.selection = new SelectionModel<FlightLegFile>(true, []);
    this.dataSourceFileRPE = new MatTableDataSource(this.listFlightDeleFile);
    console.log(this.dataSourceFileRPE, "<----this.dataSourceFileRpe")
    this.seloRpeExclude = new SeloRpeExclude();
    this.seloRpeExclude.id = [];
    if(!this.isAllSelected()){this.masterToggle();}
  //    this.flightSeloFile.typeFile

  //console.log("Torresmo Loaded");

  }


  public getFormatDateToStringIso(date: any): string {
    return this.dateService.ngbDateStringToString(date);
  }

  private isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceFileRPE.data.length;
    return numSelected === numRows;
  }

  public masterToggle() {
    if (this.selection) {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSourceFileRPE.data.forEach(row => this.selection.select(row));
    }
  }
  checkboxLabel(row?: FlightLegFile): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection?.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  public cancelOperation(): void {
    this.dialogRef.close();
  }

  public exibeInformacaoTeste(informacao: any): any{
    console.log(informacao)
    return informacao
  }

  public deleteFileRpeSelo() :void{

    if(this.selection.selected.length <= 0){
      this.openDialog(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
        properties.MODAL_CONTENT_EXCLUSION_NOT_SELECTED);
      return;
    }
    var tobe_removed : number = this.selection.selected.length;

    if(this.reason !== null && this.reason !== undefined && this.reason !== ""){
      console.log("Tamanho " + this.selection.selected.length);

      this.seloRpeExclude.reason = this.reason;
      this.seloRpeExclude.email = this.authenticationService.getCurrentUser().email;


      this.selection.selected.forEach(element => {
        this.seloRpeExclude.id.push(element.id.toString());
        this.listFlightDeleFile = this.listFlightDeleFile.filter(function(value,index,array){
          return value.id != element.id;
        });
      });

      this.deleteFiles();
      //this.deleteFile(this.listFlightDeleFile.length);
      this.controlSave = true;




      return;
    }else{
      this.openDialog(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
        properties.MODAL_CONTENT_REASON_INVALID);
      return;
    }
  }

  private deleteFiles() : void{
    if(this.seloRpeExclude.id.length==0){
      this.callModalInfomation();
      this.dialogRef.close();
      return;
    }

    this.exclusionRpeSeloService.postDeleteFileRPEAndSELO(this.seloRpeExclude)
    .subscribe(dataSource => {
      for(var hist of dataSource) {
        this.listFileExclusion.push(hist);
      }
      console.log(this.listFileExclusion);
      this.callModalInfomation();
      this.dialogRef.close();
    }), err => {
      this.openDialog(
        properties.MODAL_ERROR_TITLE,
        properties.MODAL_CONTENT_EXCLUSION_FLIGHT_INVALID);
        this.controlSave = false;
    }
  }

  private deleteFile(element :number) : void{

    element = element - 1;

    if(element < 0){
      this.callModalInfomation();
      this.dialogRef.close();
      return;
    }

    //Email do Usuário logado
    let emailUser :string = this.authenticationService.getCurrentUser().email;
  //  let emailUser  :string = "heliooliveira.everis@latam.com"; // Para teste

    this.exclusionRpeSeloService.deleteFileRPEAndSELO(this.listFlightDeleFile[element].id,
      emailUser,this.reason).subscribe(dataSource =>{
        this.listFileExclusion.push(dataSource);
        console.log(this.listFileExclusion);
        this.deleteFile(element);
      }, err => {
            this.openDialog(
              properties.MODAL_ERROR_TITLE,
              properties.MODAL_CONTENT_EXCLUSION_FLIGHT_INVALID);
              this.controlSave = false;

     });
  }

  private openDialog(titleParam: string, messageParam: string): void {

    this.dialog.open(DialogCustomComponent, {
      width: '450px',
      data: { title: titleParam, message: messageParam }
    });
  }

  public logsConfig(history: HistoricRpeSeloExcluded[]):MatDialogConfig{

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = history;
    dialogConfig.width = '95%';
    dialogConfig.height = '90%';

    return dialogConfig;

  }

  public openModalInformationExclusionFileRpeSelo(dialogConfig: MatDialogConfig): void{

   this.dialog.open(LogsComponent, dialogConfig);
    //.afterClosed().subscribe(responseDialog => {
     // this.loadUsers();
//});
  }

  private callModalInfomation():void{

      let dialogConfig = this.logsConfig(this.listFileExclusion);
      this.openModalInformationExclusionFileRpeSelo(dialogConfig);

  }


}
