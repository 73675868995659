import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportComponent } from './component/import/import.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { ImportConfirmationComponent } from './component/import-confirmation/import-confirmation.component';
import { TabToBeImportedComponent } from './component/tab-to-be-imported/tab-to-be-imported.component';
import { UtilitariosModule } from '../../app-datahealth/app-utils/utilitarios.module';
import { ModalLineErrorComponent } from './component/modal-line-error/modal-line-error.component';
import { TabAirpotTaxComponent } from './component/tab-airpot-tax/tab-airpot-tax.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TabAirportTaxRegisterComponent } from './component/tab-airport-tax-register/tab-airport-tax-register.component';
import { ExportConciliationComponent } from './component/export-conciliation/export-conciliation.component';
import { ConciliationDashboardComponent } from './component/conciliation-dashboard/conciliation-dashboard.component';
import { ComparativeDashboardBarChartComponent } from './component/conciliation-dashboard/comparative-dashboard-bar-chart/comparative-dashboard-bar-chart.component';
import { ComparativeDashboardLineChartComponent } from './component/conciliation-dashboard/comparative-dashboard-line-chart/comparative-dashboard-line-chart.component';
import { DashboardMonthlyVariationChartComponent } from './component/conciliation-dashboard/dashboard-monthly-variation-chart/dashboard-monthly-variation-chart.component';
import { DashboardFinalComponent } from './component/conciliation-dashboard/dashboard-final/dashboard-final.component';
import { ImportedSpreadsheetTableComponent } from './component/imported-spreadsheet-table/imported-spreadsheet-table.component';
import { MatInputModule } from '@angular/material/input';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [
    ImportComponent,
    ImportConfirmationComponent,
    TabToBeImportedComponent,
    ModalLineErrorComponent,
    TabAirpotTaxComponent,
    TabAirportTaxRegisterComponent,
    ExportConciliationComponent,
    ConciliationDashboardComponent,
    ComparativeDashboardBarChartComponent,
    ComparativeDashboardLineChartComponent,
    DashboardMonthlyVariationChartComponent,
    DashboardFinalComponent,
    ImportedSpreadsheetTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    UtilitariosModule,
    NgbModule,
    MatInputModule,
    NgChartsModule,

  ]
})
export class ConciliationModule { }
