import {Injectable, NgZone} from '@angular/core';
import {CookieUtilsService} from '../app-cookies/cookie-utils.service';
import {HttpClient} from '@angular/common/http';
import {GoogleAuthService} from 'ng-gapi';
import GoogleUser = gapi.auth2.GoogleUser;
import {Router} from '@angular/router';
import { UserInvoice } from './model/user.invoice';
import { AuthenticationEmailService } from './authentication-email.service';
import { DialogCustomComponent } from '../app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { properties } from '../../environments/properties';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public static readonly ACCESS_TOKEN_COOKIE_NAME: string = 'accessToken';
  public static readonly ACCESS_TYPE_COOKIE_NAME: string = 'accessType';
  public static readonly ACCESS_TOKEN_TYPE_HEADER: string = 'X-Token-Type';
  public static readonly LOCAL_STORAGE_KEY: string = 'currentUser';

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private googleAuthService: GoogleAuthService,
    private ngZone: NgZone,
    private authenticationUser: AuthenticationEmailService,
    private dialog: MatDialog,
    private cookieUtil: CookieUtilsService
  ) {
  }

  public setTokenAndType(token: string, type: string): void {
    this.cookieUtil.setCookie(AuthenticationService.ACCESS_TOKEN_COOKIE_NAME, token, 1);
    this.cookieUtil.setCookie(AuthenticationService.ACCESS_TYPE_COOKIE_NAME, type, 1);
  }

  public getToken(): string {
    return this.cookieUtil.getCookie(AuthenticationService.ACCESS_TOKEN_COOKIE_NAME);
  }

  public getTokenType(): string {
    return this.cookieUtil.getCookie(AuthenticationService.ACCESS_TYPE_COOKIE_NAME);
  }

  public isUserSignedIn(): Promise<boolean> {
    const token = this.getToken();

    const tokenType = this.getTokenType();

    if (token == null || tokenType == null) {
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }

  public signGoogle(): void {
    this.googleAuthService.getAuth()
      .subscribe((response) => {
        response.signIn().then(res => this.signInSuccessHandleGoogle(res));
      });
  }

  private signInSuccessHandleGoogle(res: GoogleUser) {
    sessionStorage.setItem('access_token', JSON.parse(JSON.stringify(res)).access_token);

    let user: UserInvoice = new UserInvoice(res.getBasicProfile().getEmail(), res.getBasicProfile().getName(), res.getBasicProfile().getImageUrl());
    this.cookieUtil.setCookie(AuthenticationService.LOCAL_STORAGE_KEY, JSON.stringify(user), 1);
    this.ngZone.run(() => {
      this.cookieUtil.setCookie(
        AuthenticationService.ACCESS_TOKEN_COOKIE_NAME, res.getAuthResponse().access_token, 1
      );
      this.cookieUtil.setCookie(
        AuthenticationService.ACCESS_TYPE_COOKIE_NAME, 'google', 1
      );
      if (this.isUserSignedIn()) {
       // console.log("Verificando email!!!");
       // console.log(res.getBasicProfile().getEmail());
        this.validateEmailUser(res.getBasicProfile().getEmail());

      } else {
        this.signOut();
        // console.error('Error to validate login.');
      }
    });
  }

  public signOut(): void {
    if (this.getTokenType() === 'google') {
      this.googleAuthService.getAuth().subscribe((auth) => {
        try {
          auth.signOut();
          auth.disconnect();
        } catch (e) {
          // console.error(e);
        }
      });
    }

    this.cookieUtil.deleteCookie(AuthenticationService.ACCESS_TOKEN_COOKIE_NAME);
    this.cookieUtil.deleteCookie(AuthenticationService.ACCESS_TYPE_COOKIE_NAME);
    this.cookieUtil.deleteCookie(AuthenticationService.LOCAL_STORAGE_KEY);
    localStorage.removeItem(AuthenticationService.LOCAL_STORAGE_KEY);
    this.router.navigate(['/login']);
  }

  public getCurrentUser(): UserInvoice {
    const userStorage = this.cookieUtil.getCookie(AuthenticationService.LOCAL_STORAGE_KEY);
    if (userStorage) {
      return JSON.parse(userStorage);
    }
    return null;
  }

  public validateEmailUser(emailUser: string): void{

      this.authenticationUser.validateEmail(emailUser).subscribe(data => {
    //   console.log("Email = " + data);
        if(!data){
          this.openDialog(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
            properties.MODAL_CONTENT_NOT_VALID_EMAIL_USER);
          this.signOut();
        }else{
         // console.log("Chamando dashboardOne!!!");
          this.router.navigate(['/dashboardOne']);
        }
      }, err=>{
        this.openDialog(
          properties.MODAL_ERROR_TITLE,
          properties.MODAL_CONTENT_ERROR_VALID_EMAIL_USER);
        this.signOut();
      });
    }

    private openDialog(titleParam: string, messageParam: string): void {
      this.dialog.open(DialogCustomComponent, {
        width: '450px',
        data: { title: titleParam, message: messageParam }
      });
    }

}
