import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Voo } from './model/voo';
import { VooRequisicao } from './model/vooRequisicao';
import { endpoints } from '../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppDataStampListService {

  constructor(private http: HttpClient) { }

  convertDataIso(voo: VooRequisicao): void {
    let arrayString: string[] = [];

    arrayString = voo.dtInicio.split('/');

    voo.dtInicio = arrayString[2] + '-' + arrayString[1] + '-' + arrayString[0] + 'T00:00:00';
  }

  getDadosVoo(voo: VooRequisicao): Observable<Voo[]> {

    if (!voo.dtInicio.includes('T')) {
      this.convertDataIso(voo);
    }

    let params1 = new HttpParams().set('flightBeginDate', voo.dtInicio);

    return this.http.get<Voo[]>(endpoints.REST_GET_ERROR_STAMP_LIST + "?" + params1, httpOptions);
  }
}
