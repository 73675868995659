import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { FunctionalityUser } from '../model/functionality-user';
import { PermissionAccessService } from './permission-access.service';

@Injectable()
export class ProfileGuard implements CanActivate{

    private functionalityUser: FunctionalityUser[];

    constructor(private authenticationService: AuthenticationService,
                private permissionAccessService: PermissionAccessService) { 
        
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

      // console.log("Verificando Guard " + this.authenticationService.getCurrentUser().email);

       return this.permissionAccessService
        .validateFunctionalityProfile(this.authenticationService.getCurrentUser().email, state.url);
    }
}

