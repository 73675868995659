import { Component, OnInit, ViewChild } from '@angular/core';
import { AirpotTaxService } from '../../service/airpot-tax.service';
import { AirportTax } from '../../model/airport-tax';
import { properties } from '../../../../../environments/properties';
import { NgbDate, NgbDateStruct, NgbDatepickerI18n, NgbDateParserFormatter, NgbDatepickerConfig, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DialogCustomComponent } from '../../../../app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { I18n } from '../../../../app-datahealth/app-search-information-by-period/CustomDatepickerI18n';
import { CustomDatepickerI18n } from '../../../../app-datahealth/app-search-information-by-period/CustomDatepickerI18n';
import { NgbDatePTParserFormatter } from '../../../../app-datahealth/app-search-information-by-period/NgbDatePTParserFormatter';
import { AirportInfo } from '../../model/airport-info';
import { TabAirportTaxRegisterComponent } from '../tab-airport-tax-register/tab-airport-tax-register.component';
import { TypeNatureAndAirport } from '../../model/type-nature-and-airport';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AirportService } from '../../service/airport.service';
import { ComboBoxAeroporto } from '../../../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { CategoryService } from '../../service/category.service';
import { UntypedFormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-tab-airpot-tax',
  templateUrl: './tab-airpot-tax.component.html',
  styleUrls: ['./tab-airpot-tax.component.css'],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{ provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter }],
  ],
})
export class TabAirpotTaxComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  dtInicialLabel = properties.HTML_CONST_DT_START;
  dtFinalLabel = properties.HTML_CONST_DT_END;
  emptyDate = properties.HTML_CONST_DT_TO_SELECTED;
  displayMonths = properties.HTML_CONST_QTD_MOUNTHS;

  public displayedColumns: string[] = ['Aeroporto', 'Natureza', 'Categoria', 'TTBS', 'TIPO', 'Tarifa', 'Inicio', 'Fim'];

  public airports: ComboBoxAeroporto[];

  public typeNatures: string[];

  public airportChoose: string;

  public typeNatureChoose: string;

  public categoryChoose: string;

  public dataSource: MatTableDataSource<AirportTax>;

  public dtInicial: NgbDate;

  public dtFinal: NgbDate;

  public activeInput: string;

  public isDisabled: boolean;

  public taxas: AirportTax[];

  public airportCtrl: UntypedFormControl = new UntypedFormControl();

  public filteredAeroportos: Observable<ComboBoxAeroporto[]>;

  public categories: string[];

  constructor(
    private airportTaxService: AirpotTaxService,
    public dialog: MatDialog,
    private airportServiceTabAir: AirportService,
    categoryService: CategoryService,
    config: NgbDatepickerConfig,
    calendar: NgbCalendar,
    public datePipe: DatePipe) {

    config.minDate = {year: 1976, month: 1, day: 1};
    config.maxDate = {year: 2099, month: 12, day: 31};
    config.outsideDays = 'hidden';

    this.typeNatures = ['Domestico', 'Internacional'];

    this.categories = categoryService.getCategories();

    airportTaxService
      .getAllLayouts()
      .subscribe(taxas => {
        this.taxas = taxas;
        this.dataSource = new MatTableDataSource(this.taxas);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });

      airportServiceTabAir.getAllAirport().subscribe(airportsTabAir => {
      this.airports = airportsTabAir;
      this.filteredAeroportos = this.airportCtrl
        .valueChanges
        .pipe(startWith(''), map(airport => {
          return airport ? this._filterAirport(airport) : this.airports;
        }));
    });

  }

  ngOnInit() {
  }

  private _filterAirport(value: string): ComboBoxAeroporto[] {

    const filterValue = value.toLowerCase();

    return this.airports.filter(airport => airport.codeICAO.toLowerCase().indexOf(filterValue) === 0);

  }


  public clickEventHandlerSelectAirTax(event: any) {
    this.activeInput = event.currentTarget.id;
  }

  public onDateStartSelectAirTa(dtSelect: NgbDate) {
    this.isDisabled = false;
    $('#dtFinal').trigger('click');
  }

  public onDateEndSelectAirT(dtSelect: NgbDate) {
  }

  public isDisabledLastOneTabAir(date: NgbDateStruct) {
    let dateIniTax: any;
    let yearIniTax: number;
    let mounthIniTax: number;
    let dayIniTax: number;

    dateIniTax = $('#dtInicial').val();
    yearIniTax = parseInt(dateIniTax.substring(6, 10), 10);
    mounthIniTax = parseInt(dateIniTax.substring(3, 5), 10);
    dayIniTax = parseInt(dateIniTax.substring(0, 2), 10);

    const selectUserDateAir = new Date(yearIniTax, (mounthIniTax - 1), dayIniTax);
    const maxDateSelectAir = new Date(yearIniTax, (mounthIniTax - 1), dayIniTax);
    maxDateSelectAir.setDate(selectUserDateAir.getDate() + 30);

    return date.year < yearIniTax
      || (date.month <= mounthIniTax && date.year === yearIniTax && date.day <= dayIniTax)
      || (date.month < mounthIniTax && date.year === yearIniTax);
  }

  public eventoPesquisa(): void {

    const airportInfo: AirportInfo = new AirportInfo();

    airportInfo.airport = this.airportChoose;
    airportInfo.typeNature = this.typeNatureChoose;
    airportInfo.dtInit = this.dtInicial;
    airportInfo.dtEnd = this.dtFinal;
    airportInfo.category = this.categoryChoose;

    const taxas = this.airportTaxService.filterByInfo(airportInfo, this.taxas);

    this.createDataSource(taxas);
  }

  private createDataSource(taxas: AirportTax[]) {
    this.dataSource = new MatTableDataSource(taxas);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public eventClear(): void {
    this.createDataSource(this.taxas);
    this.dtInicial = undefined;
    this.dtFinal = undefined;
    this.categoryChoose = undefined;
    this.typeNatureChoose = undefined;
    this.airportChoose = undefined;
  }

  public openDialog(titleParam: string, messageParam: string): void {
    this.dialog.open(DialogCustomComponent, {
      data: { title: titleParam, message: messageParam }
    });

  }

  public onchangeAirport(airport: string): void {
    this.airportChoose = airport;
  }

  public onChangeTypeNature(nature: string): void {
    this.typeNatureChoose = nature;
  }

  public includeAirportTax(): void {

    const typeNatureAndAirport: TypeNatureAndAirport = {
      airports: this.airports,
      typeNatures: this.typeNatures,
    };

    this
      .dialog
      .open(TabAirportTaxRegisterComponent, {
        width: '64%',
        height: '100%',
        backdropClass: 'display:inline-table',
        data: typeNatureAndAirport
      }).afterClosed().subscribe(response => {

        if (response === true) {

          this.getAllLayoutsWithTimeOut()
          .then(taxas => {
              this.taxas = taxas;
              this.dataSource.data = this.taxas;
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            });

        }

      });

  }

  private getAllLayoutsWithTimeOut(): Promise<AirportTax[]> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.airportTaxService.getAllLayouts().toPromise());
      }, 3 * 1000);
    });
  }
}
