<br><br>
<div>
  <div>
    <div style="display: block">
      <canvas baseChart
        [datasets]="barChartDataDasColGe"
        [labels]="barChartLabelsDasColGe"
        [options]="barChartOptionsDashClo"
        [plugins]="barChartPluginsDasColGe"
        [legend]="barChartLegendDasColGe">
      </canvas>
      <!-- <canvas baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType">
      </canvas> -->
    </div>
  </div>
</div>
