import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardInformation } from '../model/dashboard-information';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { endpoints } from '../../../../environments/endpoint';
import { Conciliation } from '../model/conciliation';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class DashboardInformationService {

  constructor(private http: HttpClient,  private cookieService: CookieService) { }

  // endpoints.REST_GET_DASHBOARD_CATEGORIZED
  // mock : https://5013fb6e-b224-493b-8af8-8030265b6119.mock.pstmn.io/dashboard
  // mockeamento: http://www.mocky.io
  public getAllDashboardInformationCategorized(): Observable<DashboardInformation> {

    // return this.http
    //   .get<DashboardInformation>('https://5013fb6e-b224-493b-8af8-8030265b6119.mock.pstmn.io/dashboard');

    return this.http
      .get<DashboardInformation>(endpoints.REST_GET_DASHBOARD_CATEGORIZED, {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get('acme-user-token')
      })});

  }

  public getAllDashboardInformation(year: string, month: string): Observable<Conciliation[]> {

    return this.http
      .get<Conciliation[]>(endpoints
        .REST_GET_DASHBOARD_INFORMATION_SPECIFIC
        .replace('{year}', year)
        .replace('{month}', month), {headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + this.cookieService.get('acme-user-token')
        })});
  }

  public getAllDashboardInformationFiltered(
    airport: string,
    year: string,
    month: string): Observable<Conciliation[]> {

    return this.http
      .get<Conciliation[]>(endpoints
        .REST_GET_DASHBOARD_INFORMATION
        .concat('?year={year}&airport={airport}&month={month}')
        .replace('{year}', year)
        .replace('{month}', month)
        .replace('{airport}', airport), {headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + this.cookieService.get('acme-user-token')
        })});
  }

}
