export class ListStampExcel {

    dtVoo: any;
    numVoo: string;
    aeroporto: string;
    descError: string;
    namePax: string;
    descSsr: string;
    nameSis: string;


    constructor(){
        this.dtVoo = undefined;
        this.numVoo = undefined;
        this.aeroporto = undefined;
        this.descError = undefined;
        this.namePax = undefined;
        this.descSsr = undefined;
        this.nameSis = undefined;

    }

}