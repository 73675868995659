<div id="pagefullexclusion">
  <form #formExclusion>
    <br />
    <div class="card text-center">
      <div class="card-header" style="background-color: #1b0088">
        <h5 style="color: #ffffff">Exclusão de RPE e Selo</h5>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-0 label">
            <label for="typeArq">Tipo de Arquivo: </label>
          </div>

          <div class="label" style="width: 100%;">
            <label for="RPE">RPE</label>
            <mat-checkbox
              id="rpeCheckBox"
              [(ngModel)]="rpe"
              name="rpeCheckBox"
              (change)="selectedCheckBox()"
              style="width: 100%;"
            ></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="label labelSelo" style="width: 100%;">
            <label for="RPE">SELO</label>
            <mat-checkbox
              id="seloCheckBox"
              [(ngModel)]="selo"
              name="seloCheckBox"
              (change)="selectedCheckBox()"
              style="width: 100%;"
            ></mat-checkbox>
          </div>
        </div>
        <br />

        <div class="row" style="width:100%;">
          <div class="col-12" style="width: 100%;">
            <mat-form-field
              id="matFormFieldOrigin"
              class="inputLayout"
              style="width: 100%;"
            >
              <input
                matInput
                id="origin"
                name="origin"
                [(ngModel)]="flightSeloRPE.airport"
                placeholder="Aeroporto"
                aria-label="Aeroporto"
                [matAutocomplete]="autoAirport"
                [formControl]="aeroportoCtrl"
                oninput="this.value = this.value.toUpperCase()"
                required
                style="width: 100%;"
              />
              <mat-autocomplete #autoAirport="matAutocomplete">
                <mat-option
                  *ngFor="let aeroporto of filteredAeroporto | async"
                  [value]="aeroporto.codeIATA"
                >
                  <span>{{ aeroporto.codeIATA }}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <br />
        <br />
        <div class="row">
          <div class="col-2 label">
            <label for="numFlight">Número Voo: </label>
          </div>
          <div class="col-10">
            <input
              type="text"
              id="numFlight"
              class="form-control"
              name="numFlight"
              [(ngModel)]="flightSeloRPE.numFlight"
              [formControl]="numFlightCtrl"
              style="width: 100%;"
            />
          </div>
        </div>
        <br />
        <br />
        <div class="row col-12">
          <div class="col-12" style="width: 100%;">
            <mat-form-field class="inputLayout" style="width: 100%;">
              <mat-label>Movimento Voo</mat-label>
              <mat-select
                [(value)]="flightSeloRPE.typeFlight"
                [formControl]="typeFlightCtrl"
                required
                style="width: 100%;"
              >
                <mat-option>Selecione</mat-option>
                <mat-option value="D">Decolagem</mat-option>
                <mat-option value="A">Pouso</mat-option>
                <mat-option value="AD">Decolagem e Pouso</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <br />
        <br />
        <div class="row">

          <div class="col-2 label">
            <label class="input-label" for="dateDTO">
              Data Inicial dos Voos:
            </label>
          </div>

          <div class="col-10">
              <mat-form-field style="width:100%;">
                <mat-label>Data Início</mat-label>
                <input matInput id="dateDTO" [matDatepicker]="datepickerInicial" [(ngModel)]="dateIniFlight" placeholder="dd/mm/yyyy" name="dateDTO" (click)="datepickerInicial.open()" style="width: 100%;">
                <mat-datepicker-toggle matSuffix [for]="datepickerInicial"></mat-datepicker-toggle>
                <mat-datepicker #datepickerInicial></mat-datepicker>
              </mat-form-field>
          </div>
        </div>
        <br />

        <div class="row col-12">
          <div class="col-12" style="width:100%;">
            <mat-form-field
              id="matFormFieldCompany"
              class="inputLayout"
              style="width: 100%;"
            >
              <input
                matInput
                id="company"
                name="company"
                [(ngModel)]="flightSeloRPE.company"
                placeholder="Empresa"
                aria-label="Empresa"
                [matAutocomplete]="autoCompany"
                [formControl]="companyCtrl"
                oninput="this.value = this.value.toUpperCase()"
                required
                style="width: 100%;"
              />
              <mat-autocomplete #autoCompany="matAutocomplete">
                <mat-option
                  *ngFor="let empresa of filteredEmpresa | async"
                  [value]="empresa.iataCode"
                >
                  <span
                    >{{ empresa.iataCode }} - {{ empresa.nameCompany }}</span
                  >
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <br />
        <br />
        <div class="row">
          <div class="col-2 label">
            <label class="input-label" for="dateDTO2">
              Data Final dos Voos:
            </label>
          </div>
          <div class="col-10">
              <mat-form-field style="width:100%">
                <mat-label>Data Fim</mat-label>
                <input matInput id="dateDTO2" [matDatepicker]="datepickerFinal" [(ngModel)]="dateEndFlight" placeholder="dd/mm/yyyy" name="dateDTO2" (click)="datepickerFinal.open()" style="width: 100%;">
                <mat-datepicker-toggle matSuffix [for]="datepickerFinal"></mat-datepicker-toggle>
                <mat-datepicker #datepickerFinal></mat-datepicker>
              </mat-form-field>
          </div>
        </div>
        <br /><br />
      </div>
    </div>
    <div class="card text-center" style="margin-top: 1%">
      <div class="card-body">
        <button
          type="button"
          class="btn btn-danger btnClean"
          (click)="clearFields()"
          [disabled]="btnExclusionCtrl"
        >
          <mat-icon style="font-size: x-large">clear</mat-icon> Limpar Campos
        </button>

        <button
          type="button"
          id="btnSearch"
          class="btn btn-primary btnSave"
          type="submit"
          (click)="exclusionEvent()"
          [disabled]="btnExclusionCtrl"
          matTooltip="Excluir"
        >
          <mat-icon style="font-size: x-large">delete</mat-icon> Excluir
        </button>

        <button
          type="button"
          id="btnSearchLogs"
          class="btn btnLogs"
          type="submit"
          (click)="logsEvent()"
          matTooltip="Logs"
        >
          <mat-icon style="font-size: x-large">access_time</mat-icon> Histórico
        </button>
      </div>
    </div>
  </form>
</div>
