<br>
<div class="row card">
  <div class="text-center col-12">
    <div class="card-header">
      Lista de RPE's Contestados
    </div>
    <div class="card-body ">

      <table mat-table [dataSource]="dataSourceContestation" class="mat-elevation-z8">
        <ng-container matColumnDef="idControlDispute">
          <th mat-header-cell *matHeaderCellDef>Contestação</th>
          <td mat-cell *matCellDef="let v">{{ v.idControlDispute }}</td>
        </ng-container>
        <ng-container matColumnDef="fkRpe">
          <th mat-header-cell *matHeaderCellDef>Núm. Rpe</th>
          <td mat-cell *matCellDef="let v">{{ v.fkRpe }}</td>
        </ng-container>
        <ng-container matColumnDef="cdCompAerea">
          <th mat-header-cell *matHeaderCellDef>Comp. Aéreas</th>
          <td mat-cell *matCellDef="let v"> {{ v.cdCompAerea }}</td>
        </ng-container>
        <ng-container matColumnDef="cdNumVoo">
          <th mat-header-cell *matHeaderCellDef>Núm. Voo</th>
          <td mat-cell *matCellDef="let v"> {{ v.cdNumVooDeparture != null ? v.cdNumVooDeparture : v.cdNumVooArrival }} </td>
        </ng-container>
        <ng-container matColumnDef="dtFlight">
          <th mat-header-cell *matHeaderCellDef>Dt. Voo</th>
          <td mat-cell *matCellDef="let v">{{ v.dtFlightDeparture !=null ? getFormatDateBr(v.dtFlightDeparture) : getFormatDateBr(v.dtFlightArrival) }}</td>
        </ng-container>

        <ng-container matColumnDef="originAirport">
          <th mat-header-cell *matHeaderCellDef>Origem</th>
          <td mat-cell *matCellDef="let v">{{ v.originAirport }}</td>
        </ng-container>

        <ng-container matColumnDef="destAirport">
          <th mat-header-cell *matHeaderCellDef>Destino</th>
          <td mat-cell *matCellDef="let v">{{ v.destAirport }}</td>
        </ng-container>

        <ng-container matColumnDef="typeFlightMovement">
          <th mat-header-cell *matHeaderCellDef>Tipo Voo</th>
          <td mat-cell *matCellDef="let v">{{ v.typeFlightMovement == 'A' ? 'Pouso' : 'Decolagem' }}</td>
        </ng-container>

        <ng-container matColumnDef="dtDispute">
          <th mat-header-cell *matHeaderCellDef>Dt. Contestação</th>
          <td mat-cell *matCellDef="let v">{{ getFormatDateToString(v.dtDispute) }}</td>
        </ng-container>

        <ng-container matColumnDef="typeDispute">
          <th mat-header-cell *matHeaderCellDef>Tp. Contestação</th>
          <td mat-cell [matTooltip]="v.typeDispute == 'A' ? 'Automático' : 'Manual' " matTooltipPosition="after" *matCellDef="let v">{{ v.typeDispute }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let v">{{ v.name }}</td>
        </ng-container>

        <ng-container matColumnDef="dtAction">
          <th mat-header-cell *matHeaderCellDef>Dt. Ação</th>
          <td mat-cell *matCellDef="let v">{{ getFormatDateToString(v.dtAction) }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" [showFirstLastButtons]="true"></mat-paginator>
      <br />

      <button mat-raised-button (click)="exportContestationAsXLSX()"
        style="background-color: #1b0088;border-color:#1b0088; color: #FFF;float: left;">
        <mat-icon>get_app</mat-icon>
        Exportar Excel
      </button>

      <button mat-raised-button style="background-color: #1b0088;border-color:#1b0088; color: #FFF; float: right;"
        routerLink="/dashboardOne">
        <mat-icon>arrow_back_ios</mat-icon>
        Voltar
      </button>
    </div><br><br>
  </div>
</div>

