import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardColumnRQ } from './model/DashboardColumnRQ';
import { DashboardColumnRS } from './model/DashboardColumnRS';
import { endpoints } from '../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppDashboardColumnWorkaroundService {

  constructor(private http: HttpClient) { }

  private convertDataIsoDashColWork(requestDashColWork: DashboardColumnRQ): void {
    let arrayStringDashWork: string[] = [];
    arrayStringDashWork = requestDashColWork.dtVoo.split('/');
    requestDashColWork.dtVoo = arrayStringDashWork[2]
      + '-' + arrayStringDashWork[1]
      + '-' + arrayStringDashWork[0]
      + 'T00:00:00';
  }

  getDataByDate(requestDashWork: DashboardColumnRQ): Observable<DashboardColumnRS[]> {

    if (!requestDashWork.dtVoo.includes('T')) {
      this.convertDataIsoDashColWork(requestDashWork);
    }
    let params1 = new HttpParams().set('flightDate', requestDashWork.dtVoo);

    return this.http.get<DashboardColumnRS[]>(endpoints.REST_GET_WORKAROUND_COLUMN + "/" + requestDashWork.sistemaOrigem + "/qtd-type?" + params1, httpOptions);
  }

}
