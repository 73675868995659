<div class="flex">
  <div class="flex-item">
    <div style="display: block;">
    <canvas class="dashLine" baseChart width="auto" height="100"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [legend]="lineChartLegendDashLine"
                [plugins]="lineChartPluginsDashLine"
                (chartClick)="chartClickedLine($event)"></canvas>
    <!-- <canvas class="dashLine" baseChart width="auto" height="100"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="lineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                [plugins]="lineChartPlugins"
                (chartClick)="chartClicked($event)"></canvas> -->
    </div>
  </div>
</div>
