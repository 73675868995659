<div class="row card">
  <div class="text-center col-12">
    <div class="card-header">
      Lista de RPE's gerados através de Workaround
    </div>
    <div class="card-body">
      <table class="mat-elevation-z8" mat-table [dataSource]="dataSourceImutavel">

        <ng-container matColumnDef="idWaRpe">
          <th mat-header-cell *matHeaderCellDef>ID Workaround</th>
          <td mat-cell *matCellDef="let v">{{ v.idWaRpe }}</td>
        </ng-container>
        <ng-container matColumnDef="airlineComp">
          <th mat-header-cell *matHeaderCellDef>Comp. Aérea</th>
          <td mat-cell *matCellDef="let v">{{ v.airlineComp }}</td>
        </ng-container>
        <ng-container matColumnDef="cdNumVoo">
          <th mat-header-cell *matHeaderCellDef>Num. Voo</th>
          <td mat-cell *matCellDef="let v">{{ v.cdNumVoo }}</td>
        </ng-container>
        <ng-container matColumnDef="dtVoo">
          <th mat-header-cell *matHeaderCellDef>Data do Voo</th>
          <td mat-cell *matCellDef="let v">{{ getFormatDateToStringIso(v.dtVoo) }}</td>
        </ng-container>
        <ng-container matColumnDef="dtProcess">
          <th mat-header-cell *matHeaderCellDef>Data Erro</th>
          <td mat-cell *matCellDef="let v">
            {{ getFormatDateToStringIso(v.dtProcess) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="tpWa">
          <th mat-header-cell *matHeaderCellDef>Tipo Erro</th>
          <td mat-cell [matTooltip]="v.tpWa" *matCellDef="let v">
            {{ v.tpWa | truncate: 10 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="adAeropOrin">
          <th mat-header-cell *matHeaderCellDef>Origem</th>
          <td mat-cell *matCellDef="let v">{{ v.adAeropOrin }}</td>
        </ng-container>

        <ng-container matColumnDef="destAirport">
          <th mat-header-cell *matHeaderCellDef>Destino</th>
          <td mat-cell *matCellDef="let v">{{ v.destAirport }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsDrWor"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsDrWor"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>

      <br />

      <button mat-raised-button (click)="exportAsXLSX()"
        style="background-color: #1b0088;border-color:#1b0088; color: #FFF; float: left;">
        <mat-icon>get_app</mat-icon>
        Exportar Excel
      </button>

      <button mat-raised-button style="background-color: #1b0088;border-color:#1b0088; color: #FFF; float: right;"
        routerLink="/dashboardOne">
        <mat-icon>arrow_back_ios</mat-icon>
        Voltar
      </button>
      <br><br>

    </div>
  </div>
</div>
