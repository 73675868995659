<br>
<form id='formulario' class="form-horizontal" (ngSubmit)="validRequestDto()" #clienteForm="ngForm">
<!-- Voltar div abaixo após entender e corrigir o formulario abaixo e comentar a que não tem o hidden-->
<div class="card" [hidden]="this.hide">
<!-- <div class="card"> -->
  <div class="card-header" style="background-color: #1b0088;">
      <h5 style="color: #ffffff;">Alteração de <b>RPE</b></h5>
  </div>

  <div class="card-body">

    <div class="card-title"
      *ngIf="mensagemResposta != undefined && status == 1"
      class="alert alert-info mensageInfo"
      role="alert">
      {{mensagemResposta}}
    </div>

    <div class="card-title"
      *ngIf="mensagemResposta != undefined && status == -1"
      class="alert alert-danger mensageInfo"
      role="alert">
      {{mensagemResposta}}
    </div>

    <div class="card-title"
    *ngIf="mensagemResposta != undefined && status == 0"
    class="alert alert-primary mensageInfo"
    role="alert">
      {{mensagemResposta}}
    </div>

    <br />
      <!-- EMPRESA AEREA -->
      <div class="row col-12 d-flex">
        <div class="col-2">
          <label for="airline">Empresa Aérea:</label>
        </div>

        <div class="col-10">
          <mat-form-field
            id="matFormFieldCompany"
            class="col-12">
            <input
              matInput id="company"
              name="company"
              [(ngModel)]="consultFileEditRPE.icaoCompany"
              placeholder="Empresa"
              aria-label="Empresa"
              [matAutocomplete]="autoCompany"
              [formControl]="empresaCtrl"
              oninput="this.value = this.value.toUpperCase()">
            <mat-autocomplete #autoCompany="matAutocomplete">
              <mat-option *ngFor="let empresa of filteredEmpresa | async" [value]="empresa.icaoCode">
                <span>{{empresa.icaoCode}} - {{empresa.nameCompany}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

    <!-- [ END ] EMPRESA AEREA -->

    <br />
    <br />
      <!-- PREFIXO AERONAVE -->
      <div class="row">
        <div class="col-2">
          <label for="prefixAircraft">Matricula Aeronave:</label>
        </div>
        <div class="col-4 campos">
          <input type="text" maxlength="6" class="form-control" name="prefixAircraft" id="prefixAircraft"
            [(ngModel)]="consultFileEditRPE.fligthLegDTO.prefix"
            [(ngModel)]="rpe.prefixAircraft"
            style="text-transform:uppercase">
        </div>

        <div class="col-2">
          <label for="typeAircraft">Modelo Aeronave:</label>
        </div>
        <div class="col-4 campos teste">
          <input type="text" maxlength="6" class="form-control" name="typeAircraft" id="typeAircraft"
             [(ngModel)]="consultFileEditRPE.fligthLegDTO.typeAircraft"
             #matAeronave="ngModel"
            style="text-transform:uppercase">
        </div>

      </div>
      <!-- [ END ] PREFIXO AERONAVE -->

      <!-- DATA DO VOO --> <br><br>
      <div class="row">
        <div class="col-2">
          <label for="dtFlight">Decolagem .<br>Data do Voo:</label>
        </div>
        <div class="col-4 campos">
          <div class="input-group">
            <mat-form-field class="inputPadrao">
            <input matInput [matDatepicker]="datepicker1" id="dtFlight" autocomplete="off"
             [(ngModel)]="dataPartida" class="inputDatePicker"
              placeholder="dd/mm/yyyy" name="dtFlight" (click)="(datepicker1.open())"/>
              <mat-datepicker-toggle matIconSuffix [for]="datepicker1"></mat-datepicker-toggle>
              <mat-datepicker #datepicker1></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="col-2">
          <label for="dtFlightArrival">Desembarque.<br>Data Voo:</label>
        </div>
        <div class="col-4 campos">
          <div class="input-group">
            <mat-form-field class="inputPadrao">
            <input matInput id="dtFlightArrival"[matDatepicker]="datepicker2"  autocomplete="off"
             [(ngModel)]="dataChegada"
             class="inputDatePicker" placeholder="dd/mm/yyyy" name="dtFlightArrival" (click)="datepicker2.open()"/>
              <mat-datepicker-toggle matIconSuffix [for]="datepicker2"></mat-datepicker-toggle>
              <mat-datepicker #datepicker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- [ END ] DATA DO VOO -->

      <!-- NUMERO DO VOO --> <br>
      <div class="row">
        <div class="col-2">
          <label for="numFlight">Decolagem .<br>Número Voo:</label>
        </div>
        <div class="col-4">
          <input
            type="number"
            class="form-control"
            name="numFlight"
            id="numFlight"
            [(ngModel)]="consultFileEditRPE.fligthLegDTO.numFlightDeparture"
            #numFlight="ngModel">
        </div>
        <div class="col-2">
          <label for="numFlightArrival">Desembarque.<br>Número Voo:</label>
        </div>
        <div class="col-4">
          <input
            type="number"
            class="form-control"
            name="numFlightArrival"
            id="numFlightArrival"
            [(ngModel)]="consultFileEditRPE.fligthLegDTO.numFlightArrival"
            #numFlightArrival="ngModel">
        </div>
      </div>
      <!-- [ END ] NUMERO DO VOO -->

      <!-- HORA DO VOO --> <br>
      <div class="row">
        <div class="col-2">
            <label for="hrFlight">Decolagem .<br>Hora do Voo:</label>
          </div>
          <div class="col-4">
            <div class="input-group">
              <input
                type="text"
                step="1"
                id="hrFlight"
                autocomplete="off"
                [(ngModel)]="consultFileEditRPE.fligthLegDTO.hrFlightDeparture"
                name="hrFlight"
                class="form-control"
                placeholder="HH:MM"/>
            </div>
          </div>
        <div class="col-2">
          <label for="hrFlightArrival">Desembarque.<br>Hora do Voo:</label>
        </div>
        <div class="col-4">
          <div class="input-group">
            <input
              type="text"
              step="1"
              name="hrFlightArrival"
              id="hrFlightArrival"
              autocomplete="off"
              [(ngModel)]="consultFileEditRPE.fligthLegDTO.hrFlightArrival"
              class="form-control"
              placeholder="HH:MM:SS" />
          </div>
        </div>
      </div>
      <!-- [ END ] HORA DO VOO -->

      <!-- SIGLA ICAO --> <br>
    <div class="row">
      <div class="col-2">
          <label for="flight-icao">Aeroporto Origem do Voo Anterior:</label>
        </div>

        <div class="col-4">
          <mat-form-field
            id="matFormFieldOrigin" class="inputPadrao">
            <input matInput
              id="airportPrevious"
              name="airportPrevious"
              [(ngModel)]="consultFileEditRPE.icaoAirportFlightPrevious"
              placeholder="Aeroporto Origem Voo Anterior"
              aria-label="Aeroporto Origem Voo Anterior"
              [matAutocomplete]="autoAirportPrev"
              [formControl]="aeroportoCtrlPrev"
              oninput="this.value = this.value.toUpperCase()">
            <mat-autocomplete #autoAirportPrev="matAutocomplete">
              <mat-option *ngFor="let aeroportoPrev of filteredAeroportoPrev | async" [value]="aeroportoPrev.codeICAO">
                <span>{{aeroportoPrev.codeICAO}} ({{aeroportoPrev.codeIATA}})</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="col-2">
          <label for="flightPrev">Aeroporto Destino:</label>
        </div>
        <div class="col-4">
          <mat-form-field
            id="matFormFieldDestiny" class="inputPadrao">
            <input matInput
              id="airportDestiny"
              name="airportDestiny"
              [(ngModel)]="consultFileEditRPE.icaoAirportDestiny"
              placeholder="Aeroporto Destino"
              aria-label="Aeroporto Destino"
              [matAutocomplete]="autoAirportDest"
              [formControl]="aeroportoCtrlDest"
              oninput="this.value = this.value.toUpperCase()">
            <mat-autocomplete #autoAirportDest="matAutocomplete">
              <mat-option *ngFor="let aeroportoDest of filteredAeroportoDest | async" [value]="aeroportoDest.codeICAO">
                <span>{{aeroportoDest.codeICAO}} ({{aeroportoDest.codeIATA}})</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <!-- [ END ] SIGLA ICAO -->


    <!-- TIPO DE ATERRISSAGEM --> <br>
    <div class="row">
      <div class="col-2">
          <label for="flight-landing">Tipo Decolagem:</label>
        </div>
        <div class="col-4">
          <mat-form-field class="inputPadrao">
            <mat-label>Tipo Decolagem</mat-label>
            <mat-select [(value)]="consultFileEditRPE.cdTypeFlightDeparture">
              <mat-option value ="">Selecione</mat-option>
              <mat-option value="0">0 - Voo Regula</mat-option>
              <mat-option value="3">3 - Voo Retorno</mat-option>
              <mat-option value="4">4 - Voo Alternado</mat-option>
              <mat-option value="6">6 - Voo Serviço</mat-option>
              <mat-option value="7">7 - Voo Fretamento</mat-option>
              <mat-option value="9">9 - Voo Charter</mat-option>
              <mat-option value="D">D - Voo Geral</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2">
          <label for="flightPrev">Tipo Pouso:</label>
        </div>
        <div class="col-4">
          <mat-form-field class="inputPadrao">
            <mat-label>Tipo Pouso</mat-label>
            <mat-select [(value)]="consultFileEditRPE.cdTypeFlightArrival">
              <mat-option value ="">Selecione</mat-option>
              <mat-option value="0">0 - Voo Regula</mat-option>
              <mat-option value="3">3 - Voo Retorno</mat-option>
              <mat-option value="4">4 - Voo Alternado</mat-option>
              <mat-option value="6">6 - Voo Serviço</mat-option>
              <mat-option value="7">7 - Voo Fretamento</mat-option>
              <mat-option value="9">9 - Voo Charter</mat-option>
              <mat-option value="D">D - Voo Geral</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- [ END ] TIPO ATERRISSAGEM -->

      <!-- TIPO DE VOO --> <br>
    <div class="row">
      <div class="col-2">
          <label for="type-flight">Tipo de Voo Decolagem:</label>
        </div>
        <div class="col-4">
          <mat-form-field
            id="matFormFieldTypeOperationDec"
            class="inputPadrao">
            <input maxlength="1"
              matInput id="typeOperationDec"
              name="typeOperationDec"
              [(ngModel)]="consultFileEditRPE.cdTypeLineFlightDeparture"
              placeholder="Tipo de Voo Decolagem"
              aria-label="Tipo de Voo Decolagem"
              [matAutocomplete]="autoTypeOperationDec"
              [formControl]="typeOperationDecCtrl"
              oninput="this.value = this.value.toUpperCase()">
            <mat-autocomplete #autoTypeOperationDec="matAutocomplete">
              <mat-option *ngFor="let typeOperationDec of filteredTypeOperationDec | async" [value]="typeOperationDec.name">
                <span>{{typeOperationDec.name}} - {{typeOperationDec.desc}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-2">
          <label for="flightPrev">Tipo de Voo Pouso:</label>
        </div>
        <div class="col-4">
          <mat-form-field
            id="matFormFieldTypeOperationPos"
            class="inputPadrao">
            <input maxlength="1"
              matInput id="typeOperationPos"
              name="typeOperationPos"
              [(ngModel)]="consultFileEditRPE.cdTypeLineFlightArrival"
              placeholder="Tipo de Voo Pouso"
              aria-label="Tipo de Voo Pouso"
              [matAutocomplete]="autoTypeOperationPos"
              [formControl]="typeOperationPosCtrl"
              oninput="this.value = this.value.toUpperCase()">
            <mat-autocomplete #autoTypeOperationPos="matAutocomplete">
              <mat-option *ngFor="let typeOperationPos of filteredTypeOperationPos | async" [value]="typeOperationPos.name">
                <span>{{typeOperationPos.name}} - {{typeOperationPos.desc}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <!-- [ END ] TIPO DE VOO -->

      <!-- CONTESTACAO DE VOO --> <br>
      <div class="row">
        <div class="col-2">
          <label for="dtDispute">Data da Contestação:</label>
        </div>
        <div class="col-4">
          <div class="input-group">
            <mat-form-field class="inputPadrao" appearance="fill">
            <input matInput [matDatepicker]="datepicker3" id="dtDispute" [(ngModel)]="dataContestacao"
              class="inputDatePicker" placeholder="dd/mm/yyyy" name="dtDispute" (click)="datepicker3.open()"/>
              <mat-datepicker-toggle matSuffix [for]="datepicker3"></mat-datepicker-toggle>
              <mat-datepicker #datepicker3></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
          <div class="col-2">
            <label for="contestation">Contestado:</label>
          </div>
          <div class="col-4">
            <mat-checkbox
              [disabled]="true"
                [checked]="checked">
            </mat-checkbox>
          </div>
      </div>
      <!-- [ END ] CONTESTACAO DE VOO -->
  </div>
</div>
  <br>
  <!-- Voltar div abaixo após entender e corrigir o formulario abaixo e comentar a que não tem o hidden-->
  <div [hidden]="this.hide" class="card text-center">
  <!-- <div class="card text-center"> -->
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <button type="button" class="btn btn-danger btnClean" (click)="cancelar()">
              Cancelar
          </button>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn-primary btnSave" (click)="validRequestDto()">
              Editar
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
