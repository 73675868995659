import { OpenDialog } from './../../../../utils/openDialog';
import { Component, ViewChild } from '@angular/core';
import { Layout } from '../../model/layout';
import { AirportService } from '../../service/airport.service';
import { LayoutService } from '../../service/layout.service';
import { ImportFileService } from '../../service/import-file.service';
import { ImportConfirmationComponent } from '../import-confirmation/import-confirmation.component';
import { TabToBeImportedComponent } from '../tab-to-be-imported/tab-to-be-imported.component';
import { TabsAndRegisters } from '../../model/tabs-and-registers';
import { TabsAndRegisterWithFile } from '../../model/tabs-and-register-with-file';
import { ComboBoxAeroporto } from '../../../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { ModalLineErrorComponent } from '../modal-line-error/modal-line-error.component';
import { ResponseImportPartialFile } from '../../model/response-import-partial-file';
import { ResponseGeneric } from '../../model/ResponseGeneric';
import { properties } from '../../../../../environments/properties';
import { DialogCustomComponent } from '../../../../app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription, interval } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ComboBoxService } from '../../../../app-datahealth/app-search-information-by-date-and-rpe/combo-box.service';
import { Protocolo } from '../../../../app-datahealth/app-enum/Protocolo';
import { ComboBoxEmpresa } from '../../../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-empresa';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-import-conciliation',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent {

  @ViewChild('file') fileAction: any;

  public aeroportoCtrl = new UntypedFormControl();
  private file: File;
  public layouts: Layout[];
  public layoutMutable: Layout[];
  public layout: number;
  public airport: string;
  public airports: ComboBoxAeroporto[];
  public registro: boolean;
  public responsePartial: ResponseImportPartialFile = new ResponseImportPartialFile();
  public airportCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredAeroportos: Observable<ComboBoxAeroporto[]>;
  public controlSave: boolean = false;
  public empresaCtrl = new UntypedFormControl();
  public empresa: ComboBoxEmpresa[];
  public filteredEmpresa: Observable<ComboBoxEmpresa[]>;
  public iataCompany: any;

  subscription: Subscription;
  intervalId: number;

  constructor(
    public comboBoxService: ComboBoxService,
    private airportService: AirportService,
    private layoutService: LayoutService,
    private importFileService: ImportFileService,
    private openDialogUtils: OpenDialog,
    private dialog: MatDialog) {

    this.airportService
      .getAllAirport()
      .subscribe(response => {
        this.airports = response;
        this.filteredAeroportos = this.airportCtrl
          .valueChanges
          .pipe(startWith(''), map(airport => {
            this.filterLayout(airport);
            return airport ? this._filterAirport(airport) : this.airports;
          }));
      });

    this.layoutService
      .getAllLayouts()
      .subscribe(response => {
        this.layouts = response.data;
        this.layoutMutable = this.layoutService.filterByAirportName(this.layouts, this.airport);
      });
      this.obterComboBoxCompany();
    this.registro = false;
  }

  private startInterval(): void{
    const source = interval(15000);
    this.subscription = source.subscribe(val => this.conciliationInProgess());
  }

  public conciliationInProgess(): void {
    if(this.controlSave){
      // console.log('new request to backend process ... ')
      this.obterComboBoxAirport();
    }else{
      clearInterval(this.intervalId);
    }
  }

  private obterComboBoxAirport(): void {
    this.comboBoxService.getComboBoxAeroporto().subscribe(
      dataSource => {
        }, err => {
          const protocolo: Protocolo = err.error;
      });
  }

  private _filterAirport(value: string): ComboBoxAeroporto[] {
    const filterValue = value.toLowerCase();

    return this.airports.filter(airport => airport.codeIATA.toLowerCase().indexOf(filterValue) === 0);

  }

  public obterComboBoxCompany(): void {
    this.comboBoxService.getComboBoxEmpresa().subscribe(
      dataSource => {
          this.empresa = dataSource;
          this.filteredEmpresa = this.empresaCtrl.valueChanges
          .pipe(
            startWith(''),
            map(empresa => empresa ? this._filterEmpresaIMP(empresa) : this.empresa.slice())
          );
        }, err => {
          const protocolo: Protocolo = err.error;
      });
  }

  private _filterEmpresaIMP(value: string): ComboBoxEmpresa[] {
    const filterValue = value.toLowerCase();
    return this.empresa.filter(empresa => empresa.iataCode.toLowerCase().indexOf(filterValue) === 0);
  }


  public isValidFile(): boolean {
    if (this.file.name.toUpperCase().endsWith('.XLS') || this.file.name.toUpperCase().endsWith('.XLSX')) {
      return true;
    }
    return false;
  }

  execute(): void {
    this.file = this.getFileFromNativeElement();
    // console.log('file import: ' + this.file);
    if (this.isValidFile()) {


      this.importFileService.uploadFilePreview(this.file, this.layout, this.airport, this.iataCompany)
        .subscribe(
          response => {
            this.modalTabs(response);
          },
          error => {
            if (error.status === 400) {
              this.openDialog(
                properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
                error.error.descricao);
            }else{
              // console.error('erro to process this request: {}', error);
              this.openDialog(
                properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
                properties.MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE);
            }

          });


    } else {
      this.openDialog(properties.MODAL_TITLE_PERIOD_SELECT_ATENCION, properties.MODAL_CONTENT_VALIDATE_TYPE_FILE_IMPORTED);
    }
  }

  modalTabs(response: ResponseGeneric): void {

    this.registro = false;

    this
      .dialog
      .open(TabToBeImportedComponent, { data: response })
      .afterClosed().subscribe(responseDialog => {

        if (responseDialog) {

          const request: TabsAndRegisterWithFile = {
            file: this.file,
            tabsToChooseCollection: response.tabsToChooses,
            layout: this.layout,
            airport: this.airport,
            company: this.iataCompany
          };

          request.tabsToChooseCollection = response.tabsToChooses.filter(tabs => tabs.check === true);

          this.registro = true;

          this.importFileService
            .uploadFilePartial(request)
            .subscribe(responsePartial => {
              this.importPartial(responsePartial, request);
            },
              error => {

                this.registro = false;

                if (error.status === 400) {

                  this.openDialog(
                    properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
                    error.error.descricao);

                } else {
                  this.dialog.open(ModalLineErrorComponent, { data: error.error.errors });
                }
              });
        }
      });
  }

  importPartial(responsePartial: ResponseGeneric, request: TabsAndRegisterWithFile) {

    this.updateReferInObjetctResponse(responsePartial);

    this.dialog.open(ImportConfirmationComponent,
      {
        data: this.responsePartial.
          tabsAndRegistersResponseDTO.
          registerConfirmationCollection
      })
      .afterClosed()
      .subscribe(nextDialog => {

        if (nextDialog !== undefined && nextDialog.bool === true) {
          this.importComplete(request);
        } else {
          this.registro = false;
        }

      });

  }

  private importComplete(request: TabsAndRegisterWithFile) {

    this.controlSave = true;
    this.startInterval();
    this.importFileService.uploadFileComplete(request).then(

      responseUpload => {
        this.registro = false;
        this.controlSave = false;
        this.openDialog(properties.MODAL_TITLE_SUCESS, properties.MODAL_BODY_SUCESS_OPERATION);
      }, error => {
        this.registro = false;
        this.controlSave = false;
        this.dialog.open(ModalLineErrorComponent, { data: error.error.errors });
      });

  }

  private updateReferInObjetctResponse(response: ResponseGeneric): void {
    this.responsePartial.tabsAndRegistersResponseDTO.tabsToChooseCollection = response.tabsToChooses;
    this.responsePartial.tabsAndRegistersResponseDTO.registerConfirmationCollection = response.registers;
  }

  private getFileFromNativeElement(): File {
    return this.fileAction.nativeElement.files[0];
  }

  public disabled(): boolean {
    return !(
      this.airport !== undefined && this.iataCompany !== undefined &&
      this.layout !== undefined) || this.registro === true;
  }

  private tableChoose(response: TabsAndRegisters) {

    const request: TabsAndRegisterWithFile = {
      file: this.file,
      tabsToChooseCollection: response.tabsToChooseCollection,
      layout: this.layout,
      airport: this.airport,
      company: this.iataCompany
    };

    this
      .dialog
      .open(TabToBeImportedComponent, { data: response.tabsToChooseCollection })
      .afterClosed().subscribe(responseDialog => {

        if (responseDialog) {

          request.tabsToChooseCollection = response
            .tabsToChooseCollection
            .filter(tabs => tabs.check === true);

          this.registro = true;
          this.importFileService.uploadFileComplete(request).then(
            responseUpload => {
              this.openDialog(
                properties.MODAL_TITLE_SUCESS,
                properties.MODAL_BODY_SUCESS_OPERATION);
              this.registro = false;
              this.controlSave = false;
          }, error => {
            this.dialog.open(ModalLineErrorComponent, { data: error.error.errors });
            this.registro = false;
            this.controlSave = false;
          });
        }
      });

  }

  private openDialog(impTitleParam: string, impMessageParam: string): void {
    this.openDialogUtils.openDialog(impTitleParam,impMessageParam)
  }

  public changeAirport(airport: any): void {
    this.airport = airport;
    this.filterLayout(this.airport);
  }

  private filterLayout(airport: any): void {
    if (this.layouts !== undefined) {
      this.layoutMutable = this.layoutService.filterByAirportName(this.layouts, airport);
    }
  }

}
