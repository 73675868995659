<br>
<div class="containerGenerate">

  <div class="card text-center">

    <div class="card-header" style="background-color: #1b0088;">
      <h5 style="color: #ffffff;">Gerar Conciliação</h5>
    </div>

    <div class="container">

        <br>
        <div class="form-row row">

            <mat-form-field
              id="matFormFieldCompany"
              class="col-4 inputGenerate">
              <input
                matInput id="company"
                name="company"
                [(ngModel)]="iataCompany"
                placeholder="Empresa"
                aria-label="Empresa"
                [matAutocomplete]="autoCompany"
                [formControl]="empresaCtrl"
                maxlength="2"
                oninput="this.value = this.value.toUpperCase()">
              <mat-autocomplete #autoCompany="matAutocomplete">
                <mat-option *ngFor="let empresa of filteredEmpresa | async" [value]="empresa.iataCode">
                  <span>{{empresa.iataCode}} - {{empresa.nameCompany}}</span>
                </mat-option>
              </mat-autocomplete>
           </mat-form-field>

            <mat-form-field class="col-4 inputGenerate" id="matFormFieldCompany">
              <mat-label>Natureza</mat-label>
              <mat-select [(value)]="typeNatureChoose" [formControl]="natureCtrl">
                <mat-option *ngFor="let nature of typeNatures" [value]="nature">
                  {{nature}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field id="matFormFieldOrigin" class="col-4 inputGenerate">
              <input matInput
                      id="origin"
                      name="origin"
                      [(ngModel)]="iataAirport"
                      placeholder="Selecione um Aeroporto"
                      aria-label="Aeroporto"
                      [matAutocomplete]="autoAirport"
                      [formControl]="aeroportoCtrl"
                      oninput="this.value = this.value.toUpperCase()"
                      maxlength="3"
                      [disabled]="controlSave">
              <mat-autocomplete #autoAirport="matAutocomplete">
                <mat-option *ngFor="let aeroporto of filteredAeroporto | async" [value]="aeroporto.codeIATA">
                  <span>{{aeroporto.codeIATA}} - {{aeroporto.descriptionName}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
        </div>

        <br>
        <div class="text-center">
            <button type="button"
                    class="btn btn-primary btnSave"
                    (click)="generatedConciliation()"
                    [disabled]="controlSave">Gerar conciliação
            </button>
        </div>

    </div>
  </div>

  <br><br>
  <div id="panelTableConciliation" class="card  text-center" style="display: none">
    <div class="card-header" style="background-color: #1b0088;">
      <h5 style="color: #ffffff;">Lista de dados divergentes</h5>
    </div>
    <div class="card-body">

      <table mat-table [dataSource]="dataSourceConciliation" class="mat-elevation-z8" style="width: 100%">

        <ng-container matColumnDef="dtOperation">
          <th mat-header-cell *matHeaderCellDef>Data de Operação</th>
          <td mat-cell *matCellDef="let con">{{ con.dtOperation }}</td>
        </ng-container>

        <ng-container matColumnDef="numFlight">
          <th mat-header-cell *matHeaderCellDef>Número do Voo</th>
          <td mat-cell *matCellDef="let con">{{ con.numFlight }}</td>
        </ng-container>

        <ng-container matColumnDef="valRateBoard">
          <th mat-header-cell *matHeaderCellDef>Valor da Taxa Emb.</th>
          <td mat-cell *matCellDef="let con">{{ con.valRateBoard }}</td>
        </ng-container>

        <ng-container matColumnDef="valRateCon">
          <th mat-header-cell *matHeaderCellDef>Valor da Taxa Conex.</th>
          <td mat-cell *matCellDef="let con">{{ con.valRateCon }}</td>
        </ng-container>

        <ng-container matColumnDef="natureFlight">
          <th mat-header-cell *matHeaderCellDef>Natureza </th>
          <td mat-cell *matCellDef="let con">{{ con.natureFlight }}</td>
        </ng-container>

        <ng-container matColumnDef="typeOperation">
          <th mat-header-cell *matHeaderCellDef>Tp. Operação</th>
          <td mat-cell *matCellDef="let con">{{ con.typeOperation }}</td>
        </ng-container>

        <ng-container matColumnDef="importedPlanEmb">
          <th mat-header-cell *matHeaderCellDef class="plan aliginText">Qtd. Plan. Emb.</th>
          <td mat-cell *matCellDef="let con" class="plan aliginText">
            {{ con.importedQtdPax.amountBoadDom != 0 ? con.importedQtdPax.amountBoadDom : con.importedQtdPax.amountBoadInt }}
         </td>
        </ng-container>

        <ng-container matColumnDef="importedPlanCon">
          <th mat-header-cell *matHeaderCellDef class="plan aliginText">Qtd. Plan. Con.</th>
          <td mat-cell *matCellDef="let con" class="plan aliginText">
            {{ con.importedQtdPax.amountConDom != 0 ? con.importedQtdPax.amountConDom :  con.importedQtdPax.amountConInt }}
          </td>
        </ng-container>

        <ng-container matColumnDef="importedGTEEmb">
            <th mat-header-cell *matHeaderCellDef class="gte aliginText">Qtd. GTE. Emb.</th>
            <td mat-cell *matCellDef="let con" class="gte aliginText">
              {{ con.gteQtdPax.amountBoadDom != 0 ? con.gteQtdPax.amountBoadDom : con.gteQtdPax.amountBoadInt}}
           </td>
          </ng-container>

          <ng-container matColumnDef="importedGTECon">
            <th mat-header-cell *matHeaderCellDef class="gte aliginText">Qtd. GTE. Con.</th>
            <td mat-cell *matCellDef="let con" class="gte aliginText">
              {{ con.gteQtdPax.amountConDom != 0 ? con.gteQtdPax.amountConDom :  con.gteQtdPax.amountConInt}}
            </td>
          </ng-container>

        <ng-container matColumnDef="diffQtdPaxBoard">
          <th mat-header-cell *matHeaderCellDef class="diff aliginText">Diff. Embarque</th>
          <td mat-cell *matCellDef="let con" class="diff aliginText">{{ con.diffQtdPaxBoard }}</td>
        </ng-container>


        <ng-container matColumnDef="diffQtdPaxConection">
          <th mat-header-cell *matHeaderCellDef class="diff aliginText">Diff. Conexão</th>
          <td mat-cell *matCellDef="let con" class="diff aliginText">{{ con.diffQtdPaxConection }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[15, 25, 50, 100]" [showFirstLastButtons]="true">
      </mat-paginator>
    </div>

    <br>
    <div>
        <button mat-raised-button (click)="exportdocFile()" class="btn btn-primary btnSave" style="background-color: #1b0088;border-color:#1b0088; color: #FFF">
          <mat-icon>get_app</mat-icon>
          Exportar Excel
        </button>
    </div>
    <br><br>
  </div>

</div>
