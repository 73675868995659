import { environment } from './environment';

const datahealth = `${environment.host}/be-gcp-data-health`;
//const datahealth = `http://localhost:8081/api/be-gcp-data-health`;
const conciliation = `${environment.host}/be-gcp-conciliation`;
//const conciliation = `http://localhost:8083/be-gcp-conciliation`;
const gte = `${environment.host}/be-gcp-gte`;
//const gte = `http://localhost:8085/be-gcp-gte`;
const robots = `${environment.robotsHost}/robots`;

export const endpoints = {

    VERSION: require('../../package.json').version,
    REST_POST_ADD_CONTROLE_CHAMADO: `${datahealth}/rpe/ticket-control`,
    REST_POST_ALT_CONTROLE_CHAMADO: `${datahealth}/rpe/ticket-control`,
    REST_GET_TOTALIZADOR_WORK_AROUND: `${datahealth}/rpe/workarounds/amount`,
    REST_GET_DADOS_VOOS: `${datahealth}/rpe/not-generated`,
    REST_GET_TOTALIZADOR_RPE_GERADO: `${datahealth}/rpe/generated/amount`,
    REST_POST_RPE_NOT_GENERATE_BY_DATE: `${datahealth}/rpe/qtd-rpe`,
    REST_POST_RPE_NOT_GENERATE_GROUP_BY_TP_ERROR: `${datahealth}/rpe/origin-error`,
    REST_POST_RPE_NOT_GENERATE_GROUP_BY_SYS_ORI: `${datahealth}/rpe/origin-error/qtd-rpe`,
    REST_GET_LIST_VALOR_MULTA: `${datahealth}/rpe/not-generated/list/value-penalty`,
    REST_GET_VALOR_MULTA: `${datahealth}/rpe/not-generated/amount/value-penalty`,
    REST_GET_RPES_NAO_GERADOS_CRITICO: `${datahealth}/rpe/not-generated/list?type=before-24-hours`,
    REST_GET_RPES_NAO_GERADOS_INTERMEDIARIO: `${datahealth}/rpe/not-generated/list?type=between-24-and-48-hours`,
    REST_GET_RPES_NAO_GERADOS_INFORMATIVO: `${datahealth}/rpe/not-generated/list?type=after-48-hours`,
    REST_GET_INDICADOR_CRITICO: `${datahealth}/rpe/not-generated/amount?type=before-24-hours`,
    REST_GET_INDICADOR_INTERMEDIARIO: `${datahealth}/rpe/not-generated/amount?type=between-24-and-48-hours`,
    REST_GET_INDICADOR_INFORMATIVO: `${datahealth}/rpe/not-generated/amount?type=after-48-hours`,
    REST_GET_TOTALIZADOR_RPE_NAO_GERADO: `${datahealth}/rpe/not-generated/amount`,
    REST_POST_DADOS_RPE_GERADO_LISTA: `${datahealth}/resume-pax-emb/flight/`,
    REST_POST_DADOS_RPE_GERADO_LISTA_DATA: `${datahealth}/resume-pax-emb/generated/flight/`,
    REST_PUT_ALT_RPE_GERADO: `${datahealth}/resume-pax-emb/{id}/{email}`,
    REST_PUT_ALT_RPE_GERADO_FILE: `${gte}/flight/rpe/{id}/id`,
    REST_POST_DATA_CONTESTED_RPES: `${datahealth}/resume-pax-emb/flight/date-flight`,
    REST_GET_CONTESTED_AMOUNT_TYPE_BY_PERIOD: `${datahealth}/contestation-control/amount/grouped`,
    REST_GET_LIST_CONTESTATION: `${datahealth}/contestation-control`,
    REST_POST_EXCEL_FILE: `${datahealth}/contestation-control/import`,
    REST_GET_COMBO_BOX_COMPANY: `${datahealth}/airlines`,
    REST_GET_TEMPLATES_ALL: `${conciliation}/template`,
    REST_OPERATION_TEMPLATE: `${conciliation}/template`,
    REST_GET_TEMPLATES_ALL_ACTIVE: `${conciliation}/template/active`,
    REST_GET_CONCILIATION_FILE: `${conciliation}/conciliation/conciliation?`,
    REST_GET_IMPORT_FILE: `${conciliation}/import?iataAirport={iataAirport}&dtInit={dtInit}&dtEnd={dtEnd}`,
    REST_DELETE_IMPORT_FILE: `${conciliation}/import/{id}`,
    REST_POST_PARCIAL_EXCEL_FILE: `${conciliation}/import/partial/{layout}?airport={airport}&company={company}&tabs=`,
    REST_POST_COMPLETE_EXCEL_FILE: `${conciliation}/import/{layout}?airport={airport}&company={company}&tabs=`,
    REST_GET_AIPORT_TAX: `${conciliation}/airports/tax`,
    REST_GET_EXPORTED_CONCILIATION: `${conciliation}/conciliation/export`,
    REST_POST_PREVIEW_EXCEL_FILE: `${conciliation}/import/preview/{layout}?airport={airport}&company={company}`,
    REST_GET_DASHBOARD_CATEGORIZED: `${conciliation}/dashboard/conciliation/categorized`,
    REST_GET_DASHBOARD_INFORMATION: `${conciliation}/dashboard/conciliation`,
    REST_GET_DASHBOARD_INFORMATION_SPECIFIC: `${conciliation}/dashboard/conciliation/categorized/year/{year}/month/{month}`,
    // Workaround
    REST_GET_WORKAROUND_BY_PERIOD: `${datahealth}/rpe/workarounds/flight/amount`,
    REST_GET_WORKAROUND_PIZZA: `${datahealth}/rpe/workarounds`,
    REST_GET_WORKAROUND_COLUMN: `${datahealth}/rpe/workarounds/origin`,
    REST_GET_WORKAROUND_LIST: `${datahealth}/rpe/workarounds/generated`,
    // RpeGerados
    REST_GET_RPE_GENERATED_X_TOTAL_FLIGHTS: `${datahealth}/rpe/generated/qtd-rpe`,
    REST_GET_RPE_GENERATED_PIZZA: `${datahealth}/rpe/generated/type`,
    REST_GET_RPE_GENERATED_COLUMN: `${datahealth}/rpe/generated/airport/type-flight`,
    REST_GET_RPE_GENERATED_LIST: `${datahealth}/rpe/generated`,
    // Selos
    REST_GET_STAMP_GENERATED_X_NOT_GENERATED: `${datahealth}/error-stamp/qtd-stamp`,
    REST_GET_STAMP_GROUP_BY_ORIGIN_SYSTEM: `${datahealth}/error-stamp/origin`,
    REST_GET_ERROR_STAMP_LIST: `${datahealth}/error-stamp/not-generated`,
    // Parameter
    REST_GET_PARAMETER_VALUE: `${datahealth}/parameters`,
    REST_PUT_PARAMETER: `${datahealth}/parameters/maintenance`,
    // PROJETO MIGRACAO - [ GTE Cloud ]
    REST_GTECLOUD_GET_AIRPORTS: `${gte}/airports`,
    REST_GTECLOUD_GET_ADMINISTRATORS_AIRPORTS: `${gte}/administrator`,
    REST_GTECLOUD_GET_COMPANY: `${gte}/company`,
    REST_GTECLOUD_COMPLEMENT: `${gte}/{value}/{identification}`,
    REST_GTECLOUD_COMPLEMENT_ID: `/{id}/id`,
    REST_GTECLOUD_GET_REPORT_RESUME_BOARDING: `${gte}/report/resume-boarding`,
    REST_GTECLOUD_GET_REPORT_RESUME_PAX_BOARDING: `${gte}/report/resume-pax-boarding`,
    REST_GTECLOUD_GET_REPORT_ANAC_BAGAGE: `${gte}/report/anac-bagage`,
    REST_GTECLOUD_GET_REPORT_ANAC_PNAE: `${gte}/report/anac-pnae`,
    REST_GTECLOUD_GET_REPORT_ANAC_INSPAC: `${gte}/report/anac-inspac`,
    REST_GTECLUOD_GET_FLIGHT_NOT_GENERATED: `${gte}/flight/{idflight}?airlineCode={airlineCode}&airport={airport}&dtFlightEnd={dtFlightEnd}&dtFlightStart={dtFlightStart}&typeReport={typeReport}`,
    REST_GTECLUOD_GET_REPORT_LIST_PAX: `${gte}/report/list-pax?company={company}&dest={dest}&dtFlight={dtFlight}&isFull={isFull}&numFlight={numFlight}&origin={origin}&nature={nature}`,
    REST_GTECLUOD_CREATED_FILES: `${gte}/flight`,
    REST_GTECLOUD_GET_REPORT_SUMMARY_RPE: `${gte}/rpe/report`,
    REST_GTECLOUD_GET_RESUME_RPE: `${gte}/rpe/{id}/file/resume`,
    REST_GTECLOUD_GET_RESUME_STAMP: `${gte}/stamp/{id}/file/resume`,
    REST_GTECLOUD_GET_RPE_FILE: `${gte}/rpe/{id}/file`,
    REST_GTECLOUD_GET_RPE_FILE_LIST: `${gte}/flight/rpe/ids`,
    REST_GTECLOUD_GET_STAMP_FILE_LIST: `${gte}/flight/stamp/ids`,
    REST_GTECLOUD_GET_USER_VALIDATE_EMAIL: `${gte}/user/validate/{emailUser}`,
    REST_GTECLOUD_GET_ALL_USERS_PROFILE: `${gte}/user`,
    REST_GTECLOUD_GET_ALL_PROFILES: `${gte}/profile`,
    REST_GTECLOUD_SAVE_USERS_PROFILE:`${gte}/user`,
    REST_GTECLOUD_VALIDATE_FUNCTIONALITY:`${gte}/functionality/{emailUser}/{funcAccess}`,
    REST_GTECLOUD_GET_FLIGHT_TO_DELETE:`${gte}/file/consult`,
    REST_GTECLOUD_POST_FLIGHT_DELETE:`${gte}/file/bulkdelete`,
    REST_GTECLOUD_DELETE_FILE_RPE_SELO:`${gte}/file/delete/{id}/{user}/{reason}`,
    REST_GTECLOUD_LOGS_DELETED_FILE_RPE_SELO:`${gte}/file/logs/{email}`,
    REST_GET_COMBO_BOX_TYPE_OPERATION: `${gte}/type-operation`,
    ROBOTS_GERACAO_MANUAL: `${robots}`
};
