import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-third-level-dashboard',
  templateUrl: './app-third-level-dashboard.component.html',
  styleUrls: ['./app-third-level-dashboard.component.css']
})
export class ThirdLevelDashboardComponent implements OnInit {

  titleCharOriginProblem = 'Sistema de origem';
  titleCharProblem = 'Identificação do problema';

  constructor() { }

  ngOnInit() {
  }

}
