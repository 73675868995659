<br>
<form>

  <div class="card text-center">
    <div class="card-header" style="background-color: #1b0088;">
      <h5 style="color: #ffffff;">Manutenção de Tarifa</h5>
    </div>
    <div class="card-body">
      <div class="row justify-content-center">
        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>Aeroporto</mat-label>

            <input matInput id="airport" name="airport" [(ngModel)]="airportChoose" placeholder="Aeroporto"
              aria-label="Aeroporto" [formControl]="airportCtrl" [matAutocomplete]="autoAeroporto"
              oninput="this.value = this.value.toUpperCase()">

            <mat-autocomplete #autoAeroporto="matAutocomplete">
              <mat-option *ngFor="let airport of filteredAeroportos  | async" [value]="airport.codeIATA">
                {{airport.codeIATA}} - {{airport.descriptionName}}
              </mat-option>
            </mat-autocomplete>

          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>Natureza</mat-label>
            <mat-select [(value)]="typeNatureChoose">
              <mat-option *ngFor="let nature of typeNatures" [value]="nature">
                {{nature}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="col-sm-4">

          <mat-form-field>
            <mat-label>Categoria</mat-label>
            <mat-select [(value)]="categoryChoose">
              <mat-option *ngFor="let category of categories" [value]="category">
                {{category}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <br>

        <div class="col-sm-6">
          <div class="input-group">
            <mat-form-field class="inputDate">
              <mat-label>INICIO VIGÊNCIA</mat-label>
              <input matInput id="dtInicial" [matDatepicker]="datepickerInicio" [(ngModel)]="dtInicial"
              class="form-control" placeholder="dd/mm/yyyy" name="dp" maxlength="10" (click)="datepickerInicio.open()">
              <mat-hint>dd/mm/yyyy</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="datepickerInicio"></mat-datepicker-toggle>
              <mat-datepicker #datepickerInicio></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="col-sm-6">

          <div class="input-group">
            <mat-form-field class="inputDate">
              <mat-label>FIM VIGÊNCIA</mat-label>
              <input matInput id="dtFinal" [matDatepicker]="datepickerFinal" [(ngModel)]="dtFinal"
              class="form-control" placeholder="dd/mm/yyyy" name="dtFinal" maxlength="10" (click)="datepickerFinal.open()">
              <mat-hint>dd/mm/yyyy</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="datepickerFinal"></mat-datepicker-toggle>
              <mat-datepicker #datepickerFinal></mat-datepicker>
            </mat-form-field>
          </div>

        </div>
      </div>

      <br>
      <div class="row justify-content-center">
        <div class="col-sm-3">
          <button
            matInput mat-raised-button
            color="primary"
            class="button-pesquisa"
            style="background-color: #1b0088;border-color:#1b0088; color: #FFF;"
            (click)="eventoPesquisa()">
            <mat-icon>search</mat-icon> Pesquisar</button>
        </div>
        <div class="col-sm-3">
          <button
            matInput mat-raised-button
            class="button-pesquisa"
            style="background-color: #ed1650; border-color:#ed1650; color: #FFF;"
            (click)="eventClear()"><mat-icon>delete</mat-icon> Limpar</button>
        </div>
        <div class="col-sm-3">
          <button
            matInput mat-raised-button
            class="button-pesquisa"
            style="background-color: #1b0088;border-color:#1b0088; color: #FFF;"
            (click)="includeAirportTax()">
            <mat-icon>add</mat-icon> Incluir
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<br>
<div class="card text-center">
  <div class="card-header">
    Lista de Tarifas
  </div>
  <div class="card-body">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0">

      <ng-container matColumnDef="Aeroporto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>AEROPORTO</th>
        <td mat-cell *matCellDef="let v">{{ v.iataAirport }}</td>
      </ng-container>

      <ng-container matColumnDef="Natureza">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>NATUREZA</th>
        <td mat-cell *matCellDef="let v">{{ v.typeNature}}</td>
      </ng-container>

      <ng-container matColumnDef="Categoria">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>CATEGORIA</th>
        <td mat-cell *matCellDef="let v">{{ v.airportCategory}}</td>
      </ng-container>

      <ng-container matColumnDef="TTBS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>CÓDIGO TTBS</th>
        <td mat-cell *matCellDef="let v">{{ v.codeTtbs}}</td>
      </ng-container>

      <ng-container matColumnDef="TIPO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TIPO DE EMBARQUE</th>
        <td mat-cell *matCellDef="let v">{{ v.departureType}}</td>
      </ng-container>

      <ng-container matColumnDef="Tarifa">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TARIFA</th>
        <td mat-cell *matCellDef="let v">{{ v.valueTax | mascaraDinheiro }}</td>
      </ng-container>

      <ng-container matColumnDef="Inicio">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>INÍCIO VIGÊNCIA</th>
        <td mat-cell *matCellDef="let v">{{ v.dtStart}}</td>
      </ng-container>

      <ng-container matColumnDef="Fim">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>FIM VIGÊNCIA</th>
        <td mat-cell *matCellDef="let v">{{ v.dtEnd}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" [showFirstLastButtons]="true"></mat-paginator>
  </div>
</div>
