<div class="row card">
  <div class="text-center col-12">
    <div class="card-header">
      Lista de RPE's que apresentaram erro durante o processamento
    </div>
    <div class="card-body">

      <mat-form-field style="width: 100%;">
        <input matInput (keyup)="applyFilter($event.target.value)"
          placeholder="Pesquisa por do número do Voo ou pela Origem do Voo" />
      </mat-form-field>

      <ng-container *ngFor="let column of displayedColumnsDtFig" matColumnDef="{{column}}">
        <mat-header-cell *matHeaderCellDef>
          <span mat-sort-header>{{column | uppercase}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row[column]}}</mat-cell>
      </ng-container>

      <table class="mat-elevation-z8" mat-table [dataSource]="dataSourceMutavel">

        <ng-container matColumnDef="checked">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox [value]="true" [disabled]="isDisabled" aria-label="Select All" [checked]="allSelected"
              [indeterminate]="indeterminate" (change)="toggleSelectAll($event)">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let v">
            <mat-checkbox [(ngModel)]="v.checked" (change)="filtrarNumeroTicket(v)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="iataComp">
          <th mat-header-cell *matHeaderCellDef>Companhia Aerea</th>
          <td mat-cell *matCellDef="let v">{{ v.airlineCodeRPE }}</td>
        </ng-container>
        <ng-container matColumnDef="numeroVoo">
          <th mat-header-cell *matHeaderCellDef>Número Voo</th>
          <td mat-cell *matCellDef="let v">{{ v.flightNumberRPE }}</td>
        </ng-container>
        <ng-container matColumnDef="dataVoo">
          <th mat-header-cell *matHeaderCellDef>Data e Hora do Voo</th>
          <td mat-cell *matCellDef="let v">{{ formatDateHour(v.flightDateRPE) }}</td>
        </ng-container>
        <ng-container matColumnDef="dataGeracao">
          <th mat-header-cell *matHeaderCellDef>Data Erro</th>
          <td mat-cell *matCellDef="let v">
            {{ getFormatDateToStringIso(v.errorGenerationDateRPE) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="tipoErro">
          <th mat-header-cell *matHeaderCellDef>Tipo Erro</th>
          <td mat-cell [matTooltip]="v.errorDescriptionRPE" *matCellDef="let v">
            {{ v.errorTypeRPE | truncate: 10 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="tipoOrigem">
          <th mat-header-cell *matHeaderCellDef>Origem</th>
          <td mat-cell *matCellDef="let v">{{ v.originAirportCodeRPE }}</td>
        </ng-container>
        <ng-container matColumnDef="dataEnvioRpe">
          <th mat-header-cell *matHeaderCellDef>Data Abertura Ticket</th>
          <td mat-cell *matCellDef="let v">
            {{ v.ticketControl != null ? getFormatDateToStringIso(v.ticketControl.dtTckStart) : '' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="dataFimAjuste">
          <th mat-header-cell *matHeaderCellDef>Data Encerramento Ticket</th>
          <td mat-cell *matCellDef="let v">
            {{ v.ticketControl != null ? getFormatDateToStringIso(v.ticketControl.dtTckEnd) : '' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ticket">
          <th mat-header-cell *matHeaderCellDef>Ticket</th>
          <td mat-cell *matCellDef="let v">{{ v.ticketControl != null ? v.ticketControl.numTck : '' }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsDtFig"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsDtFig"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>

      <br />

      <button mat-raised-button (click)="exportAsXLSX()"
        style="background-color: #1b0088;border-color:#1b0088; color: #FFF; float: left;">
        <mat-icon>get_app</mat-icon>
        Exportar Excel
      </button>

      <button mat-raised-button (click)="openDialog()" style="float: left; margin-left: 1%;">
        <mat-icon>add</mat-icon> Abrir Chamado
      </button>

      <button mat-raised-button style="background-color: #1b0088;border-color:#1b0088; color: #FFF; float: right;"
        routerLink="/dashboardOne">
        <mat-icon>arrow_back_ios</mat-icon>
        Voltar
      </button>
      <br>
    </div>
  </div>
