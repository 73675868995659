import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../app-authenticator/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private authenticationService: AuthenticationService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    if (request.url.includes('app.config.json')) {
      return next.handle(request);
    }
    //const token:any = this.authenticationService.getToken();

    //const tokenType = this.authenticationService.getTokenType();

    // let headers = {};
    // headers['Authorization'] = `Bearer ${token}`;
    // headers[AuthenticationService.ACCESS_TOKEN_TYPE_HEADER] = 'accessType';
    // headers['Accept'] = 'application/json';
    let contentType = 'application/json'

    let headers = new HttpHeaders();
    if (request.url.includes("import/preview")) {
      contentType = 'multipart/form-data'
      headers = new HttpHeaders({
        Authorization: 'Bearer ' + sessionStorage.getItem('access_token') ?? this.cookieService.get('acme-user-token'),
      });
    } else if(request.url.includes("import/partial")){
      contentType = 'multipart/form-data'
      headers = new HttpHeaders({
        Authorization: 'Bearer ' + sessionStorage.getItem('access_token') ?? this.cookieService.get('acme-user-token'),
      });
    }else{
      headers = new HttpHeaders({
        'Content-Type': contentType,
        Accept: 'application/json, application/octet-stream',
        //security Dont Remove it
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'X-Frame-Options': 'sameorigin',
        'X-XSS-Protection': '1',
        Authorization: 'Bearer ' + sessionStorage.getItem('access_token') ?? this.cookieService.get('acme-user-token'),
      });
    }
    if (this.cookieService.get('acme-user-token') !== null && 'accessType' !== null) {

      request = request.clone({
        headers
      });

    }

    //console.log("intercept headers: {}", headers);
    //console.log("intercept request: {}", request);
    next.handle(request)

    return next.handle(request);

  }
}
