import { Component, OnInit } from '@angular/core';
import { DashboardInformationService } from '../../service/dashboard-information.service';
import { DashboardInformation } from '../../model/dashboard-information';
import { DateService } from '../../service/date.service';
import { DashboardTypeFilterService } from '../../service/dashboard-type-filter.service';

@Component({
  selector: 'app-conciliation-dashboard',
  templateUrl: './conciliation-dashboard.component.html',
  styleUrls: ['./conciliation-dashboard.component.css']
})
export class ConciliationDashboardComponent implements OnInit {

  dashboardInformation: DashboardInformation;

  public types: string[];

  public typeDashboard: string;

  constructor(
    dashboardInformationService: DashboardInformationService,
    private dateService: DateService,
    dashboardTypeFilter: DashboardTypeFilterService) {

    this.types = dashboardTypeFilter.getTypeFilter();

    this.typeDashboard = this.types[0];

    dashboardInformationService
      .getAllDashboardInformationCategorized()
      .subscribe(dashboard => {
        this.dashboardInformation = dashboard;
        this.dashboardInformation
          .lastTwelveMonths
          .map(conciliation => {
            conciliation.dtFlight = this.dateService.stringToDate(conciliation.dtFlight);
            return conciliation;
          });
        this.dashboardInformation
          .lastYear
          .map(conciliation => {
            conciliation.dtFlight = this.dateService.stringToDate(conciliation.dtFlight);
            return conciliation;
          });
      });
  }

  ngOnInit() {
  }

}
