import { DataChar } from './model/DataChar';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { DashboardlineRQ } from './model/DashboardlineRQ';
import { DashboardlineRS } from './model/DashboardlineRS';
import { Protocolo } from '../app-enum/Protocolo';
import { DashboardLineService } from './app-dashboard-line.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Periodo } from '../app-search-information-by-period/Periodo';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { properties } from '../../../environments/properties';
import { DialogCustomComponent } from '../app-dialog-custom/app-dialog-custom.component';
import { MatDialog } from '@angular/material/dialog';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-dashboard-line',
  templateUrl: './app-dashboard-line.component.html',
  styleUrls: ['./app-dashboard-line.component.css']
})

export class DashboardLineComponent implements OnInit {

  @Input() identification: string;
  @Input() events: Observable<Periodo>;
  private eventsSubscription: any;

  @Output() dateSelectDashOne = new EventEmitter<NgbDateStruct>();

  public request: DashboardlineRQ;
  public response: DashboardlineRS[];
  public indexSelectColun;
  public lineChartData: any[] = [{ data: [], label: '' }];
  public lineChartLabels: any[] = [];

  public lineChartOptions: (any & { annotation: any }) = {
    responsive: true,
    legend: {
      position: 'top',
    },
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'naoGerado',
          position: 'left',
        },
      ]
    },
    annotation: {},
    title: {
      display: true,
      text: 'RPE\'s não gerados'
    }
  };

  public lineChartColorsDashLine: any[] = [];
  public lineChartLegendDashLine = true;
  public lineChartTypeDashLine = 'line';
  public lineChartPluginsDashLine = [pluginAnnotations];

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  constructor(

    private router: Router,
    private dashboardLineService: DashboardLineService,
    public dialog: MatDialog) {
    this.request = new DashboardlineRQ();
    this.response = new Array<DashboardlineRS>();
    this.request.dtInicio = this.getFormatDateToRqDashLine(this.getActualStartDateToFirstApresentationDasLi());
    this.request.dtFim =  this.getFormatDateToRqDashLine(this.getActualEndDateToFirstApresentationLine());
    this.getDataDashboardLine(null);

  }

  public chartClickedLine(e: any): void {
    if (e.active.length > 0) {
      this.indexSelectColun = e.active[0]._index;
      //console.log('[ INFO ] ' + ' [ SELECT LABEL ] [ 1 ] ' + this.lineChartLabels[this.indexSelectColun]);
      this.router.navigate(['dashboardTwo', this.lineChartLabels[this.indexSelectColun]]);
    }
  }

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe((periodo) => this.getDataDashboardLine(periodo));
  }

    private getDataToCharLine(data: DataChar): void {
      this.lineChartData = [{ data: [], label: '' }];
      data.label = 'RPE Não gerados';
      this.lineChartData.splice(0, this.lineChartData.length);
      this.lineChartData.push(data);
      this.setColorsLineDashLine();
    }

  /**
   * FUNCAO PARA FORMATAR A DATA APRESENTADA NA LEGENDA DOS GRAFICOS
   * @param dt
   */
  private getFormatDateLine(dt: string): string {
    return dt.substring(8, 10) + '/' + dt.substring(5, 7) + '/' + dt.substring(0, 4) ;
  }

  private setColorsLineDashLine(): void {
    this.lineChartColorsDashLine = [
        {
          backgroundColor: 'rgba(224,255,255,0.5)',
          borderColor: 'rgba(27,0,136,1)',
          pointBackgroundColor: 'rgb(245,245,245)',
          pointRadius: 10,
          pointHoverRadius: 10,
          pointStyle: 'circle',
        },
        {
          backgroundColor: 'rgba(224,255,255,0.5)',
          borderColor: 'rgba(100,0,136,1)',
          pointBackgroundColor: 'rgb(245,245,245)',
          pointRadius: 10,
          pointHoverRadius: 10,
          pointStyle: 'circle',
        }
    ];
  }

  private getActualEndDateToFirstApresentationLine(): Date {
    return new Date();
  }

  private getActualStartDateToFirstApresentationDasLi(): Date {
    const date: Date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  }

  private getDataDashboardLine(periodoDshLi: Periodo): void {

    if (periodoDshLi != undefined || periodoDshLi != null) {
      this.request.dtInicio = periodoDshLi.getDtInicio();
      this.request.dtFim = periodoDshLi.getDtFim();
    }

    let rsDsLi: DashboardlineRS[];
    this.dashboardLineService.getDataByDate(this.request).subscribe(
      data => {
        rsDsLi = data;
        //console.log(rs);
      },
      err => {
        const protocolo: Protocolo = err.error;
        //console.log(protocolo);
        this.setDashBoardNullLine();
        this.openDialogLine(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
              properties.MODAL_CONTENT_DASHBOARD_ERROR_DATA_NOT_FOUND
        );
      },
      () => {
        const dataChar = new DataChar();
        const arrayData = new Array();

        /**
         * ORDENADO RESPOSTA PELA DATA DO VOO
         */
        rsDsLi.sort(function(a, b) {
          return a.dtVoo < b.dtVoo ? -1 : a.dtVoo > b.dtVoo ? 1 : 0;
        });

        this.lineChartLabels = [];
        for (const value of rsDsLi) {
          this.lineChartLabels.push(this.getFormatDateLine(value.dtVoo));
          arrayData.push(value.qntRpe);
        }

        dataChar.data = arrayData;
        this.getDataToCharLine(dataChar);
      }
    );
  }

  private setDashBoardNullLine(): void {
    this.indexSelectColun = null;
    this.lineChartData =  [{ data: [], label: '' }];
    this.lineChartLabels = [];
  }

  public openDialogLine(titleParam: string, messageParam: string): void {
    const dialogRef = this.dialog.open(DialogCustomComponent, {
        width: '450px',
        data: {title: titleParam, message: messageParam}
    });
  }

  private getFormatDateToRqDashLine(date: Date): string {
    return date.getFullYear()
      + '-' + (date.getMonth() + 1 )
        + '-' +  date.getDate();
  }

}
