<div class="internal-container">

  <table class="mat-elevation-z8" mat-table [dataSource]="dataSourceImutavel">

    <ng-container matColumnDef="idRpe">
      <th mat-header-cell *matHeaderCellDef>ID RPE</th>
      <td mat-cell *matCellDef="let v">{{ v.idRpe }}</td>
    </ng-container>
    <ng-container matColumnDef="empiata">
      <th mat-header-cell *matHeaderCellDef>Comp. Aérea</th>
      <td mat-cell *matCellDef="let v">{{ v.empiata }}</td>
    </ng-container>
    <ng-container matColumnDef="cdNumVoo">
      <th mat-header-cell *matHeaderCellDef>Num. Voo</th>
      <td mat-cell *matCellDef="let v">{{ v.cdNumVoo }}</td>
    </ng-container>
    <ng-container matColumnDef="dtVoo">
      <th mat-header-cell *matHeaderCellDef>Data do Voo</th>
      <td mat-cell *matCellDef="let v">{{ formatDateHour(v.dtVoo) }}</td>
    </ng-container>
    <ng-container matColumnDef="dtGeneratedRpe">
      <th mat-header-cell *matHeaderCellDef>Data de Geração</th>
      <td mat-cell *matCellDef="let v">{{ formatDateHour(v.dtGeneratedRpe) }}</td>
    </ng-container>
    <ng-container matColumnDef="sisOrigin">
      <th mat-header-cell *matHeaderCellDef>Origem</th>
      <td mat-cell *matCellDef="let v">{{ v.sisOrigin }}</td>
    </ng-container>
    <ng-container matColumnDef="destAirport">
      <th mat-header-cell *matHeaderCellDef>Destino</th>
      <td mat-cell *matCellDef="let v">{{ v.destAirport }}</td>
    </ng-container>
    <ng-container matColumnDef="tipo">
      <th mat-header-cell *matHeaderCellDef>Tipo Voo</th>
      <td mat-cell *matCellDef="let v">{{ v.tipo == 'P' ? 'Pouso' : 'Decolagem'}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsDtRpG"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsDtRpG"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[10, 25, 50]"
    showFirstLastButtons
  ></mat-paginator>

  <br />

  <div>
    <button mat-raised-button (click)="exportAsXLSX()" style="background-color: #1b0088;border-color:#1b0088; color: #FFF">
      <mat-icon>get_app</mat-icon>
      Exportar Excel
    </button>
  </div>

  </div>
