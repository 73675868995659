import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FlightLeg } from '../models/flight-leg';
import { endpoints } from '../../../../environments/endpoint';
import { ConsultFormFlightLeg } from '../models/consult-form-flight-leg';
import { DateService } from '../../../app-conciliation/conciliation/service/date.service';
import { RpeInfo } from '../models/RpeInfo';
import { StampInfo } from '../models/StampInfo';
import { StampIds } from '../models/StampIds';
import * as moment from 'moment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'text/plain'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SummaryRpeService {
  constructor(private http: HttpClient, private dateService: DateService) { }

  public getReportSummaryByFilterOnFlightLeg(consultFormFlightLeg: ConsultFormFlightLeg, typeFlightLeg: string): Observable<FlightLeg[]> {

    const url: string = endpoints.REST_GTECLOUD_GET_REPORT_SUMMARY_RPE;

    const airport = consultFormFlightLeg.airport;
    const company = consultFormFlightLeg.company;
    //const flightEndDate = this.dateService.ngbDateToStringWithTime(consultFormFlightLeg.flightEndDate);
    //const flightStartDate = this.dateService.ngbDateToStringWithTime(consultFormFlightLeg.flightStartDate);
    const flightEndDate = moment(consultFormFlightLeg.flightEndDate).format('YYYY-MM-DD') + 'T03:00:00';
    const flightStartDate = moment(consultFormFlightLeg.flightStartDate).format('YYYY-MM-DD') + 'T03:00:00';


    const numFlightArrival = consultFormFlightLeg.numFlightArrival;
    const numFlightDeparture = consultFormFlightLeg.numFlightDeparture;

    let parameters = `?airport=${airport}` +
      `&company=${company}` +
      `&flightEndDate=${flightEndDate}` +
      `&flightStartDate=${flightStartDate}` +
      `&type=${typeFlightLeg}`;

    if (numFlightArrival !== null) {
      parameters = parameters + `&numFlightArrival=${numFlightArrival}`;
    }

    if (numFlightDeparture !== null) {
      parameters = parameters + `&numFlightDeparture=${numFlightDeparture}`;
    }

    return this.http.get<FlightLeg[]>(url.concat(parameters));

  }

  public getRpeInfo(idFlightLeg: number): Observable<RpeInfo> {
    const url: string = endpoints.REST_GTECLOUD_GET_RESUME_RPE.replace('{id}', idFlightLeg.toString());
    return this.http.get<RpeInfo>(url);
  }

  public getStampInfo(idFlightLeg: number): Observable<StampInfo[]> {
    const url: string = endpoints.REST_GTECLOUD_GET_RESUME_STAMP.replace('{id}', idFlightLeg.toString());
    return this.http.get<StampInfo[]>(url);
  }

  public getFileRPEtoDownloadList(idRPE: number[], icaoAirport: string, icaoCompany: string, fileType: string): Observable<Blob> {

    let rq: any = new Object();
    rq.airport = icaoAirport;
    rq.company = icaoCompany;
    rq.listFlightToProcess = idRPE;
    rq.fileType = fileType;

    return this.http.post(endpoints.REST_GTECLOUD_GET_RPE_FILE_LIST, rq, {
      responseType: 'blob'
    });
  }

  public getFileStamptoDownloadList(stamps: StampIds): Observable<Blob> {
    console.log(JSON.stringify(stamps));
    return this.http.post(endpoints.REST_GTECLOUD_GET_STAMP_FILE_LIST, stamps, {
      responseType: 'blob'
    });
  }

  public getFileRPEtoDownloadListPax(flightLeg: FlightLeg, isFull: boolean): Observable<Blob> {

    let url = endpoints.REST_GTECLUOD_GET_REPORT_LIST_PAX;
    url = url.replace('{company}', flightLeg.company);
    url = url.replace('{origin}', flightLeg.iataAirportOrigin);
    url = url.replace('{dest}', flightLeg.iataAirportDest);
    url = url.replace('{dtFlight}', this.dateService.dateStringNoHour(flightLeg.dateFlightDeparture));
    url = url.replace('{numFlight}', flightLeg.numFlightDeparture.toString());
    url = url.replace('{isFull}', isFull ? 'true' : 'false');
    url = url.replace('{nature}', flightLeg.typeNature);

    return this.http.get(url, {
      responseType: 'blob'
    });

  }

}
