export class ListContestationExcel {

     idControlDispute: number;
     fkRpe: number;
     cdCompAerea: string;
     cdNumVoo: string;
     dtFlight: string;
     originAirport: string;
     destAirport: string;
     typeFlightMovement: string;
     dtDispute: any;
     typeDispute: string;
     name: string;
     dtAction: any;

     constructor(){
        this.idControlDispute = undefined;
        this.fkRpe = undefined;
        this.cdCompAerea = undefined;
        this.cdNumVoo = undefined;
        this.dtFlight = undefined;
        this.originAirport = undefined;
        this.destAirport = undefined;
        this.typeFlightMovement = undefined;
        this.dtDispute = undefined;
        this.typeDispute = undefined;
        this.name = undefined;
        this.dtAction = undefined;
     }

}