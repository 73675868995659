import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup } from '@angular/forms';

export class ConsultFormFlightLeg {

    flightStartDate: NgbDate;
    flightEndDate: NgbDate;
    numFlightArrival: number = null;
    numFlightDeparture: number = null;
    company: string;
    airport: string;

    constructor(formulario: UntypedFormGroup) {
        this.airport = formulario.get('airport').value;
        this.company = formulario.get('company').value;
        this.numFlightDeparture = formulario.get('numFlightDeparture').value;
        this.numFlightArrival = formulario.get('numFlightArrival').value;
        this.flightEndDate = formulario.get('flightEndDate').value;
        this.flightStartDate = formulario.get('flightStartDate').value;
    }
    
}
