import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-second-level-dashboard-workaround',
  templateUrl: './app-second-level-dashboard-workaround.component.html',
  styleUrls: ['./app-second-level-dashboard-workaround.component.css']
})
export class AppSecondLevelDashboardWorkaroundComponent implements OnInit {

  titleModule = 'Workaround Origem do Problema';

  constructor() { }

  ngOnInit() {
  }

}
