<br>
<!-- LINHA DE INDICADORES -->
<div class="row myCard">
  <div class="text-center col-6 myColSix">
    <div class="card-header">
      Indicadores de Geração de RPE
    </div>
    <div class="card-body myCardBody">
      <div style="margin-top: 1%;">
        <app-indicators [events]="eventsSubject.asObservable()"></app-indicators>
      </div>
    </div>
  </div>

  <div class="text-center col-6 myColSix">
    <div class="card-header">
      Situação Atual de Processamento em: {{ dataAtual | date:'dd/MM/yyyy' }}
    </div>
    <div class="card-body myCardBody">
      <div style="display: inline-block; margin-top: 1%;">
        <app-totalizers></app-totalizers>
      </div>
    </div>
  </div>
</div>
<!--FIM LINHA INDICADORES -->

<div class="row myCard" style="margin-top: 1%;">
  <!-- INPUTS DE DATA -->
  <div class="text-center col-6">
    <div class="card-body myCardBody">
      <app-search-information-by-period (periodo)="receberPeriodo($event)">
      </app-search-information-by-period>
    </div>
  </div>
  <div class="text-center  col-6">
    <div class="card-body myCardBody">
      <app-app-payments [events]="eventsSubject.asObservable()"></app-app-payments>
    </div>
  </div>
</div>

<!-- [ PRIMEIRA LINHA DE DASHBOARDS ] -->
<div class="row myCard" style="margin-top: 1%;">
  <div class="col-12">
    <app-dashboard-line id="dashboarRPENaoGerado" [events]="eventsSubject.asObservable()"></app-dashboard-line>
  </div>
</div>

<!-- [ SEGUNDA LINHA DE DASHBOARDS ] -->
<div class="row myCard" style="margin-top: 1%;">
  <div class="col-6">
    <app-dashboard-workaround [events]="eventsSubject.asObservable()"></app-dashboard-workaround>
  </div>
  <div class="col-6">
    <app-dashboard-rpe-generated-flights [events]="eventsSubject.asObservable()"></app-dashboard-rpe-generated-flights>
  </div>
  <br />
</div>

<!-- [ TERCEIRA LINHA DE DASHBOARDS ] -->
<div class="row myCard" style="margin-top: 1%;">
  <div class="col-6">
    <app-app-dashboard-contestation [events]="eventsSubject.asObservable()"></app-app-dashboard-contestation>
  </div>

  <div class="col-6">
    <app-dashboard-stamp [events]="eventsSubject.asObservable()"></app-dashboard-stamp>
  </div>
  <br />
</div>

<br><br><br>