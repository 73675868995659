import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { endpoints } from '../../../environments/endpoint';
import { DashboardlineRQ } from './model/DashboardlineRQ';
import { DashboardlineRS } from './model/DashboardlineRS';
import { Observable} from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppDashboardContestationService {

  constructor(private http: HttpClient) { }

  convertDataIso(requestDC: DashboardlineRQ): void {
    let arrayString: string[] = [];
    const dtInicio = new Date(requestDC.dtInicio).toISOString();
    const dtFim = new Date(requestDC.dtFim).toISOString();

    arrayString = dtInicio.split('T');
    requestDC.dtInicio = arrayString[0] + 'T00:00:00';
    arrayString = dtFim.split('T');
    requestDC.dtFim = arrayString[0] + 'T23:59:59';
  }

  getDataByDate(requestDC: DashboardlineRQ): Observable<DashboardlineRS[]> {

    if (!requestDC.dtInicio.includes('T')) {
      this.convertDataIso(requestDC);
    }

    let params1 = new HttpParams().set('flightBeginDate', requestDC.dtInicio);
    let params2 = new HttpParams().set('flightEndDate', requestDC.dtFim);
    // console.log('Data: {}', request);
    return this.http.get<DashboardlineRS[]>(endpoints.REST_GET_CONTESTED_AMOUNT_TYPE_BY_PERIOD+"?"+params1+"&"+params2, httpOptions);

  }

}
