<img src="{{img}}">
<h2 mat-dialog-title style="margin-left: 50px">{{title}}</h2>

<mat-dialog-content>
  <label>{{message}}</label>
</mat-dialog-content>

<mat-dialog-actions style="display: flex !important; justify-content: center !important;">
  <button mat-raised-button mat-dialog-close>Ok</button>
</mat-dialog-actions>
