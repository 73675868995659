<div>
  <form #formAdministrator>
  <br>
  <div class="card text-center">
    <div class="card-header" style="background-color: #1b0088;">
      <h5 style="color: #ffffff; font-size: 1.0rem;">Manutenção de Administradores de Aeroportos</h5>
    </div>
    <div class="card-body">

      <div>
        <button mat-raised-button
          (click)="openCreatedAdministratorAirport(null)"
          matTooltip="Cadastrar um Novo administrador"
          class="btn btn-primary btnSave"
          style="background-color: #1b0088;border-color:#1b0088; color: #FFF; font-size: 1.0rem;float: right;">
          <mat-icon>add</mat-icon>
          Novo
        </button>
      </div>
      <br><br>

      <table mat-table [dataSource]="dataSourceAdministratorAirports" class="mat-elevation-z8" style="width: 100%;">

        <ng-container matColumnDef="identification">
          <th mat-header-cell *matHeaderCellDef>Identificação</th>
          <td mat-cell *matCellDef="let adm">{{ adm.identification }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let adm">{{ adm.name }}</td>
        </ng-container>

        <ng-container matColumnDef="editOrDelete">
          <th mat-header-cell *matHeaderCellDef>Editar / Excluir</th>
          <td mat-cell *matCellDef="let adm">
              <button matTooltip="Editar" mat-flat-button color="primary" class="iconButtonTable"
              [disabled]="this.validEdit" (click)="editAdministrator(adm)"
              [disabled]="controlSave"
              matTooltip="Editar este Administrador">
              <mat-icon>edit</mat-icon>
            </button>
              <button mat-raised-button
                  type="button"
                  matTooltip="Deletar este Administrador"
                  class="btn btn-danger"
                  (click)="deleteAdministrator(adm)"
                  [disabled]="controlSave">
                  <mat-icon style="margin-left: 1%;font-size: x-large;">delete</mat-icon>
              </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[15, 25, 50, 100]" [showFirstLastButtons]="true">
      </mat-paginator>
    </div>

  </div>
  </form>
</div>
