import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { TabsAndRegisters } from '../model/tabs-and-registers';
import { endpoints } from '../../../../environments/endpoint';
import { TabsAndRegisterWithFile } from '../model/tabs-and-register-with-file';
import { ResponseImportPartialFile } from '../model/response-import-partial-file';
import { ResponseGeneric } from '../model/ResponseGeneric';
import { ImportFile } from '../model/importFile';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateService } from './date.service';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ImportFileService {

  private REST_POST_PARCIAL_EXCEL_FILE = endpoints.REST_POST_PARCIAL_EXCEL_FILE;
  private REST_POST_COMPLETE_EXCEL_FILE = endpoints.REST_POST_COMPLETE_EXCEL_FILE;
  private REST_POST_PREVIEW_EXCEL_FILE = endpoints.REST_POST_PREVIEW_EXCEL_FILE;
  private REST_GET_IMPORT_FILE = endpoints.REST_GET_IMPORT_FILE;
  private REST_DELETE_IMPORT_FILE = endpoints.REST_DELETE_IMPORT_FILE;

  constructor(private http: HttpClient, private dateService: DateService) { }

  public uploadFilePreview(file: File, layoutId: number, airport: string, company: string): Observable<ResponseGeneric> {

    const formData: FormData = new FormData();
    formData.append('file', file, file.name,);

    return this.http.post<ResponseGeneric>(this.REST_POST_PREVIEW_EXCEL_FILE
      .replace('{layout}', layoutId.toString())
      .replace('{airport}', airport.toString())
      .replace('{company}', company.toString())
      , formData,{
        headers: new HttpHeaders({'Content-Type':'multipart/form-data'}),
        reportProgress: true
      });

  }

  public uploadFilePartial(request: TabsAndRegisterWithFile): Observable<ResponseGeneric> {

    const formData: FormData = new FormData();

    formData.append('file', request.file, request.file.name);

    return this.http.post<ResponseGeneric>(this.REST_POST_PARCIAL_EXCEL_FILE
      .replace('{layout}', request.layout.toString())
      .replace('{airport}', request.airport)
      .replace('{company}', request.company)
      .concat(request.tabsToChooseCollection[0].nameTable),
      formData,{
        headers: new HttpHeaders({'Content-Type':'multipart/form-data'}),
        reportProgress: true
      });

  }

  public uploadFileComplete(request: TabsAndRegisterWithFile): Promise<any> {

    const formData: FormData = new FormData();

    formData.append('file', request.file, request.file.name);

    const tabs: string[] = request.tabsToChooseCollection.map(tab => tab.nameTable);

    return this
      .http
      .post(this.REST_POST_COMPLETE_EXCEL_FILE
        .replace('{layout}', request.layout.toString())
        .replace('{airport}', request.airport)
        .replace('{company}', request.company)
        .concat(tabs.toString()),
        formData)
      .toPromise();

  }

  public getImportFileInformationByAirportName(iataAirport: string, dtInit: NgbDate, dtEnd: NgbDate): Observable<ImportFile[]> {
    console.log(dtEnd)
    console.log(dtInit)
    return this.
      http.
      get<ImportFile[]>(this.REST_GET_IMPORT_FILE
        .replace('{iataAirport}', iataAirport)
        .replace('{dtInit}', moment(dtInit).format('YYYY-MM-DD'))
        .replace('{dtEnd}', moment(dtEnd).format('YYYY-MM-DD'))
        //.replace('{dtInit}', this.dateService.ngbDateToString(dtInit))
        //.replace('{dtEnd}', this.dateService.ngbDateToString(dtEnd))
      );

  }

  public deleteImportFileInformationById(idImportFile: number): Observable<undefined> {

    return this.
      http
      .delete<undefined>(this.REST_DELETE_IMPORT_FILE
        .replace('{id}', idImportFile.toString())
      );

  }
}
