<form class="alinhamentoInput">
  <div class="container">
  <div class="form-row row d-flex">
    <div class="col-5 mb-3">
      <mat-form-field appearance="fill">
        <mat-label>{{ dtInicialLabel }} - {{ dtFinalLabel }}</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-hint>dd/mm/yyyy – dd/mm/yyyy</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>
    </div>
    <div class="col-2 d-flex align-items-center mb-3 marginBtSearch">
      <button mat-raised-button id="btnSearch" class="btn btn-primary" type="submit" (click)="eventoPesquisa()">
        <mat-icon>search</mat-icon>
      </button>
    </div>


  </div>
</div>

  <!-- <div class="col-md-5 mb-3">
      <label for="dtInicial">{{ dtInicialLabel }}</label>
      <div class="input-group">
        <input
          type="date"
          id="dtInicial"
          autocomplete="off"
          [(ngModel)]="dtInicial"
          (click)="d1.toggle()"
          (click)="clickEventHandlerSelectSearInf($event)"
          (dateSelect)="onDateStartSelect($event)"
          class="form-control"
          placeholder="dd/mm/yyyy"
          name="dp"
          [displayMonths]="displayMonths"
          ngbDatepicker
          #d1="ngbDatepicker"/>
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="d1.toggle()"
            type="button">
          </button>
        </div>
      </div>
    </div> -->
  <!-- <div class="col-md-5 mb-3">
      <label for="dtFinal">{{ dtFinalLabel }}</label>
      <div class="input-group">
        <input
          id="dtFinal"
          disabled="{{isDisabled}}"
          autocomplete="off"
          [(ngModel)]="dtFinal"
          (click)="d2.toggle()"
          (click)="clickEventHandlerSelectSearInf($event)"
          (dateSelect)="onDateEndSelect($event)"
          [markDisabled]="isDisabledLastOne"
          class="form-control"
          placeholder="dd/mm/yyyy"
          name="dtFinal"
          [displayMonths]="displayMonths"
          ngbDatepicker
          #d2="ngbDatepicker"/>
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="d2.toggle()"
            type="button">
          </button>
        </div> -->
  <!-- <div [hidden]="dtFinal" class="alert alert-danger"> {{ emptyDate }}</div> -->
  <!-- </div>
    </div> -->
  <!-- <div class="col-md-2 mb-3">
        <button mat-raised-button id="btnSearch" class="btn btn-primary" type="submit" (click)="eventoPesquisa()"><mat-icon>search</mat-icon></button>
    </div> -->
  <!-- </div> -->
</form>
