import { Component, ViewChild} from '@angular/core';
import { AppUploadFileService } from '../app-upload-file/app-upload-file.service';
import { Retorno } from '../app-upload-file/model/retorno';
import { properties } from '../../../environments/properties';
import { forkJoin } from 'rxjs';
import { DialogCustomComponent } from '../app-dialog-custom/app-dialog-custom.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-contestation-upload-file',
  templateUrl: './app-contestation-upload-file.html',
  styleUrls: ['./app-contestation-upload-file.css']
})
export class UploadContestationFileComponent  {

  @ViewChild('file') file
  public files: Set<File> = new Set();

  public displayedColumns: string[] = ['lineInExcelFile', 'descricao'];
  public dataSourceExcel: MatTableDataSource<Retorno>;
  progress;
  canBeClosed = true;
  primaryButtonText = 'Enviar';
  showCancelButton = true;
  uploading = false;
  uploadSuccessful = false;
  DISPUTE_SAVE = 5203;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('formUpload') form;

  ngOnInit(){}

  constructor(public uploadService: AppUploadFileService, public dialog: MatDialog){}

  uploadFile(event) {
    // console.log('novo arquivo selecionado');
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.add(element);
      this.closeDialog();
    }
  }

  closeDialog() {

    this.uploadService.upload(this.files, (retorno: Retorno[]) => {

      this.dataSourceExcel = new MatTableDataSource(
        retorno.filter( v => v.status != this.DISPUTE_SAVE)
      );

      this.dataSourceExcel.paginator = this.paginator;
      if(this.dataSourceExcel[0] == undefined || this.dataSourceExcel[0] == null){
        this.openDialogCont(
          properties.MODAL_TITLE_SUCESS,
            properties.MODAL_CHAMADO_MENSAGEM_PLANILHA_INSERIDA_SUCESSO)
            this.form.nativeElement.reset()
        }

    });

    let allProgressObservables = [];
    for (let key in this.progress) {
      allProgressObservables.push(this.progress[key].progress);
    }

    forkJoin(allProgressObservables).subscribe(end => {
      this.canBeClosed = true;
      this.uploadSuccessful = true;
      this.uploading = false;
    });

  }

  public openDialogCont(titleParam: string, messageParam: string): void {
    const dialogRef = this.dialog.open(DialogCustomComponent, {
        width: '450px',
        data: {title: titleParam, message: messageParam}
    });
  }

}
