import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppDataContestationListService } from './app-data-contestation-list.service';
import { RequestListContestation } from './model/request-list-contestation';
import { ContestationList } from './model/contestation-list';
import { Protocolo } from '../app-enum/Protocolo';
import { ExcelService } from '../../app-conciliation/service/excel.service';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-app-data-contestation-list',
  templateUrl: './app-data-contestation-list.component.html',
  styleUrls: ['./app-data-contestation-list.component.css']
})
export class AppDataContestationListComponent implements OnInit {

  public displayedColumns: string[] = [
    'idControlDispute',
    'fkRpe',
    'cdCompAerea',
    'cdNumVoo',
    'dtFlight',
    'originAirport',
    'destAirport',
    'typeFlightMovement',
    'dtDispute',
    'typeDispute',
    'name',
    'dtAction'
  ];

  public dataSourceContestation: MatTableDataSource<ContestationList>;
  public request: RequestListContestation;
  private eventsSubscription: any;
  public dataAtual: Date = new Date();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private excelService: ExcelService,
              private datePipe: DatePipe,
              public dataContestationService: AppDataContestationListService) {
                this.request = new RequestListContestation();
              }

  ngOnInit() {
    this.eventsSubscription = this.route.params.subscribe(params => {
      this.request.dtVoo = params['date'];
      this.request.type = params['type'];

      if (this.request.type === "Automatico"){
        this.request.type = "A";
      }else{
        this.request.type = "M";
      }
      // console.log("Data Request: ", this.request.dtVoo);
      this.getContestationListData(this.request.dtVoo, this.request.type);
    });
  }

  private getContestationListData(dtVoo: string, type: string): void {
    this.request.dtVoo = this.getConvertRequest(this.request.dtVoo);
    this.dataContestationService.getDadosVoo(this.request).subscribe(
      dataSource => {
          this.dataSourceContestation = new MatTableDataSource(dataSource);
          // console.log("Data Response: ", dataSource);
          this.dataSourceContestation.paginator = this.paginator;
        }, err => {
          let protocolo: Protocolo = err.error;
      });
  }

  public getConvertRequest(dt: string): string{
    let dtString: string[];
    dtString = dt.split("/");
    return dtString[2] + "-" + dtString[1] + "-" + dtString[0] + "T00:00:00";
  }

  public getFormatDateToString(dt: string): string {
    if ( dt === null || dt === undefined) {
      return '';
    }
    let dtarr: string[];
    dtarr = dt.split('T');
    dtarr = dtarr[0].split('-');
    return dtarr[2] + '-' + dtarr[1] + '-' + dtarr[0];
  }

  public getFormatDateBr(dt: string): string {
    // console.log('data voo: ' + dt);
    if ( dt === null || dt === undefined) {
      return '';
    }
    let dtarr: string[];
    dtarr = dt.split(' ');
    dtarr = dtarr[0].split('-');

    return dtarr[2] + '-' + dtarr[1] + '-' + dtarr[0];
  }

  exportContestationAsXLSX(): void {
    this.excelService.exportContestationAsXLSXFile(
      this.dataSourceContestation.data,
      'LISTA_CONTESTAÇÂO' +
        '_' +
        this.datePipe.transform(this.dataAtual, 'dd_MM_yyyy')
    );
  }

}
