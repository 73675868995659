import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DashboardlineRQ } from './model/DashboardlineRQ';
import { DashboardlineRS } from './model/DashboardlineRS';
import { Observable } from 'rxjs';
import { endpoints } from '../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppDashboardStampService {

  constructor(private http: HttpClient) { }

  convertDataIso(requestDSTA: DashboardlineRQ): void {
    let arrayString: string[] = [];
    const dtInicio = new Date(requestDSTA.dtInicio).toISOString();
    const dtFim = new Date(requestDSTA.dtFim).toISOString();

    arrayString = dtInicio.split('T');
    requestDSTA.dtInicio = arrayString[0] + 'T00:00:00';
    arrayString = dtFim.split('T');
    requestDSTA.dtFim = arrayString[0] + 'T23:59:59';
  }

  getDataByDate(requestDSTA: DashboardlineRQ): Observable<DashboardlineRS[]> {

    if (!requestDSTA.dtInicio.includes('T')) {
      this.convertDataIso(requestDSTA);
    }

    let params1 = new HttpParams().set('flightBeginDate', requestDSTA.dtInicio);
    let params2 = new HttpParams().set('flightEndDate', requestDSTA.dtFim);

    return this.http.get<DashboardlineRS[]>(endpoints.REST_GET_STAMP_GENERATED_X_NOT_GENERATED + "?" + params1 + "&" + params2, httpOptions);

  }

}
