import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { endpoints } from '../../../environments/endpoint';
import { UserInvoice } from '../../app-authenticator/model/user.invoice';
import { AuthenticationService } from '../../app-authenticator/authentication.service';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class HeaderComponent implements OnInit {

  public version: string;
  public production: boolean = environment.production;
  public user: UserInvoice = new UserInvoice('', '', '');

  subscription: Subscription;
  intervalId: number;
  
  constructor(public appHeaderService: AuthenticationService) { }

  ngOnInit() {

    if (this.production === false) {
      this.version =  endpoints.VERSION;
    }

    const source = interval(2000);
    this.subscription = source.subscribe(val =>  this.getUserInfo());
  }

  public getUserInfo(): void{
    let userTemp: UserInvoice = this.appHeaderService.getCurrentUser();
    // console.log('get info in header: {}', userTemp );
    if(userTemp != undefined && userTemp != null && userTemp.name != undefined){
      this.user = userTemp;
    }else {
      this.user = new UserInvoice('', '', '');
    }
  }

  public logoutAplication(){
    this.appHeaderService.signOut();
  }

}
