import { Injectable } from '@angular/core';
import { Layout } from '../model/layout';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { endpoints } from '../../../../environments/endpoint';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(private http: HttpClient) { }

  public getAllLayouts(): Observable<any> {

    return this
      .http
      .get<any>(endpoints.REST_GET_TEMPLATES_ALL_ACTIVE);

  }

  public filterByAirportName(layoutArray: Layout[], airportName: string): Layout[] {
    return layoutArray.filter(layout => layout.iataAirport === airportName);
  }
}
