<div class="flex">
    <div class="flex-item">
      <div style="display: block;">
      <canvas baseChart
        [datasets]="lineChartDataRepGen"
        [labels]="lineChartLabelsRepGen"
        [options]="lineChartOptions"
        [plugins]="lineChartPlugins"
        [legend]="lineChartLegend"
        (chartClick)="chartClicked($event)">
      </canvas>
      <!-- <canvas baseChart
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [plugins]="lineChartPlugins"
        [legend]="lineChartLegend"
        [chartType]="lineChartType"
        (chartClick)="chartClicked($event)">
      </canvas> -->
    </div>
  </div>
</div>
g
