<div class="modalContent">
<p>

  Confirmar abas a serem importadas

</p>
<div *ngIf="checkHasWarn(); then thenBlock else elseBlock"></div>

<ng-template #thenBlock>
  <div *ngFor="let recipient of warn | keyvalue">
    <strong class='warn-choose-tab'>
      {{recipient.value}}
      <br><br>
    </strong>
  </div>
  <strong>Caso deseje continuar, por favor, selecione quais abas o sistema deverá importar:</strong>
</ng-template>

<ng-template #elseBlock>
  <strong>Por favor, selecione quais abas o sistema deverá importar:</strong>
</ng-template>


<br>
<br>

<section *ngFor="let tab of tabsToChoose">

    <mat-checkbox [(ngModel)]="tab.check">{{tab.nameTable}}</mat-checkbox>

    <mat-divider></mat-divider>

</section>

<button mat-stroked-button (click)="dialogClose()" [disabled] = "checked()">OK</button>
</div>

