import { Component, OnInit, Input } from '@angular/core';
import { DashboardInformation } from '../../../model/dashboard-information';
import { Conciliation } from '../../../model/conciliation';
import { DateService } from '../../../service/date.service';
import { Router } from '@angular/router';
import { DashboardTypeFilterService } from '../../../service/dashboard-type-filter.service';
import { MatLabel } from '@angular/material/form-field';

@Component({
  selector: 'app-comparative-dashboard-line-chart',
  templateUrl: './comparative-dashboard-line-chart.component.html',
  styleUrls: ['./comparative-dashboard-line-chart.component.css']
})
export class ComparativeDashboardLineChartComponent implements OnInit {


  public lineChartData: any[] = [{ data: [], label: '' }];

  public lineChartLabels: MatLabel[] = [
    'Janeiro', 'Fevereiro', 'Março',
    'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro',
    'Outubro', 'Novembro', 'Dezembro'];

  public lineChartOptions: (any & { annotation: any }) = {
    responsive: true,
    legend: {
      position: 'top',
    },
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'naoGerado',
          position: 'left',
        },
      ]
    },
    onClick: (event, array: any[]) => {

      const active = array[0]._index;

      const year = new Date().getFullYear();

      const month = active + 1;

      this.router.navigate(['dashboard-final', year, month]);

    },
    annotation: {},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    },
  };

  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  yearNow: number;

  type: string;

  types: string[];

  public conciliations: Conciliation[];

  constructor(private dateService: DateService,
              private router: Router,
              dashboardTypeFilter: DashboardTypeFilterService
  ) {
    this.yearNow = new Date().getFullYear();
    this.types = dashboardTypeFilter.getTypeFilter();
  }

  ngOnInit() {
  }

  @Input()
  set typeDashboard(type: string) {

    this.type = type;

    if (this.conciliations !== undefined) {
      this.setLineChart(this.conciliations);
    }

  }

  @Input()
  set dashboard(dashboard: DashboardInformation) {

    if (dashboard !== undefined) {
      this.conciliations = dashboard.lastTwelveMonths;
      this.setLineChart(this.conciliations);
    }
  }

  setLineChart(conciliations: Conciliation[]) {

    if (this.type === this.types[0]) {

      this.lineChartData = [
        {
          data: this.getConciliationFrom(conciliations, this.yearNow),
          label: 'Evolução ultimo 12 meses',
          fill: false,
          datalabels: {
            formatter: (value) => {
              return 'R$' + value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
          }
        },
      ];
    } else {

      this.lineChartData = [
        {
          data: this.getConciliationFrom(conciliations, this.yearNow),
          label: 'Evolução ultimo 12 meses',
          fill: false,
        },
      ];
    }

  }

  private getConciliationFrom(conciliations: Conciliation[], year: number): number[] {

    const data: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    conciliations
      .filter(conciliation => conciliation.dtFlight.getFullYear() === year)
      .forEach(conciliation => {

        const month: number = conciliation.dtFlight.getMonth();

        if (this.type === this.types[0]) {
          data[month] = conciliation.valueRate;
        } else {
          data[month] = conciliation.qntPassager;
        }
      });

    return data;
  }


}
