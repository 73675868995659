<div class="card text-center col-12">
  <div class="card-header titleLogs">
    <h5>Logs Exclusão</h5>
  </div>
  <table
    class="mat-elevation-z8"
    mat-table
    [dataSource]="dataSourceHistoric"
    style="width: 100%"
  >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>id</th>
      <td mat-cell *matCellDef="let v">
        {{ v.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="flightPrefix">
      <th mat-header-cell *matHeaderCellDef>Prefixo</th>
      <td mat-cell *matCellDef="let v">
        {{ v.flightPrefix }}
      </td>
    </ng-container>

    <ng-container matColumnDef="flightAircraft">
      <th mat-header-cell *matHeaderCellDef>Aeronave</th>
      <td mat-cell *matCellDef="let v">
        {{ v.flightAircraft }}
      </td>
    </ng-container>

    <ng-container matColumnDef="flightOrigin">
      <th mat-header-cell *matHeaderCellDef>Origem</th>
      <td mat-cell *matCellDef="let v">
        {{ v.flightOrigin }}
      </td>
    </ng-container>

    <ng-container matColumnDef="flightDestination">
      <th mat-header-cell *matHeaderCellDef>Destino</th>
      <td mat-cell *matCellDef="let v">
        {{ v.flightDestination }}
      </td>
    </ng-container>

    <ng-container matColumnDef="numFlightArrival">
      <th mat-header-cell *matHeaderCellDef>Número Pouso</th>
      <td mat-cell *matCellDef="let v">
        {{ v.numFlightArrival }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dateFlightArrival">
      <th mat-header-cell *matHeaderCellDef>Data Pouso</th>
      <td mat-cell *matCellDef="let v">
        {{ getFormatDateToStringIso(v.dateFlightArrival) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="numFlightDeparture">
      <th mat-header-cell *matHeaderCellDef>Número Decolagem</th>
      <td mat-cell *matCellDef="let v">
        {{ v.numFlightDeparture }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dateFlightDeparture">
      <th mat-header-cell *matHeaderCellDef>Data Decolagem</th>
      <td mat-cell *matCellDef="let v">
        {{ getFormatDateToStringIso(v.dateFlightDeparture) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="flightCompany">
      <th mat-header-cell *matHeaderCellDef>Companhia</th>
      <td mat-cell *matCellDef="let v">
        {{ v.flightCompany }}
      </td>
    </ng-container>

    <ng-container matColumnDef="typeNature">
      <th mat-header-cell *matHeaderCellDef>Natureza</th>
      <td mat-cell *matCellDef="let v">
        {{ v.typeNature }}
      </td>
    </ng-container>

    <ng-container matColumnDef="descPath">
      <th mat-header-cell *matHeaderCellDef>Caminho arquivo</th>
      <td mat-cell *matCellDef="let v">
        {{ v.descPath }}
      </td>
    </ng-container>

    <ng-container matColumnDef="idFlightLeg">
      <th mat-header-cell *matHeaderCellDef>RPE/SELO Anterior</th>
      <td mat-cell *matCellDef="let v">
        {{ v.idFlightLeg }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dateFileGeneratedBefore">
      <th mat-header-cell *matHeaderCellDef>Data Gerado</th>
      <td mat-cell *matCellDef="let v">
        {{ getFormatDateToStringIso(v.dateFileGeneratedBefore) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="typeFileGeneratedBefore">
      <th mat-header-cell *matHeaderCellDef>Tipo Gerado</th>
      <td mat-cell *matCellDef="let v">
        {{ v.typeFileGeneratedBefore }}
      </td>
    </ng-container>

    <ng-container matColumnDef="reason">
      <th mat-header-cell *matHeaderCellDef>Razão</th>
      <td mat-cell *matCellDef="let v">
        {{ v.reason }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dateUpdate">
      <th mat-header-cell *matHeaderCellDef>Data Deleção</th>
      <td mat-cell *matCellDef="let v">
        {{ getFormatDateToStringIso(v.dateUpdate) }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[10, 25, 50]"
    showFirstLastButtons
  ></mat-paginator>
  <br />
  <div class="form-row aliginCenterContent">
    <button
      mat-raised-button
      matTooltip="OK "
      class="btn btn-primary btnOK"
      (click)="cancelOperation()"
    >
      OK
    </button> 
  </div>
  <br/>
</div>
