<div class="form-rpe">
  <br />

  <div class="card">
    <div class="card-header">
      Detalhe do RPE
    </div>
    <div class="card-body">

      <mat-tab-group dynamicHeight>
        <mat-tab label="Embarque (Faturamento)">
          <br>
          <h5 class="card-title" style="font-size: 14px;">Dados do Voo </h5>
          <div class="example-small-box mat-elevation-z4">
            <div class="containerReadInput">
              <label for="aeroportoReferencia">Aeroporto Referencia</label>
              <input matInput readonly 
                id="aeroportoReferencia" 
                [(ngModel)]="rpe.aeroportoReferencia"
                name="aeroportoReferencia" 
                type="text" 
                class="form-control"
                matTooltip="{{ getAirportIataByIcao(rpe.aeroportoReferencia) }} - {{ getDescriptionByIcao(rpe.aeroportoReferencia) }} ">
            </div>
            <div class="containerReadInput">
              <label for="aeronaveOperador">Cia Operadora</label>
              <input matInput readonly id="aeronaveOperador" value="{{getIataByIcaoAirlineComp(rpe.aeronaveOperador)}}" name="aeronaveOperador"
                type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="vooNumeroDecolagem">Num. Voo Decolagem</label>
              <input matInput readonly id="vooNumeroDecolagem" value="{{converter(rpe.vooNumeroDecolagem)}}"
                name="vooNumeroDecolagem" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="programadoDataDecolagem">Data Decolagem</label>
              <input matInput readonly id="programadoDataDecolagem" [(ngModel)]="rpe.programadoDataDecolagem"
                name="programadoDataDecolagem" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="programadoHorarioDecolagem">Horario Decolagem</label>
              <input matInput readonly id="programadoHorarioDecolagem" [(ngModel)]="rpe.programadoHorarioDecolagem"
                name="programadoHorarioDecolagem" type="text" class="form-control">
            </div>
          </div>
          <div class="example-small-box mat-elevation-z4">
            <div class="containerReadInput">
              <label for="aeronaveMarcas">Aeronave Prefixo</label>
              <input matInput readonly id="aeronaveMarcas" value="{{formatPrefix(rpe.aeronaveMarcas)}}" name="aeronaveMarcas"
                type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="especieDoVoo">Tipo de Voo</label>
              <input matInput readonly id="especieDoVoo" [(ngModel)]="rpe.especieDoVoo" name="especieDoVoo" type="text"
                class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="participacao">Participação</label>
              <input matInput readonly id="participacao" [(ngModel)]="rpe.participacao" name="participacao" type="text"
                class="form-control">
            </div>
            <!--
              <div class="containerReadInput">
                <label for="desParticipacao">Descrição Participação</label>
                <input matInput readonly id="desParticipacao" [(ngModel)]="rpe.desParticipacao" name="desParticipacao"
                  type="text" class="form-control">
              </div>
            -->
            <div class="containerReadInput">
              <label for="desEspecieDoVoo">Descrição Espécie do Voo</label>
              <input matInput readonly id="desEspecieDoVoo" [(ngModel)]="rpe.desEspecieDoVoo" name="desEspecieDoVoo"
                type="text" class="form-control">
            </div>
          </div>
          <br>
          <h5 class="card-title" style="font-size: 14px;">Passageiros Isentos </h5>
          <!-- PAX ISENTOS -->
          <div class="example-small-box mat-elevation-z4">
            <div class="containerReadInput">
              <label for="paxColoDecolagem">Colo </label>
              <input matInput readonly id="paxColoDecolagem" name="paxColoDecolagem" type="text" class="form-control"
                value="{{converter(rpe.paxColoDecolagem)}}">
            </div>
            <div class="containerReadInput">
              <label for="paxTarifaConexaoDom">Cnx. Doméstico</label>
              <input matInput readonly id="paxTarifaConexaoDom" value="{{converter(rpe.paxTarifaConexaoDom)}}"
                name="paxTarifaConexaoDom" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxTarifaConexaoInt">Cnx. Internacional</label>
              <input matInput readonly id="paxTarifaConexaoInt" value="{{converter(rpe.paxTarifaConexaoInt)}}"
                name="paxTarifaConexaoInt" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxEscala">Trânsito</label>
              <input matInput readonly id="paxEscala" value="{{converter(rpe.paxEscala)}}" name="paxEscala" type="text"
                class="form-control">
            </div>

            <div class="containerReadInput">
              <label for="paxEscala">Outros Isentos</label>
              <input matInput readonly id="paxOtherIsent" value="{{converter(getOtherIsents())}}" name="paxOtherIsent" type="text"
                class="form-control">
            </div>

            <div class="containerReadInput">
              <label for="paxEscala">Total de Isentos</label>
              <input matInput readonly id="paxAll" value="{{converter(rpe.paxEscala, rpe.paxTarifaConexaoInt, rpe.paxTarifaConexaoDom, rpe.paxColoDecolagem, getOtherIsents() )}}" name="paxAll" type="text"
                class="form-control">
            </div>

          </div>
          <br>
          <h5 class="card-title" style="font-size: 14px;">Totais de Passageiros </h5>
          <div class="example-small-box mat-elevation-z4">
            <div class="containerReadInput">
              <label for="paxTarifaEmbarqueDom">Pax. Pagantes Dom.</label>
              <input matInput readonly id="paxTarifaEmbarqueDom" value="{{converter(rpe.paxTarifaEmbarqueDom)}}"
                name="paxTarifaEmbarqueDom" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxTarifaEmbarqueInt">Pax. Pagantes Int</label>
              <input matInput readonly id="paxTarifaEmbarqueInt" value="{{converter(rpe.paxTarifaEmbarqueInt)}}"
                name="paxTarifaEmbarqueInt" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxTarifaEmbarqueDom">Total de Pax. Pagantes</label>
              <input matInput readonly type="text" class="form-control"
                value="{{converter(rpe.paxTarifaEmbarqueInt, rpe.paxTarifaEmbarqueDom)}}">
            </div>
            <div class="containerReadInput">
              <label for="paxTarifaEmbarqueInt">Total de Pax.</label>
              <input matInput readonly type="text" class="form-control"
                value="{{converter(rpe.paxTarifaEmbarqueInt, rpe.paxTarifaEmbarqueDom, rpe.paxEscala, rpe.paxTarifaConexaoInt, rpe.paxTarifaConexaoDom, rpe.paxColoDecolagem)}}">
            </div>
          </div>
          <br>
          <h5 class="card-title" style="font-size: 14px;">Dados Estatísticos </h5>
          <div class="example-small-box mat-elevation-z4">
            <table class="tableFaturamento" style="width: 100%;">
              <tr>
                <th></th>
                <th>Doméstico</th>
                <th>Internacional</th>
              </tr>
              <tr>
                <td>
                  <h5 class="card-title" style="font-size: 14px;">Bagagem </h5>
                </td>
                <td>
                  <div>
                    <!--<label for="bagagemEmbDom">Emb. Domestico</label>-->
                    <input matInput readonly id="bagagemEmbDom" value="{{converter(rpe.bagagemEmbDom)}}" name="bagagemEmbDom"
                      type="text" class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="bagagemEmbInt">Emb. Internacional</label>-->
                    <input matInput readonly id="bagagemEmbInt" value="{{converter(rpe.bagagemEmbInt)}}" name="bagagemEmbInt"
                      type="text" class="form-control">
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 class="card-title" style="font-size: 14px;">Carga </h5>
                </td>
                <td>
                  <div>
                    <!--<label for="cargaEmbDom">Emb. Doméstico</label> -->
                    <input matInput readonly id="cargaEmbDom" value="{{converter(rpe.cargaEmbDom)}}" name="cargaEmbDom"
                      type="text" class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="cargaEmbInt">Emb. Internacional</label> -->
                    <input matInput readonly id="cargaEmbInt" value="{{converter(rpe.cargaEmbInt)}}" name="cargaEmbInt"
                      type="text" class="form-control">
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 class="card-title" style="font-size: 14px;">Correio </h5>
                </td>
                <td>
                  <div>
                    <!--<label for="correioEmbDom">Emb. Domestico</label> -->
                    <input matInput readonly id="correioEmbDom" value="{{converter(rpe.correioEmbDom)}}" name="correioEmbDom"
                      type="text" class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="correioEmbInt">Emb. Internacional</label> -->
                    <input matInput readonly id="correioEmbInt" value="{{converter(rpe.correioEmbInt)}}" name="correioEmbInt"
                      type="text" class="form-control">
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab label="Desembarque (Faturamento)">
          <br>
          <div class="example-small-box mat-elevation-z4">
            <table class="tableFaturamento" style="width: 100%;">
              <tr>
                <th></th>
                <th>Doméstico</th>
                <th>Internacional</th>
                <th>Cnx. Dom.</th>
                <th>Cnx. Inter.</th>
                <th>Total</th>
              </tr>
              <tr>
                <td>
                  <h5 class="card-title" style="font-size: 14px;">Passageiros </h5>
                </td>
                <td>
                  <div>
                    <!--<label for="desPaxDom">desPaxDom</label>-->
                    <input matInput readonly id="desPaxDom" value="{{converter(rpe.desPaxDom)}}" name="desPaxDom" type="text"
                      class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="desPaxInt">desPaxInt</label>-->
                    <input matInput readonly id="desPaxInt" value="{{converter(rpe.desPaxInt)}}" name="desPaxInt" type="text"
                      class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="desPaxConexaoDom">Cnx. Doméstico</label>-->
                    <input matInput readonly id="desPaxConexaoDom" value="{{converter(rpe.desPaxConexaoDom)}}"
                      name="desPaxConexaoDom" type="text" class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="desPaxConexaoInt">Cnx. Internacional</label>-->
                    <input matInput readonly id="desPaxConexaoInt" value="{{converter(rpe.desPaxConexaoInt)}}"
                      name="desPaxConexaoInt" type="text" class="form-control">
                  </div>
                </td>
                <td>
                  {{ converter(rpe.desPaxDom, rpe.desPaxInt, rpe.desPaxConexaoDom, rpe.desPaxConexaoInt) }}
                </td>
              </tr>
              <tr>
                <td>
                  <h5 class="card-title" style="font-size: 14px;">Bagagem (Kg)</h5>
                </td>
                <td>
                  <div>
                    <!--<label for="desBagagemDom">desBagagemDom</label>-->
                    <input matInput readonly id="desBagagemDom" value="{{converter(rpe.desBagagemDom)}}" name="desBagagemDom"
                      type="text" class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="desBagagemInt">desBagagemInt</label>-->
                    <input matInput readonly id="desBagagemInt" value="{{converter(rpe.desBagagemInt)}}" name="desBagagemInt"
                      type="text" class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="desBagagemConexaoDom">Cnx. Doméstico</label>-->
                    <input matInput readonly id="desBagagemConexaoDom" value="{{converter(rpe.desBagagemConexaoDom)}}"
                      name="desBagagemConexaoDom" type="text" class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="desBagagemConexaoInt">Cnx. Internacional</label>-->
                    <input matInput readonly id="desBagagemConexaoInt" value="{{converter(rpe.desBagagemConexaoInt)}}"
                      name="desBagagemConexaoInt" type="text" class="form-control">
                  </div>
                </td>
                <td>
                  {{ converter(rpe.desBagagemDom, rpe.desBagagemInt, rpe.desBagagemConexaoDom, rpe.desBagagemConexaoInt ) }}
                </td>
              </tr>
              <tr>
                <td>
                  <h5 class="card-title" style="font-size: 14px;">Carga </h5>
                </td>
                <td>
                  <div>
                    <!--<label for="desCargaDom">desCargaDom</label> -->
                    <input matInput readonly id="desCargaDom" value="{{converter(rpe.desCargaDom)}}" name="desCargaDom"
                      type="text" class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="desCargaInt">desCargaInt</label> -->
                    <input matInput readonly id="desCargaInt" value="{{converter(rpe.desCargaInt)}}" name="desCargaInt"
                      type="text" class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="desCargaConexaoDom">Cnx. Doméstico</label> -->
                    <input matInput readonly id="desCargaConexaoDom" value="{{converter(rpe.desCargaConexaoDom)}}"
                      name="desCargaConexaoDom" type="text" class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="desCargaConexaoInt">Cnx. Internacional</label> -->
                    <input matInput readonly id="desCargaConexaoInt" value="{{converter(rpe.desCargaConexaoInt)}}"
                      name="desCargaConexaoInt" type="text" class="form-control">
                  </div>
                </td>
                <td>
                  {{  converter(rpe.desCargaDom, rpe.desCargaInt, rpe.desCargaConexaoDom, rpe.desCargaConexaoInt) }}
                </td>
              </tr>
              <tr>
                <td>
                  <h5 class="card-title" style="font-size: 14px;">Correio </h5>
                </td>
                <td>
                  <div>
                    <!--<label for="desCorreioDom">desCorreioDom</label> -->
                    <input matInput readonly id="desCorreioDom" value="{{converter(rpe.desCorreioDom)}}" name="desCorreioDom"
                      type="text" class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="desCorreioInt">desCorreioInt</label> -->
                    <input matInput readonly id="desCorreioInt" value="{{converter(rpe.desCorreioInt)}}" name="desCorreioInt"
                      type="text" class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="desCorreioConexaoDom">Cnx. Domestico</label> -->
                    <input matInput readonly id="desCorreioConexaoDom" value="{{converter(rpe.desCorreioConexaoDom)}}"
                      name="desCorreioConexaoDom" type="text" class="form-control">
                  </div>
                </td>
                <td>
                  <div>
                    <!--<label for="desCorreioConexaoInt">Cnx. Internacional</label> -->
                    <input matInput readonly id="desCorreioConexaoInt" value="{{converter(rpe.desCorreioConexaoInt)}}"
                      name="desCorreioConexaoInt" type="text" class="form-control">
                  </div>
                </td>
                <td>
                  {{ converter(rpe.desCorreioDom, rpe.desCorreioInt, rpe.desCorreioConexaoDom, rpe.desCorreioConexaoInt) }}
                </td>
              </tr>
            </table>
          </div>
          <br>
          <div class="example-small-box mat-elevation-z4">
            <div class="containerReadInput">
              <label for="vooNumeroPouso">Número Pouso</label>
              <input matInput readonly id="vooNumeroPouso" value="{{converter(rpe.vooNumeroPouso)}}" name="vooNumeroPouso"
                type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="programadoDataPouso">Data Pouso</label>
              <input matInput readonly id="programadoDataPouso" [(ngModel)]="rpe.programadoDataPouso"
                name="programadoDataPouso" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="programadoHorarioPouso">Horario Pouso</label>
              <input matInput readonly id="programadoHorarioPouso" [(ngModel)]="rpe.programadoHorarioPouso"
                name="programadoHorarioPouso" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="vooNaturezaPouso">Natureza Pouso</label>
              <input matInput readonly id="vooNaturezaPouso" [(ngModel)]="rpe.vooNaturezaPouso" name="vooNaturezaPouso"
                type="text" class="form-control">
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Informações de RIMA">
          <br>
          <h5 class="card-title" style="font-size: 14px;">Voo de Decolagem </h5>
          <div class="example-small-box mat-elevation-z4">
            <div class="containerReadInput">
              <label for="aeroportoAnterior">Aeroporto Anterior</label>
              <input matInput readonly id="aeroportoAnterior" [(ngModel)]="rpe.aeroportoAnterior"
                name="aeroportoAnterior" type="text" class="form-control"
                matTooltip="{{ getAirportIataByIcao(rpe.aeroportoAnterior) }} - {{ getDescriptionByIcao(rpe.aeroportoAnterior) }} ">
            </div>
            <div class="containerReadInput">
              <label for="aeroportoPosterior">Aeroporto Posterior</label>
              <input matInput readonly id="aeroportoPosterior" [(ngModel)]="rpe.aeroportoPosterior"
                name="aeroportoPosterior" type="text" class="form-control"
                matTooltip="{{ getAirportIataByIcao(rpe.aeroportoPosterior) }} - {{ getDescriptionByIcao(rpe.aeroportoPosterior) }} ">
            </div>
            <div class="containerReadInput">
              <label for="aeronaveTipo">Modelo Aeronave </label>
              <input matInput readonly id="aeronaveTipo" [(ngModel)]="rpe.aeronaveTipo" name="aeronaveTipo" type="text"
                class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="aeronaveGrupo">Grupo Aeronave</label>
              <input matInput readonly id="aeronaveGrupo" [(ngModel)]="rpe.aeronaveGrupo" name="aeronaveGrupo"
                type="text" class="form-control">
            </div>
          </div>
          <div class="example-small-box mat-elevation-z4">
            <div class="containerReadInput">
              <label for="vooTipoDecolagem">Tipo Decolagem</label>
              <input matInput readonly id="vooTipoDecolagem" [(ngModel)]="rpe.vooTipoDecolagem" name="vooTipoDecolagem"
                type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="vooLinhaDecolagem">Linha Decolagem</label>
              <input matInput readonly id="vooLinhaDecolagem" [(ngModel)]="rpe.vooLinhaDecolagem"
                name="vooLinhaDecolagem" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxOrigemDecolagem">Pax. Origem Decolagem</label>
              <input matInput readonly id="paxOrigemDecolagem" value="{{converter(rpe.paxOrigemDecolagem)}}"
                name="paxOrigemDecolagem" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxConexaoDomesticoDecolagem">Pax. Cnx Dom. </label>
              <input matInput readonly id="paxConexaoDomesticoDecolagem" value="{{converter(rpe.paxConexaoDomesticoDecolagem)}}" name="paxConexaoDomesticoDecolagem" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxConexaoInternacionalDecolagem">Pax. Cnx. Int. </label>
              <input matInput readonly id="paxConexaoInternacionalDecolagem" value="{{converter(rpe.paxConexaoInternacionalDecolagem)}}" name="paxConexaoInternacionalDecolagem" type="text" class="form-control">
            </div>
          </div>
          <div class="example-small-box mat-elevation-z4">
            <div class="containerReadInput">
              <label for="tripulanteExtraDecolagem">Pax. Trip. Extra</label>
              <input matInput readonly id="tripulanteExtraDecolagem" value="{{converter(rpe.tripulanteExtraDecolagem)}}" name="tripulanteExtraDecolagem" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxConvidadosGovernoBrasileiroDecolagem">Pax. Convidados do Governo</label>
              <input matInput readonly id="paxConvidadosGovernoBrasileiroDecolagem" value="{{converter(rpe.paxConvidadosGovernoBrasileiroDecolagem)}}" name="paxConvidadosGovernoBrasileiroDecolagem" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxRetornoAlternadoDecolagem">Pax. Retorno Alternado</label>
              <input matInput readonly id="paxRetornoAlternadoDecolagem" value="{{converter(rpe.paxRetornoAlternadoDecolagem)}}" name="paxRetornoAlternadoDecolagem" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="fiscalAnacDecolagem">Pax. Fiscal ANAC</label>
              <input matInput readonly id="fiscalAnacDecolagem" value="{{converter(rpe.fiscalAnacDecolagem)}}" name="fiscalAnacDecolagem" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="tripulanteExtraEscala">Pax. Trip Extra Escala</label>
              <input matInput readonly id="tripulanteExtraEscala" value="{{converter(rpe.tripulanteExtraEscala)}}" name="tripulanteExtraEscala" type="text" class="form-control">
            </div>
          </div>

          <br>
          <mat-divider></mat-divider>
          <br>

          <h5 class="card-title" style="font-size: 14px;">Voo de Pouso</h5>
          <div class="example-small-box mat-elevation-z4">
            <div class="containerReadInput">
              <label for="vooTipoPouso">Tipo Pouso</label>
              <input matInput readonly id="vooTipoPouso" value="{{converter(rpe.vooTipoPouso)}}" name="vooTipoPouso" type="text"
                class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="vooLinhaPouso">Linha de Pouso</label>
              <input matInput readonly id="vooLinhaPouso" value="{{converter(rpe.vooLinhaPouso)}}" name="vooLinhaPouso"
                type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxDestinoPouso">Pax. Destino Pouso</label>
              <input matInput readonly id="paxDestinoPouso" value="{{converter(rpe.paxDestinoPouso)}}" name="paxDestinoPouso"
                type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxConexaoDomesticoPouso">Pax. Cnx. Dom. </label>
              <input matInput readonly id="paxConexaoDomesticoPouso" value="{{converter(rpe.paxConexaoDomesticoPouso)}}"
                name="paxConexaoDomesticoPouso" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxConexaoInternacionalPouso">Pax. Cnx. Int. </label>
              <input matInput readonly id="paxConexaoInternacionalPouso" value="{{converter(rpe.paxConexaoInternacionalPouso)}}"
                name="paxConexaoInternacionalPouso" type="text" class="form-control">
            </div>
          </div>
          <div class="example-small-box mat-elevation-z4">
            <div class="containerReadInput">
              <label for="tripulanteExtraPouso">Pax. Trip. Extra Pouso</label>
              <input matInput readonly id="tripulanteExtraPouso" value="{{converter(rpe.tripulanteExtraPouso)}}"
                name="tripulanteExtraPouso" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxConvidadosGovernoBrasileiroPouso">Pax. Convidados do Governo</label>
              <input matInput readonly id="paxConvidadosGovernoBrasileiroPouso"
              value="{{converter(rpe.paxConvidadosGovernoBrasileiroPouso)}}" name="paxConvidadosGovernoBrasileiroPouso"
                type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxRetornoAlternadoPouso">Pax. Retorno Alternado</label>
              <input matInput readonly id="paxRetornoAlternadoPouso" value="{{converter(rpe.paxRetornoAlternadoPouso)}}"
                name="paxRetornoAlternadoPouso" type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="fiscalAnacPouso">Pax. Fiscal ANAC</label>
              <input matInput readonly id="fiscalAnacPouso" value="{{converter(rpe.fiscalAnacPouso)}}" name="fiscalAnacPouso"
                type="text" class="form-control">
            </div>
            <div class="containerReadInput">
              <label for="paxColoPouso">Pax. Colo</label>
              <input matInput readonly id="paxColoPouso" value="{{converter(rpe.paxColoPouso)}}" name="paxColoPouso" type="text"
                class="form-control">
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <br>
  <button mat-raised-button mat-dialog-close class="btnClean" [mat-dialog-close]="true">
    <mat-icon style="margin-left: 1%;font-size: x-large;">clear</mat-icon>
    Fechar 
  </button>
  <br>
  
</div>