<div class="row card">
  <div class="text-center col-12">
    <div class="card-header">
      Lista de Selos que não foram gerados
    </div>
    <div class="card-body ">
      <table class="mat-elevation-z8" mat-table [dataSource]="dataSourceImutavel">

        <ng-container matColumnDef="empiata">
          <th mat-header-cell *matHeaderCellDef>Comp. Aérea</th>
          <td mat-cell *matCellDef="let v">{{ v.empiata }}</td>
        </ng-container>

        <ng-container matColumnDef="dtVoo">
          <th mat-header-cell *matHeaderCellDef>Dt. Voo</th>
          <td mat-cell *matCellDef="let v">{{ formatDateHour(v.dtVoo) }}</td>
        </ng-container>

        <ng-container matColumnDef="numVoo">
          <th mat-header-cell *matHeaderCellDef>Num. Voo</th>
          <td mat-cell *matCellDef="let v">{{ v.numVoo }}</td>
        </ng-container>

        <ng-container matColumnDef="aeroporto">
          <th mat-header-cell *matHeaderCellDef>Aeroporto</th>
          <td mat-cell *matCellDef="let v">{{ v.aeroporto }}</td>
        </ng-container>

        <ng-container matColumnDef="descError">
          <th mat-header-cell *matHeaderCellDef>Desc Erro</th>
          <td mat-cell [matTooltip]="v.descError" *matCellDef="let v">
            {{ v.descError | truncate: 10 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="namePax">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell [matTooltip]="v.namePax" *matCellDef="let v">
            {{ v.namePax | truncate: 15 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="descSsr">
          <th mat-header-cell *matHeaderCellDef>Desc SSR</th>
          <td mat-cell *matCellDef="let v">{{ v.descSsr }}</td>
        </ng-container>

        <ng-container matColumnDef="nameSis">
          <th mat-header-cell *matHeaderCellDef>Nome Sistema</th>
          <td mat-cell *matCellDef="let v">{{ v.nameSis }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsStamLis"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsStamLis"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>

      <br />
      <button mat-raised-button (click)="exportAsXLSX()"
        style="background-color: #1b0088;border-color:#1b0088; color: #FFF; float: left;">
        <mat-icon>get_app</mat-icon>
        Exportar Excel
      </button>

      <button mat-raised-button style="background-color: #1b0088;border-color:#1b0088; color: #FFF; float: right;"
        routerLink="/dashboardOne">
        <mat-icon>arrow_back_ios</mat-icon>
        Voltar
      </button>
      <br><br>

    </div>
  </div>
</div>
