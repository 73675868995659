import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { endpoints } from '../../../../environments/endpoint';
import { FlightLegFile } from '../model/FlightLegFile';
import { FlightSeloRPE } from '../model/FlightSeloRPE';
import { SeloRpeExclude } from '../model/SeloRpeExclude';
import { HistoricRpeSeloExcluded } from '../model/HistoricRpeSeloExcluded';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ExclusionFileRpeSeloService {

  constructor(private http: HttpClient) { }

  getFlightLegToDelete(flightSeloRpe: FlightSeloRPE): Observable<FlightLegFile[]> {
    //console.log("URL= " +endpoints.REST_GTECLOUD_GET_FLIGHT_TO_DELETE);
    return this.http.put<FlightLegFile[]>(endpoints.REST_GTECLOUD_GET_FLIGHT_TO_DELETE, flightSeloRpe, httpOptions);
  }

  postDeleteFileRPEAndSELO(seloRpeExclude : SeloRpeExclude): Observable<HistoricRpeSeloExcluded[]>{
   // console.log("URL= " +endpoints.REST_GTECLOUD_POST_FLIGHT_DELETE);
    return this.http.post<HistoricRpeSeloExcluded[]>(endpoints.REST_GTECLOUD_POST_FLIGHT_DELETE,seloRpeExclude, httpOptions);
  }

  getHistoricRpeSeloDelete(userEmail: string): Observable<HistoricRpeSeloExcluded[]>{
    
    let url :string = endpoints.REST_GTECLOUD_LOGS_DELETED_FILE_RPE_SELO;
    url = url.replace('{email}', userEmail);
   // console.log("URL= " +url);
    
    return this.http.get<HistoricRpeSeloExcluded[]>(url,httpOptions);
  }

  deleteFileRPEAndSELO(idFile :number, userEmail :string, reason :string ): Observable<HistoricRpeSeloExcluded> {

    let url :string = endpoints.REST_GTECLOUD_DELETE_FILE_RPE_SELO;
    url = url.replace('{id}', idFile.toString()).replace('{user}', userEmail)
    .replace('{reason}', reason);  
   // console.log("URL= " + url);
    return this.http.delete<HistoricRpeSeloExcluded>(url, httpOptions);
    

  }
}
