import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DashboardlineRQ } from './model/DashboardlineRQ';
import { DashboardlineRS } from './model/DashboardlineRS';
import { Observable } from 'rxjs';
import { endpoints } from '../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppDashboardRpeGeneratedFlightsService {

  constructor(private http: HttpClient) { }

  convertDataIso(requestDRG: DashboardlineRQ): void {
    let arrayString: string[] = [];
    const dtInicio = new Date(requestDRG.dtInicio).toISOString();
    const dtFim = new Date(requestDRG.dtFim).toISOString();

    arrayString = dtInicio.split('T');
    requestDRG.dtInicio = arrayString[0] + 'T00:00:00';
    arrayString = dtFim.split('T');
    requestDRG.dtFim = arrayString[0] + 'T23:59:59';
  }

  getDataByDate(requestDRG: DashboardlineRQ): Observable<DashboardlineRS[]> {

    if (!requestDRG.dtInicio.includes('T')) {
      this.convertDataIso(requestDRG);
    }

    let params1 = new HttpParams().set('flightBeginDate', requestDRG.dtInicio);
    let params2 = new HttpParams().set('flightEndDate', requestDRG.dtFim);

    return this.http.get<DashboardlineRS[]>(endpoints.REST_GET_RPE_GENERATED_X_TOTAL_FLIGHTS + "?" + params1 + "&" + params2, httpOptions);

  }
}
