import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Periodo } from '../app-search-information-by-period/Periodo';
import { MatDialog } from '@angular/material/dialog';
import { IndicadoresComponent } from '../app-indicators/app-indicators.component';
import { IndicadoresService } from '../app-indicators/app-indicators.service';
import { ValorMultaService } from '../app-indicators/app-value-penalty.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-indicators-list',
  templateUrl: './app-home-indicators-list.component.html',
  styleUrls: ['./app-home-indicators-list.component.css']
})
export class HomeListaIndicadoresComponent extends IndicadoresComponent implements OnInit {

  public eventsSubjectId: Subject<string> = new Subject<string>();
  public eventsSubject: Subject<Periodo> = new Subject<Periodo>();

  constructor(
      public indicadorService: IndicadoresService,
      public valorMultaService: ValorMultaService,
      public router: Router,
      public dialog: MatDialog) {

    super(indicadorService, valorMultaService, router, dialog);
  }

  ngOnInit() {
  }

  // função para receber emit Output do Filho
  receberId(tipoLista: string) {
    // console.log('Foi emitido o evento e chegou no pai >>>> ', tipoLista);
    this.eventsSubjectId.next(tipoLista);
  }

  // função para receber emit Output do Filho
  receberPeriodo(periodo: Periodo) {
    // console.log('Foi emitido o evento e chegou no pai >>>> ', periodo);
    this.eventsSubject.next(periodo);
  }

}
