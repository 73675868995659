<div style="display: block;">
  <canvas class="dashLine" baseChart width="800" height="400"
  [datasets]="lineChartData"
  [labels]="lineChartLabels"
  [options]="lineChartOptions"
  [legend]="lineChartLegendMonV"
  [plugins]="lineChartPluginsMonV"
  (chartClick)="chartClicked($event)"></canvas>
  <!-- <canvas class="dashLine" baseChart width="800" height="400"
  [datasets]="lineChartData"
  [labels]="lineChartLabels"
  [options]="lineChartOptions"
  [colors]="lineChartColors"
  [legend]="lineChartLegend"
  [chartType]="lineChartType"
  [plugins]="lineChartPlugins"
  (chartClick)="chartClicked($event)"></canvas> -->
</div>
