export class RequestListContestation {

  public dtVoo: string;
  public type: string;

  constructor(){
    this.dtVoo = '';
    this.type = '';
  }
  
}
