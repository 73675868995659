import { OpenDialog } from './../../../utils/openDialog';
import { Component, OnInit, Inject } from '@angular/core';
import { User } from '../model/User';
import { Profile } from '../model/Profile';
import { ProfileService } from '../service/profile.service';
import { UserProfileService } from '../service/user-profile.service';
import { Profiler } from 'inspector';
import { DialogCustomComponent } from '../../../../app/app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { properties } from '../../../../environments/properties';
import { AuthenticationEmailService } from '../../../../app/app-authenticator/authentication-email.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-app-modal-administrator-user',
  templateUrl: './app-modal-administrator-user.component.html',
  styleUrls: ['./app-modal-administrator-user.component.css']
})
export class AppModalAdministratorUserComponent implements OnInit {


  public user: User;
  public typeProfileChoose: Profile;
  public typeProfiles: Profile[];

  private readonly EMAIL_LATAM: string = "@latam.com";




  constructor(public profileService :ProfileService,
    public userProfileService: UserProfileService,
    public validationEmailService: AuthenticationEmailService,
    public dialog: MatDialog,
    private openDialogUtils: OpenDialog,
    @Inject(MAT_DIALOG_DATA) public userProfile: User,
    private dialogRef: MatDialogRef<AppModalAdministratorUserComponent>) {
      this.obterComboBoxProfile();
     }

  ngOnInit() {
    this.user = new User();
     this.typeProfileChoose = new Profile();

  }


  public cancelOperation(): void {
    this.dialogRef.close();
  }

  public obterComboBoxProfile(): void {
    this.profileService.getAllProfile().subscribe(
    dataSource => {
     this.typeProfiles = dataSource;
     this.user = this.userProfile;
     this.typeProfileChoose= this.userProfile.profile;
    //console.log(this.user);
    });
  }

  public saveUserProfile(){

    if(this.unfilledFields()){
        return;
    }

    if(this.checkEmail()){
      this.openDialog(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
        properties.MODAL_CONTENT_EMAIL_LATAM_INVALID);
      return;
    }

    this.user.profile = this.typeProfiles.find( data => data.type === this.typeProfileChoose.type);
   // console.log(this.user);

    this.userProfileService.saveUserProfile(this.user).subscribe(dataSource =>{
      this.openDialog(
        properties.MODAL_TITLE_SUCESS,
        properties.MODAL_CONTENT_USER_SAVE_SUCESS);
      this.dialogRef.close();
    }, err => {
        this.openDialog(
          properties.MODAL_ERROR_TITLE,
          properties.MODAL_CONTENT_USER_SAVE_ERROR);
    });
  }

  private openDialog(mdAdmtitleParam: string, mdAdmmessageParam: string): void {
    this.openDialogUtils.openDialog(mdAdmtitleParam,mdAdmmessageParam)
  }

  private unfilledFields(): boolean{

    if(this.userProfile.name === null || this.userProfile.name === undefined
      || this.userProfile.name === ''){
        this.openDialog(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_NAME_USER_INVALID);
        return true;
      }

    if(this.userProfile.email === null || this.userProfile.email === undefined
      || this.userProfile.email === ''){
        this.openDialog(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_EMAIL_USER_INVALID);
        return true;
      }

    if(this.typeProfileChoose.type === null || this.typeProfileChoose.type === undefined
      || this.typeProfileChoose.type === ''){
        this.openDialog(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_PROFILE_USER_INVALID);
        return true;
      }


      return false;

  }

  private checkEmail (): boolean{

    let email: string = this.userProfile.email.substring(this.userProfile.email.indexOf("@"));

    return email !== this.EMAIL_LATAM;
  }

}
