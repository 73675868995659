import { Component, OnInit } from '@angular/core';
//import { UserService } from '../utils/user-service.service';
import { AuthenticationService } from '../app-authenticator/authentication.service';
import { GoogleApiService, GoogleAuthService } from 'ng-gapi';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieUtilsService } from '../app-cookies/cookie-utils.service';
import * as _ from "lodash";
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-app-login',
  templateUrl: './app-login.component.html',
  styleUrls: ['./app-login.component.css']
})
export class AppLoginComponent implements OnInit {

  private cookieUtil: CookieUtilsService = new CookieUtilsService();

  constructor(private authenticationService: AuthenticationService,
              private gapiService: GoogleApiService,
              private activatedRoute: ActivatedRoute) {


    console.log('constructor in login app .... ')
    const route:any = this.activatedRoute;
    const url = new URL(route.pathname ?? environment.robotsHost) ;
    const tokenGet = url.searchParams.get('token');
    if (tokenGet) {
      this.authenticationService.setTokenAndType(tokenGet, 'everis');
    } else {
      // First make sure gapi is loaded can be in AppInitilizer
      this.gapiService.onLoad().subscribe();
    }
  }

  ngOnInit() {
    this.authenticationService.isUserSignedIn().then(
      resp => {
        // console.log('resp login: ' + resp);
        if (resp != null && resp == true) {
          // console.log('redirect to home .... ');
          //this.route.navigate(['/dashboardOne']);
        } else {
          this.authenticationService.signOut();
          this.authenticationService.signGoogle();
        }
      }
    );
  }

  public isLoggedIn(): boolean {
    if (this.authenticationService.getCurrentUser()) {
      return true;
    }
    return false;
  }

  public signIn() {
    this.authenticationService.signGoogle();
  }

}
