<br>
<form>
  <div class="card text-center">
    <div class="card-header" style="background-color: #1b0088;">
      <h5 style="color: #ffffff;">Importação de Planilha para Conciliação.</h5>
    </div>
    <div class="card-body">

      <p>
        <mat-form-field id="matFormFieldCompany" class="inputLayout">
          <input matInput id="company" name="company" [(ngModel)]="iataCompany" placeholder="Empresa"
            aria-label="Empresa" [matAutocomplete]="autoCompany" [formControl]="empresaCtrl" maxlength="2"
            oninput="this.value = this.value.toUpperCase()">
          <mat-autocomplete #autoCompany="matAutocomplete">
            <mat-option *ngFor="let empresa of filteredEmpresa | async" [value]="empresa.iataCode">
              <span>{{empresa.iataCode}} - {{empresa.nameCompany}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field class="inputLayout">
          <mat-label>Aeroporto</mat-label>

          <input matInput id="airport" name="airport" [(ngModel)]="airport" placeholder="Aeroporto"
            aria-label="Aeroporto" [formControl]="airportCtrl" [matAutocomplete]="autoAeroporto" maxlength="3"
            oninput="this.value = this.value.toUpperCase()">

          <mat-autocomplete #autoAeroporto="matAutocomplete">
            <mat-option *ngFor="let airport of filteredAeroportos  | async" [value]="airport.codeIATA">
              {{airport.codeIATA}} - {{airport.descriptionName}}
            </mat-option>
          </mat-autocomplete>

        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="inputLayout">
          <mat-label>Layout</mat-label>
          <mat-select id="layout" [(value)]="layout">
            <mat-option *ngFor="let layout of layoutMutable" [value]="layout.idLayout">
              {{layout.idLayout}} - {{layout.nameLayout}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </p>

      <mat-card-actions>
        <div *ngIf="disabled(); then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
          <form enctype="multipart/form-data">
            <div class="uploadfilecontainer disabled disabled-input-mouse" (click)="file.click()" appDragDrop
              (onFileDropped)="uploadFile($event)">
              <input hidden type="file" [disabled]="disabled()" #file (change)="execute()">
            </div>
          </form>
        </ng-template>
        <ng-template #elseBlock>
          <form enctype="multipart/form-data">
            <div class="uploadfilecontainer enabled" (click)="file.click()" appDragDrop
              (onFileDropped)="uploadFile($event)">
              <input hidden type="file" #file (change)="execute()">
            </div>
          </form>
        </ng-template>
      </mat-card-actions>

    </div>
  </div>
</form>
