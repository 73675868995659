export class ListWorkaroundExcel {

    idWaRpe: number;
    airlineComp: string;
    cdNumVoo: string;
    dtVoo: any;
    dtProcess: any;
    tpWa: string;
    adAeropOrin: string;
    destAirport: string;


    constructor(){
        this.idWaRpe = undefined;
        this.airlineComp = undefined;
        this.cdNumVoo = undefined;
        this.dtVoo = undefined;
        this.dtProcess = undefined;
        this.tpWa = undefined;
        this.adAeropOrin = undefined;
        this.destAirport = undefined;

    }

}