import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Profile } from '../model/Profile';
import { endpoints } from '../../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }

  getAllProfile(): Observable<Profile[]> {

    return this.http.get<Profile[]>(endpoints.REST_GTECLOUD_GET_ALL_PROFILES, httpOptions);

  }
}
