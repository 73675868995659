import { TicketControl } from './embedded/ticket-control';

export class TicketInsert {

    public collectionIdRpe: number[];
    public registerTickerControlDTO: TicketControl;


    constructor(){
        this.registerTickerControlDTO = undefined;
    }
}
