import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IndicadoresService } from './app-indicators.service';
import { Indicador } from './app-indicators';
import { ValorMultaService } from './app-value-penalty.service';
import { Multa } from './app-value-penalty';
import { Observable } from 'rxjs';
import { Periodo } from '../app-search-information-by-period/Periodo';
import { Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { properties } from '../../../environments/properties';
import { DialogCustomComponent } from '../app-dialog-custom/app-dialog-custom.component';

@Component({
  selector: 'app-indicators',
  templateUrl: './app-indicators.component.html',
  styleUrls: ['./app-indicators.component.css']
})
export class IndicadoresComponent implements OnInit {

  @Input("dtInicio")
  dtInicio: any;

  @Input("dtFim")
  dtFim: any;

  public periodo: Periodo;
  indicadorCritico: Indicador = { quantidade: 0 };
  indicadorIntermediario: Indicador = { quantidade: 0 };
  indicadorInformativo: Indicador = { quantidade: 0 };

  private eventsSubscription: any;

  @Input() events: Observable<Periodo>;
  @Output() tipoLista = new EventEmitter<string>();

  constructor(
    public indicadorService: IndicadoresService,
    public valorMultaService: ValorMultaService,
    public router: Router,
    public dialog: MatDialog) {

  }

  ngOnInit() {
    this.obterIndicadorCritico();
    this.obterIndicadorIntermediario();
    this.obterIndicadorInformativo();
  }

  public obterIndicadorCritico(): void {
    this.indicadorService.obterIndicadorCritico().subscribe(response => this.indicadorCritico = response);
  }

  public obterIndicadorIntermediario(): void {
    this.indicadorService.obterIndicadorIntermediario().subscribe(response => this.indicadorIntermediario = response);
  }

  public obterIndicadorInformativo(): void {
    this.indicadorService.obterIndicadorInformativo().subscribe(response => this.indicadorInformativo = response);
  }

  public getIdRequestCritico(tipoLista: string) {
    if (this.indicadorCritico.quantidade != 0) {
    this.router.navigate(['/listaIndicadores', tipoLista]);
    } else {
      this.openDialogInd(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_INDICATORS_DATA_NOT_FOUND
      );
    }
  }

  public getIdRequestInformativo(tipoLista: string) {
    if (this.indicadorInformativo.quantidade != 0) {
    this.router.navigate(['/listaIndicadores', tipoLista]);
    } else {
      this.openDialogInd(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_INDICATORS_DATA_NOT_FOUND
      );
    }
  }

  public getIdRequestIntermediario(tipoLista: string) {
    if (this.indicadorIntermediario.quantidade != 0){
    this.router.navigate(['/listaIndicadores', tipoLista]);
    } else {
      this.openDialogInd(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_INDICATORS_DATA_NOT_FOUND
      );
    }
  }

  private getNgDateByDate(data: Date): NgbDate{
    return new NgbDate(data.getFullYear(),(data.getMonth()+1),data.getDate());
  }

  public openDialogInd(titleParam: string, messageParam: string): void {
    const dialogRef = this.dialog.open(DialogCustomComponent, {
        width: '450px',
        data: {title: titleParam, message: messageParam}
    });
  }
}
