import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbDatepickerConfig, NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Rpe } from '../app-search-information-by-date-and-rpe/model/rpe';
import { SearchInformationByDateAndRpeComponent } from '../app-search-information-by-date-and-rpe/app-search-information-by-date-and-rpe.component';
import { RpeEdit } from '../app-edit-rpe-form/model/rpe-edit';
import { DatePipe } from '@angular/common';
import { ComboBoxService } from '../app-search-information-by-date-and-rpe/combo-box.service';
import { DialogCustomComponent } from '../app-dialog-custom/app-dialog-custom.component';
import { HistFileRpeEdit } from '../app-edit-rpe-form/model/hist-file-rpe-edit';
import { ConsultFileRpeEdit } from '../app-edit-rpe-form/model/consult-file-rpe-edit';
import { RpeEditResponse } from '../app-edit-rpe-form/model/rpe-edit-response';
import { EditarRpeFormularioComponent } from '../app-edit-rpe-form/app-edit-rpe-form.component';

@Component({
  selector: 'app-home-edit-rpe',
  templateUrl: './app-home-edit-rpe.component.html',
  styleUrls: ['./app-home-edit-rpe.component.css']
})
export class HomeEditarRpeComponent extends SearchInformationByDateAndRpeComponent implements OnInit {

  public eventsSubject: Subject<Rpe> = new Subject<Rpe>();

  public eventsSubjectTwo: Subject<RpeEdit> = new Subject<RpeEdit>();

  public eventsSubjectId: Subject<number> = new Subject<number>();

  public hide: boolean;

  public checked: boolean;

  public histFileRpeEdit: HistFileRpeEdit;

  public consultFileEditRPE: ConsultFileRpeEdit;

  public rpeEditResponse: RpeEditResponse;



  constructor(private editcomponet: EditarRpeFormularioComponent, config: NgbDatepickerConfig, calendar: NgbCalendar, datePipe: DatePipe, comboBoxService: ComboBoxService, matDialog: MatDialog) {
    super(config, calendar, datePipe, comboBoxService, matDialog);
    this.hide = true;
    this.checked = false;
    this.histFileRpeEdit = new HistFileRpeEdit();
    this.consultFileEditRPE = new ConsultFileRpeEdit();
    this.consultFileEditRPE.fligthLegDTO = new RpeEditResponse();
    this.rpeEditResponse = new RpeEditResponse();
  }

  ngOnInit() {
  }

  public openDialog(titleParam: string, messageParam: string): void {
    const dialogRef = this.dialog.open(DialogCustomComponent, {
      width: '450px',
      data: { title: titleParam, message: messageParam }
    });
  }

  receberRpe(rpeRequest: Rpe) {
    console.log('Foi emitido eventsSubject 1')
    this.eventsSubject.next(rpeRequest);
    //this.editcomponet.getDadosRpeGeradoForm(rpeRequest);
  }

  receberIdRpe(idRpe: number) {
    console.log('Foi emitido e ta na função receberIdRpe', idRpe)
    this.eventsSubjectId.next(idRpe);
  }

  receberRpeGerado(rpeGeradoRequest: RpeEdit) {

    console.log('Foi emitido o evento e chegou no pai 2 >>>> ', rpeGeradoRequest);

    this.eventsSubjectTwo.next(rpeGeradoRequest);

  }

}
