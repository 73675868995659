<br />
<div class="row">
    <!-- [ GRAFICO COM OS PROBLEMAS DE ORIGEM ] -->
    <div class="col-5">
      <div class="titleDash">
        <h4>{{ titleCharOriginProblem }}</h4>
      </div>
      <app-dashboard-pizza-rpe-generated></app-dashboard-pizza-rpe-generated>
    </div>
    <!-- [ GRAFICO COM OS PROBLEMAS ENCONTRADOS ] -->
    <div class="col-5">
      <div class="titleDash">
        <h4>{{ titleCharProblem }}</h4>
      </div>
      <app-dashboard-column-rpe-generated></app-dashboard-column-rpe-generated>
    </div>
  </div>
  <br />
 <!-- [ TABELA COM OS PROBLEMAS ] -->
 <div class="mat-app-background basic-container">
    <app-data-rpe-generated-list></app-data-rpe-generated-list>
</div>
