import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-third-level-dashboard-rpe-generated',
  templateUrl: './app-third-level-dashboard-rpe-generated.component.html',
  styleUrls: ['./app-third-level-dashboard-rpe-generated.component.css']
})
export class AppThirdLevelDashboardRpeGeneratedComponent implements OnInit {

  titleCharOriginProblem = 'Tipo de Voo';
  titleCharProblem = 'Aeroporto de Origem';

  constructor() { }

  ngOnInit() {
  }

}
