<br>
  <!-- [ DASHBOARDS ORIGEM DO PROBLEMA] -->
  <div class="row">
    <div class="titleDash">
      <h4 class="headerTitleModule">{{ titleModule }}</h4>
    </div>
      <div class="centraliza">
      <app-dashboard-pizza-rpe-generated></app-dashboard-pizza-rpe-generated>
     </div> 
  </div>
  <br>

  <button 
    mat-raised-button 
    style="background-color: #1b0088;border-color:#1b0088; color: #FFF; float: right;"
    routerLink="/dashboardOne">
    <mat-icon>arrow_back_ios</mat-icon>
    Voltar
  </button>
