import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class FormSearchGeneratedFile {
    companyName: string;
    airportName: string;
    numberFlightArrival: number;
    numberFlightDeparture: number;
    startDate: NgbDate;
    endDate: NgbDate;
    fileType: string;
    all: boolean = false;
}