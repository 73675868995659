export class  Administrator {

    identification: number;
    name: string;
    pathFile: string;
    conxFtpUser: string;
    conxFTPSecret: string;
    host: string;
    nameUserLastUpdate: string;
    lastUpdate: any;

}
