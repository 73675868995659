
import { Component, Inject } from '@angular/core';
import { ModalChamadoService } from './app-dialog-ticket.service';
import { Protocolo } from './model/protocolo';
import ProtocoloChamado from '../app-enum/protocolo-chamado';
import { Status } from './model/status';
import { Voo } from '../app-data-flight-list/model/voo';
import { properties } from '../../../environments/properties';
import { UntypedFormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { NgbDatePTParserFormatter } from '../app-search-information-by-period/NgbDatePTParserFormatter';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n, I18n } from '../app-search-information-by-period/CustomDatepickerI18n';
import { NgbDatepickerConfig, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DialogCustomComponent } from '../app-dialog-custom/app-dialog-custom.component';
import { TicketInsert } from './model/ticket-insert';
import { TicketControl } from './model/embedded/ticket-control';
import { TicketEdit } from './model/ticket-edit';
import { Ticket } from '../app-data-flight-list/model/ticket';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-ticket',
  templateUrl: './app-dialog-ticket.component.html',
  styleUrls: ['./app-dialog-ticket.component.css'],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter}],
  ],
})

export class ModalChamadoComponent  {
  public isDisabled: boolean;
  public formulario: any;
  private activeInput: string;
  public dataMin = undefined;
  public protocoloInsert: Protocolo;
  displayMonths = properties.HTML_CONST_QTD_MOUNTHS;

  public chamadoInsert: TicketInsert;
  public chamadoEdit: TicketEdit;
  public registerTickerControlDTO: TicketControl;
  public ticketControl: Ticket;
  public alertCadastroRealizado: boolean;
  public alertNaoFoiPossivelCadastro: boolean;
  public mensagemResposta: string;
  public status: Status;

  constructor(
    public dialog: MatDialog,
    config: NgbDatepickerConfig,
    calendar: NgbCalendar,
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ModalChamadoComponent>,
    public modalChamadoService: ModalChamadoService,
    @Inject(MAT_DIALOG_DATA) public data: Voo[]) {

      this.formulario =   this.fb.group(
        {
          numTck: [undefined, Validators.required],
          dtTckEnd: [undefined, Validators.required],
          comments: [undefined, Validators.required]
      });

      this.protocoloInsert = new Protocolo();

      this.chamadoInsert = new TicketInsert();
      this.chamadoInsert.registerTickerControlDTO = new TicketControl();
      this.chamadoInsert.collectionIdRpe = new Array();
      this.data.forEach( voo => this.chamadoInsert.collectionIdRpe.push(voo.idRPE));
      const voo: Voo = this.data[0];
      this.chamadoInsert.registerTickerControlDTO.numTck = voo.ticketControl != null ? voo.ticketControl.numTck : 0;
      this.chamadoInsert.registerTickerControlDTO.comments = voo.ticketControl != null ? voo.ticketControl.comments : '';
      voo.ticketControl.dtTckEnd != null ? this.chamadoInsert.registerTickerControlDTO.dtTckEnd = this.setCalendarValue(voo.ticketControl.dtTckEnd) : console.log('');
      // console.log("ChamadoINSERT: ", this.chamadoInsert);

      this.chamadoEdit = new TicketEdit();
      this.chamadoEdit.ticketControlDTO = new TicketControl();
      this.chamadoEdit.collectionIdTicketControl = new Array();
      this.data.forEach( vooEdit =>  this.chamadoEdit.collectionIdTicketControl.push(vooEdit.ticketControl.idTicketControl));
      const vooEdit: Voo = this.data[0];
      this.chamadoEdit.ticketControlDTO.numTck = vooEdit.ticketControl.numTck;
      this.chamadoEdit.ticketControlDTO.dtTckEnd = vooEdit.ticketControl.dtTckEnd;
      this.chamadoEdit.ticketControlDTO.comments = vooEdit.ticketControl.comments;
      voo.ticketControl.dtTckEnd != null ? this.chamadoEdit.ticketControlDTO.dtTckEnd = this.setCalendarValue(voo.ticketControl.dtTckEnd) : console.log('');
      const current = new Date();
      // console.log("ChamadoEdit: ", this.chamadoEdit)

      config.maxDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate()
      };

      config.outsideDays = 'hidden';
  }

  private setCalendarValue(dt: string): NgbDate {

    let dtArray: string[];
    dtArray = dt.split('-');

    return new NgbDate(parseInt(dtArray[0]),
                        parseInt(dtArray[1]),
                          parseInt(dtArray[2]));
  }

  abrirChamado() {
    if (this.chamadoEdit.collectionIdTicketControl[0] == null ) {
      // console.log("chamadoInsert: ", this.chamadoInsert);
      this.modalChamadoService.addChamado(this.chamadoInsert).subscribe(protocoloResponse => {
      this.protocoloInsert = protocoloResponse;
      // console.log(this.protocoloInsert);
      this.validarStatusCadastroChamado(this.protocoloInsert);
      if( this.protocoloInsert.status == ProtocoloChamado.DATAHEALTH_ASSOCIADO ){
        this.status = Status.ERRO;
      }else {
        this.status = Status.SUCESSO;
        this.dialogRef.close();
        this.openDialogDialTi(
          properties.MODAL_TITLE_SUCESS,
          properties.MODAL_CONTENT_SUCESS_INSERT
        );
      }
    },
      err => {
        let protocolo: Protocolo = err.error;
        this.validarStatusCadastroChamado(protocolo);
        //console.log(protocolo);
        this.status = Status.ERRO;
        //console.log("Chamou o INSERIR");
      });
    } else {
        this.modalChamadoService.editChamado(this.chamadoEdit).subscribe(protocoloResponse => {
        let protocolo: Protocolo = protocoloResponse;
        this.validarStatusCadastroChamado(protocolo);
        //console.log(protocolo);
        this.status = Status.SUCESSO;
        this.dialogRef.close();
        this.openDialogDialTi(
          properties.MODAL_TITLE_SUCESS,
            properties.MODAL_CONTENT_SUCESS_EDIT
        );
      },
        err => {
          let protocolo: Protocolo = err.error;
          this.validarStatusCadastroChamado(protocolo);
          //console.log(protocolo);
          this.status = Status.ERRO;
          //console.log("Chamou o ALTERAR");
          //console.log("TRATAMENTO PROTOCOLO: ",protocolo.status);
        });
    }
    this.mensagemResposta = properties.MODAL_CHAMADO_MENSAGEM_CARREGANDO;
    this.status = Status.SEM_INFORMACOES;
  }

  validarStatusCadastroChamado(protocolo: Protocolo): void {
    // console.log('recebendo isso como protocolo: ' + (protocolo != undefined && protocolo != null) ? JSON.stringify(protocolo) : 'deu rui é null: ' + protocolo );
    switch (protocolo.status) {
      case ProtocoloChamado.CONTROLE_SALVO:
        this.mensagemResposta = properties.MODAL_CHAMADO_MENSAGEM_SUCESSO;
        break;
      case ProtocoloChamado.DATAHEALTH_ASSOCIADO:
        this.mensagemResposta = properties.MODAL_CHAMADO_MENSAGEM_DATAHEALTH_JA_ASSOCIADO;
        break;
      case ProtocoloChamado.DATAHEALTH_NAO_ENCONTRADO:
        this.mensagemResposta = properties.MODAL_CHAMADO_MENSAGEM_DATAHEALTH_NAO_ENCONTRADO;
        break;
      case ProtocoloChamado.REQUEST_INVALIDO:
        this.mensagemResposta = properties.MODAL_CHAMADO_MENSAGEM_REQUEST_INVALIDO;
        break;
      default:
        this.mensagemResposta = protocolo.descricao;
        break;
    }
  }

  private getIsoByDate(dtNgbDate: NgbDate, control: boolean): string {
    const hour: string = control ? '00:00:00' : '23:59:59';
    const monthTemp: string = (dtNgbDate.month < 10 ) ? '0' + dtNgbDate.month : '' + dtNgbDate.month;
    const dayTemo: string = (dtNgbDate.day < 10 ) ? '0' + dtNgbDate.day : '' + dtNgbDate.day;

    const dateIso: string =  dtNgbDate.year
                            + '-' + monthTemp
                              + '-' + dayTemo
                                + 'T' + hour;

    //console.log('[ PERIODO ] {}: ' + dateIso);
    return dateIso;
  }

  public onDateStartSelect(dtSelect: NgbDate) {
    //console.log('CLICADO DT START INPUT: ' + dtSelect.day + '/'
    //                                          + dtSelect.month + '/'
    //                                            + dtSelect.year
    //                                              + ' ACTUAL INPUT: ' + this.activeInput);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public openDialogDialTi(titleParam: string, messageParam: string): void {
    const dialogRef = this.dialog.open(DialogCustomComponent, {
        width: '450px',
        data: {title: titleParam, message: messageParam}
    });
  }
}


