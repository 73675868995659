<app-header></app-header>
<ngx-ui-loader
  fgsSize="75"
  [fgsType]="'rectangle-bounce-pulse-out-rapid'"
  bgsPosition="bottom-right"
  bgsType="rectangle-bounce-pulse-out-rapid"
  bgsSize="75">
</ngx-ui-loader>
<div class="container">
  <router-outlet></router-outlet>
</div>

