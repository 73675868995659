import { LayoutAmount } from './LayoutAmount';
import { LayoutFlight } from './LayoutFlight';

export class Layout {

    idLayout: number;
    nameLayout: string;
    iataAirport: string;
    startLine: number;
    conciliationType: string;
    layoutAmount: LayoutAmount;
    layoutFlight: LayoutFlight;
    statusLayout: string;

    constructor() {
        this.idLayout = undefined;
        this.nameLayout = undefined;
        this.iataAirport = undefined;
        this.startLine = undefined;
        this.conciliationType = undefined;
        this.layoutAmount = new LayoutAmount();
        this.layoutFlight = new LayoutFlight();
        this.statusLayout = undefined;
    }
}
