<div class="modalContentTwo">

  <h4>Linhas em desacordo com layout para a planilha inserida</h4>

  <p>O sistema não conseguiu interpretar a importação</p>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

    <ng-container matColumnDef="linha">
      <th mat-header-cell *matHeaderCellDef>LINHA INCORRETA</th>
      <td mat-cell *matCellDef="let v">{{ v.line }}</td>
    </ng-container>

    <ng-container matColumnDef="aba">
      <th mat-header-cell *matHeaderCellDef>ABA INTERPRETADA</th>
      <td mat-cell *matCellDef="let v">{{ v.tab}}</td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" [showFirstLastButtons]="true"></mat-paginator>

  <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
    <mat-button-toggle value="bold" (click)="dialogClose()">Fechar</mat-button-toggle>
  </mat-button-toggle-group>

</div>
