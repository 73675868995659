import { OpenDialog } from './../../utils/openDialog';

import { FlightGenerated } from './model/FlightGenerated';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormSearchGeneratedFile } from './model/FormSearchGeneratedFile';
import { Router, ActivatedRoute } from '@angular/router';
import { ComboBoxService } from '../../app-datahealth/app-search-information-by-date-and-rpe/combo-box.service';
import { DateService } from '../../app-conciliation/conciliation/service/date.service';
import { I18n, CustomDatepickerI18n } from '../../app-datahealth/app-search-information-by-period/CustomDatepickerI18n';
import { NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatePTParserFormatter } from '../../app-datahealth/app-search-information-by-period/NgbDatePTParserFormatter';
import { ComboBoxAeroporto } from '../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { ComboBoxEmpresa } from '../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-empresa';
import { Protocolo } from '../../app-datahealth/app-enum/Protocolo';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AppGeneratedManualFilesService } from './app-generated-manual-files.service';
import { FlightProcess } from './model/FlightProcess';
import { DialogCustomComponent } from '../../../../src/app/app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { properties } from '../../../../src/environments/properties';
import * as FileSaver from 'file-saver';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-app-generated-manual-files',
  templateUrl: './app-generated-manual-files.component.html',
  styleUrls: ['./app-generated-manual-files.component.css'],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{ provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter }],
  ],
})
export class AppGeneratedManualFilesComponent implements OnInit {

  private RPE: string = "RPE";
  private STAMP: string = "SELO";

  public displayedColumnsMnFile: string[] = [
    'select',
    'compyCodeIataArrival',
    'originIataCodeArrival',
    'destIataCodeArrival',
    'numFlightArrival',
    'dateFlightArrivalOrigin',
    'dateFlightArrivalDestine',
    'compyCodeIata',
    'originIataCode',
    'destIataCode',
    'numFlightDeparture',
    'dttmFlightDepartureOrigin',
    'dttmFlightDepartureDestine'
  ];

  private IMG_SUCCESMF: string = "../../assets/success.png";
  private IMG_ERRORMF: string = "../../assets/error.png";

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dataSourceflightsMF = new MatTableDataSource<FlightProcess>();

  public formSearchMF: FormSearchGeneratedFile = new FormSearchGeneratedFile();
  public controlSaveMF: boolean = false;
  public controlShowTableMF: boolean = true;

  public aeroportoGMF: ComboBoxAeroporto[] = [];
  public empresaGMF: ComboBoxEmpresa[];

  aeroportoCtrl = new FormControl();
  empresaCtrl = new FormControl();
  flightNumberCtrl = new FormControl();
  dateCtrl = new FormControl();

  filteredAeroporto: Observable<ComboBoxAeroporto[]>;
  filteredEmpresa: Observable<ComboBoxEmpresa[]>;
  selection = new SelectionModel<any>(true, []);

  eventsSubscription: any;
  reportType: string = "";
  reportTitle: string = "";

  constructor(
    private routeGe: ActivatedRoute,
    public comboBoxServiceGe: ComboBoxService,
    public dateService: DateService,
    private dialogGe: MatDialog,
    private appGeneratedManualFilesServiceGe: AppGeneratedManualFilesService,
    private openDialogUtils: OpenDialog) {

  }

  ngOnInit() {

    this.eventsSubscription = this.routeGe.params.subscribe(params => {
      this.reportType = this.getReportTypeByRequestGMF(params['typeSerach']);
      this.clearFields();
      this.controlShowTableMF = true;
    });

    this.obterComboBoxAirportGMF();
    this.obterComboBoxCompanyGMF();
  }

  ngDoCheck(){
    this.isValidToRenderHeaderGMF();

  }

  public isValidToRenderHeaderGMF(): void{
    // console.log("change request header: " + this.reportType);
    if(this.reportType == 'rpe'){
      $("#headerRpeFlight").show();
    }else{
      $("#headerRpeFlight").hide();
    }
  }

  public getReportTypeByRequestGMF(typeFileSearch: string): string {
    // console.log("receive this request: " + typeFileSearch);
    this.reportTitle = typeFileSearch == "rpe" ? "RPE's" : "SELO's";
    return typeFileSearch;
  }

  public enableToGeneratedFilesGMF(): boolean {
    return this.selection?.selected?.length <= 0;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceflightsMF.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceflightsMF.data.forEach(row => this.selection.select(row));
  }

  clearDataSourceListGMF() {
    this.selection?.clear();
  }

  /**
   * [[ FUNCAO PARA BUSCAR OS VOOS QUE NÃO FORAM PROCESSADOS ]]
   */
  public searchFlight() {

    this.disableInputs();
    this.controlShowTableMF = true;

    if(!this.isValidToSearch()){
      this.enableInputs();
      this
      .dialogGe
      .open(
        DialogCustomComponent,
        {width: '450px',
        data: { title: properties.MODAL_ERROR_TITLE, message: properties.MODAL_CHAMADO_MENSAGEM_REQUEST_INVALIDO }});
    }

    this.appGeneratedManualFilesServiceGe
      .getFlightNotProcessedAuto(this.formSearchMF, this.reportType)
      .subscribe(
        dataSource => {

          if(dataSource.length > 0){

            this.controlShowTableMF = false;
            dataSource.sort((a, b) => (a.numFlightDeparture < b.numFlightDeparture) ? -1 : 1);
            dataSource.sort((a, b) => (a.dttmFlightDepartureOrigin < b.dttmFlightDepartureOrigin) ? -1 : 1);
            this.clearDataSourceListGMF();
            this.dataSourceflightsMF = new MatTableDataSource(dataSource);
            this.dataSourceflightsMF.paginator = this.paginator;

          }else{
            this
            .dialogGe
            .open(
              DialogCustomComponent, {
                width: '450px',
                data: { title: properties.MODAL_ERROR_TITLE, message: properties.MODAL_CONTENT_ERROR_GENERATED_FILE_MANUAL }});
          }
          //console.log('succes operation, flights not process: ' + JSON.stringify(dataSource));
        }, err => {

          this
          .dialogGe
          .open(
            DialogCustomComponent, {
              width: '450px',
              data: { title: properties.MODAL_ERROR_TITLE, message: properties.MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE }});
          this.enableInputs();
        }, () => {

          this.enableInputs();
        });

  }

  /**
   * [[ VALIDACAO VALIDAR PARAMETROS DE BUSCA ]]
   */
  public isValidToSearch(): boolean {

    return this.isEmptyGMF(this.formSearchMF.airportName) && this.isEmptyGMF(this.formSearchMF.companyName) && this.formSearchMF.startDate != null;
  }

  /**
   * [[ FUNCAO PARA LIMPAR OS CAMPOS DA TELA ]]
   */
  public clearFields() {
    this.formSearchMF = new FormSearchGeneratedFile();
    this.dataSourceflightsMF = new MatTableDataSource<FlightProcess>();
  }
  // Andrietii
  public obterComboBoxAirportGMF(): void {
    this.comboBoxServiceGe.getComboBoxAeroporto().subscribe(
      dataSource => {
        this.aeroportoGMF = dataSource;
        console.log(this.aeroportoGMF, "<---This.aeroportoGMF generated manual")
        console.log(this.aeroportoCtrl.valueChanges
          .pipe(
            startWith(''),
            //map(aeroporto => aeroporto ? this._filterAeroporto(aeroporto) : this.aeroportoGMF.slice())
            map(aeroporto => this._filterAeroporto(aeroporto || ''))
            ), "<---This.aeroportoCtrl.valueChanges generated manual")
            this.filteredAeroporto = this.aeroportoCtrl.valueChanges.pipe(
              startWith(''),
              map(value => this._filterAeroporto(value || ''))
            );
            console.log(this.filteredAeroporto, "<---This.filtered aeroporto generated manual")

          }, err => {
        const protocolo: Protocolo = err.error;
      });
  }



  public obterComboBoxCompanyGMF(): void {
    this.comboBoxServiceGe.getComboBoxEmpresa().subscribe(
      dataSource => {
        this.empresaGMF = dataSource;
        this.filteredEmpresa = this.empresaCtrl.valueChanges
          .pipe(
            startWith(''),
            map(empresa => empresa ? this._filterEmpresa(empresa) : this.empresaGMF.slice())
          );
      }, err => {
        const protocolo: Protocolo = err.error;
      });
      console.log(this.filteredAeroporto, "<---This.filtered aeroporto generated manual")
  }

  private _filterAeroporto(value: string): ComboBoxAeroporto[] {
    const filterValue = value.toLowerCase();
    console.log(value, "<----value função filterAeroporto")
    console.log(value.toLowerCase(), "<---value.toLowerCase() função filterAeroporto");
    console.log(this.aeroportoGMF, "<----Aeroporto GMF na função filterAeroporto");
    console.log(this.aeroportoGMF.filter(aeroporto => aeroporto.codeIATA.toLowerCase().includes(filterValue)), "<---Aeroporto GMF filtrado na função filterAeroporto");
    return this.aeroportoGMF.filter(aeroporto => aeroporto.codeIATA.toLowerCase().includes(filterValue));
  }


  private _filterEmpresa(value: string): ComboBoxEmpresa[] {
    const filterValue = value.toLowerCase();
    return this.empresaGMF.filter(empresa => empresa.iataCode.toLowerCase().indexOf(filterValue) === 0);
  }

  public getFormatDateToStringIso(date: any): string {
    return this.dateService.ngbDateTimeToStringNoSeconds(date);
  }

  public disableInputs(): void {
    this.controlSaveMF = true;
    this.aeroportoCtrl.disable();
    this.empresaCtrl.disable();
    this.flightNumberCtrl.disable();
    this.dateCtrl.disable();
  }

  public enableInputs(): void {
    this.controlSaveMF = false;
    this.aeroportoCtrl.enable();
    this.empresaCtrl.enable();
    this.flightNumberCtrl.enable();
    this.dateCtrl.enable();
  }

  /**
   * [[ GERAR ARQUIVOS DE RPE E SELO ]]
   */
  public generatedFiles(): void {

    this.disableInputs();
    let listFlightGenerate: Array<FlightGenerated> = new Array();
    this.selection.selected.forEach( flight => { listFlightGenerate.push(this.factoryGeneratedFile(flight, this.formSearchMF.airportName)); })

    this.appGeneratedManualFilesServiceGe
    .createdFilesManualy(listFlightGenerate)
    .toPromise()
    .then(response => {
      const nameFile = 'GeracaoManual.zip';
      const type = 'application/zip';
      if (response.size != 0) {
        // console.log('process this request ' + JSON.stringify(response));
        this.saveBlobResumeBoardingGMF(response, nameFile, type);
        this.searchFlight();
      } else {
        // console.log('error in this operation creation SELO files: ' );
        this.openDialog(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_ERROR_GENERATED_FILE_ZIP);
        this.enableInputs();
        this.selection.clear();
      }
    }).catch(error => {
      console.log(error)
      this.openDialog(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
        properties.MODAL_CONTENT_ERROR_GENERATED_FILE_ZIP);
      this.enableInputs()
      this.selection.clear();
    });

  }

  /**
   * [[ MODAL DE DIALOGO ]]
   * @param titleParam
   * @param messageParam
   */
  private openDialog(genTitleParam: string, genMessageParam: string): void {
    this.openDialogUtils.openDialog(genTitleParam,genMessageParam)
  }

  /**
   * [[ REALIZAR O DOWNLOAD DO ARQUIVO ]]
   * @param response
   * @param nameFile
   * @param typeFile
   */
  saveBlobResumeBoardingGMF(response: Blob, nameFile: string, typeFile: string): any {
    const blob = new Blob([response], { type:  typeFile});
    const file = new File([blob], nameFile, { type: typeFile });
    FileSaver.saveAs(file);
  }

  /**
   * [[ TRANSFORMACAO DO OBJETO ]]
   * @param flight
   */
  factoryGeneratedFile(flight: FlightProcess, airport: string): FlightGenerated {
    let flightGeneratedFile: FlightGenerated = new FlightGenerated();

    console.log("valid this flight to process: " + flight);
    if(this.isValidFlightSearchArrival(flight, airport)){

      flightGeneratedFile.oriIataCode = flight.originIataCode;
      flightGeneratedFile.destIataCode = flight.destIataCode;
      flightGeneratedFile.compyIataCode = flight.compyCodeIata;
      flightGeneratedFile.dttmFlight = flight.dttmFlightDepartureOrigin;
      flightGeneratedFile.numFlight = flight.numFlightDeparture;

    }else{

      flightGeneratedFile.oriIataCode = flight.originIataCodeArrival;
      flightGeneratedFile.destIataCode = flight.destIataCodeArrival;
      flightGeneratedFile.compyIataCode = flight.compyCodeIataArrival;
      flightGeneratedFile.dttmFlight = flight.dttmFlightArrivalOrigin;
      flightGeneratedFile.numFlight = flight.numFlightArrival;
    }

    flightGeneratedFile.reportType = (this.reportType == 'rpe') ? this.STAMP : this.RPE ;
    return flightGeneratedFile;
  }

  private isValidFlightSearchArrival(flight: FlightProcess, airport: string): boolean{

    return (airport == flight.originIataCode || airport == flight.destIataCode);
  }

  /**
   * [[ VALIDACAO DE STRING ]]
   * @param object
   */
  public isEmptyGMF(object: string): boolean{
    return object != null && object != undefined && object.trim() != "";
  }

}
