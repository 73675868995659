import { Component, OnInit } from '@angular/core';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { DashboardColumnRQ } from './model/DashboardColumnRQ';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardcolumnService } from './app-dashboard-column.service';
import { DashboardColumnRS } from './model/DashboardColumnRS';
import { Protocolo } from '../app-enum/Protocolo';
import { DataChar } from '../app-dashboard-line/model/DataChar';

@Component({
  selector: 'app-dashboard-column',
  templateUrl: './app-dashboard-column.component.html',
  styleUrls: ['./app-dashboard-column.component.css']
})

export class DashboardcolumnComponent implements OnInit {

  private eventsSubscription: any;
  public request: DashboardColumnRQ = new DashboardColumnRQ();

  public barChartOptionsClumn: any = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{
      ticks: {
          min: 0,
          // stepSize: 1
      }
    }] },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
      }
    }
  };

  public barChartTypeDasCol: any = 'bar';
  public barChartLegendDasCol = true;
  public barChartPluginsDasCol = [pluginDataLabels];

  public barChartDataDasCol: any[] = [];
  public barChartLabelsDasCol: any[] = [];

  private setCharData(rs: DashboardColumnRS[]): void {

    if (rs === null) {
      this.barChartDataDasCol = [{ data: [], label: '' }];
      return;
    }

    const dataDCl = new DataChar();
    const arrayDataDCl = new Array();
    for (const value of rs) {
      arrayDataDCl.push(value.qntRpe);
    }
    this.barChartDataDasCol = [{ data: [], label: '' }];
    dataDCl.label = 'Descrição do Problema';
    dataDCl.data = arrayDataDCl;
    this.barChartDataDasCol.splice(0, this.barChartDataDasCol.length);
    this.barChartDataDasCol.push(dataDCl);
  }

  private setCharLabels(rs: DashboardColumnRS[]): void {

    const arrayData = new Array();
    for (const value of rs) {
      let op = value.tipoErro;
      if ( op.length > 50 ) {
        op = 'OUTROS';
      }
      arrayData.push(op);
    }
    this.barChartLabelsDasCol = arrayData;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dashboardcolumnService: DashboardcolumnService) {

    this.setCharData(null);
    // this.setCharLabels();
  }

  ngOnInit() {
    this.eventsSubscription = this.route.params.subscribe(params => {
      this.request.dtVoo = params['date'];
      this.request.sistemaOrigem = params['sys'];
      // console.log('[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 3 ] ' + this.request.dtVoo);
      // console.log('[ INFO ] [ SELECT LABEL ] [ RECEIVER ] [ 3 ] ' + this.request.sistemaOrigem);
      this.getDataDashboardColumn(this.request.dtVoo);
    });
  }

  private getDataDashboardColumn(dtSearch: string): void {
    let rs: DashboardColumnRS[];
    this.dashboardcolumnService.getDataByDate(this.request).subscribe(
      data => {
        rs = data;
        // console.log('DASHBOARD COLUMN ' + JSON.stringify(rs));
      },
      err => {
        const protocolo: Protocolo = err.error;
        // console.log(protocolo);
      },
      () => {

        this.setCharData(rs);
        this.setCharLabels(rs);
      }
    );
  }

}
