import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseModel } from '../app-conciliation-layout/model/ResponseModel';
import { endpoints } from '../../../environments/endpoint';
import { timeout } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class GenerateConciliationService {

  constructor(private http: HttpClient) { }

  public getFileConciliation(airport: string, company: string, nature: string): Observable<ResponseModel> {
    let url = endpoints.REST_GET_CONCILIATION_FILE;
    if(airport !== null && airport !== undefined && airport !== ""){
      url= url + ('iataAirport='+airport);
    }
    if(company !== null && company !== undefined && company !== ""){
      if(airport !== null && airport !== undefined && airport !== ""){
        url += '&';
      }

      url= url + ('iataCompany=' + company + '&nature=' + nature);
    }

   // console.log("URL = " + url);
    return this.http.get<ResponseModel>(url, httpOptions) 
    .pipe(
      timeout(86400000)
    );
  }

}
