import { AirportTax } from './airport-tax';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class AirportInfo {

    airport: string;
    typeNature: string;
    dtInit: NgbDate;
    dtEnd: NgbDate;
    category: string;

    filtered(airportTax: AirportTax): boolean {

        let bool = true;

        if (this.airport !== undefined && this.airport.trim() !== '') {
            bool = bool && this.airport === airportTax.iataAirport;
        }

        if (this.category !== undefined) {
            bool = bool && this.category === airportTax.airportCategory;
        }

        if (this.typeNature !== undefined) {
            bool = bool && this.typeNature === airportTax.typeNature;
        }

        if (this.dtInit !== undefined) {
            bool = bool && this.dtEquals(airportTax.dtStart, this.dtInit);
        }

        if (this.dtEnd !== undefined) {
            bool = bool && this.dtEquals(airportTax.dtEnd, this.dtEnd);
        }

        return bool;
    }

    dtEquals(dateString: string, date: NgbDate): boolean {

        const dateArray = dateString.split('/');

        const day = Number(dateArray[0]);
        const month = Number(dateArray[1]);
        const year = Number(dateArray[2]);

        return date.day === day && date.month === month && date.year === year;

    }
}
