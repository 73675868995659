
import { BaseChartDirective } from 'ng2-charts';
import { DataChar } from './model/DataChar';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { DashboardlineRQ } from './model/DashboardlineRQ';
import { DashboardlineRS } from './model/DashboardlineRS';
import { Protocolo } from '../app-enum/Protocolo';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Periodo } from '../app-search-information-by-period/Periodo';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { properties } from '../../../environments/properties';
import { DialogCustomComponent } from '../app-dialog-custom/app-dialog-custom.component';
import { AppDashboardWorkaroundService } from './app-dashboard-workaround.service';
import { MatDialog } from '@angular/material/dialog';
import { Color } from 'chartjs-plugin-datalabels/types/options';
import { MatLabel } from '@angular/material/form-field';

@Component({
  selector: 'app-dashboard-workaround',
  templateUrl: './app-dashboard-workaround.component.html',
  styleUrls: ['./app-dashboard-workaround.component.css']
})
export class AppDashboardWorkaroundComponent implements OnInit {

  @Input() identification: string;
  @Input() events: Observable<Periodo>;
  private eventsSubscription: any;

  @Output() dateSelectDashOne = new EventEmitter<NgbDateStruct>();

  public request: DashboardlineRQ;
  public response: DashboardlineRS[];
  public indexSelectColun;
  public lineChartData: any[] = [{ data: [], label: '' }];
  public lineChartLabels: MatLabel[] = [];

  public lineChartOptions: (any & { annotation: any }) = {
    responsive: true,
    legend: {
      position: 'top',
    },
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'Gerado',
          position: 'left',
        },
      ]
    },
    annotation: {},
  };

  public lineChartColorsDasWor: any[] = [];
  public lineChartLegendDasWor = true;
  public lineChartTypeDasWor = 'line';
  public lineChartPluginsDasWor = [pluginAnnotations];

  @ViewChild(BaseChartDirective) chart: any;

  constructor(

    private router: Router,
    private dashboardLineService: AppDashboardWorkaroundService,
    public dialog: MatDialog) {
    this.request = new DashboardlineRQ();
    this.response = new Array<DashboardlineRS>();
    this.request.dtInicio = this.getFormatDateToRqDashWork(this.getActualStartDateToFirstApresentationDsWork());
    this.request.dtFim =  this.getFormatDateToRqDashWork(this.getActualEndDateToFirstApresentationWorkD());
    this.getDataDashboardLine(null);

  }

  public chartClickedWork(e: any): void {
    if (e.active.length > 0) {
      this.indexSelectColun = e.active[0]._index;
      //console.log('[ INFO ] ' + ' [ SELECT LABEL ] [ 1 ] ' + this.lineChartLabels[this.indexSelectColun]);
      this.router.navigate(['dashboardTwoWorkaround', this.lineChartLabels[this.indexSelectColun]]);
    }
  }

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe((periodo) => this.getDataDashboardLine(periodo));
  }

    private getDataToChar(data: DataChar): void {
      this.lineChartData = [{ data: [], label: '' }];
      data.label = 'Workarounds gerados';
      this.lineChartData.splice(0, this.lineChartData.length);
      this.lineChartData.push(data);
      this.setColorsLineWork();
    }

  /**
   * FUNCAO PARA FORMATAR A DATA APRESENTADA NA LEGENDA DOS GRAFICOS
   * @param dt
   */
  private getFormatDateWork(dt: string): string {
    return dt.substring(8, 10) + '/' + dt.substring(5, 7) + '/' + dt.substring(0, 4) ;
  }

  private setColorsLineWork(): void {
    this.lineChartColorsDasWor = [
        {
          backgroundColor: 'rgba(224,255,255,0.5)',
          borderColor: 'rgba(27,0,136,1)',
          pointBackgroundColor: 'rgb(245,245,245)',
          pointRadius: 10,
          pointHoverRadius: 10,
          pointStyle: 'circle',
        },
        {
          backgroundColor: 'rgba(224,255,255,0.5)',
          borderColor: 'rgba(100,0,136,1)',
          pointBackgroundColor: 'rgb(245,245,245)',
          pointRadius: 10,
          pointHoverRadius: 10,
          pointStyle: 'circle',
        }
    ];
  }

  private getActualEndDateToFirstApresentationWorkD(): Date {
    return new Date();
  }

  private getActualStartDateToFirstApresentationDsWork(): Date {
    const date: Date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  }

  private getDataDashboardLine(periodoDsWor: Periodo): void {

    if (periodoDsWor != undefined || periodoDsWor != null) {
      this.request.dtInicio = periodoDsWor.getDtInicio();
      this.request.dtFim = periodoDsWor.getDtFim();
    }

    let rsDsWor: DashboardlineRS[];
    this.dashboardLineService.getDataByDate(this.request).subscribe(
      data => {
        rsDsWor = data;
        //console.log(rs);
      },
      err => {
        const protocolo: Protocolo = err.error;
        //console.log(protocolo);
        this.setDashBoardNullWork();
        this.openDialogDashWor(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
              properties.MODAL_CONTENT_DASHBOARD_ERROR_DATA_NOT_FOUND
        );
      },
      () => {
        const dataChar = new DataChar();
        const arrayData = new Array();

        /**
         * ORDENADO RESPOSTA PELA DATA DO VOO
         */
        rsDsWor.sort(function(a, b) {
          return a.dtVoo < b.dtVoo ? -1 : a.dtVoo > b.dtVoo ? 1 : 0;
        });

        this.lineChartLabels = [];
        for (const value of rsDsWor) {
          this.lineChartLabels.push(this.getFormatDateWork(value.dtVoo));
          arrayData.push(value.qntRpe);
        }

        dataChar.data = arrayData;
        this.getDataToChar(dataChar);
      }
    );
  }

  private setDashBoardNullWork(): void {
    this.indexSelectColun = null;
    this.lineChartData =  [{ data: [], label: '' }];
    this.lineChartLabels = [];
  }

  public openDialogDashWor(titleParam: string, messageParam: string): void {
    const dialogRef = this.dialog.open(DialogCustomComponent, {
        width: '450px',
        data: {title: titleParam, message: messageParam}
    });
  }

  private getFormatDateToRqDashWork(date: Date): string {
    return date.getFullYear()
      + '-' + (date.getMonth() + 1 )
        + '-' +  date.getDate();
  }

}
