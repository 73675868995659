import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '../../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ResumeBoardingService {

  constructor(private http: HttpClient) { }

  getDataReportBoarding(airport: string, dtStart: string, dtEnd: string): Observable<Blob> {

    // console.log('request to report: ', airport, dtStart, dtEnd)
    const params = new HttpParams({
      fromObject: {
        airport,
        dtStart: dtStart+'T00:00:00',
        dtEnd: dtEnd+'T23:59:59'
      }
    });

    return this
      .http
      .get(endpoints.REST_GTECLOUD_GET_REPORT_RESUME_BOARDING, {
        params,
        responseType: 'blob'
      });
  }

}
