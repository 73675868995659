import { OpenDialog } from './../../../../utils/openDialog';
import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbDateStruct, NgbDatepickerI18n, NgbDateParserFormatter, NgbDatepickerConfig, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ComboBoxAeroporto } from '../../../../app-datahealth/app-search-information-by-date-and-rpe/model/combo-box-aeroporto';
import { properties } from '../../../../../environments/properties';
import { AirportService } from '../../service/airport.service';
import { I18n } from '../../../../app-datahealth/app-search-information-by-period/CustomDatepickerI18n';
import { NgbDatePTParserFormatter } from '../../../../app-datahealth/app-search-information-by-period/NgbDatePTParserFormatter';
import { CustomDatepickerI18n } from '../../../../app-datahealth/app-search-information-by-period/CustomDatepickerI18n';
import { ExportConciliationService } from '../../service/export-conciliation.service';
import * as FileSaver from 'file-saver';
import { DialogCustomComponent } from '../../../../app-datahealth/app-dialog-custom/app-dialog-custom.component';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-export-conciliation',
  templateUrl: './export-conciliation.component.html',
  styleUrls: ['./export-conciliation.component.css'],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{ provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter }],
  ],
})
export class ExportConciliationComponent implements OnInit {


  dtInicialLabel = properties.HTML_CONST_DT_START;
  dtFinalLabel = properties.HTML_CONST_DT_END;
  emptyDate = properties.HTML_CONST_DT_TO_SELECTED;
  displayMonths = properties.HTML_CONST_QTD_MOUNTHS;

  public airports: ComboBoxAeroporto[];

  public airportChoose: string;

  public activeInput: string;

  public isDisabled: boolean;

  public dtInicial: NgbDate;

  public dtFinal: NgbDate;

  public airportCtrl: UntypedFormControl = new UntypedFormControl();

  public filteredAeroportos: Observable<ComboBoxAeroporto[]>;

  constructor(
    airportServiceConc: AirportService,
    private exportConciliationService:
      ExportConciliationService,
      private openDialogUtils: OpenDialog,
      config: NgbDatepickerConfig,
      calendar: NgbCalendar,
      public datePipe: DatePipe,) {
        config.minDate = {year: 1976, month: 1, day: 1};
        config.maxDate = {year: 2099, month: 12, day: 31};
        config.outsideDays = 'hidden';
        airportServiceConc.getAllAirport().subscribe(airportsCon => {
      this.airports = airportsCon;
      this.filteredAeroportos = this.airportCtrl
        .valueChanges
        .pipe(startWith(''), map(airport => {
          return airport ? this._filterAirport(airport) : this.airports;
        }));
    });

  }

  ngOnInit() {
  }


  private _filterAirport(value: string): ComboBoxAeroporto[] {

    const filterValue = value.toLowerCase();

    return this.airports.filter(airport => airport.codeICAO.toLowerCase().indexOf(filterValue) === 0);

  }

  public clickEventHandlerSelectExpConc(event: any) {
    this.activeInput = event.currentTarget.id;
  }

  public onDateStartSelect(dtSelect: NgbDate) {
    this.isDisabled = false;
    $('#modalDtFinal').trigger('click');
  }

  public onDateEndSelectExCon(dtSelect: NgbDate) {
  }

  public isDisabledLastOneExp(date: NgbDateStruct) {

    const dateIniExp: any = $('#modalDtInicial').val();

    const yearIniExp: number = parseInt(dateIniExp.substring(6, 10), 10);
    const mounthIniExp: number = parseInt(dateIniExp.substring(3, 5), 10);
    const dayIniExp: number = parseInt(dateIniExp.substring(0, 2), 10);

    const dayEnd = date.day;
    const monthEnd = date.month;
    const yearEnd = date.year;

    const dateStartExp = new Date();
    dateStartExp.setMonth(mounthIniExp);
    dateStartExp.setFullYear(yearIniExp);
    dateStartExp.setDate(dayIniExp);

    const dateEndExp = new Date();
    dateEndExp.setMonth(monthEnd);
    dateEndExp.setFullYear(yearEnd);
    dateEndExp.setDate(dayEnd);

    return dateEndExp.getTime() < dateStartExp.getTime();
  }

  public export(): void {
    this
      .exportConciliationService.getExportedConciliation(this.airportChoose, this.dtInicial, this.dtFinal)
      .toPromise()
      .then(response => {
        if (response) { this.saveBlob(response); } else {
          this.openDialog(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
            properties.HTML_CONST_EXPORTED_NULL);
        }
      })
      .catch(error => console.log(error));
  }

  saveBlob(response: Blob): any {
    const toDay = new Date();
    const date: string = toDay.getDate() + '-' + (toDay.getMonth() + 1) + '-' + toDay.getFullYear();
    const nameFile = this.airportChoose.toLocaleUpperCase() + '_' + date + '_CONCILIADOS.xls';
    const blob = new Blob([response], { type: 'application/vnd.ms-excel' });
    const file = new File([blob], nameFile, { type: 'application/vnd.ms-excel' });
    FileSaver.saveAs(file);
  }

  disabled(): boolean {
    return this.airportChoose === undefined || this.dtInicial === undefined || this.dtFinal === undefined;
  }

  private openDialog(expTitleParam: string, expMessageParam: string): void {
    this.openDialogUtils.openDialog(expTitleParam,expMessageParam)
  }

}
