<br>
<div class="form-row">
  <div class="text-center col-12">

    <button type="button" class="btn btn-danger btnClean" (click)="closeModal()">
      <mat-icon style="font-size: x-large;position: relative;top: 5px;">close</mat-icon> Cancelar
    </button>

    <button type="button" class="btn btn-danger btnSave" style="margin-left: 1%;"  (click)="generatedList(true)">
      <mat-icon style="font-size: x-large;position: relative;top: 5px;">get_app</mat-icon> Detalhada
    </button>

    <button type="button" class="btn btn-danger btnSave" style="margin-left: 1%;" (click)="generatedList(false)">
      <mat-icon style="font-size: x-large;position: relative;top: 5px;">get_app</mat-icon> Resumida
    </button>

  </div>
</div>