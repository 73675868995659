import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { endpoints } from '../../../environments/endpoint';
import { Observable } from 'rxjs';
import { Multa } from './app-value-penalty';
import { Periodo } from '../app-search-information-by-period/Periodo';
import { Index } from '../app-rpe-not-generated-list/model';

@Injectable({
  providedIn: 'root'
})
export class ValorMultaService {

  constructor(private http: HttpClient) { }

  public obterValorMulta(periodo: Periodo): Observable<Multa> {

    console.log(periodo.getDtFim(), "<----getDtFim")
    console.log(periodo.getDtInicio(), "<----getDtInicio")
    let params1 = new HttpParams().set('flightBeginDate', this.formatarDataParaString(periodo.getDtInicio()) + "T00:00:00");
    let params2 = new HttpParams().set('flightEndDate', this.formatarDataParaString(periodo.getDtFim()) + "T23:59:59");

    return this.http.get<Multa>(endpoints.REST_GET_VALOR_MULTA + "?" + params1 + "&" + params2);
  }

  public formatarDataParaString(objetoData) {
    const { day, month, year } = objetoData;

    // Acrescenta zeros à esquerda, se necessário
    const dia = day < 10 ? '0' + day : day;
    const mes = month < 10 ? '0' + month : month;

    // Retorna a data no formato desejado
    return `${year}-${mes}-${dia}`;
}
}
