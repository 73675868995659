/**
 *ARQUIVO DE CONFIGURACAO DE ENDPOINTS UTILIZADOS PELA APLICACAO
 */

export const properties = {

 MODAL_TITLE_PERIOD_SELECT_ATENCION: 'Atenção! ',
 MODAL_TITLE_SUCESS: 'Sucesso!',
 MODAL_ERROR_TITLE: 'Atenção!',
 MODAL_BODY_SUCESS_OPERATION: 'Operação realizada com sucesso',
 MODAL_CONTENT_VALIDATE_AIRPORT_DUPLICATE: 'Aeroporto já vinculado, verifique os campos e tente novamente!',
 MODAL_CONTENT_VALIDATE_AIRPORT_DUPLICATE_MESSAGE: 'Este aeroporto já está associado a outra administradora, desvincule-o antes de uma nova associação!',
 MODAL_REMOVE_AIRPORT: 'Você deseja realmente desvincular este aeroporto?',
 MODAL_CONTENT_VALIDATE_TYPE_FILE_IMPORTED: 'Apenas são permitidos arquivos com os formatos .xls e .xlsx',
 MODAL_CONTENT_VALIDATE_FIELDS_FAILDS: 'Verifique os campos correspondentes as quantidade de passageiros, ao menos um dos registros se faz necessário!',
 MODAL_CONTENT_MAX_30_PERIOD_SELECT: 'Não é permitido selecionar um perído superior a 30 dias',
 MODAL_CONTENT_NEGATIVE_PERIOD_SELECT: 'Não é permitido selecionar a data inicial maior do que a data final',
 MODAL_CONTENT_DASHBOARD_ERROR_DATA_NOT_FOUND: 'Não foram encontrados dados referentes ao período selecionado.',
 MODAL_CONTENT_DASHBOARD_ERROR_DATA_NOT_FOUND_CONCILIATION: 'Não foram encontrados dados divergêntes.',
 MODAL_CONTENT_INDICATORS_DATA_NOT_FOUND: 'O indicador não possui dados para ser apresentado.',
 MODAL_CONTENT_INDICATORS_DATA_NOT_FOUND_RPE_NOT_PROCESSED: 'O indicador não possui RPE\'s que tiveram problemas em sua gereção, o valor de previsão de multa é composto apenas de RPE\'s que foram CONTESTADOS.',
 MODAL_CONTENT_INDICATORS_REQUEST_PERIOD: 'Insira o periodo desejado.',
 MODAL_CONTENT_SUCESS_INSERT: 'Controle chamado inserido com sucesso.',
 MODAL_CONTENT_SUCESS_EDIT: 'Controle chamado alterado com sucesso.',
 MODAL_CONTENT_RPE_GENERATED_NOT_FOUND: 'Não foram encontrados dados referentes as informações solicitadas.',
 MODAL_CHAMADO_MENSAGEM_CARREGANDO: 'Cadastrando Chamado ... Aguarde',
 MODAL_CHAMADO_MENSAGEM_CARREGANDO_PARAMETRO: 'Alterando parametro ... Aguarde',
 MODAL_CHAMADO_MENSAGEM_SUCESSO: 'Chamado cadastrado com sucesso.',
 MODAL_CHAMADO_MENSAGEM_DATAHEALTH_JA_ASSOCIADO: 'Número DataHealth já está associado a outro chamado.',
 MODAL_CHAMADO_MENSAGEM_DATAHEALTH_NAO_ENCONTRADO: 'Número DataHeath informado não encontrado/inexistente.',
 MODAL_CHAMADO_MENSAGEM_REQUEST_INVALIDO: 'Dados informados inválidos. Verifique os dados informados.',
 MODAL_CHAMADO_MENSAGEM_PLANILHA_INSERIDA_SUCESSO: 'Planilha importada com sucesso.',
 MODAL_CONTENT_NOT_VALID_AIRPORT_SELECT: 'É necessário selecionar um aeroporto',
 MODAL_CONTENT_ERROR_IN_GENERATED_SERVICE: 'Erro ao realizar esta operação, por favor tente novamente!',
 MODAL_CONTENT_SUCESS_PARAM_EDIT: 'Parametro alterado com sucesso.',
 MODAL_CONTENT_SELOS_GERADOS: 'Selecione um indicador de selos não gerados.',
 MODAL_CONTENT_RPE_GERADOS: 'Selecione um indicador de RPEs gerados.',
 MODAL_CONTENT_PARAMETER_NULL: 'Preencha os campos vazios, para prosseguir.',
 HTML_CONST_RPE_GENERATED_X_ADM: 'RPE\'s Contestados',
 HTML_CONST_RPE_NOT_GENERATED: 'RPE\'s Não Gerados',
 HTML_CONST_SELO_NOT_GENERATED_X_GENERATED: 'Selos Gerados x Selos Não Gerados',
 HTML_CONST_RPE_GENERATED_WORKAROUND: 'RPE\'s Gerados por Workaround',
 HTML_CONST_WORKAROUND: 'Workarounds Gerados',
 HTML_CONST_RPES_GENERATEDS_X_FLIGHTS: 'RPE\'s Gerados X Total de Voos',
 HTML_CONST_EXPORTED_NULL: 'Relatório Conciliado Sem Informações',
 HTML_CONST_DT_START: 'Data Inicial',
 HTML_CONST_DT_END: 'Data final',
 HTML_CONST_DT_TO_SELECTED: 'Selecione a data desejada',
 HTML_CONST_QTD_MOUNTHS: 2,
 MODAL_CONTENT_ERROR_IN_REGISTER_NEW_ADMINSTRATOR: 'Não foi possivel vincular os seguintes aeroportos: ',
 MODAL_CONTENT_ERROR_GENERATED_FILE_ZIP: 'Falha ao gerar arquivo ZIP, por favor tente novamente',
 MODAL_CONTENT_ERROR_GENERATED_FILE: 'Relatório Sem Informações para serem visualizadas',
 MODAL_CONTENT_ERROR_GENERATED_FILE_MANUAL: 'Não foram encontrados registros a serem gerados',
 MODAL_CONTENT_NOT_VALID_AIRPORT_OR_COMPANY_SELECT: 'É necessário selecionar um aeroporto ou uma empresa com natureza',
 MODAL_CONTENT_NOT_VALID_NATURE: 'É necessário selecionar a natureza',
 MODAL_CONTENT_NOT_VALID_COMPANY: 'É necessário selecionar a empresa',
 MODAL_SEND_MESSAGE_QUEUE: 'Registro enviado para fila.',
 MODAL_SEND_MESSAGE_QUEUE_ERROR: 'Falha ao enviar registro para fila, por favor tente novamente',
 MODAL_CONTENT_NOT_VALID_EMAIL_USER:'Usuário não tem permissão para ingressar no sistema GTE, por favor procurar o administrador do sistema para regularizar seu acesso',
 MODAL_CONTENT_ERROR_VALID_EMAIL_USER:'Erro na validação do e-mail',
 MODAL_CONTENT_USER_SAVE_SUCESS:'Usuário Salvo com sucesso!',
 MODAL_CONTENT_USER_SAVE_ERROR:'Erro para salvar usuário!',
 MODAL_CONTENT_LOAD_USER_ERROR:'Erro para Carregar usuários!',
 MODAL_CONTENT_EMAIL_LATAM_INVALID:'E-mail invalido, por favor cadastrar e-mail da LATAM  ex: @latam.com',
 MODAL_CONTENT_NAME_USER_INVALID: 'É necessário informa o nome',
 MODAL_CONTENT_PROFILE_USER_INVALID: 'É necessário informa o perfil',
 MODAL_CONTENT_EMAIL_USER_INVALID: 'É necessário informa o e-mail',
 MODAL_CONTENT_NUMBER_FLIGHT_INVALID: 'É necessário informa o número do voo',
 MODAL_CONTENT_DATE_FLIGHT_INVALID: 'É necessário informa a data do voo',
 MODAL_CONTENT_MOVEMENT_FLIGHT_INVALID: 'É necessário informar o movimento do voo, decolagem, pouso ou decolagem e pouso',
 MODAL_CONTENT_CONSULT_FLIGHT_INVALID: 'Erro ao consultar voos',
 MODAL_CONTENT_FILE_DELETE_NOT_FOUND: 'Não foi encontrado arquivos para exclusão',
 MODAL_CONTENT_REASON_INVALID: 'É necessário informa o motivo da exclusão',
 MODAL_CONTENT_EXCLUSION_FLIGHT_INVALID: 'Erro na Exclusão do arquivo',
 MODAL_CONTENT_EXCLUSION_NOT_SELECTED: 'É necessário selecionar pelomenos 1 para excluir',
 MODAL_CONTENT_LOGS_NOT_FOUND: 'Não foi encontrado nenhum Log de deleção em seu nome',
 MODAL_CONTENT_ERROR_ROBOT: "Não foi possível iniciar o processo de geração.",
 MODAL_CONTENT_ERROR_TIMEOUT: "TimeOut"
};
