export class HistFileRpeEdit{

// Campo 59 RPE
public  histIcaoAirportFlightPrevious: string;
// Campo 60 RPE
public  histIcaoAirportDestiny: string;
// Campo 63 RPE
public  histCdTypeFlightDeparture: string;
// Campo 62 RPE
public  histCdTypeFlightArrival: string;
// Campo 72 RPE
public  histCdTypeLineFlightDeparture: string;
// Campo 71 RPE
public  histCdTypeLineFlightArrival: string;

}