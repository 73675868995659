export class LayoutAmount {    
    amountPaxBoardDom: string;
    amountPaxBoardInt: string;
    amountPaxConDom: string;
    amountPaxConInt: string;

    constructor(){
        this.amountPaxBoardDom= undefined;
        this.amountPaxBoardInt= undefined;
        this.amountPaxConDom= undefined;
        this.amountPaxConInt = undefined;
    }
}