import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { DashboardlineRQ } from './model/DashboardlineRQ';
import { DashboardlineRS } from './model/DashboardlineRS';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Periodo } from '../app-search-information-by-period/Periodo';
import { Observable } from 'rxjs';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { Router } from '@angular/router';
import { DialogCustomComponent } from '../app-dialog-custom/app-dialog-custom.component';
import { properties } from '../../../environments/properties';
import { DataChar } from '../app-dashboard-line/model/DataChar';
import { Protocolo } from '../app-enum/Protocolo';
import { AppDashboardStampService } from './app-dashboard-stamp.service';
import { MatDialog } from '@angular/material/dialog';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-dashboard-stamp',
  templateUrl: './app-dashboard-stamp.component.html',
  styleUrls: ['./app-dashboard-stamp.component.css']
})
export class AppDashboardStampComponent implements OnInit {

  @Input() identification: string;
  @Input() events: Observable<Periodo>;
  private eventsSubscription: any;

  @Output() dateSelectDashOne = new EventEmitter<NgbDateStruct>();

  public requestStam: DashboardlineRQ;
  public responseStam: DashboardlineRS[];
  public indexSelectColunStam;
  public indexLabelPointStam;
  public lineChartDataStam: any[] = [{ data: [], label: '' }];
  public lineChartLabelsStam: any[] = [];

  public lineChartOptionsStam: (any & { annotation: any }) = {
    responsive: true,
    legend: {
      position: 'top',
    },
    hover:{
      intersect: true,
      mode: "nearest",
    },
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'gerados',
          position: 'left',
        },
      ]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'top',
        display: false
      }
    },
    annotation: {},
    title: {
      display: true,
      text: 'Selos Gerados x Selos Não Gerados'
    }
  };

  public lineChartColors: any[] = [];
  public lineChartLegend = true;
  public lineChartType = 'bar';
  public lineChartPlugins = [pluginAnnotations];


  @ViewChild(BaseChartDirective) chart: any;

  constructor(

    private router: Router,
    private dashboardRpeGeneratedService: AppDashboardStampService,
    public dialog: MatDialog) {
    this.requestStam = new DashboardlineRQ();
    this.responseStam = new Array<DashboardlineRS>();
    this.requestStam.dtInicio = this.getFormatDateToRqDashStam(this.getActualStartDateToFirstApresentationDsSta());
    this.requestStam.dtFim =  this.getFormatDateToRqDashStam(this.getActualEndDateToFirstApresentationStamp());
    this.getDataDashboardLine(null);

  }

  public chartClicked(e: any): void {
    // console.log('troca de tela ... ')
    if (e.active.length > 0) {
      this.indexSelectColunStam = e.active[0]._index;
      this.indexLabelPointStam = e.active[0].$datalabels.$context.dataset.label;

      if (this.indexLabelPointStam == 'Selos Não Gerados') {
        if (this.lineChartDataStam[1].data[this.indexSelectColunStam] == 0) {
          this.openDialogDashSta(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
                properties.MODAL_CONTENT_INDICATORS_DATA_NOT_FOUND
          );
          return;
        }
      }

      if (this.indexLabelPointStam == 'Selos Gerados'){
          this.openDialogDashSta(
            properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
                properties.MODAL_CONTENT_SELOS_GERADOS
          );
          return;
      }

      this.router.navigate(['dashboardTwoStamp', this.lineChartLabelsStam[this.indexSelectColunStam]]);
    }
  }

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe((periodo) => this.getDataDashboardLine(periodo));
  }

  private getDataDashboardLine(periodo: Periodo): void {

    if (periodo != undefined || periodo != null) {
      this.requestStam.dtInicio = periodo.getDtInicio();
      this.requestStam.dtFim = periodo.getDtFim();
    }

    let rs: DashboardlineRS[];
    this.dashboardRpeGeneratedService.getDataByDate(this.requestStam).subscribe(
      data => {
        rs = data;
        // console.log("Data: ", rs);
      },
      err => {
        const protocolo: Protocolo = err.error;
        //console.log(protocolo);
        this.setDashBoardNull();
        this.openDialogDashSta(
          properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
              properties.MODAL_CONTENT_DASHBOARD_ERROR_DATA_NOT_FOUND
        );
      },
      () => {
        const dataChar = new DataChar();
        const arrayData = new Array();

        /**
         * ORDENADO RESPOSTA PELA DATA DO VOO
         */
        rs.sort(function(a, b) {
          return a.dtVoo < b.dtVoo ? -1 : a.dtVoo > b.dtVoo ? 1 : 0;
        });

        this.lineChartLabelsStam = [];

        //TESTE INSERCAO

        this.lineChartDataStam = [{ data: [], label: '' }];
        this.lineChartDataStam.splice(0, this.lineChartDataStam.length);

        let dataAuto: DataChar = new DataChar();
        let dataManual: DataChar = new DataChar();
        dataAuto.label = 'Selos Gerados';
        dataManual.label = 'Selos Não Gerados';

        var arrayDataAuto = new Array();
        var arrayDataManual = new Array();

        for (const value of rs) {

          /**
           * VALIDANDO SE JA EXISTE A DATA NO VETOR DE DATAS
           */
          if(!this.inVector(this.getFormatDate(value.dtVoo))){
            this.lineChartLabelsStam.push(this.getFormatDate(value.dtVoo));
            // console.log("VER GRAFICO");
          }

          if (value.tipo == 'G'){
            arrayDataAuto.push(value.qntSelo);
          } else if(!this.inVectorAutomatic(rs, value.dtVoo)){
            arrayDataAuto.push(0);
          }

          if (value.tipo == 'N'){
            arrayDataManual.push(value.qntSelo);
          } else if(!this.inVectorManual(rs, value.dtVoo)){
            arrayDataManual.push(0);
          }

        }

        dataAuto.data = arrayDataAuto;
        dataManual.data = arrayDataManual;

        this.lineChartDataStam.push(dataAuto);
        this.lineChartDataStam.push(dataManual);

        this.setColorsLine();
        // dataChar.data = arrayData;
        // this.getDataToChar(dataChar);
      }
    );
  }

  private inVector(date: any){
    // console.log("[[ INFO ]] VERIFICANDO SE EXISTE ESSA DATA: " + date);
    let control: boolean = false;
    for (const myDate of this.lineChartLabelsStam) {
      if (myDate == date) {
        control = true;
        break;
      }
    }
    return control;
  }

  private inVectorAutomatic(rs: DashboardlineRS[], actualDate: any): boolean{

    let control = false;
    for (const value of rs) {
      if(value.tipo == 'G' && this.getFormatDate(value.dtVoo) == this.getFormatDate(actualDate)){
        control = true;
        break;
      }
    }

    return control;
  }

  private inVectorManual(rs: DashboardlineRS[], actualDate: any): boolean{

    let control = false;
    for (const value of rs) {
      if (value.tipo == 'N' && this.getFormatDate(value.dtVoo) == this.getFormatDate(actualDate)) {
        control = true;
        break;
      }
    }

    return control;
  }

  /**
   * FUNCAO PARA FORMATAR A DATA APRESENTADA NA LEGENDA DOS GRAFICOS
   * @param dt
   */

  private getFormatDate(dt: any): string {
      if ( dt === null || dt === undefined) {
        return '';
      }
      let dtarr: string[];
      dtarr = dt.split('T');
      dtarr = dtarr[0].split('-');
      return dtarr[2] + '/' + dtarr[1] + '/' + dtarr[0];
  }

  private setColorsLine(): void {
    this.lineChartColors = [
        {
          backgroundColor: 'rgba(224,255,255,0.5)',
          borderColor: 'rgba(27,0,136,1)',
          pointBackgroundColor: 'rgb(245,245,245)',
          pointRadius: 10,
          pointHoverRadius: 10,
          pointStyle: 'circle',
        },
        {
          backgroundColor: 'rgba(219,112,147,0.3)',
          borderColor: 'rgba(255,0,136,1)',
          pointBackgroundColor: 'rgb(255,245,245)',
          pointRadius: 10,
          pointHoverRadius: 10,
          pointStyle: 'circle',
        }
    ];
  }

  private getActualEndDateToFirstApresentationStamp(): Date {
    return new Date();
  }

  private getActualStartDateToFirstApresentationDsSta(): Date {
    const date: Date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  }

  private setDashBoardNull(): void {
    this.indexSelectColunStam = null;
    this.lineChartDataStam =  [{ data: [], label: '' }];
    this.lineChartLabelsStam = [];
  }

  public openDialogDashSta(titleParam: string, messageParam: string): void {
    const dialogRef = this.dialog.open(DialogCustomComponent, {
        width: '450px',
        data: {title: titleParam, message: messageParam}
    });
  }

  private getFormatDateToRqDashStam(date: Date): string {
    return date.getFullYear()
      + '-' + (date.getMonth() + 1 )
        + '-' +  date.getDate();
  }

}
