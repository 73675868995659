import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FunctionalityUser } from '../model/functionality-user';
import { endpoints } from '../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class PermissionAccessService {

  constructor(private http: HttpClient) { }

  public validateFunctionalityProfile(email: string, functionality: string): Observable<boolean> {

   // console.log("Chamando o Micro-Servico email " +email);
   // console.log("Chamando o Micro-Servico func " +functionality);
    let url = endpoints.REST_GTECLOUD_VALIDATE_FUNCTIONALITY;
    url = url.replace('{emailUser}', email);
    url = url.replace('{funcAccess}', functionality.replace("/",""));
  //  console.log("URL " +url);    
    
    return this.http.get<boolean>(url, httpOptions);

  }

  
}
