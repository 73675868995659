export class ConciliationExcelReport {
    public dtOperation: string;
    public numFlight: string;
    public valRateBoard: number;
    public valRateCon: number;
    public natureFlight: string;
    public typeOperation: string;
    public importedPlanEmb: number; 
    public importedPlanCon: number;
    public importedGTEEmb: number;
    public importedGTECon: number; 
    public diffQtdPaxBoard: number; 
    public diffQtdPaxConection: number; 
    public amountIsn: number; 
    public amountIsnColo: number; 
    public amountIsnTransit: number; 
}