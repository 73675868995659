import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { User } from '../model/User';
import { Observable } from 'rxjs';
import { endpoints } from '../../../../environments/endpoint';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private http: HttpClient) { }

  public getListUsersProfile(): Observable<User[]> {
    
    return this.http.get<User[]>(endpoints.REST_GTECLOUD_GET_ALL_USERS_PROFILE, httpOptions);
  }

  public saveUserProfile(user: User): Observable<User>{

    return this.http.post<User>(endpoints.REST_GTECLOUD_SAVE_USERS_PROFILE, user, httpOptions);
  }
}
