import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { endpoints } from '../../../../environments/endpoint';
import { Observable } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateService } from './date.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ExportConciliationService {

  constructor(private http: HttpClient, private dateService: DateService) { }


  public getExportedConciliation(airport: string, dtStart: NgbDate, dtEnd: NgbDate): Observable<Blob> {


    const params = new HttpParams({
      fromObject: {
        airport,
        //ANDRIETTI AQUI
        start: moment(dtStart).format('YYYY-MM-DD'),
        end: moment(dtEnd).format('YYYY-MM-DD')

      }
    });

    return this
      .http
      .get(endpoints.REST_GET_EXPORTED_CONCILIATION, {
        params,
        responseType: 'blob'
      });

  }

}
