<div class="example-container">

  <mat-form-field>
    <mat-select placeholder="Tipo" [(ngModel)]="typeDashboard" name="typeDashboard">
      <mat-option *ngFor="let  type of types" [value]="type">
        {{type}}
      </mat-option>
    </mat-select>
  </mat-form-field>


</div>

<div class="row">

  <div class="col">
    <app-comparative-dashboard-line-chart [dashboard]="dashboardInformation" [typeDashboard]="typeDashboard">
    </app-comparative-dashboard-line-chart>
  </div>

  <div class="col-7">
    <app-comparative-dashboard-bar-chart [dashboard]="dashboardInformation" [typeDashboard]="typeDashboard">
    </app-comparative-dashboard-bar-chart>
  </div>
</div>

<div class="row">
  <div class="col">
    <app-dashboard-monthly-variation-chart [dashboard]="dashboardInformation" [typeDashboard]="typeDashboard">
    </app-dashboard-monthly-variation-chart>
  </div>
</div>
