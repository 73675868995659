import { TabsAndRegisters } from './tabs-and-registers';
import { LineError } from './line-error';

export class ResponseImportPartialFile {

    tabsAndRegistersResponseDTO: TabsAndRegisters;
    lineErrorDTO: LineError;

    constructor(){
        this.tabsAndRegistersResponseDTO = new TabsAndRegisters();
        this.lineErrorDTO = new LineError();
    }
}
