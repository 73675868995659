import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-second-level-dashboard-stamp',
  templateUrl: './app-second-level-dashboard-stamp.component.html',
  styleUrls: ['./app-second-level-dashboard-stamp.component.css']
})
export class AppSecondLevelDashboardStampComponent implements OnInit {

  titleModule = 'Selos Não Gerados';

  constructor() { }

  ngOnInit() {
  }

}
