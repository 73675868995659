<nav class="navbar navbar-expand-lg navbar-light bg-light">

  <a class="navbar-brand imageheader" href="#">
    <!--[ STATICA ] <img src="https://s.latamstatic.com/static/latam/images/design-image/latam/logo-footer.svg" alt="">-->
    <!--[ ANIMADA ] --><img src="../../assets/logo-gif.gif" alt="">
    <!--<strong class="versao">{{version}}</strong>-->
  </a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">

    <button mat-button style="font-size: 15px; color: aliceblue" routerLink="/dashboardOne">
      <span class="mat-button-wrapper"> 
        <mat-icon
            class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">home
        </mat-icon>
      </span>
    </button>
    <button mat-button [matMenuTriggerFor]="menuDatahealth" style="font-size: 15px; color: aliceblue">
      <span class="mat-button-wrapper"> Datahealth <mat-icon
          class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">arrow_drop_down
        </mat-icon></span>
    </button>
    <mat-menu #menuDatahealth="matMenu" xPosition="after">
      <button mat-menu-item routerLink="/editarRpe">Edição de RPE</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/importarExcel">Importar Planilha - Cont. Auto.</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/editarParametro">Manutenção de Parâmentros</button>
    </mat-menu>

    <!-- [[ wpenha ]] menu conciliacao -->
    <button mat-button [matMenuTriggerFor]="menuConciliacao" style="font-size: 15px; color: aliceblue">
      <span class="mat-button-wrapper"> Conciliação <mat-icon
          class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">arrow_drop_down
        </mat-icon></span>
    </button>
    <mat-menu #menuConciliacao="matMenu" xPosition="after">
      <button mat-menu-item [matMenuTriggerFor]="conciliation">Conciliação</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/manutencao-tarifa">Manutenção de Tarifa</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/dashboard-conciliation">Dashboard</button>
      <mat-divider></mat-divider>
    </mat-menu>

    <!-- [[ MENU CONCILIACAO ]] -->
    <mat-menu #conciliation="matMenu">
      <button mat-menu-item routerLink="/manutencaolayout">Manutenção de Layout</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/importarConciliacao">Importação de Planilhas para Conciliação</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/gerarConciliacao">Geração de Conciliação</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/export-conciliation">Relatório de Aeroportos Conciliados</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/imported-spreadsheet-table">Tabela de Planilhas Importadas</button>
      <mat-divider></mat-divider>
    </mat-menu>


    <!-- [[ MENU GTE CLOUD ]] -->
    <button mat-button [matMenuTriggerFor]="gtecloud" style="font-size: 15px; color: aliceblue">
      <span class="mat-button-wrapper"> GTE Cloud <mat-icon
          class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">arrow_drop_down
        </mat-icon></span>
    </button>
    
    <mat-menu #gtecloud="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="gteCloudCadastros">Cadastros</button>
      <mat-divider></mat-divider>
      <button mat-menu-item [matMenuTriggerFor]="gteCloudGeneratedSearch">Consultas</button>
      <mat-divider></mat-divider>
      <button mat-menu-item [matMenuTriggerFor]="gteCloudInformes">Relatórios</button>
      <mat-divider></mat-divider>   
      <button mat-menu-item [matMenuTriggerFor]="gteCloudGenerated">Geração</button>
      <mat-divider></mat-divider>         
      <mat-divider></mat-divider>   
      <button mat-menu-item [matMenuTriggerFor]="gteCloudExclusion">Exclusão</button>
      <mat-divider></mat-divider> 
    </mat-menu>

    <mat-menu #gteCloudGenerated="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="generatedBy">Geração Manual Síncrona</button>
      <button mat-menu-item [matMenuTriggerFor]="generatedByAsnic">Geração Manual Assíncrona</button>
      <mat-divider></mat-divider>
    </mat-menu>

    <mat-menu #gteCloudCadastros="matMenu">
      <button mat-menu-item routerLink="/administrator-airport">Administradoras de Aeroportos</button>
      <mat-divider></mat-divider>
    </mat-menu>

    <mat-menu #gteCloudInformes="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="gteCloudInformeAnac">Relatórios ANAC</button>
      <mat-divider></mat-divider>
      <button mat-menu-item [matMenuTriggerFor]="gteCloudInformeResumido">Relatórios Resumidos</button>
      <mat-divider></mat-divider>
    </mat-menu>

    <mat-menu #gteCloudGeneratedSearch="matMenu">
      <button mat-menu-item routerLink="/rpe/report-summary/rpe">Consulta de RPE(s)</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/rpe/report-summary/stamp">Consulta de SELO(s)</button>
      <mat-divider></mat-divider>
    </mat-menu>
    
    <mat-menu #generatedBy="matMenu">
      <button mat-menu-item routerLink="/generated-files/rpe">RPE</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/generated-files/stamp">SELO</button>
      <mat-divider></mat-divider>
    </mat-menu>

    <mat-menu #generatedByAsnic="matMenu">
      <button mat-menu-item routerLink="/generated-files-async/rpe">RPE</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/generated-files-async/stamp">SELO</button>
      <mat-divider></mat-divider>
    </mat-menu>

    <mat-menu #gteCloudInformeAnac="matMenu">
      <button mat-menu-item routerLink="/report/anac-bagage">ANAC - Bagagens</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/report/anac-inspac">ANAC - INSPAC</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/report/anac-pnae">ANAC - Passageiros PNAE</button>
      <mat-divider></mat-divider>
    </mat-menu>

    <mat-menu #gteCloudInformeResumido="matMenu">
      <button mat-menu-item routerLink="/report/resume-boarding">Resumo de Embarque</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/report/resume-pax-boarding">Resumo de Passageiros Embarcados</button>
      <mat-divider></mat-divider>
    </mat-menu>

    <mat-menu #gteCloudExclusion="matMenu">
      <button mat-menu-item routerLink="/exclusionSeloRPE">Excluir RPE e SELO</button>
      <mat-divider></mat-divider>
    </mat-menu>

     <!-- [[ MENU Administrado de Acesso ]] -->
     <button mat-button [matMenuTriggerFor]="menuAcesso" style="font-size: 15px; color: aliceblue">
      <span class="mat-button-wrapper"> Acesso <mat-icon
          class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">arrow_drop_down
        </mat-icon></span>
    </button>

    <mat-menu #menuAcesso="matMenu">
      <button mat-menu-item routerLink="/administratorUser">Administrar Acessos</button>
      <mat-divider></mat-divider>
    </mat-menu>

    
  </div>

    <!-- [[ MENU SAIR ]] -->
    <button mat-button [matMenuTriggerFor]="userMenu" style="font-size: 15px; color: aliceblue; float: right;">
      <span class="mat-button-wrapper mat-button-base"> {{user.name}} <mat-icon
          class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">arrow_drop_down
        </mat-icon></span>
    </button>
    <mat-menu #userMenu="matMenu">
      <button mat-menu-item (click)="logoutAplication()">
        <mat-icon
          class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">exit_to_app
        </mat-icon>
        Sair
      </button>
      <mat-divider></mat-divider>
    </mat-menu>
  
</nav>
