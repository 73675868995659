import { Component, OnInit, Input } from '@angular/core';
import { TotalizadoresService } from './app-totalizers.service';
import { Totalizador } from './app-totalizers';

@Component({
  selector: 'app-totalizers',
  templateUrl: './app-totalizers.component.html',
  styleUrls: ['./app-totalizers.component.css']
})
export class TotalizadoresComponent implements OnInit {

  totalizadorGerado: Totalizador = { quantidade: 0 };
  totalizadorWorkAround: Totalizador = { quantidade: 0 };
  totalizadorNaoGerado: Totalizador = { quantidade: 0 };

  constructor(private totalizadorService: TotalizadoresService) {
  }

  ngOnInit() {
    this.obterTotalizadorGerado();
    this.obterTotalizadorWorkAround();
    this.obterTotalizadorNaoGerado();
  }

  public obterTotalizadorGerado(): void {
    this.totalizadorService.obterTotalizadorGerado().subscribe(response => this.totalizadorGerado = response);
  }

  public obterTotalizadorWorkAround(): void {
    this.totalizadorService.obterTotalizadorWorkAround().subscribe(response => this.totalizadorWorkAround = response);
  }

  public obterTotalizadorNaoGerado(): void {
    this.totalizadorService.obterTotalizadorNaoGerado().subscribe(response => this.totalizadorNaoGerado = response);
  }

}
