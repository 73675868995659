import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { RequestListContestation } from './model/request-list-contestation';
import { ContestationList } from './model/contestation-list';
import { Observable } from 'rxjs';
import { endpoints } from '../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppDataContestationListService {

  constructor(private http: HttpClient) { }

  getDadosVoo(request: RequestListContestation): Observable<ContestationList[]> {
    let params1 = new HttpParams().set('dtDispute', request.dtVoo);
    let params2 = new HttpParams().set('type', request.type);
    //console.log("URL: ", endpointsREST_GET_LIST_CONTESTATION+"?"+params1+"&"+params2);
    return this.http.get<ContestationList[]>(endpoints.REST_GET_LIST_CONTESTATION+"?"+params1+"&"+params2, httpOptions); 
  }

}
