import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogCustomComponent } from "../app-datahealth/app-dialog-custom/app-dialog-custom.component";

@Injectable({
  providedIn: 'root', // <---- Adiciona isto ao serviço
})

export class OpenDialog {

  constructor(public dialog: MatDialog,) { }

  openDialog(titleParam: string, messageParam: string): void {
     this.dialog.open(DialogCustomComponent, {
      width: '450px',
      data: { title: titleParam, message: messageParam }
    });

  }
}
