import { TicketControl } from './embedded/ticket-control';

export class TicketEdit {

    public collectionIdTicketControl: number[];
    public ticketControlDTO: TicketControl;


    constructor(){
        this.ticketControlDTO = undefined;
    }
}
