import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeListDashboardComponent } from './app-datahealth/app-home-dashboard-list/app-home-dashboard-list.component';
import { SecondLevelDashboardComponent } from './app-datahealth/app-second-level-dashboard/app-second-level-dashboard.component';
import { ThirdLevelDashboardComponent } from './app-datahealth/app-third-level-dashboard/app-third-level-dashboard.component';
import { HomeListaIndicadoresComponent } from './app-datahealth/app-home-indicators-list/app-home-indicators-list.component';
import { AppLoginComponent } from './app-login/app-login.component';
import { AuthFilter } from './app-authenticator/auth.filter';
import { HomeEditarRpeComponent } from './app-datahealth/app-home-edit-rpe/app-home-edit-rpe.component';
import { AppDataContestationListComponent } from './app-datahealth/app-data-contestation-list/app-data-contestation-list.component';
import { AppUploadFileComponent } from './app-datahealth/app-upload-file/app-upload-file.component';
import { ImportComponent } from './app-conciliation/conciliation/component/import/import.component';
import { ImportConfirmationComponent } from './app-conciliation/conciliation/component/import-confirmation/import-confirmation.component';
import { AppConciliationLayoutComponent } from './app-conciliation/app-conciliation-layout/app-conciliation-layout.component';
import { AppSecondLevelDashboardWorkaroundComponent } from './app-datahealth/app-second-level-dashboard-workaround/app-second-level-dashboard-workaround.component';
import { AppThirdLevelDashboardWorkaroundComponent } from './app-datahealth/app-third-level-dashboard-workaround/app-third-level-dashboard-workaround.component';
import { AppSecondLevelDashboardRpeGeneratedComponent } from './app-datahealth/app-second-level-dashboard-rpe-generated/app-second-level-dashboard-rpe-generated.component';
import { AppThirdLevelDashboardRpeGeneratedComponent } from './app-datahealth/app-third-level-dashboard-rpe-generated/app-third-level-dashboard-rpe-generated.component';
import { AppSecondLevelDashboardStampComponent } from './app-datahealth/app-second-level-dashboard-stamp/app-second-level-dashboard-stamp.component';
import { AppFormParameterComponent } from './app-datahealth/app-form-parameter/app-form-parameter.component';
import { AppGenerateConciliationComponent } from './app-conciliation/app-generate-conciliation/app-generate-conciliation.component';
import { TabAirpotTaxComponent } from './app-conciliation/conciliation/component/tab-airpot-tax/tab-airpot-tax.component';
import { ExportConciliationComponent } from './app-conciliation/conciliation/component/export-conciliation/export-conciliation.component';
import { ConciliationDashboardComponent } from './app-conciliation/conciliation/component/conciliation-dashboard/conciliation-dashboard.component';
import { DashboardFinalComponent } from './app-conciliation/conciliation/component/conciliation-dashboard/dashboard-final/dashboard-final.component';
import { ImportedSpreadsheetTableComponent } from './app-conciliation/conciliation/component/imported-spreadsheet-table/imported-spreadsheet-table.component';
import { AppAdminsitratorAirportComponent } from './app-gte/app-administrator-airport/app-administrator-airport.component';
import { AppReportsComponent } from './app-gte/app-reports/app-reports.component';
import { AppGeneratedManualFilesComponent } from './app-gte/app-generated-manual-files/app-generated-manual-files.component';
import { RpeResumeFileComponent } from './app-gte/rpe-resume-file/rpe-resume-file.component';
import { AppRpeReportSummaryComponent } from './app-gte/app-flight-report-summary/app-rpe-report-summary.component';
import { AppAdministratorComponent } from './app-access/app-access-administrator/app-administrator/app-administrator.component';
import { ProfileGuard } from './app-authenticator/guard/profile.guard';
import { AppExclusionRpeSeloComponent } from './app-gte/app-exclusion/app-exclusion-rpe-selo.component';
import { AppGeneratedManualFilesAsyncComponent } from './app-gte/app-generated-manual-files-async/app-generated-manual-files-async.component';

const routes: Routes = [

  { path: '', redirectTo: 'dashboardOne', pathMatch: 'full', canActivate: [AuthFilter] },
  { path: 'login', component: AppLoginComponent },
  { path: 'dashboardOne', component: HomeListDashboardComponent, canActivate: [AuthFilter] },
  { path: 'dashboardTwo/:date', component: SecondLevelDashboardComponent, canActivate: [AuthFilter] },
  { path: 'dashboardTre/:date/:sys', component: ThirdLevelDashboardComponent, canActivate: [AuthFilter] },
  { path: 'listaIndicadores/:tipo-lista', component: HomeListaIndicadoresComponent, canActivate: [AuthFilter] },
  { path: 'listaIndicadores/:dt-ini/:dt-fim/:tipo-lista', component: HomeListaIndicadoresComponent, canActivate: [AuthFilter] },
  { path: 'editarRpe', component: HomeEditarRpeComponent, canActivate: [AuthFilter]  },
  { path: 'listContestation/:date/:type', component: AppDataContestationListComponent, canActivate: [AuthFilter] },
  { path: 'importarExcel', component: AppUploadFileComponent, canActivate: [AuthFilter] },
  { path: 'importarConciliacao', component: ImportComponent, canActivate: [AuthFilter] },
  { path: 'confirmarImportacao', component: ImportConfirmationComponent, canActivate: [AuthFilter] },
  { path: 'dashboardTwoWorkaround/:date', component: AppSecondLevelDashboardWorkaroundComponent, canActivate: [AuthFilter] },
  { path: 'dashboardTreWorkaround/:date/:sys', component: AppThirdLevelDashboardWorkaroundComponent, canActivate: [AuthFilter] },
  { path: 'dashboardRpeGeneratedPizza/:date/:type', component: AppSecondLevelDashboardRpeGeneratedComponent, canActivate: [AuthFilter] },
  { path: 'dashboardTreRpeGenerated/:date/:sys', component: AppThirdLevelDashboardRpeGeneratedComponent, canActivate: [AuthFilter] },
  { path: 'dashboardTwoStamp/:date', component: AppSecondLevelDashboardStampComponent, canActivate: [AuthFilter] },
  { path: 'editarParametro', component: AppFormParameterComponent, canActivate: [AuthFilter] },
  { path: 'manutencaolayout', component: AppConciliationLayoutComponent, canActivate: [AuthFilter] },
  { path: 'dashboard-conciliation', component: ConciliationDashboardComponent, canActivate: [AuthFilter]},
  { path: 'dashboard-final/:year/:month', component: DashboardFinalComponent, canActivate: [AuthFilter]},
  { path: 'manutencao-tarifa', component: TabAirpotTaxComponent, canActivate: [AuthFilter]},
  { path: 'gerarConciliacao', component: AppGenerateConciliationComponent, canActivate: [AuthFilter]},
  { path: 'export-conciliation', component: ExportConciliationComponent, canActivate: [AuthFilter]},
  { path: 'imported-spreadsheet-table', component: ImportedSpreadsheetTableComponent, canActivate: [AuthFilter]},
  { path: 'administrator-airport', component: AppAdminsitratorAirportComponent, canActivate: [AuthFilter]},
  { path: 'report/:typeReport', component: AppReportsComponent, canActivate: [AuthFilter]},
  { path: 'generated-files/:typeSerach', component: AppGeneratedManualFilesComponent, canActivate: [AuthFilter]},

  { path: 'generated-files-async/:typeSerach', component: AppGeneratedManualFilesAsyncComponent, canActivate: [AuthFilter]},

  { path: 'rpe/report-summary/:typeSerach', component: AppRpeReportSummaryComponent, canActivate: [AuthFilter]},
  { path: 'rpe/resume-file/:id', component: RpeResumeFileComponent, canActivate: [AuthFilter]},
  { path: 'administratorUser', component: AppAdministratorComponent, canActivate: [AuthFilter, ProfileGuard]},
  { path: 'exclusionSeloRPE', component: AppExclusionRpeSeloComponent, canActivate: [AuthFilter, ProfileGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
