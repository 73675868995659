import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Voo } from './model/voo';
import { VooRequisicao } from './model/vooRequisicao';
import { endpoints } from '../../../environments/endpoint';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppDataRpeGeneratedListService {

  constructor(private http: HttpClient) { }

  getDadosVoo(voo: VooRequisicao): Observable<Voo[]> {

    if (voo.sistOrigin == "Decolagem") {
      voo.sistOrigin = "D"
    } else {
      voo.sistOrigin = "A"
    }

    let params1 = new HttpParams().set('flightBeginDate', voo.dtInicio);
    let params2 = new HttpParams().set('flightEndDate', voo.dtFim);
    let params3 = new HttpParams().set('type', voo.sistOrigin);

    return this.http.get<Voo[]>(endpoints.REST_GET_RPE_GENERATED_LIST + "?" + params1 + "&" + params3, httpOptions);
  }

}
