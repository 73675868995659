import { Component, OnInit } from '@angular/core';
import { ParametersEdit } from './model/parameters-edit';
import { AppFormParameterService } from './app-form-parameter.service';
import { Protocolo } from '../app-enum/Protocolo';
import { ParametersRequest } from './model/parameters-request';
import { Status } from './model/status';
import ProtocoloChamado from '../app-enum/protocolo-chamado';
import { MatDialog } from '@angular/material/dialog';
import { DialogCustomComponent } from '../app-dialog-custom/app-dialog-custom.component';
import { properties } from '../../../environments/properties';


@Component({
  selector: 'app-form-parameter',
  templateUrl: './app-form-parameter.component.html',
  styleUrls: ['./app-form-parameter.component.css']
})
export class AppFormParameterComponent implements OnInit {

  public parametersMulta: ParametersEdit;
  public parametersDias: ParametersEdit;
  public parameterRequest: ParametersRequest;
  public status: Status;
  public mensagemResposta: string;

  constructor(public parameterService: AppFormParameterService,
    public dialog: MatDialog) {
    this.parametersMulta = new ParametersEdit();
    this.parametersDias = new ParametersEdit();
    this.parameterRequest = new ParametersRequest();
  }

  ngOnInit() {
    this.obterParametros();
  }

  private obterParametros(): void {
    this.parameterService.getParameters().subscribe(
      dataSource => {

        if(dataSource[1].nameParameter == "VL_MULTA"){
          this.parametersMulta.id = dataSource[1].id;
          this.parametersMulta.nameParameter = dataSource[1].nameParameter;
          this.parametersMulta.valueParameter = dataSource[1].valueParameter;
        }

        if(dataSource[0].nameParameter == "QTD_DIAS_MULTA"){
        this.parametersDias.id = dataSource[0].id;
        this.parametersDias.nameParameter = dataSource[0].nameParameter;
        this.parametersDias.valueParameter = dataSource[0].valueParameter;
        }

      },
      err => {
        const protocolo: Protocolo = err.error;
      });
  }

  public alterarParametros(): void {

    // console.log("Valor Multa com mascara: ", this.parametersMulta.value);
    if(this.parametersDias.valueParameter == undefined || this.parametersMulta.valueParameter == undefined ){
      this.openDialogFormPa(
        properties.MODAL_TITLE_PERIOD_SELECT_ATENCION,
          properties.MODAL_CONTENT_PARAMETER_NULL
      );
      return;
    }

    let arrayList = new Array();

    arrayList.push(this.parametersMulta);
    arrayList.push(this.parametersDias);

    this.parameterRequest.parameterEdit = arrayList;

    this.parameterService.editParameter(this.parameterRequest).subscribe(
      dataSource => {
        const protocolo: Protocolo = dataSource;
        this.validarStatusCadastroChamado(protocolo);
        this.status = Status.SUCESSO;
        this.openDialogFormPa(
          properties.MODAL_TITLE_SUCESS,
            properties.MODAL_CONTENT_SUCESS_PARAM_EDIT
        );

      },
      err => {

        const protocolo: Protocolo = err.error;
        this.validarStatusCadastroChamado(protocolo);
        this.status = Status.ERRO;

      });

      this.mensagemResposta = properties.MODAL_CHAMADO_MENSAGEM_CARREGANDO_PARAMETRO;
      this.status = Status.SEM_INFORMACOES;

  }


  validarStatusCadastroChamado(protocolo: Protocolo): void {
    switch (protocolo.status) {
      case ProtocoloChamado.CONTROLE_SALVO:
        this.mensagemResposta = 'Parametro alterado com sucesso.';
        break;
      case ProtocoloChamado.DATAHEALTH_NAO_ENCONTRADO:
        this.mensagemResposta = 'Parametro informado não encontrado/inexistente.';
        break;
      case ProtocoloChamado.REQUEST_INVALIDO:
        this.mensagemResposta = 'Dados informados invalidos.';
      default:
        this.mensagemResposta = protocolo.descricao;
        break;
    }
  }

  public openDialogFormPa(titleParam: string, messageParam: string): void {
    const dialogRef = this.dialog.open(DialogCustomComponent, {
        width: '450px',
        data: {title: titleParam, message: messageParam}
    });
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

}
