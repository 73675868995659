<div style="display: inline-block;">

  <div class="indicadores indicadorInformativo">
      <pre>Informativo</pre>
      <button 
        type="button" 
        (click)="getIdRequestInformativo('informativo')" 
        class="btn btn-primary indicador-chamado-informativo btnIndicadorChamado" 
        ngbTooltip="Indicador de RPE's informativos">{{indicadorInformativo.quantidade}}
      </button>
      <img src="../../assets/dash-cinza-warning.png" alt="">
  </div>

  <div class="indicadores indicadorIntermediario">
      <pre>Intermediário</pre>
      <button type="button" 
        (click)="getIdRequestIntermediario('intermediario')" 
        class="btn btn-primary indicador-chamado-intermediario btnIndicadorChamado" 
        ngbTooltip="Indicador de RPE's intermediarios">{{indicadorIntermediario.quantidade}}
      </button>
      <img src="../../assets/dash-cinza-warning.png" alt="">
  </div>

  <div class="indicadores indicadorCritico">
      <pre>Crítico</pre>
      <button 
        type="button" 
        (click)="getIdRequestCritico('critico')" 
        class="btn btn-primary indicador-chamado-critico btnIndicadorChamado" 
        ngbTooltip="Indicador de RPE's criticos">{{indicadorCritico.quantidade}}
      </button>
      <img src="../../assets/dash-cinza-warning.png" alt="">
    </div>

</div>
