import { Administrator } from './Administrator';
import { Airport } from './Airport';

export class  AirportAdministrator {

    administrator: Administrator;
    airports: Airport[];
    airportsError: Airport[];
    information: string;
    status: number;

}
