import { Profile } from './Profile';

export class  User {

    id: number;
    name: string;
    email: string;
    perfil: string;
    status: string;
    profile: Profile;

}